import React from "react";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { resetSortsOnly } from "../../helpers/utils_validation";
// COMPONENTS
import ModalLG from "../shared/ModalLG";
import ReportFilterPicker from "../reportpicker/ReportFilterPicker";
import ReportFilterOptions from "../reportpicker/ReportFilterOptions";
import ReportShiftPicker from "../reportpicker/ReportShiftPicker";
import ReportDateHandler from "../reports/ReportDateHandler";
import ReportDatePicker from "../reportpicker/ReportDatePicker";
import ReportSortPicker from "../reportpicker/ReportSortPicker";
import ServicePlanPickerController from "./ServicePlanPickerController";

/**
 * Purpose: acts as a 'wrapper' around all form handlers, UI & configuration of inputs.
 *
 * Allows custom number of the following:
 * - 'filters': Selects the dataset.
 * - 'filterOptions': Specifies the subset of a previously selected dataset.
 * - 'sorts': Determines *how* the data is organized & presented.
 * - 'sortOptions': Determines the specfic order the data is organized in.
 */

export const getSort = (settings) => {
	if (isEmptyVal(settings.sortBy)) return "";
	const { sortBy } = settings;
	const trimmed = sortBy.replace(" ", "");
	return `sort${trimmed}`;
};

const ServicePlanPicker = ({
	reportName,
	formState,
	setFormState,
	handleCheckbox,
	handleReset,
	showPicker,
	closeModal,
	createReport,
	cancelReport,
	reportTypeOpts = [`Daily`, `Historical`],
	fieldsToValidate = [],
	currentFacility = {},
	// CONFIG
	dateRangeRestrictions = {},
	config = {},
}) => {
	const { values } = formState;
	// custom dropdown handler
	const handleSettings = (name, val) => {
		return setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};
	// multi-radio selection (uses 'id')
	const handleFilters = (val) => {
		return setFormState({
			...formState,
			values: {
				...values,
				filterBy: val,
			},
		});
	};
	// handles settings.sortBy
	const handleSorts = (val) => {
		return setFormState({
			...formState,
			values: {
				...values,
				sortBy: val,
			},
		});
	};
	// handles sortByXXXX radios
	const handleSortOptions = (val) => {
		return setFormState({
			...formState,
			values: {
				...values,
				[getSort(values)]: val,
			},
		});
	};
	// handle date-range picker
	const handleDateRange = ({ startDate, endDate }) => {
		return setFormState({
			...formState,
			values: {
				...values,
				startDate,
				endDate,
			},
		});
	};
	// remove sorting
	const removeSorting = (e) => {
		const resetSettings = resetSortsOnly({ ...values });
		return setFormState({
			...formState,
			values: {
				...values,
				...resetSettings,
			},
		});
	};

	return (
		<>
			{showPicker && (
				<ModalLG title={`${reportName}: Settings`} closeModal={closeModal}>
					<ServicePlanPickerController
						settings={values}
						handleFilters={handleFilters}
						handleCheckbox={handleCheckbox}
						handleSettings={handleSettings}
						handleDateRange={handleDateRange}
						handleReset={handleReset}
						reportTypeOpts={reportTypeOpts}
						reportName={reportName}
						createReport={createReport}
						cancelReport={cancelReport}
						fieldsToValidate={fieldsToValidate}
					>
						<ReportFilterPicker
							filters={config?.[values.reportType]?.filters}
						/>
						<ReportFilterOptions
							filterOptions={config?.[values.reportType]?.filterOptions}
							handleFilterOptions={handleSettings}
						/>

						<ReportShiftPicker
							settings={values}
							shifts={config?.[values.reportType]?.shiftOptions}
							handleShifts={handleCheckbox}
						/>

						<ReportDatePicker settings={values}>
							<ReportDateHandler
								settings={values}
								handleSettings={handleSettings}
								handleDateRange={handleDateRange}
								restrictions={dateRangeRestrictions}
							/>
						</ReportDatePicker>

						{/* FORM SECTIONS GO HERE - SORTS */}
						<ReportSortPicker
							sorts={config?.[values.reportType]?.sorts}
							sortOptions={config?.[values.reportType]?.sortOptions}
							handleSortBy={handleSorts}
							handleSortOptions={handleSortOptions}
							removeSorting={removeSorting}
						/>
					</ServicePlanPickerController>
				</ModalLG>
			)}
		</>
	);
};

export default ServicePlanPicker;

ServicePlanPicker.defaultProps = {
	config: {},
	fieldsToValidate: [],
	dateRangeRestrictions: {
		isActive: false,
		rangeStart: "",
		rangeEnd: "",
	},
};

ServicePlanPicker.propTypes = {
	reportName: PropTypes.string.isRequired,
	formState: PropTypes.object.isRequired,
	setFormState: PropTypes.func.isRequired,
	handleCheckbox: PropTypes.func.isRequired,
	handleReset: PropTypes.func.isRequired,
	showPicker: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
	config: PropTypes.object.isRequired,
	fieldsToValidate: PropTypes.array.isRequired,
	dateRangeRestrictions: PropTypes.shape({
		isActive: PropTypes.bool,
		rangeStart: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.instanceOf(Date),
		]),
		rangeEnd: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.instanceOf(Date),
		]),
	}),
};
