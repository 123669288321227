import React, { useState } from "react";
import styles from "../../css/calendar/Shift.module.scss";
import { PropTypes } from "prop-types";
// HELPERS/UTILS
import { isEmptyArray } from "../../helpers/utils_types";
import { groupTasksByStatus } from "../../helpers/utils_tasks";
import { format } from "date-fns";
// COMPONENTS
import ModalLG from "../shared/ModalLG";
import ADLShiftDetails from "./ADLShiftDetails";
import ShiftStatusBadge from "./ShiftStatusBadge";

const hasTasks = (tasks) => {
	if (isEmptyArray(tasks)) return false;
	return true;
};

const getSelectedTask = (tasks = []) => {
	if (isEmptyArray(tasks)) return [];
	return tasks[0];
};

const Shift = ({
	shift,
	adl,
	day,
	tasks = [],
	currentUser = {},
	currentResident = {},
	currentFacility = {},
}) => {
	const [showShiftDetails, setShowShiftDetails] = useState(false);
	const [selected, setSelected] = useState({
		selectedTask: getSelectedTask(tasks),
		selectedTaskIndex: 1,
		allTasks: tasks,
	});
	const { shifts } = currentFacility;

	const modalHandler = (tasks) => {
		setShowShiftDetails(true);
		setSelected({
			selectedTask: getSelectedTask(tasks),
			selectedTaskIndex: 1,
			allTasks: tasks,
		});
	};

	const changeSelected = (task, taskIndex) => {
		setSelected({
			...selected,
			selectedTask: task,
			selectedTaskIndex: taskIndex,
		});
	};

	// handles clicking 1 of the status badges and populating "selected"...
	// based off the status
	const selectByStatus = (tasks) => {
		setSelected({
			...selected,
			selectedTask: getSelectedTask(tasks),
			allTasks: tasks,
		});
	};

	const { pastDue, notComplete, exceptions, complete } = groupTasksByStatus(
		tasks,
		day,
		shifts
	);

	return (
		<>
			<button
				className={hasTasks(tasks) ? styles.Shift : styles.Shift_noTasks}
				title={`${adl}__${shift}__${format(day, "ddd")}`}
				onClick={() => modalHandler(tasks)}
			>
				<span className={styles.Shift_label}>{shift}</span>

				{/* {hasTasks(tasks) && ( */}
				<>
					{hasTasks(complete) && (
						<ShiftStatusBadge
							status={`COMPLETE`}
							tasks={complete}
							count={complete?.length ?? 0}
							key={`STATUS_COMPLETE`}
							selectByStatus={selectByStatus}
						/>
					)}

					{hasTasks(notComplete) && (
						<ShiftStatusBadge
							status={`NOT-COMPLETE`}
							tasks={notComplete}
							count={notComplete?.length ?? 0}
							key={`STATUS_NOT-COMPLETE`}
							selectByStatus={selectByStatus}
						/>
					)}
					{hasTasks(pastDue) && (
						<ShiftStatusBadge
							status={`PAST-DUE`}
							tasks={pastDue}
							count={pastDue?.length ?? 0}
							key={`STATUS_PAST-DUE`}
							selectByStatus={selectByStatus}
						/>
					)}
					{hasTasks(exceptions) && (
						<ShiftStatusBadge
							status={`EXCEPTION`}
							tasks={exceptions}
							count={exceptions?.length ?? 0}
							key={`STATUS_EXCEPTION`}
							selectByStatus={selectByStatus}
						/>
					)}
				</>
				{/* )} */}
			</button>

			{showShiftDetails && (
				<ModalLG
					title={`${adl} Shift Details`}
					closeModal={() => setShowShiftDetails(false)}
				>
					<ADLShiftDetails
						adl={adl}
						shift={shift}
						day={day}
						selectedTask={selected.selectedTask} // specific task, OR defaults to 1st task if no status is selected
						selectedTaskIndex={selected.selectedTaskIndex}
						allTasksForShift={selected.allTasks} // every task for this shift
						changeSelected={changeSelected}
						currentResident={currentResident}
						currentFacility={currentFacility}
						currentUser={currentUser}
					/>
				</ModalLG>
			)}
		</>
	);
};

export default Shift;

Shift.defaultProps = {
	currentFacility: {},
	currentResident: {},
	currentUser: {},
	tasks: [],
};

Shift.propTypes = {
	currentResident: PropTypes.object.isRequired,
	currentUser: PropTypes.object.isRequired,
	currentFacility: PropTypes.object.isRequired,
	tasks: PropTypes.array,
	shift: PropTypes.string.isRequired,
	adl: PropTypes.string.isRequired,
	day: PropTypes.instanceOf(Date).isRequired,
};
