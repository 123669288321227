import React from "react";
import styles from "../../css/notes/TaskNoteActions.module.scss";
import { PropTypes } from "prop-types";
import { enableFeatureViaInternalList } from "../../helpers/utils_features";

const EditButton = ({ initEdit }) => {
	return (
		<button type="button" onClick={initEdit} className={styles.EditButton}>
			Edit
		</button>
	);
};
const StrikeOutButton = ({ isStrikeOut, initStrikeOut }) => {
	return (
		<button
			type="button"
			onClick={initStrikeOut}
			className={styles.StrikeOutButton}
		>
			{!isStrikeOut ? "Strike-Out" : "Undo Strike-Out"}
		</button>
	);
};
const DeleteButton = ({ initDelete }) => {
	return (
		<button type="button" onClick={initDelete} className={styles.DeleteButton}>
			Delete
		</button>
	);
};

const TaskNoteActions = ({
	vals = {},
	currentUser = {},
	initEdit,
	initStrikeOut,
	initDelete,
}) => {
	return (
		<div className={styles.TaskNoteActions}>
			<div className={styles.TaskNoteActions_inner}>
				<EditButton initEdit={initEdit} />
				<div className={styles.TaskNoteActions_inner_bullet}>•</div>
				<StrikeOutButton
					isStrikeOut={vals?.isStrikeOut}
					initStrikeOut={initStrikeOut}
				/>
				<div className={styles.TaskNoteActions_inner_bullet}>•</div>
				{enableFeatureViaInternalList(currentUser?.userID) && (
					<>
						<DeleteButton initDelete={initDelete} />
					</>
				)}
			</div>
		</div>
	);
};

export default TaskNoteActions;

TaskNoteActions.defaultProps = {};

TaskNoteActions.propTypes = {};
