import React from "react";
import styles from "../../css/daily/DailySummaryCardBottom.module.scss";
import { PropTypes } from "prop-types";
import { themeColors } from "../../helpers/utils_styles";
import CheckboxSM from "../shared/CheckboxSM";

const DailySummaryCardBottom = ({
	completeAll,
	isCompleted = false,
	handleMarkComplete,
	enableMarkAllComplete = false,
	category = {},
	children,
}) => {
	return (
		<section className={styles.DailySummaryCardBottom}>
			{/* MARK ALL COMPLETE (BY ADL) */}
			{enableMarkAllComplete && (
				<CheckboxSM
					val={completeAll || isCompleted}
					name={`mark${category.AdlCategoryType}Complete`}
					id={`mark${category.AdlCategoryType}Complete`}
					label="Mark All Complete"
					customTxt={{ color: themeColors.main.green }}
					handleCheckbox={handleMarkComplete}
				/>
			)}
			{children}
		</section>
	);
};

export default DailySummaryCardBottom;

DailySummaryCardBottom.defaultProps = {};

DailySummaryCardBottom.propTypes = {
	completeAll: PropTypes.bool,
	isCompleted: PropTypes.bool,
	handleMarkComplete: PropTypes.func,
	children: PropTypes.any,
};
