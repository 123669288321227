import React from "react";
import styles from "../../css/calendar/ShiftDetailsTaskList.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyObj } from "../../helpers/utils_types";
import ShiftDetailsTask from "./ShiftDetailsTask";
import { getShiftName } from "../../helpers/utils_shifts";
import { getTaskIdType } from "../../helpers/utils_tasks";
import { generateID } from "../../helpers/utils_processing";

// checks if the current task in the list matches the selectedTask record
const isSelected = (selectedTask, task) => {
	if (isEmptyObj(selectedTask)) return false;
	if (selectedTask[getTaskIdType(selectedTask)] === task[getTaskIdType(task)]) {
		return true;
	} else {
		return false;
	}
};

const ShiftDetailsTaskList = ({
	tasks = [],
	selectedTask = {},
	shift,
	adl,
	day,
	changeSelected,
	currentFacility = {},
}) => {
	if (isEmptyArray(tasks)) {
		return (
			<div className={styles.ShiftDetailsTaskList_empty}>
				<h2 className={styles.ShiftDetailsTaskList_empty_msg}>
					No {adl} tasks found for the {shift} shift
				</h2>
			</div>
		);
	}
	return (
		<div className={styles.ShiftDetailsTaskList}>
			<div className={styles.ShiftDetailsTaskList_top}>
				<h4 className={styles.ShiftDetailsTaskList_top_title}>
					Other {adl} tasks for the {shift} shift -
				</h4>

				<span className={styles.ShiftDetailsTaskList_top_text}>
					Click a task below for details
				</span>
			</div>
			{!isEmptyArray(tasks) &&
				tasks.map((task, index) => (
					<ShiftDetailsTask
						changeSelected={() => changeSelected(task, index + 1)}
						isSelected={isSelected(selectedTask, task)}
						day={day}
						task={task}
						taskIndex={index + 1}
						currentFacility={currentFacility}
						key={`${getShiftName(task?.AssessmentShiftId)}__${generateID(4)}`}
					/>
				))}
		</div>
	);
};

export default ShiftDetailsTaskList;

ShiftDetailsTaskList.defaultProps = {
	tasks: [],
	selectedTask: {},
};

ShiftDetailsTaskList.propTypes = {
	tasks: PropTypes.arrayOf(PropTypes.object),
	shift: PropTypes.string.isRequired,
	adl: PropTypes.string.isRequired,
	day: PropTypes.instanceOf(Date).isRequired,
	selectedTask: PropTypes.object.isRequired,
};
