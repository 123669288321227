import React, { useEffect, useRef } from "react";
import styles from "../../css/app/UpdateBanner.module.scss";
import { PropTypes } from "prop-types";
import UpdateButton from "./UpdateButton";
import { useOutsideClick } from "../../utils/useOutsideClick";

const UpdateBanner = ({ initUpdate, closeBanner }) => {
	const bannerRef = useRef();
	const { isOutside } = useOutsideClick(bannerRef);

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (isOutside) {
			closeBanner();
		}

		return () => {
			isMounted = false;
		};
	}, [closeBanner, isOutside]);

	return (
		<div className={styles.UpdateBanner} ref={bannerRef}>
			<div className={styles.UpdateBanner_inner}>
				<div className={styles.UpdateBanner_inner_content}>
					<div className={styles.UpdateBanner_inner_content_msg}>
						An Update is Available!
					</div>
				</div>
				<div className={styles.UpdateBanner_inner_button}>
					<UpdateButton refreshHandler={initUpdate} />
				</div>
			</div>
		</div>
	);
};

export default UpdateBanner;

UpdateBanner.defaultProps = {};

UpdateBanner.propTypes = {};
