import React, { useState } from "react";
import styles from "../../css/reportsviewer/ReportViewerTableHeading.module.scss";
import { PropTypes } from "prop-types";
import sprite from "../../assets/icons/carets-arrows.svg";

// rotates the 'down caret' when changing sorting
// when heading clicked
const isSort = {
	transform: "rotate(-180deg)",
	transition: "all .3s ease-in-out",
};

const ReportViewerTableHeading = ({ heading, handleHeading, sortType }) => {
	const [isSorted, setIsSorted] = useState(sortType === heading);

	return (
		<div
			className={styles.ReportViewerTableHeading}
			onClick={() => {
				setIsSorted(!isSorted);
				handleHeading(heading);
			}}
		>
			<div className={styles.ReportViewerTableHeading_heading}>
				<div className={styles.ReportViewerTableHeading_heading_text}>
					{heading}
				</div>
				<svg
					className={styles.ReportViewerTableHeading_heading_icon}
					style={isSorted ? isSort : {}}
				>
					<use xlinkHref={`${sprite}#icon-caret-down`}></use>
				</svg>
			</div>
		</div>
	);
};

export default ReportViewerTableHeading;

ReportViewerTableHeading.defaultProps = {
	isSorted: false,
};

ReportViewerTableHeading.propTypes = {
	isSorted: PropTypes.bool.isRequired,
	heading: PropTypes.string.isRequired,
	handleHeading: PropTypes.func.isRequired,
};
