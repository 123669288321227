import React, { useCallback, useState } from "react";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { useAlerts, initialSettings } from "../../utils/useAlerts";
import { useRecents } from "../../utils/useRecents";
import { useScrollPosition, config } from "../../utils/useScrollPosition";
import { useEffect } from "react";
import { sendPageTracking } from "../../helpers/utils_tracking";
import { mergeDailyResidentData } from "../../helpers/utils_residentData";
import { populateState } from "../../helpers/utils_initialResources";
import {
	getDailyReportDesc,
	getReportDescByType,
} from "../../helpers/utils_descriptions";
import {
	hasFacility,
	getFacilityDay,
	getCurrentFacilityStr,
	getCurrentResidentStr,
	formatAndSortFacilities,
} from "../../helpers/utils_facility";
import {
	getShiftIDsFromSettings,
	getDailyTaskCreated,
} from "../../helpers/utils_reports";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	taskCreatedCols as cols,
	getTaskCreatedRows,
} from "../../helpers/utils_table";
import {
	TASKCREATED_CONFIG,
	amendHistoricalConfig,
} from "../../helpers/utils_config";
import {
	formatAndSortResidents,
	getResidentObj,
} from "../../helpers/utils_residents";
import { format } from "date-fns";
import { updateRecentsModel } from "../../helpers/utils_recentlyViewed";
import { generateID } from "../../helpers/utils_processing";
import {
	getDateRangeTypeKey,
	getFilterByKey,
	getFields,
} from "../../helpers/utils_validation";
import {
	calculateRestrictionRange,
	getStartAndEndDates,
} from "../../helpers/utils_dates";
// COMPONENTS
import ReportTypeController from "../../components/reports/ReportTypeController";
import ConfigurablePicker from "../../components/reportpicker/ConfigurablePicker";
import ReportViewer from "../../components/reports/ReportViewer";
import ReportsViewerController from "../../components/reportsviewer/ReportsViewerController";

// ##TODOS:
// - Re-wire 'Recently Viewed' feature handlers
// - Double-check report picker validation is working properly

const checkForDateRange = (settings) => {
	if (settings?.dateRangeType === `Today`) return true;
	if (isEmptyVal(settings?.dateRangeType)) return false;
	return true;
};

const addDateRange = (settings) => {
	if (isEmptyVal(settings?.dateRangeType)) return false;
	if (settings?.dateRangeType === `Today`) return false;
	return true;
};

// initial input values
const initialValState = {
	reportType: "Daily",
	filterBy: "",
	filterByResident: "",
	filterByFacility: "",
	filterByADL: "",
	shiftAM: false,
	shiftPM: false,
	shiftNOC: false,
	dateRangeType: "",
	byQuarter: "",
	byMonth: "",
	byDate: "",
	byDateRange: "",
	startDate: "",
	endDate: "",
};

const TaskCreatedReport = ({
	globalState,
	currentUser,
	currentFacility,
	currentResident,
	residents = [],
	facilities = [],
	residentsByFacility = {},
	adlCategories = [],
	dispatch,
}) => {
	useScrollPosition(config);
	const { formState, setFormState, handleCheckbox, handleReset } = useForm({
		...initialValState,
	});
	const { cache, addItem, removeItem } = useRecents(
		"Recents-TaskCreatedReport",
		{
			recents: [],
			timestamp: Date.now(),
		}
	);
	const { dispatchAlert, AlertsHandler } = useAlerts({
		...initialSettings,
		expiry: 2000,
		msg: {
			heading: "Success!",
			subheading: `You've changed facilities`,
		},
	});
	const { values } = formState;

	const [selectedResident, setSelectedResident] = useState(
		getCurrentResidentStr(currentResident)
	);
	const [selectedFacility, setSelectedFacility] = useState(
		getCurrentFacilityStr(currentFacility)
	);

	const [facilityData, setFacilityData] = useState({});
	const [showReportPicker, setShowReportPicker] = useState(false);
	const [isLoadingReport, setIsLoadingReport] = useState(false);
	const [dailyReportData, setDailyReportData] = useState([]);
	// date-range restriction (from ALADVSystem)
	const [rangeRestriction, setRangeRestriction] = useState(
		calculateRestrictionRange(3)
	);

	const { shifts: shiftTimes } = currentFacility;

	const FILTER_OPTIONS = [
		{
			name: "filterByResident",
			id: "Resident",
			label: "Choose a resident",
			placeholder: "Resident...",
			options: [...formatAndSortResidents(currentFacility.residents)],
		},
		{
			name: "filterByFacility",
			id: "Facility",
			label: "Choose a facility",
			placeholder: "Facility...",
			options: [...formatAndSortFacilities(currentUser.facilities)],
		},
		{
			name: "filterByADL",
			id: "ADL",
			label: "Choose a ADL",
			placeholder: "ADL...",
			options: [...adlCategories],
		},
	];

	const getFieldsToValidate = (values) => {
		const fields = getFields(values, [
			`reportType`,
			`filterBy`,
			getFilterByKey(values),
			`dateRangeType`,
			getDateRangeTypeKey(values),
		]);
		return fields;
	};

	const getDailyReport = async () => {
		// ##TODOS:
		// - Add 'shiftIDs' to request, once Jose's added this feature.
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const shiftIDs = getShiftIDsFromSettings(values); // STILL NOT ENABLED IN ALA SERVICES
		const { startDate, endDate } = getFacilityDay(shiftTimes);

		const reportData = await getDailyTaskCreated(
			token,
			facilityID,
			shiftIDs,
			new Date(startDate).toISOString(),
			new Date(endDate).toISOString()
		);

		const recentsModel = updateRecentsModel({
			id: generateID(3),
			type: `DailyTaskCreatedReport`,
			name: `Daily Task Created Report`,
			desc: getDailyReportDesc(values, `Task Created`),
			dateCreated: format(new Date(), "M/D/YYYY h:mm A"),
			data: { ...values },
		});
		addItem(recentsModel);

		if (!isEmptyArray(reportData)) {
			setIsLoadingReport(false);
			return setDailyReportData([...reportData]);
		} else {
			setDailyReportData([]);
			return setIsLoadingReport(false);
		}
	};

	const getHistoricalReport = async () => {
		if (!hasFacility(selectedFacility)) {
			setIsLoadingReport(false);
			return dispatchAlert("warning", {
				heading: `Please select a facility.`,
			});
		}

		const { token } = currentUser;
		const shiftIDs = getShiftIDsFromSettings(values); // STILL NOT ENABLED IN ALA SERVICES
		const { startDate, endDate } = getStartAndEndDates(values);
		const reportData = await getDailyTaskCreated(
			token,
			values,
			shiftIDs,
			startDate,
			endDate
		);
		// const reportData = await getClientSideReport(
		// 	token,
		// 	values,
		// 	facilities,
		// 	getHistoricalTaskCreated
		// );

		if (!isEmptyArray(reportData)) {
			setIsLoadingReport(false);
			return setDailyReportData(reportData);
		} else {
			setIsLoadingReport(false);
			return setDailyReportData([]);
		}
	};

	// checks for facility, then checks 'reportType'
	const createReport = async (e) => {
		switch (true) {
			case !hasFacility(selectedFacility): {
				dispatchAlert(`warn`, {
					heading: `No facility selected!`,
				});
				return setShowReportPicker(false);
			}
			case values.reportType === `Daily`: {
				setShowReportPicker(false);
				setIsLoadingReport(true);
				return await getDailyReport();
			}
			case values.reportType === `Historical`: {
				setShowReportPicker(false);
				setIsLoadingReport(true);
				return await getHistoricalReport();
			}

			default:
				return;
		}
	};

	const cancelReport = (e) => {
		e.preventDefault();
		setShowReportPicker(false);
		handleReset(e);
	};

	// ##TODOS:
	// - Wire up 'rerunReport()' & 'recently viewed'
	const rerunReport = async (recentsModel) => {
		// fetch report w/ recentsModel here...
	};

	const changeFacility = (selectedVal) => {
		// not needed; facility is loaded via 'setFacility'
		return dispatchAlert("info", {
			heading: `Changed Facility`,
			subheading: `Loaded ${selectedVal}.`,
		});
	};

	/**
	 * Extracts resident from 'selectedResident' state.
	 * Inits 'LOADING' state
	 * Fires off request, then syncs resident data to global store
	 */
	// ✓ - ADDED 9/23/2021 at 8:49 AM
	const loadResident = useCallback(async () => {
		if (isEmptyVal(selectedResident)) return alert("Select a resident");
		if (!selectedResident.includes("~ ALA ID:")) return;

		const { token } = currentUser;
		// match resident record from ID
		const curResident = getResidentObj(
			residentsByFacility,
			currentFacility?.facilityID ?? currentUser.facilityID, // 'null' causes empty 'selectedFacility'
			selectedResident
		);

		dispatch({ type: "LOADING" });

		const merged = await mergeDailyResidentData(
			token,
			curResident.ResidentID,
			new Date()
		);
		const oldState = { ...globalState };
		const newState = populateState(merged, oldState);
		// sync to state
		return dispatch({
			type: "SUCCESS",
			data: {
				newState: newState,
			},
		});
	});

	// sends tracking to Sentry.io
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		sendPageTracking(currentUser?.username, {
			pagename: `TaskCreatedReport`,
			path: `/dashboard/reports/taskcreated`,
		});
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ReportTypeController
				reportTitle="Task Created Report"
				reportDesc={getReportDescByType(`Task Created`, values)}
				setFacilityData={setFacilityData}
				reportType={values.reportType}
				currentUser={currentUser}
				currentFacility={currentFacility}
				residents={residents}
				residentsByFacility={residentsByFacility}
				selectedFacility={selectedFacility}
				setSelectedFacility={setSelectedFacility}
				setSelectedResident={setSelectedResident}
				selectedResident={selectedResident}
				changeFacility={changeFacility}
				changeResident={loadResident}
				recentlyViewedList={cache.recents}
				removeRecentsItem={removeItem}
				rerunReport={rerunReport}
				cancelReport={cancelReport}
				dispatch={dispatch}
				handleReportsPicker={() => setShowReportPicker(true)}
			>
				<ReportsViewerController
					key={`TASKCREATED-${values.reportType}-${isEmptyArray(
						dailyReportData
					)}`}
					showSMARTTable={true}
					showMirror={false}
					noReportData={isEmptyArray(dailyReportData)}
					reportType={values.reportType}
					isLoading={isLoadingReport}
					tableData={{
						size: `FULL`,
						title: `${values.reportType} Task Created Report`,
						cols: [...cols],
						data: [...getTaskCreatedRows(dailyReportData)],
						enableActions: true,
					}}
				/>
			</ReportTypeController>

			{/* REPORT PICKER */}
			<ConfigurablePicker
				reportName={`Task Created Report`}
				reportTypeOpts={[`Daily`, `Historical`]}
				formState={formState}
				setFormState={setFormState}
				handleCheckbox={handleCheckbox}
				handleReset={handleReset}
				currentFacility={currentFacility}
				currentUser={currentUser}
				adls={adlCategories}
				showPicker={showReportPicker}
				closeModal={() => setShowReportPicker(false)}
				createReport={createReport}
				cancelReport={cancelReport}
				fieldsToValidate={getFieldsToValidate(values)}
				config={amendHistoricalConfig(
					{ ...TASKCREATED_CONFIG },
					"filterOptions",
					[...FILTER_OPTIONS]
				)}
				dateRangeRestrictions={{
					isActive: true,
					rangeStart: rangeRestriction.rangeStart,
					rangeEnd: rangeRestriction.rangeEnd,
				}}
			/>

			{AlertsHandler}
		</>
	);
};

export default TaskCreatedReport;

TaskCreatedReport.defaultProps = {
	currentUser: {},
	currentFacility: {},
	currentResident: {},
	residents: [],
	facilities: [],
	residentsByFacility: {},
};

TaskCreatedReport.propTypes = {
	currentUser: PropTypes.shape({
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired,
		password: PropTypes.string.isRequired,
		userID: PropTypes.string.isRequired,
		facilityID: PropTypes.string.isRequired,
		title: PropTypes.string,
		isAdmin: PropTypes.bool.isRequired,
		token: PropTypes.string.isRequired,
		facilities: PropTypes.array.isRequired,
	}),
	currentResident: PropTypes.shape({
		ResidentID: PropTypes.number,
		isLOA: PropTypes.bool,
		FirstName: PropTypes.string,
		LastName: PropTypes.string,
		FloorUnit: PropTypes.string,
		RoomNum: PropTypes.string,
	}),
	currentFacility: PropTypes.shape({
		communityName: PropTypes.string,
		facilityID: PropTypes.string,
		parentID: PropTypes.string,
		address: PropTypes.object,
		residents: PropTypes.array,
		shifts: PropTypes.array,
	}),
	residents: PropTypes.array.isRequired,
	facilities: PropTypes.array,
	residentsByFacility: PropTypes.object,
	adlCategories: PropTypes.array,
	dispatch: PropTypes.func.isRequired,
	children: PropTypes.any,
};
