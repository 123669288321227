import React from "react";
import styles from "../../css/timeview/SearchInput.module.scss";
import { PropTypes } from "prop-types";

const SearchInput = ({ name, id, searchVal, handleSearch, placeholder }) => {
	return (
		<div className={styles.SearchInput}>
			<input
				type="text"
				name={name}
				id={id}
				value={searchVal}
				onChange={handleSearch}
				className={styles.SearchInput_input}
				placeholder={placeholder}
			/>
		</div>
	);
};

export default SearchInput;

SearchInput.defaultProps = {};

SearchInput.propTypes = {};
