import React from "react";
import styles from "../../css/residentinfo/ResidentSvcPlanEntry.module.scss";
import { PropTypes } from "prop-types";
import {
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
} from "../../helpers/utils_types";

const customCSS = {
	selectors: {
		width: "100%",
		minWidth: "30rem",
		maxWidth: "100%",
	},
};

const getGoals = (detailsMap = {}) => {
	const keys = Object.keys(detailsMap);
	const allGoals = keys.map((key, idx) => {
		const record = detailsMap?.[key];
		const goal = record?.goals ?? `Unknown goal - ${idx}`;
		return goal;
	});

	return allGoals;
};
const getInterventions = (detailsMap = {}) => {
	const keys = Object.keys(detailsMap);
	const allInterventions = keys.map((key, idx) => {
		const record = detailsMap?.[key];
		const intervention =
			record?.interventions ?? `Unknown intervention - ${idx}`;
		return intervention;
	});

	return allInterventions;
};

const GoalsUI = ({ details = {} }) => {
	const goals = getGoals(details);

	if (isEmptyObj(details)) {
		return <div className={styles.GoalsEmpty}>No recorded goals.</div>;
	}
	return (
		<div className={styles.GoalsUI}>
			<ul className={styles.GoalsUI_list}>
				{!isEmptyArray(goals) &&
					goals.map((goal, idx) => (
						<li key={`GOAL-ENTRY-${idx}`} className={styles.GoalsUI_list_entry}>
							{goal}
						</li>
					))}
			</ul>
		</div>
	);
};
const InterventionsUI = ({ details = {} }) => {
	const interventions = getInterventions(details);

	if (isEmptyObj(details)) {
		return (
			<div className={styles.InterventionsEmpty}>
				No recorded interventions.
			</div>
		);
	}
	return (
		<div className={styles.InterventionsUI}>
			<ul className={styles.InterventionsUI_list}>
				{!isEmptyArray(interventions) &&
					interventions.map((intervention, idx) => (
						<li
							key={`INTERVENTION-ENTRY-${idx}`}
							className={styles.InterventionsUI_list_entry}
						>
							{intervention}
						</li>
					))}
			</ul>
		</div>
	);
};

const ResidentSvcPlanEntry = ({ svcPlanEntry = {} }) => {
	const {
		problem = "Unknown",
		// goals = [],
		// interventions = [],
		notes = "No notes",
		dateUpdated,
		details,
	} = svcPlanEntry;

	return (
		<div className={styles.ResidentSvcPlanEntry}>
			<div className={styles.ResidentSvcPlanEntry_inner}>
				<div className={styles.ResidentSvcPlanEntry_inner_col1}>{problem}</div>
				<div className={styles.ResidentSvcPlanEntry_inner_col2}>
					<GoalsUI
						key={`GOALS`}
						details={details} // extract all goals into array
					/>
				</div>
				<div className={styles.ResidentSvcPlanEntry_inner_col3}>
					<InterventionsUI
						key={`INTERVENTIONS`}
						details={details} // extract all goals into array
					/>
				</div>
				{/* <div className={styles.ResidentSvcPlanEntry_inner_col4}>
					{dateUpdated}
				</div> */}
			</div>
		</div>
	);
};

export default ResidentSvcPlanEntry;

ResidentSvcPlanEntry.defaultProps = {};

ResidentSvcPlanEntry.propTypes = {};
