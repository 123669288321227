import React from "react";
import styles from "../../css/app/ALAUpdatedUnitTypeSelector.module.scss";
import { PropTypes } from "prop-types";
import CustomDropdownSM from "../shared/CustomDropdownSM";
import { UNIT_TYPES as rawTypes } from "../../helpers/utils_options";

const customCSS = {
	input: {
		width: "15rem",
		// height: '3rem'
	},
};

const unitTypes = ["All", ...rawTypes];

const ALAUpdatedUnitTypeSelector = ({ unitTypeVal, handleUnitType }) => {
	return (
		<div className={styles.ALAUpdatedUnitTypeSelector}>
			<CustomDropdownSM
				name="selectedUnitType"
				id="selectedUnitType"
				selection={unitTypeVal}
				setSelection={handleUnitType}
				options={unitTypes}
				customStyles={customCSS.input}
			/>
		</div>
	);
};

export default ALAUpdatedUnitTypeSelector;

ALAUpdatedUnitTypeSelector.defaultProps = {};

ALAUpdatedUnitTypeSelector.propTypes = {};
