import React from "react";
import styles from "../../css/adlshiftreport/AdlShiftReportHeadings.module.scss";
import { PropTypes } from "prop-types";

const AdlShiftReportHeadings = () => {
	return (
		<header className={styles.AdlShiftReportHeadings}>
			<section className={styles.AdlShiftReportHeadings_left}>
				<h4 className={styles.AdlShiftReportHeadings_left_heading}>Resident</h4>
			</section>
			<section className={styles.AdlShiftReportHeadings_center}>
				<h4 className={styles.AdlShiftReportHeadings_center_heading}>AM</h4>
			</section>
			<section className={styles.AdlShiftReportHeadings_center}>
				<h4 className={styles.AdlShiftReportHeadings_center_heading}>PM</h4>
			</section>
			<section className={styles.AdlShiftReportHeadings_center}>
				<h4 className={styles.AdlShiftReportHeadings_center_heading}>NOC</h4>
			</section>
		</header>
	);
};

export default AdlShiftReportHeadings;

AdlShiftReportHeadings.defaultProps = {};

AdlShiftReportHeadings.propTypes = {};
