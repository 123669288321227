import React from "react";
import styles from "../../css/adlschedule/AdlShiftSettingsPanel.module.scss";
import { PropTypes } from "prop-types";
// REUSABLE/SHARED COMPONENTS
import TabsContainer from "../../components/shared/TabsContainer";
import TabButtons from "../../components/shared/TabButtons";
import TabButton from "../../components/shared/TabButton";
import TabPanels from "../../components/shared/TabPanels";
import TabPanel from "../../components/shared/TabPanel";
import AdlShiftReportPanel from "../../components/adlshiftreport/AdlShiftReportPanel";
import AdlSelectionPanel from "../../components/adlshiftreport/AdlSelectionPanel";

const AdlShiftSettingsPanel = ({ openResidentTable, openShiftReport }) => {
	return (
		<section className={styles.AdlShiftSettingsPanel}>
			<TabsContainer defaultIndex={0}>
				<TabButtons>
					<TabButton>
						<span>Select Resident(s)</span>
					</TabButton>
					<TabButton>
						<span>View Current Settings</span>
					</TabButton>
				</TabButtons>

				<TabPanels>
					<TabPanel>
						<AdlSelectionPanel openResidentTable={openResidentTable} />
					</TabPanel>
					<TabPanel>
						<AdlShiftReportPanel openShiftReport={openShiftReport} />
					</TabPanel>
				</TabPanels>
			</TabsContainer>
			{/*  */}
		</section>
	);
};

export default AdlShiftSettingsPanel;

AdlShiftSettingsPanel.defaultProps = {};

AdlShiftSettingsPanel.propTypes = {
	openResidentTable: PropTypes.func.isRequired,
	openShiftReport: PropTypes.func.isRequired,
};
