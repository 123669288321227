import React from "react";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import FormSection from "../forms/FormSection";
import Divider from "../forms/Divider";
import CustomDropdown from "../shared/CustomDropdown";

const hideDatePicker = (reportType) => {
	if (isEmptyVal(reportType)) return true;
	if (reportType === `Daily`) return true;
	return false;
};

const ReportDatePicker = ({
	settings,
	handleSettings,
	dateRangeTypes = [`Custom Range`, `Today`, `By Month`],
	children,
}) => {
	if (hideDatePicker(settings?.reportType)) {
		return null;
	}
	return (
		<>
			<FormSection
				icon="calendarCheck"
				title="Select a Date Range"
				hoverText="The range to grab data from."
			>
				<CustomDropdown
					name="dateRangeType"
					id="dateRangeType"
					selection={settings.dateRangeType}
					placeholder="Select range type..."
					setSelection={handleSettings}
					options={dateRangeTypes}
				/>
				{children}
			</FormSection>
			<Divider />
		</>
	);
};

export default ReportDatePicker;

ReportDatePicker.defaultProps = {
	dateRangeTypes: [`Custom Range`, `Today`, `By Month`],
};

ReportDatePicker.propTypes = {
	settings: PropTypes.object.isRequired,
	handleSettings: PropTypes.func.isRequired,
	dateRangeTypes: PropTypes.arrayOf(PropTypes.string),
};
