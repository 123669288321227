import React from "react";
import styles from "../../css/residenttable/ResidentCheckbox.module.scss";
import { PropTypes } from "prop-types";

const ResidentCheckbox = ({
	label,
	name,
	id,
	val,
	isDisabled = false,
	handleCheckbox,
	handleClick,
}) => {
	return (
		<div className={styles.ResidentCheckbox}>
			<input
				value={val}
				type="checkbox"
				name={name}
				id={id}
				checked={val}
				className={styles.ResidentCheckbox_checkbox}
				onChange={handleCheckbox}
				onClick={handleClick}
				disabled={isDisabled}
			/>
			<label htmlFor={id} className={styles.ResidentCheckbox_label}>
				{label}
			</label>
		</div>
	);
};

export default React.memo(ResidentCheckbox);

ResidentCheckbox.defaultProps = {
	readOnly: false,
	isDisabled: false,
};

ResidentCheckbox.propTypes = {
	label: PropTypes.string,
	name: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),
	id: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),
	val: PropTypes.bool.isRequired,
	handleCheckbox: PropTypes.func.isRequired,
	handleClick: PropTypes.func.isRequired,
};
