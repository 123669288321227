import React from "react";
import styles from "../../css/residentinfo/ResidentInfo.module.scss";
import { PropTypes } from "prop-types";
import { themeColors } from "../../helpers/utils_styles";
import { isEmptyArray } from "../../helpers/utils_types";
import { capitalizeAll } from "../../helpers/utils_processing";
import { getDateOfBirth, getFormattedIsoDate } from "../../helpers/utils_dates";

// RESIDENT: GENERAL INFO
// VALUES: ResidentType, RoomNum, FloorUnit, AptPhone, DateOfBirth, ServicePlanDue, CodeStatus...
// ...and Contacts

const ResidentInfo = ({ profile = {}, contacts = [] }) => {
	return (
		<section className={styles.ResidentInfo}>
			<div className={styles.ResidentInfo_cell}>
				<span>
					Resident Type:{" "}
					<b style={{ color: themeColors.main.green }}>
						{profile.ResidentType}
					</b>
				</span>
			</div>
			<div className={styles.ResidentInfo_cell}>
				<span>
					ALA ID: <b style={{ color: themeColors.main.main }}>{profile.ID}</b>
				</span>
			</div>
			<div className={styles.ResidentInfo_cell}>
				<span>
					Apt #: <b>{profile.RoomNum}</b>
				</span>
			</div>
			<div className={styles.ResidentInfo_cell}>
				<span>
					Unit: <b>{profile.FloorUnit}</b>
				</span>
			</div>
			<div className={styles.ResidentInfo_cell}>
				<span>
					Phone Number: <b>{profile.AptPhone}</b>
				</span>
			</div>
			<div className={styles.ResidentInfo_cell}>
				<span>
					DOB: <b>{getDateOfBirth(profile.DateOfBirth)}</b>
				</span>
			</div>
			<div className={styles.ResidentInfo_cell}>
				<span>
					Service Plan Due: <b>{getFormattedIsoDate(profile.ServicePlanDue)}</b>
				</span>
			</div>
			<div className={styles.ResidentInfo_code}>
				<span>
					Code Status: <b>{capitalizeAll(profile.CodeStatus)}</b>
				</span>
			</div>
			<hr style={{ opacity: ".4", margin: "1rem 0 0 0" }} />
			<div className={styles.ResidentInfo_title}>Contacts</div>
			<div className={styles.ResidentInfo_contacts}>
				{!isEmptyArray(contacts) &&
					contacts.map((entry, index) => (
						<div
							key={`${entry.ID}-${index}`}
							className={styles.ResidentInfo_contacts_entry}
						>
							<span>
								<b>Relationship:</b> {entry.Relationship}
							</span>
							<span>
								<b>Primary #:</b> {entry.PhonePrimary}
							</span>
							<span>
								<b>Contact Type:</b> {entry.EmergencyContactType}
							</span>
						</div>
					))}
			</div>
		</section>
	);
};

export default ResidentInfo;

ResidentInfo.defaultProps = {
	profile: {},
	contacts: [],
};

ResidentInfo.propTypes = {
	profile: PropTypes.object.isRequired,
	contacts: PropTypes.arrayOf(PropTypes.object),
};
