import React, { useRef, useEffect } from "react";
import styles from "../../css/daily/QuickCreateTask.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { blueGrey, purple } from "../../helpers/utils_styles";

// components
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	cancel: {
		backgroundColor: "transparent",
		color: blueGrey[700],
		padding: ".4rem 1.2rem",
	},
	save: {
		backgroundColor: purple[600],
		color: "#ffffff",
		padding: ".4rem 1.2rem",
	},
};

const submitKey = "ENTER" || "Enter";

const QuickCreateTask = ({
	adl = {},
	adlTaskName,
	closeModal,
	handleChange,
	createAdlTask,
	cancelAdlTask,
}) => {
	const inputRef = useRef();
	const modalRef = useRef();
	const { isOutside } = useOutsideClick(modalRef);

	// click outside to close
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (isOutside) {
			closeModal();
		}

		return () => {
			isMounted = false;
		};
	}, [closeModal, isOutside]);

	// focus input 'onMount'
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (inputRef.current) {
			inputRef.current.focus();
		}

		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<div className={styles.QuickCreateTask} ref={modalRef}>
			<div className={styles.QuickCreateTask_top}>
				<div className={styles.QuickCreateTask_top_title}>
					Create {adl.AdlCategoryType} Task
				</div>
				<svg
					className={styles.QuickCreateTask_top_closeIcon}
					onClick={closeModal}
				>
					<use xlinkHref={`${sprite}#icon-clearclose`}></use>
				</svg>
			</div>
			<form onSubmit={createAdlTask} className={styles.QuickCreateTask_main}>
				<label className={styles.QuickCreateTask_main_label}>
					Enter Task Name
				</label>
				<input
					type="text"
					name={`create${adl.AdlCategoryType}Task`}
					id={`create${adl.AdlCategoryType}Task`}
					value={adlTaskName}
					className={styles.QuickCreateTask_main_input}
					onChange={handleChange}
					placeholder="Resident needs..."
					onSubmit={createAdlTask}
					ref={inputRef}
				/>
				<div className={styles.QuickCreateTask_main_hint}>
					Hit 'ENTER' key or click 'Save' to create task.
				</div>
				<div className={styles.QuickCreateTask_actions}>
					<ButtonSM customStyles={customCSS.cancel} handleClick={cancelAdlTask}>
						Cancel
					</ButtonSM>
					<ButtonSM customStyles={customCSS.save} handleClick={createAdlTask}>
						Save
					</ButtonSM>
				</div>
			</form>
		</div>
	);
};

export default QuickCreateTask;

QuickCreateTask.defaultProps = {};

QuickCreateTask.propTypes = {};
