import { currentEnv } from "./utils_env";
import { auth } from "./utils_endpoints";
import { SERVICES } from "./utils_apps";
import { isEmptyVal } from "./utils_types";

/**
 * 'SystemUser': an administrative user that performs & executes actions on behalf of users, especially un-authenticated users.
 */
const SYSTEM_USER = {
	username: `${process.env.REACT_APP_SYSTEM_USER_LOGIN}`,
	password: `${process.env.REACT_APP_SYSTEM_USER_PASSWORD}`,
	isAuthenticated: false,
	token: null,
};

const systemLogin = async (username, password, appName, callback = null) => {
	let url = currentEnv.base + auth.login;
	url += "?" + new URLSearchParams({ loginId: username });
	url += "&" + new URLSearchParams({ loginPwd: encodeURIComponent(password) });
	url += "&" + new URLSearchParams({ loginApp: appName });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		if (callback) return callback();

		return response.Data;
	} catch (err) {
		return err;
	}
};

// authenticates the system user
const getSystemUserAuth = async () => {
	const { username, password } = SYSTEM_USER;
	const { AdvantageTracker } = SERVICES;
	// decoded password: seems to work w/ & w/o???
	// const decodedPassword = decodeURIComponent(
	// 	process.env.REACT_APP_SYSTEM_USER_PASSWORD
	// );

	const token = await systemLogin(username, password, AdvantageTracker.alias);

	if (!isEmptyVal(token)) {
		console.log(`✅ 'SYSTEM' Was Initialized...`);
		return {
			username: username,
			password: password,
			token: token,
			isAuthenticated: true,
		};
	} else {
		console.log(`❌ Oops! Failed:`);
		return {
			username: username,
			password: password,
			token: null,
			isAuthenticated: false,
		};
	}
};

export { SYSTEM_USER };
export { getSystemUserAuth };
