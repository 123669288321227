import React from "react";
import styles from "../../css/views/DailyTimeView.module.scss";
import { PropTypes } from "prop-types";

const DailyTimeView = ({ state, dispatch, history }) => {
	const { app, globals, user } = state;
	const { isLoading } = app;

	return (
		<div className={styles.DailyTimeView}>
			<header className={styles.DailyTimeView_header}>
				<h1 className={styles.DailyTimeView_header_title}>
					Services By Time View
				</h1>
			</header>
		</div>
	);
};

export default DailyTimeView;

DailyTimeView.defaultProps = {};

DailyTimeView.propTypes = {};
