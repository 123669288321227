import React, { useState } from "react";
import styles from "../../css/daily/CommunityShifts.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import {
	getShiftName,
	getCurrentShift,
	isBetweenYesterdayNOC,
} from "../../helpers/utils_shifts";
import { format } from "date-fns";
import { generateID } from "../../helpers/utils_processing";
import { utcToLocal } from "../../helpers/utils_dates";

const CommunityShift = ({
	isCurrent = false,
	isYesterdayNOC = false,
	shift = {},
}) => {
	if (isCurrent) {
		return (
			<div className={styles.CommunityShift_isCurrent}>
				<div className={styles.CommunityShift_isCurrent_name}>
					{getShiftName(shift?.AssessmentShiftId)}
				</div>
				<div className={styles.CommunityShift_isCurrent_times}>
					{format(utcToLocal(shift.StartTime), "h:mm A")}
					{" – "}
					{format(utcToLocal(shift.EndTime), "h:mm A")}
				</div>
				{isYesterdayNOC && (
					<div className={styles.CommunityShift_isCurrent_isBeforeStart}>
						Yesterday
					</div>
				)}
			</div>
		);
	}
	return (
		<div className={styles.CommunityShift}>
			<div className={styles.CommunityShift_name}>
				{getShiftName(shift?.AssessmentShiftId)}
			</div>
			<div className={styles.CommunityShift_times}>
				{format(utcToLocal(shift.StartTime), "h:mm A")}
				{" – "}
				{format(utcToLocal(shift.EndTime), "h:mm A")}
			</div>
		</div>
	);
};

CommunityShift.defaultProps = {
	isCurrent: false,
	isBeforeStart: false,
	shift: {},
};
CommunityShift.propTypes = {
	isCurrent: PropTypes.bool,
	isBeforeStart: PropTypes.bool,
	shift: PropTypes.object.isRequired,
};

const CommunityShifts = ({ communityInfo = {} }) => {
	const [currentShift, setCurrentShift] = useState(
		getCurrentShift(communityInfo.shifts)
	);
	const [isYesterdaysNOC, setIsYesterdaysNOC] = useState(
		isBetweenYesterdayNOC(communityInfo.shifts)
	);

	return (
		<div className={styles.CommunityShifts}>
			<section className={styles.CommunityShifts_section}>
				{!isEmptyArray(communityInfo?.shifts) &&
					communityInfo.shifts.map((shift, idx) => (
						<CommunityShift
							shift={shift}
							isCurrent={
								getShiftName(shift?.AssessmentShiftId) === currentShift
							}
							key={`${shift?.ModifiedBy}-${generateID(6)}`}
							isYesterdayNOC={isYesterdaysNOC}
						/>
					))}
			</section>
		</div>
	);
};

export default CommunityShifts;

CommunityShifts.defaultProps = {
	communityInfo: {},
};

CommunityShifts.propTypes = {
	communityInfo: PropTypes.shape({
		communityName: PropTypes.string.isRequired,
		facilityID: PropTypes.string.isRequired,
		residents: PropTypes.arrayOf(PropTypes.object).isRequired,
		shifts: PropTypes.arrayOf(PropTypes.object).isRequired,
	}),
};
