import { useState, useLayoutEffect } from "react";
import { getViewport } from "../helpers/utils_styles";

/**
 * "useViewportSize":
 * - Tracks the inner width & height of the viewport and excludes the top & bottom nav that appears on mobile screens.
 * - This provides a more accurate screen size based off the inner dimensions.
 */
const useViewportSize = () => {
	const [viewport, setViewport] = useState(() => {
		return getViewport();
	});

	useLayoutEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		const handleResize = (e) => {
			setViewport(() => getViewport());
		};

		window.addEventListener("resize", handleResize);

		return () => {
			isMounted = false;
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return viewport;
};

export { useViewportSize };
