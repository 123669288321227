import React from "react";
import styles from "../../css/reportpicker/ReportPickerDesc.module.scss";
import { PropTypes } from "prop-types";
import FormSection from "../forms/FormSection";

const ReportPickerDesc = ({ desc, reportName }) => {
	return (
		<FormSection
			icon="chart"
			title="Description of Requested Report"
			hoverText="A summary of the requested report."
		>
			<div className={styles.ReportPickerDesc}>
				<div className={styles.ReportPickerDesc_label}>
					<div className={styles.ReportPickerDesc_label_item}>
						{reportName}:
					</div>
					<div className={styles.ReportPickerDesc_label_subItem}>{desc}</div>
				</div>
			</div>
		</FormSection>
	);
};

export default ReportPickerDesc;

ReportPickerDesc.defaultProps = {};

ReportPickerDesc.propTypes = {
	reportName: PropTypes.string,
	desc: PropTypes.string,
};
