import React from "react";
import styles from "../../css/forms/StrengthIndicator.module.scss";
import { PropTypes } from "prop-types";
import { main } from "../../helpers/utils_styles";
import {
	blue,
	red,
	pink,
	green,
	yellow,
	grey,
	teal,
} from "../../helpers/utils_styles";
import { getPasswordStrength } from "../../helpers/utils_validation";

/**
 * Determines the width of the indicator bar based off the score.
 * @returns {String} - Returns string width (ie '100%', or' '0%' etc.)
 */
const getIndicatorBarWidth = (score = null) => {
	if (!score) return `0%`;
	if (score > 100) return `100%`;
	return `${score}%`;
};
/**
 * Returns specific color shade for current score.
 */
const getIndicatorColor = (score) => {
	switch (true) {
		case score > 105: {
			return green[400];
		}
		case score > 100: {
			return green[400];
		}
		case score > 80: {
			return teal[500];
		}
		case score > 70: {
			return blue[400];
		}
		case score > 50: {
			return yellow[400];
		}
		case score > 30: {
			return main.orange;
		}
		case score > 10: {
			return pink[600];
		}
		case score > 5: {
			return red[600];
		}
		default:
			return grey[200];
	}
};
const getIndicatorStyles = (score) => {
	const width = getIndicatorBarWidth(score);
	const bgColor = getIndicatorColor(score);

	return {
		width: width,
		backgroundColor: bgColor,
	};
};

const StrengthIndicator = ({ score }) => {
	return (
		<div className={styles.StrengthIndicator}>
			<div className={styles.StrengthIndicator_strength}>
				<div
					className={styles.StrengthIndicator_strength_bar}
					style={getIndicatorStyles(score)}
				></div>
			</div>
			<span className={styles.StrengthIndicator_label}>
				{getPasswordStrength(score)}
			</span>
		</div>
	);
};

export default StrengthIndicator;

StrengthIndicator.defaultProps = {};

StrengthIndicator.propTypes = {};
