import React from "react";
import styles from "../../css/adlschedule/AdlRows.module.scss";
import { PropTypes } from "prop-types";
import Spinner from "../shared/Spinner";

const AdlRows = ({ isLoading = false, children }) => {
	return (
		<div className={styles.AdlRows}>{isLoading ? <Spinner /> : children}</div>
	);
};

export default AdlRows;

AdlRows.defaultProps = {};

AdlRows.propTypes = {
	children: PropTypes.any.isRequired,
};
