import React from "react";
import styles from "../../css/adlschedule/EditShiftModal.module.scss";
import { PropTypes } from "prop-types";
import EditShiftForm from "./EditShiftForm";

const EditShiftModal = ({
	shift,
	shiftName,
	shiftTimeVals = {},
	currentShiftTimes = {}, // 'startTime' & 'endTime' formatted as strings
	allShifts = [],
	handleShiftTimes,
	cancelChanges,
	saveChanges,
}) => {
	return (
		<article className={styles.EditShiftModal}>
			<EditShiftForm
				shift={shift}
				shiftName={shiftName}
				shiftTimeVals={shiftTimeVals}
				currentShiftTimes={currentShiftTimes}
				allShifts={allShifts}
				handleShiftTimes={handleShiftTimes}
				cancelChanges={cancelChanges}
				saveChanges={saveChanges}
			/>
		</article>
	);
};

export default EditShiftModal;

EditShiftModal.defaultProps = {
	shiftTimeVals: {},
};

EditShiftModal.propTypes = {
	shift: PropTypes.object.isRequired,
	shiftName: PropTypes.string.isRequired,
	shiftTimeVals: PropTypes.object.isRequired, // input values
	handleShiftTimes: PropTypes.func.isRequired,
	cancelChanges: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
};
