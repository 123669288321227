import React from "react";
import { PropTypes } from "prop-types";
import styles from "../../css/calendar/Week.module.scss";
// helpers
import { findTasksByDay } from "../../helpers/utils_tasks";
import { generateID } from "../../helpers/utils_processing";
import { format, isToday } from "date-fns";
// COMPONENTS
import Spinner from "../shared/Spinner";
import ADLHeadings from "./ADLHeadings";
import DailyADL from "./DailyADL";

const Week = ({
	currentDays,
	categories,
	isLoading = false,
	weeklyTasks = [],
	currentFacility = {},
	currentResident = {},
	currentUser = {},
}) => {
	if (isLoading) {
		return <Spinner />;
	}
	return (
		<section className={styles.Week}>
			<ADLHeadings categories={categories} />
			{/* DailyADL tiles */}
			{currentDays.map((day, index) => (
				<DailyADL
					day={day}
					isToday={isToday(day)}
					categories={categories}
					currentUser={currentUser}
					currentFacility={currentFacility}
					currentResident={currentResident}
					tasks={findTasksByDay(weeklyTasks, format(day, "dddd"))}
					key={`${format(day, "ddd")}_${generateID(12)}_${index}`}
				/>
			))}
		</section>
	);
};

export default Week;

Week.defaultProps = {
	isLoading: false,
	weeklyTasks: [],
	currentFacility: {},
	currentResident: {},
	currentUser: {},
};

Week.propTypes = {
	currentDays: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
	categories: PropTypes.arrayOf(PropTypes.object).isRequired,
	isLoading: PropTypes.bool.isRequired,
	weeklyTasks: PropTypes.arrayOf(PropTypes.object).isRequired,
	currentFacility: PropTypes.object.isRequired,
	currentResident: PropTypes.object.isRequired,
	currentUser: PropTypes.object.isRequired,
};
