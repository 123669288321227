import { useState, useEffect } from "react";

/**
 * Checks if an element exists in the DOM & is visible
 * @param {String} refToEl - Reference to a DOM element. (eg 'id', 'class' name etc)
 */
const elExists = (refToEl) => {
	return document.body.contains(refToEl);
};

/**
 * Used for printing contents of a DOM element.
 * @param {String} printID - 'id' attribute from the target DOM element, to be printed.
 */

export const usePrint = (printID) => {
	// store the printable DOM element (iframe)
	const [printable, setPrintable] = useState(
		document.querySelector("#" + printID)
	);

	// grabs the iframe's content window
	// then triggers focus and the print events
	const handlePrint = (e) => {
		if (!printable) return;
		if (!elExists(printable)) return;

		const iframe = printable?.contentWindow;

		iframe.focus();
		iframe.print();
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		// make sure iframe element is set/stored in state when mounted
		setPrintable(document.querySelector("#" + printID));
		return () => {
			isMounted = false;
		};
	}, [printID]);

	return {
		handlePrint,
	};
};
