import React, { useEffect, useState } from "react";
import styles from "../../css/app/LoginForm.module.scss";
import { PropTypes } from "prop-types";
// utils
import { brand } from "../../helpers/utils_styles";
import { isValid } from "../../helpers/utils_validation";
import { versionRelease } from "../../helpers/utils_version";
// components
import TextInput from "../shared/TextInput";
import PasswordInput from "../shared/PasswordInput";
import ButtonSM from "../shared/ButtonSM";
import { currentEnvName, isDevOrTest } from "../../helpers/utils_env";

const customCSS = {
	loginBtn: {
		backgroundColor: brand.blueDark,
		color: "#ffffff",
		fontSize: "1.5rem",
		fontWeight: "600",
		padding: "1rem 2.2rem",
	},
};

const wasLoggedOut = (history) => {
	const {
		state: { from },
	} = history.location;
	if (from.includes("/dashboard")) return true;
	return false;
};

const formTitles = {
	test: "(TEST)",
	prod: "",
	dev: "",
	local: "",
};

const Title = () => {
	return (
		<h4 className={styles.LoginForm_form_title}>
			<span>Login</span> <b>{formTitles[currentEnvName]}</b>
		</h4>
	);
};

const LoginForm = ({
	vals,
	handleChange,
	handleLogin,
	handleSignup,
	isLoading = false,
	showSignup = true,
}) => {
	const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);

	// "watches" the form fields for non-empty values and enables the button
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isValid(vals, ["username", "password"])) {
			return setIsReadyToSubmit(true);
		}
		setIsReadyToSubmit(false);
		return () => {
			isMounted = false;
		};
	}, [vals]);

	return (
		<section className={styles.LoginForm}>
			<form className={styles.LoginForm_form} onSubmit={handleLogin}>
				<Title />
				{/* <h4 className={styles.LoginForm_form_title}>
					<span>Login</span>{" "}
					{isDevOrTest() && (
						<span className={styles.LoginForm_form_title_test}>(TEST)</span>
					)}
				</h4> */}
				<TextInput
					label="Username"
					val={vals.username}
					handleChange={handleChange}
					name="username"
					id="username"
					autoComplete="username"
					required={true}
					inputMode="email"
				/>
				<PasswordInput
					label="Password"
					val={vals.password}
					handleChange={handleChange}
					name="password"
					id="password"
					autoComplete="password"
					required={true}
				/>
				<ButtonSM
					type="submit"
					handleClick={handleLogin}
					customStyles={customCSS.loginBtn}
					isDisabled={!isReadyToSubmit || isLoading}
				>
					<span>{isLoading ? "Logging in..." : "Login"}</span>
				</ButtonSM>
				<div className={styles.LoginForm_form_bottom}>
					<p className={styles.LoginForm_form_bottom_version}>
						{versionRelease}
					</p>
				</div>
			</form>
		</section>
	);
};

export default LoginForm;

LoginForm.propTypes = {
	vals: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleLogin: PropTypes.func.isRequired,
	handleSignup: PropTypes.func,
};
