import React, { useRef, useEffect } from "react";
import { PropTypes } from "prop-types";
import { themeColors } from "../../helpers/utils_styles";
import { useOutsideClick } from "../../utils/useOutsideClick";
import {
	getCompleteRepeatDesc,
	showDays,
	showCycleOptions,
	getRepeatText,
	getRepeatOptionsText,
	daysRange,
} from "../../helpers/utils_repeatingTasks";
import {
	DAYS_SLICED as days,
	RECURRING_TYPES as recurringTypes,
} from "../../helpers/utils_options";
import styles from "../../css/daily/RepeatTaskModal.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";

import CustomDropdownSM from "../shared/CustomDropdownSM";
import ButtonSM from "../shared/ButtonSM";
import Checkbox from "../shared/Checkbox";
import RadioButton from "../shared/RadioButton";

const saveBtn = {
	backgroundColor: themeColors.main.main,
	color: "#ffffff",
	padding: ".8rem 1.7rem",
	borderRadius: ".5rem",
	marginRight: "1rem",
};
const discardBtn = {
	backgroundColor: "transparent",
	color: themeColors.main.blackBlue,
	border: "1px solid #ccc",
	padding: ".75rem 1.2rem",
};

// USE REAL DATES FOR THIS
// MON, TUES, FRI ETC
const DayOption = ({
	dayVal,
	label,
	name,
	id,
	handleDay,
	isSelected = false,
}) => {
	return (
		<div
			className={isSelected ? styles.DayOption_isSelected : styles.DayOption}
		>
			<Checkbox
				name={name}
				id={id}
				val={dayVal}
				label={label}
				handleCheckbox={handleDay}
			/>
		</div>
	);
};

// RADIO BUTTONS - 4TH MONDAY OF EVERY MONTH/23RD OR EVERY MONTH ETC.
const CycleOption = ({ name, id, label, optionVal, handleOption }) => {
	return (
		<div className={styles.CycleOption}>
			<RadioButton
				name={name}
				id={id}
				label={label}
				val={optionVal}
				handleSelection={handleOption}
			/>
		</div>
	);
};

const RepeatTaskModal = ({
	vals = {},
	selectDay,
	saveRepeat,
	cancelRepeat,
	handleSettings,
	handleDaySelections,
	handleCycleOptions,
	getRepeatDescText,
	closeRepeatWindow,
}) => {
	const windowRef = useRef();
	const { isOutside } = useOutsideClick(windowRef);

	const saveHandler = (e) => {
		const desc = getCompleteRepeatDesc(vals);
		getRepeatDescText(desc);
		saveRepeat(e);
	};

	const cancelHandler = (e) => {
		closeRepeatWindow();
		cancelRepeat(e);
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside) {
			return closeRepeatWindow();
		}
		return () => {
			isMounted = false;
		};
	}, [closeRepeatWindow, isOutside]);

	return (
		<>
			<aside className={styles.RepeatTaskWindow} ref={windowRef}>
				<section className={styles.RepeatTaskWindow_top}>
					<h2 className={styles.RepeatTaskWindow_top_heading}>
						Select repeat pattern
					</h2>
					<svg
						className={styles.RepeatTaskWindow_top_closeIcon}
						onClick={() => closeRepeatWindow()}
					>
						<use xlinkHref={`${sprite}#icon-clearclose`}></use>
					</svg>
				</section>
				{/* START DATE SECTION */}
				<section className={styles.RepeatTaskWindow_repeatType}>
					<span className={styles.RepeatTaskWindow_repeatType_occurs}>
						Occurs
					</span>
					<CustomDropdownSM
						name="recurringType"
						id="recurringType"
						selection={vals.recurringType}
						setSelection={handleSettings}
						options={recurringTypes}
						inputSize={20}
					/>
				</section>
				{/* REPEAT CYCLE - (e.g. Repeat Every x days|weeks|months|qtrs) */}
				<section className={styles.RepeatTaskWindow_repeatCycle}>
					<svg className={styles.RepeatTaskWindow_repeatCycle_icon}>
						<use xlinkHref={`${sprite}#icon-update`}></use>
					</svg>
					<span className={styles.RepeatTaskWindow_repeatCycle_desc}>
						Repeat every{" "}
					</span>
					<CustomDropdownSM
						name="recurringCycle"
						id="recurringCycle"
						options={daysRange}
						inputSize={7}
						selection={vals.recurringCycle}
						setSelection={handleSettings}
					/>
					<span className={styles.RepeatTaskWindow_repeatCycle_type}>
						{getRepeatText(vals)}
					</span>
				</section>
				{/* REPEAT CYCLE OPTIONS - (e.g. Days or 2nd Weekday of each n) */}
				<section className={styles.RepeatTaskWindow_repeatOptions}>
					<div className={styles.RepeatTaskWindow_repeatOptions_days}>
						{showDays(vals) &&
							days.map((day, index) => (
								<DayOption
									key={`${day}__${index}`}
									dayVal={vals[`isRecurring${day}`]}
									day={day}
									label={day}
									name={`isRecurring${day}`}
									id={`isRecurring${day}`}
									selectDay={selectDay}
									handleDay={handleDaySelections}
								/>
							))}
					</div>
					{showCycleOptions(vals.recurringType) && (
						<div className={styles.RepeatTaskWindow_repeatDays_options}>
							<CycleOption
								name="repeatSubCycle"
								id="Weekday"
								label={getRepeatOptionsText(vals).weekday}
								optionVal={vals.repeatSubCycle}
								handleOption={handleCycleOptions}
							/>
							<CycleOption
								name="repeatSubCycle"
								id="Date"
								label={getRepeatOptionsText(vals).date}
								optionVal={vals.repeatSubCycle}
								handleOption={handleCycleOptions}
							/>
						</div>
					)}
				</section>
				{/* TEXT DESCRIPTION OR REPEAT SETTINGS */}
				<section className={styles.RepeatTaskWindow_repeatDesc}>
					<span>{getCompleteRepeatDesc(vals)}</span>
				</section>
				{/* ACTION BUTTONS - SAVE/CANCEL */}
				<section className={styles.RepeatTaskWindow_buttons}>
					{/*  */}
					<ButtonSM handleClick={saveHandler} customStyles={saveBtn}>
						<span>Save</span>
					</ButtonSM>
					<ButtonSM handleClick={cancelHandler} customStyles={discardBtn}>
						<span>Cancel</span>
					</ButtonSM>
				</section>

				{/*  */}
			</aside>
		</>
	);
};

export default RepeatTaskModal;

RepeatTaskModal.defaultProps = {};

RepeatTaskModal.propTypes = {
	vals: PropTypes.object.isRequired,
	closeRepeatWindow: PropTypes.func.isRequired,
	selectDay: PropTypes.func.isRequired,
	saveRepeat: PropTypes.func.isRequired,
	cancelRepeat: PropTypes.func.isRequired,
	handleSettings: PropTypes.func.isRequired,
	handleCycleOptions: PropTypes.func.isRequired, // NOT SURE IF THIS IS NEEDED???
};
