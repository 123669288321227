import React, { useState } from "react";
import styles from "../../css/calendar/Calendar.module.scss";
import { PropTypes } from "prop-types";
import { useDates } from "../../utils/useDates";
// COMPONENTS
import * as Sentry from "@sentry/react";
import ResidentDetails from "./ResidentDetails";
import CalendarNav from "./CalendarNav";
import CalendarHeading from "./CalendarHeading";
import Week from "./Week";

// ##TODOS:
// 1. THINK ABOUT LOCALSTATE FOR SWITCHING BETWEEN WEEKS (IE LAST WEEK, NEXT WEEK ETC)
//    1a. CONSIDER HIDING/DISABLING THE "PREV" & "NEXT" BUTTONS IN <CalendarNav/> UNTIL THAT FEATURE IS ENABLED
// 3. CREATE A "CACHE" HOOK THAT CACHES THE "weeklyTasks" CURRENT STATE, *BEFORE* SWITCHING BETWEEN PAST/FUTURE WEEK(S)
// 		3a. CONSIDER STORING IN 'sessionStorage'/'localStorage' FOR EASY RECALL
// 		3b. CONSIDER STORING IN 'IndexedDB' AND FETCHING VIA SERVICE-WORKER/WEB-WORKER
// 4. WIRE UP THE "fetchWeeklyTasks" HANDLER & TIE IT INTO A "CACHING" HELPER FOR QUICK RECALL OF PREVIOUSLY USED DATA.

const Calendar = ({
	currentUser = {},
	categories = [],
	currentResident = {},
	currentFacility = {},
	weeklyTasks = [],
	isLoading = false,
}) => {
	const { globalDates, getNextWeek, getPrevWeek } = useDates();
	const { month, week, year } = globalDates;
	// contains a months worth of tasks week (ie last week, current & next week)
	const [monthlyTasks, setMonthlyTasks] = useState({
		lastWeek: [],
		thisWeek: [],
		nextWeek: [],
	});

	// when "Current Month" in nav is clicked
	const changeMonth = () => {
		// open <MonthPicker/> when 'month' is clicked in <CalendarNav/>
		// manually set active month/year from <MonthPicker/>
	};
	const changeWeekPast = () => {
		// when user clicks "prevWeek" or "nextWeek"
		// load up weekly tasks from localState property
		// set current tasks to "lastWeek"s tasks
		getPrevWeek();
	};
	const changeWeekFuture = () => {
		// set current tasks to "nextWeek"s tasks
		getNextWeek();
	};

	return (
		<Sentry.ErrorBoundary>
			<article className={styles.Calendar}>
				<section className={styles.Calendar_details}>
					<ResidentDetails
						currentResident={currentResident}
						currentUser={currentUser}
					/>
				</section>
				<CalendarNav
					currentYear={year}
					currentMonth={month}
					currentWeek={week}
					changeMonth={changeMonth}
					getNextWeek={changeWeekFuture}
					getPrevWeek={changeWeekPast}
					weekControls={{
						disableNext: true,
						disablePrev: true,
					}}
				/>
				<CalendarHeading currentMonth={month} currentWeek={week} />
				<Week
					isLoading={isLoading}
					currentDays={week.days}
					currentMonth={month}
					categories={categories}
					weeklyTasks={[...weeklyTasks.currentWeek]}
					currentFacility={currentFacility}
					currentResident={currentResident}
					currentUser={currentUser}
				/>
			</article>
		</Sentry.ErrorBoundary>
	);
};

export default Calendar;

Calendar.defaultProps = {
	weeklyTasks: [],
	categories: [],
	currentResident: {},
};

Calendar.propTypes = {
	categories: PropTypes.arrayOf(PropTypes.object), // not required if NO resident is already selected (ie from the daily view)
	currentUser: PropTypes.shape({
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired,
		password: PropTypes.string.isRequired,
		token: PropTypes.string.isRequired,
		userID: PropTypes.string.isRequired,
		facilityID: PropTypes.string.isRequired,
		isAdmin: PropTypes.bool.isRequired,
	}),
	currentResident: PropTypes.shape({
		FirstName: PropTypes.string,
		LastName: PropTypes.string,
		Age: PropTypes.number,
		ResidentID: PropTypes.number,
		FloorUnit: PropTypes.string,
		RoomNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		isLOA: PropTypes.bool,
	}),
};
