import React from "react";
import styles from "../../css/adlschedule/AdlTableBody.module.scss";
import { PropTypes } from "prop-types";

const AdlTableBody = ({ children }) => {
	return <section className={styles.AdlTableBody}>{children}</section>;
};

export default AdlTableBody;

AdlTableBody.defaultProps = {};

AdlTableBody.propTypes = {
	children: PropTypes.any.isRequired,
};
