import React, { useState } from "react";
import styles from "../../css/daily/RecentReassess.module.scss";
import { PropTypes } from "prop-types";
import {
	hasReassess,
	getReassessFromTaskNotes,
	findReassessHistory,
} from "../../helpers/utils_reassess";
import {
	emailReg,
	emailRegGroup,
	getMatchGroups,
	matchesPattern,
} from "../../helpers/utils_validation";
import { format } from "date-fns";
import { isEmptyArray } from "../../helpers/utils_types";

// shows the btn text & record count, if available
const getBtnText = (showHistory = false, matchingRecords) => {
	const count = isEmptyArray(matchingRecords) ? null : matchingRecords.length;
	const action = showHistory ? `Hide ` : `Show `;
	let txt = action;
	txt += `Suggestion History `;
	// add (count), if exists
	if (!count) return txt;
	txt += `(${count})`;

	return txt;
};

const getAddedBy = (record = {}) => {
	const { Notes } = record;
	const groups = getMatchGroups(Notes, emailRegGroup);
	const emailGroup = groups?.email;

	return emailGroup?.email;
};

const ReassessEntry = ({ record = {} }) => {
	return (
		<li className={styles.ReassessEntry}>
			<div className={styles.ReassessEntry_notes}>
				{record?.Notes ?? `No notes found.`}
			</div>
			<div className={styles.ReassessEntry_timestamp}>
				Added: {format(record?.CreatedDate, "M/D/YYYY h:mm A")}{" "}
				{getAddedBy(record)}
			</div>
		</li>
	);
};

const RecentReassess = ({
	currentResident = {},
	task = {},
	reassessRecords = [],
}) => {
	const [showHistory, setShowHistory] = useState(false);
	// array of ServicePlanChange (aka. reassess) entries
	const [reassessHistory, setReassessHistory] = useState(
		findReassessHistory(task, reassessRecords)
	);

	if (!hasReassess(task)) {
		return null;
	}
	return (
		<div className={styles.RecentReassess}>
			<div className={styles.RecentReassess_viewHistory}>
				<button
					className={styles.RecentReassess_viewHistory_btn}
					onClick={() => setShowHistory(!showHistory)}
				>
					{getBtnText(showHistory, reassessHistory)}
				</button>
			</div>
			{showHistory && (
				<ul
					className={styles.RecentReassess_entry}
					style={!showHistory ? { height: "0" } : { height: "initial" }}
				>
					{!isEmptyArray(reassessHistory) &&
						reassessHistory.map((record, idx) => (
							<ReassessEntry
								key={record?.AssessmentTaskId * idx}
								record={record}
							/>
						))}
				</ul>
			)}
		</div>
	);
};

export default RecentReassess;

RecentReassess.defaultProps = {
	currentUser: {},
	task: {},
};

RecentReassess.propTypes = {
	vals: PropTypes.object,
	task: PropTypes.object,
	currentUser: PropTypes.object,
};
