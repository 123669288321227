import React from "react";
import styles from "../../css/calendar/ADLHeadings.module.scss";
import sprite from "../../assets/icons/adlIcons.svg";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import { checkAdlNaming } from "../../helpers/utils_categories";
import { getIconName } from "../../helpers/utils_styles";

// ##TODOS:
// - Add 'ADL' icon to each ADL heading

const ADLHeadings = ({ categories = [] }) => {
	return (
		<div className={styles.ADLHeadings}>
			{!isEmptyArray(categories) &&
				categories.map((adl, index) => (
					<div
						className={styles.ADLHeadings_adl}
						key={`${adl.AdlCategoryType}_${index}`}
					>
						<div className={styles.ADLHeadings_adl_wrapper}>
							<svg className={styles.ADLHeadings_adl_wrapper_icon}>
								<use xlinkHref={`${sprite}#icon-${getIconName(adl)}`}></use>
							</svg>
						</div>
						<span>{checkAdlNaming(adl?.AdlCategoryType)}</span>
					</div>
				))}
		</div>
	);
};

export default ADLHeadings;

ADLHeadings.defaultProps = {};

ADLHeadings.propTypes = {
	categories: PropTypes.arrayOf(PropTypes.object).isRequired,
};
