import React, { useState } from "react";
import styles from "../../css/notes/NewTaskNote.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { blueGrey, red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import NoteInput from "./NoteInput";
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	cancel: {
		padding: ".2rem 1.2rem",
		backgroundColor: "transparent",
		fontSize: "1.5rem",
		fontWeight: "600",
		color: red[600],
		borderRadius: ".5rem",
	},
	save: {
		padding: ".2rem 1.2rem",
		backgroundColor: blueGrey[800],
		fontSize: "1.5rem",
		fontWeight: "600",
		color: "#ffffff",
		marginRight: "1rem",
		borderRadius: ".5rem",
	},
};

const visible = {
	visibility: "hidden",
};
const invisible = {
	visibility: "hidden",
};

const placeholder = `Add a note or list: 
1. Item 1
2. Item 2...`;

const SavedMessage = () => {
	return (
		<div className={styles.SavedMessage}>
			<div className={styles.SavedMessage_iconWrapper}>
				<svg className={styles.SavedMessage_iconWrapper_icon}>
					<use xlinkHref={`${sprite}#icon-check_circle`}></use>
				</svg>
			</div>
			<div className={styles.SavedMessage_msg}>
				Success! Your note was saved.
			</div>
		</div>
	);
};

const NewTaskNote = ({
	vals = {},
	handleChange,
	handleSubmit,
	syncTaskNotes, // syncs task note onChange w/ 'DailySummaryListItem'
	saveNewTaskNote,
	cancelNewTaskNote,
	wasNoteSaved = false,
	saveRef,
}) => {
	const [showActions, setShowActions] = useState(!isEmptyVal(vals.newNote));

	const handleNewNote = (e) => {
		handleChange(e);
		syncTaskNotes(e);
		setShowActions(!isEmptyVal(vals.newNote));
	};

	return (
		<div className={styles.NewTaskNote}>
			<div
				className={styles.NewTaskNote_input}
				// onClick={() => setShowActions(!showActions)}
			>
				{wasNoteSaved && <SavedMessage />}
				{!wasNoteSaved && (
					<NoteInput
						key={`NEW-NOTE`}
						name="newNote"
						id="newNote"
						val={vals.newNote}
						handleChange={handleNewNote}
						handleSubmit={handleSubmit}
						maxChar={500}
						enableCharCount={true}
						autoFocus={false}
						// placeholder="Add a note..."
						placeholder={placeholder}
					/>
				)}
			</div>
			{/* {showActions && !wasNoteSaved && ( */}
			<div className={styles.NewTaskNote_actions}>
				<ButtonSM
					type="button"
					btnRef={saveRef}
					isDisabled={isEmptyVal(vals.newNote)}
					handleClick={saveNewTaskNote}
					customStyles={{
						...customCSS.save,
						visibility: "visible",
					}}
				>
					Save Note
				</ButtonSM>
				<ButtonSM
					type="button"
					handleClick={cancelNewTaskNote}
					customStyles={{
						...customCSS.cancel,
						visibility: "visible",
					}}
				>
					Cancel
				</ButtonSM>
			</div>
			{/* )} */}
		</div>
	);
};

export default NewTaskNote;

NewTaskNote.defaultProps = {};

NewTaskNote.propTypes = {};
