import React, { useState, useRef, useEffect } from "react";
import { PropTypes } from "prop-types";
import styles from "../../css/reports/ReportPane.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { useOutsideClick } from "../../utils/useOutsideClick";
// components
import Spinner from "../shared/Spinner";

const featureSet = {
	enablePrint: false,
};

const sizes = {
	HALF: {
		width: "45%",
		height: "45%",
	},
	SM: {
		width: "30rem",
		height: "40rem",
	},
	MD: {
		width: "50rem",
		height: "60rem",
	},
	LG: {
		width: "100%",
		height: "100%",
	},
	FULL: {
		width: "100%",
		height: "90rem",
	},
};

const ReportPane = ({
	isLoading,
	loadingMsg = null,
	title,
	size,
	handlePrint,
	printID = "printable",
	customStyles = {},
	children,
}) => {
	const menuRef = useRef();
	const { isOutside } = useOutsideClick(menuRef);
	const [showMenu, setShowMenu] = useState(false);
	const [isCollapsed, setIsCollapsed] = useState(false);

	const custom = {
		...customStyles,
		...sizes[size],
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside) {
			return setShowMenu(false);
		}
		return () => {
			isMounted = false;
		};
	}, [isOutside]);

	return (
		<article className={styles.ReportPane} style={custom}>
			<section className={styles.ReportPane_top}>
				<div
					className={styles.ReportPane_top_toggle}
					onClick={() => setIsCollapsed(!isCollapsed)}
				>
					<h4 className={styles.ReportPane_top_toggle_title}>{title}</h4>
					<svg className={styles.ReportPane_top_toggle_icon}>
						<use
							xlinkHref={`${sprite}#icon-${
								isCollapsed ? "arrow_drop_up" : "arrow_drop_down"
							}`}
						></use>
					</svg>
				</div>
				<div className={styles.ReportPane_top_icons}>
					{featureSet?.enablePrint && (
						<svg
							className={styles.ReportPane_top_icons_icon}
							onClick={handlePrint}
						>
							<use xlinkHref={`${sprite}#icon-print1`}></use>
						</svg>
					)}
					<svg
						className={styles.ReportPane_top_icons_icon}
						onClick={() => setShowMenu(true)}
					>
						<use xlinkHref={`${sprite}#icon-settings1`}></use>
					</svg>

					{/* CORNER-RIGHT MENU */}
					<div className={styles.refWrapper} ref={menuRef}>
						{showMenu && (
							<div className={styles.ReportPane_top_icons_menu}>
								<svg
									className={styles.closeIcon}
									onClick={() => setShowMenu(false)}
								>
									<use xlinkHref={`${sprite}#icon-clearclose`}></use>
								</svg>
								<ul className={styles.ReportPane_top_icons_menu_list}>
									{featureSet?.enablePrint && (
										<li className={styles.ReportPane_top_icons_menu_list_item}>
											Print panel report
										</li>
									)}
									<li
										className={styles.ReportPane_top_icons_menu_list_item}
										onClick={() => setIsCollapsed(true)}
									>
										<b>-</b> Collapse panel
									</li>
									<li
										className={styles.ReportPane_top_icons_menu_list_item}
										onClick={() => setIsCollapsed(false)}
									>
										<b>+</b> Expand panel
									</li>
								</ul>
							</div>
						)}
					</div>
				</div>
			</section>

			{/* props.children - RECHARTS GOES BELOW HERE... */}
			{!isCollapsed && (
				<section
					className={styles.ReportPane_dataViz}
					style={{ height: `calc(${custom.height} - 10rem)` }}
					id={printID}
				>
					{isLoading ? (
						<>
							<span className={styles.loadingMsg}>{loadingMsg}</span>
							<Spinner />
						</>
					) : (
						children
					)}
				</section>
			)}
		</article>
	);
};

export default ReportPane;

ReportPane.defaultProps = {
	isLoading: false,
	customStyles: {},
	loadingMsg: null,
};

ReportPane.propTypes = {
	title: PropTypes.string,
	size: PropTypes.string,
	isLoading: PropTypes.bool,
	loadingMsg: PropTypes.string,
	customStyles: PropTypes.object,
	children: PropTypes.any,
};
