import { currentEnv } from "./utils_env";
import { reassess } from "./utils_endpoints";
import { ReassessModel } from "./utils_models";
import { isUITask, isScheduledTask } from "./utils_tasks";
import { isEmptyArray, isEmptyVal } from "./utils_types";

/**
 * @description - Saves 'Task Reassess' record to ALA Services for one or more task records.
 * @param {String} token - Base64 encoded auth token.
 * @param {Object|Array} reassessRecords - One or more 'AssessmentTrackingReassess' records. Objects will be merged in an array for the request.
 * @returns {Array} - Returns an array of 'AssessmentTrackingReassess' IDs that were updated.
 */
const saveReassessMany = async (token, reassessRecords) => {
	let url = currentEnv.base + reassess.saveReassessMany;

	let records;
	if (!Array.isArray(reassessRecords)) {
		records = [reassessRecords];
	}

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(records),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches ALL 'Reassess Records' for a specific 'AssessmentTrackingTask'.
 */
const getReassessByTrackingTask = async (token, taskID) => {
	let url = currentEnv.base + reassess.getReassess;
	url += "?" + new URLSearchParams({ AssessmentTrackingTaskId: taskID });
	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err;
	}
};
/**
 * Fetches ALL 'Reassess Records' for a specific 'AssessmentTask'.
 */
const getReassessByTracking = async (token, assessmentTaskID) => {
	let url = currentEnv.base + reassess.getReassess;
	url += "?" + new URLSearchParams({ AssessmentTaskId: assessmentTaskID });
	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err;
	}
};

// REASSESS MODEL UPDATER(S)
const updateReassessModel = (vals, userID, task, currentUser) => {
	if (!isScheduledTask(task)) return;
	if (!vals.reassess) return;

	const { username } = currentUser;

	const newNotes = !isEmptyVal(vals?.reassessNotes)
		? `${vals.reassessNotes} \n\n (${username})`
		: `No reassess notes`;

	const base = new ReassessModel();
	base.setUserID(userID);
	base.setProp("CreatedBy", userID);
	base.setTaskID(task?.AssessmentTaskId);
	base.setTrackingID(task?.AssessmentTrackingId);
	base.setTrackingTaskID(task?.AssessmentTrackingTaskId);
	// base.setNotes(vals?.reassessNotes ?? `No reassess notes`);
	base.setNotes(newNotes);
	base.setProp(`IsActive`, true);

	return base.getModel();
};

const initAndUpdateReassessModel = (vals, task, currentUser) => {
	if (!isScheduledTask(task)) return;
	if (!vals.reassess) return;

	const { userID, username } = currentUser;

	const newNotes = !isEmptyVal(vals?.reassessNotes)
		? `${vals.reassessNotes} \n\n (${username})`
		: `No reassess notes`;

	const base = new ReassessModel();
	base.setUserID(userID);
	base.setProp("CreatedBy", userID);
	base.setTaskID(task?.AssessmentTaskId);
	base.setTrackingID(task?.AssessmentTrackingId);
	base.setTrackingTaskID(task?.AssessmentTrackingTaskId);
	// base.setNotes(vals?.reassessNotes ?? `No reassess notes`);
	base.setNotes(newNotes);
	base.setProp(`IsActive`, true);

	return base.getModel();
};

// updates model, fires off request
const applyReassessToTracking = async (
	token,
	vals,
	userID,
	task,
	currentUser
) => {
	if (!vals.reassess) return {};

	const updatedModel = updateReassessModel(vals, userID, task, currentUser);
	const reassessIDs = await saveReassessMany(token, updatedModel);

	const success = !isEmptyArray(reassessIDs);

	if (success) {
		return {
			success: success,
			reassessRecord: {
				...updatedModel,
				AssessmentTrackingReassessId: reassessIDs?.[0] ?? 0,
			},
			ids: reassessIDs,
		};
	} else {
		return { success, reassessRecord: {}, ids: reassessIDs };
	}
};

// REASSESS TYPE HELPERS

/**
 * Finds matching 'Reassess' record for a given task id.
 * @param {Number} taskID - An 'AssessmentTrackingTaskId' from the target task
 * @param {Array} reassessRecords - An array of 'AssessmentTrackingReassess' records.
 */
const matchReassessByTaskID = (taskID, reassessRecords = []) => {
	if (isEmptyArray(reassessRecords)) return null;
	return reassessRecords.reduce((match, record) => {
		const { AssessmentTrackingTaskId } = record;
		if (AssessmentTrackingTaskId === taskID) {
			match = { ...record };
			return match;
		}
		return match;
	}, {});
};
/**
 * Finds ALL 'Reassess Records' by 'AssessmentTrackingId'.
 */
const matchReassessByTrackingID = (trackingID, reassessRecords = []) => {
	if (isEmptyArray(reassessRecords)) return [];
	return reassessRecords.filter(
		(record) => record?.AssessmentTrackingId !== trackingID
	);
};

/**
 * Finds ALL matching reassess records that match either the 'Assessment Task' or the 'Tracking Task'.
 */
const findReassessHistory = (task, allHistory = []) => {
	if (isEmptyArray(allHistory)) return [];
	return allHistory.filter((record) => {
		if (
			record?.AssessmentTrackingTaskId === task?.AssessmentTrackingTaskId ||
			record?.AssessmentTaskId === task?.AssessmentTaskId
		) {
			// if matching assessment task or tracking task, then keep record
			return record;
		}
		return;
	});
};

const hasReassess = (task) => {
	if (isUITask(task)) {
		const hasEntry =
			!isEmptyVal(task?.ReasonForReassess) ||
			!isEmptyVal(task?.Reason) ||
			task?.AssessmentResolutionId === 2;
		return hasEntry;
	} else {
		return task?.AssessmentResolutionId === 2;
	}
};

// extracts the 'reassess notes' from a task's 'TaskNotes' field
const getReassessFromTaskNotes = (task) => {
	const reassess = /(Reassess:)(.{1,})/gm;
	const timestamp =
		/([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{1,4})( at )([0-9]{1,2})(:)([0-9]{1,2})\s(AM|PM)/gm;
	const rawNotes = task?.TaskNotes;

	const notes = rawNotes.match(reassess)?.[0];
	const timeStamp = rawNotes.match(timestamp)?.[0];
	return {
		notes,
		timeStamp,
	};
};

// request helpers
export {
	getReassessByTracking,
	getReassessByTrackingTask,
	saveReassessMany,
	applyReassessToTracking,
};

export { hasReassess };

export { getReassessFromTaskNotes };

// model updaters
export { updateReassessModel, initAndUpdateReassessModel };

export {
	matchReassessByTaskID,
	matchReassessByTrackingID,
	findReassessHistory,
};
