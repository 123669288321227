import React from "react";
import styles from "../../css/reports/ReportInfoPanel.module.scss";
import { PropTypes } from "prop-types";
// components
import ReportsController from "./ReportsController";
import RecentlyViewedReports from "./RecentlyViewedReports";
import TabsContainer from "../shared/TabsContainer";
import TabButton from "../shared/TabButton";
import TabButtons from "../shared/TabButtons";
import TabPanels from "../shared/TabPanels";
import TabPanel from "../shared/TabPanel";
import ReportSettings from "./ReportSettings";
import ReportDesc from "./ReportDesc";

const ReportInfoPanel = ({
	title,
	reportTitle,
	reportDesc,
	reportSettings = {},
	recentlyViewedList = [],
	rerunReport,
	removeRecentsItem,
	handleReportsPicker,
	emptyMsg = `No report found.`,
	hasLoaded = false,
}) => {
	return (
		<div className={styles.ReportInfoPanel}>
			<ReportsController>
				<TabsContainer defaultIndex={1}>
					<TabButtons>
						<TabButton>Recently Viewed</TabButton>
						<TabButton>Report Settings</TabButton>
					</TabButtons>
					<TabPanels>
						<TabPanel>
							<RecentlyViewedReports
								emptyMsg={emptyMsg}
								reportDesc={reportDesc}
								recentlyViewedList={recentlyViewedList}
								rerunReport={rerunReport}
								removeItem={removeRecentsItem}
								handleReportsPicker={handleReportsPicker}
							/>
						</TabPanel>
						<TabPanel>
							<ReportSettings
								hasLoaded={hasLoaded}
								title={reportTitle}
								reportDesc={reportDesc}
								reportSettings={reportSettings}
								handleReportsPicker={handleReportsPicker}
							/>
						</TabPanel>
					</TabPanels>
				</TabsContainer>
			</ReportsController>
		</div>
	);
};

export default ReportInfoPanel;

ReportInfoPanel.defaultProps = {
	recentlyViewedList: [],
	emptyMsg: `No report found.`,
};

ReportInfoPanel.propTypes = {
	reportDesc: PropTypes.string,
	emptyMsg: PropTypes.string,
	recentlyViewedList: PropTypes.array,
	rerunReport: PropTypes.func.isRequired,
	removeRecentsItem: PropTypes.func.isRequired,
	handleReportsPicker: PropTypes.func.isRequired,
};
