import { isEmptyObj, isEmptyVal, isEmptyArray, hasProp } from "./utils_types";
import { getChangedDays, getChangedShifts } from "./utils_updates";
import { getRecurringTypeID } from "./utils_repeatingTasks";
import { format } from "date-fns";
import { removeDupsAndFalsey, removeDuplicates } from "./utils_processing";

// ##TODOS:
// - Fix report picker validation
// - 'filterByXXXX' is NOT getting picked up in validator

/**
 * @description - Takes an object and an array of keys and checks if the keys are empty or not and returns true if valid and false if invalid(or empty)
 * @param {Object} vals - An object of key/value pairs (typically form values)
 * @param {Array} keysToCheck - An array of object keys that need to be checked/validated.
 */
const isValid = (vals = {}, keysToCheck = []) => {
	if (isEmptyObj(vals)) return false;
	const tests = keysToCheck.map((key) => {
		if (isEmptyVal(vals[key])) {
			return false;
		}
		return true;
	});
	return tests.includes(false) ? false : true;
};

/**
 * Default validation 'error' messages for handling password requirements.
 */
const ERROR_MSGS = {
	hasNum: `Must include a number.`,
	hasLower: `Must include a lowercase letter.`,
	hasUpper: `Must include a uppercase letter.`,
	hasSpecial: `Must include a special character.`,
	hasMinLength: `Must be at least 6 characters.`,
};

////////////////////////////////////////////////////////////////////////
/////////////////////// SHIFT TIMES' VALIDATORS ///////////////////////
////////////////////////////////////////////////////////////////////////

// ##TODOS:
// - Create 'Shift times' validation:
// 		- Prevent shift times from overlapping
// 		- Prevent 'gaps' in a facility's 24hrs

/**
 * PASSWORD VALIDATOR UTILS & PATTERNS
 *
 */

/**
 * Password validation patterns:
 * - Numbers
 * - Uppercase/lowercase
 * - Special chars
 * - Min. length
 */
/**
 * REGEX PATTERNS
 * - Special chars, numbers, uppercase, lowercase
 */
const PATTERNS = {
	specialChars: /[!@#$%^&*(),.?":{}|<>]{1,}/gm,
	numbers: /(\d{1,})/gm,
	upperCase: /([A-Z]{1,})/gm,
	lowerCase: /([a-z]{1,})/gm,
};
/**
 * Password requirement(s) variations:
 * - Numbers
 * - Lowercase/uppercase
 * - Non-words
 * - Min. length (6)
 */
const PATTERN_VARIATIONS = {
	hasNum: /\d/,
	hasLower: /[a-z]/,
	hasUpper: /[A-Z]/,
	hasNonWord: /\W/,
	hasMinLength: /^.{6,}$/,
};
const PATTERNS_USERNAME = {
	hasNum: /\d{1,}/,
	hasLower: /[a-z]{1,}/,
	hasUpper: /[A-Z]{1,}/,
	hasMinLength: /^.{6}/,
};

const sortByReg = /(^sortBy)(?=[\w]{1,})/gm; // matches 'sortBy'
const sortTypeReg = /(By[\w]{1,})/gm; // matches 'ByXXXX'
const sortPrefix = /(sortBy)/gm;

const emailReg =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emailRegGroup =
	/^(?<email>(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/;

const getAllSorts = (settings) => {
	const allKeys = Object.keys(settings);
	return allKeys.filter((key) => key.match(sortByReg) || key.match(sortPrefix));
};
// this will take a 'settings' object typically used in a formState instance & extract all the 'sortBy' key names and reset only those fields.
const resetSortsOnly = (settings) => {
	const allSorts = getAllSorts(settings);
	return allSorts.reduce((reset, sort) => {
		if (settings.hasOwnProperty(sort)) {
			settings[sort] = "";
			reset = { ...settings };
			return { ...reset };
		}
		return { ...reset };
	}, {});
};

////////////////////////////////////////////////////////////////////////
//////////////////// RECURRING SETTINGS VALIDATORS ////////////////////
////////////////////////////////////////////////////////////////////////

// sorts ['Mon', 'Tues',....] into alpha order for comparison
const sortDays = (days) => {
	if (isEmptyArray(days)) return [];
	return days.sort((a, b) => a.localeCompare(b));
};
const changedStart = (vals, task) => {
	const { startDate } = vals;
	const { RecurringStartDate } = task;
	return isEmptyVal(RecurringStartDate) !== isEmptyVal(startDate);
};
const changedEnd = (vals, task) => {
	const { startDate } = vals;
	const { RecurringEndDate } = task;
	return isEmptyVal(RecurringEndDate) !== isEmptyVal(startDate);
};
// checks if date range has changed
const changedDateRange = (fromVals, fromTask) => {
	const diffStart = changedStart(fromVals, fromTask);
	const diffEnd = changedEnd(fromVals, fromTask);
	if (!diffStart && !diffEnd) return false;

	const startChanged =
		format(fromTask?.RecurringStartDate, "MM/DD/YYYY") !==
		format(fromVals?.startDate, "MM/DD/YYYY");

	const endChanged =
		format(fromTask?.RecurringEndDate, "MM/DD/YYYY") !==
		format(fromVals?.endDate, "MM/DD/YYYY");

	return diffStart || diffEnd || startChanged || endChanged;
};
// checks if 'recurring days' have changed
const changedDays = (vals, task) => {
	const changed = getChangedDays(vals, task);
	if (changed.length !== task?.RecurringDays.length) {
		return true;
	} else {
		// match each day
		const sortChanged = sortDays(changed);
		const sortTask = sortDays(task?.RecurringDays);
		return JSON.stringify(sortChanged) !== JSON.stringify(sortTask);
	}
};
// checks if 'recurring shifts' have changed
const changedShifts = (vals, task) => {
	const { RecurringShifts } = task;
	const changed = getChangedShifts(vals, task);
	return JSON.stringify(changed) !== JSON.stringify(RecurringShifts);
};
// checks if 'recurring type' has changed
const changedRecurringType = (vals, task) => {
	const { AssessmentRecurringId: taskRecurringID } = task;
	return getRecurringTypeID(vals?.recurringType) !== taskRecurringID;
};
// checks if 'isLocked' has changed
const changedLock = (vals, task) => {
	const { IsLocked } = task;
	const { isLocked } = vals;
	return IsLocked !== isLocked;
};
// checks if settings have changed
const changedSettings = (vals, task) => {
	const hasChanged =
		changedRecurringType(vals, task) ||
		changedDateRange(vals, task) ||
		changedShifts(vals, task) ||
		changedDays(vals, task) ||
		changedLock(vals, task);

	return hasChanged;
};

/**
 * Checks whether any target fields have been touched or changed.
 * @param {Object} formState - An object from 'useForm()'s local state with 'values' & 'touched' properties
 * @param {String[]} fieldsToCheck - An array of string field names to validate/check
 * @returns {Boolean} - Returns whether any fields have been touched or changed
 */
const checkForChanges = (formState, fieldsToCheck = []) => {
	const { values, touched } = formState;
	// iterate thru 'fieldsToCheck' & check 'touched' and 'values'
	const hasAnyChanges = fieldsToCheck.some((field) => {
		const isNotEmpty = !isEmptyVal(values?.[field]);
		const wasTouched = hasProp(touched, field);
		return isNotEmpty && wasTouched;
	});

	return hasAnyChanges;
};

//////////////////////////////////////////////////////////////////
//////////////////////// VALIDATION UTILS ////////////////////////
//////////////////////////////////////////////////////////////////

// REQUIREMENTS:
// - reportType
// - filterBy & filterByXXXX
// - shiftAM | PM | NOC
// - dateRangeType & byMonth | Quarter | Year
// - sortBy & sortByXXXX

// gets the sortByXXXX key
const getSortByKey = (settings) => {
	if (isEmptyVal(settings)) return "";
	const { sortBy } = settings;
	const trimmed = sortBy.replace(" ", "");

	return `sort${trimmed}`;
};
// gets the filterByXXXX key
const getFilterByKey = (settings) => {
	const { filterBy } = settings;
	if (isEmptyVal(filterBy)) return "";
	return `filterBy${filterBy}`;
};
// gets the dateRangeType key name
const getDateRangeTypeKey = (settings) => {
	if (isEmptyVal(settings.dateRangeType)) return;
	if (settings?.dateRangeType === `Today`) return;
	if (settings?.dateRangeType === `Custom Range`) return;

	const type = settings?.dateRangeType.split(" ")[1];
	const val = `by${type}`;
	return val;
};
// checks for shift selections
const hasShift = (settings) => {
	const { shiftAM, shiftPM, shiftNOC } = settings;
	return shiftAM || shiftPM || shiftNOC;
};

// SINGLE RESPONSIBILITY VALIDATOR FIELD UTILS
// gets fields for 'byXXXX' date range type
const getDateField = (
	settings,
	fieldsToValidate = [`reportType`, `filterBy`, `dateRangeType`]
) => {
	const { dateRangeType } = settings;

	if (dateRangeType === `Today`) {
		return [...fieldsToValidate];
	} else if (dateRangeType === `Custom Range`) {
		return [...fieldsToValidate, `startDate`, `endDate`];
	} else if (isEmptyVal(dateRangeType)) {
		return [...fieldsToValidate, `dateRangeType`];
	} else {
		return [
			...fieldsToValidate,
			`dateRangeType`,
			getDateRangeTypeKey(settings),
		];
	}
};
// get fields for 'filterByXXXX'
const getFilterField = (
	settings,
	fieldsToValidate = [`reportType`, `filterBy`, `dateRangeType`]
) => {
	if (!isEmptyVal(settings?.filterBy)) {
		return [...fieldsToValidate, getFilterByKey(settings)];
	} else {
		return [...fieldsToValidate, getFilterByKey(settings)];
	}
};
// gets field for 'dateRangeType'
const getDateRangeField = (
	settings,
	fieldsToValidate = [`reportType`, `filterBy`, `dateRangeType`]
) => {
	if (
		!isEmptyVal(settings?.dateRangeType) &&
		settings.dateRangeType !== `Today`
	) {
		return [...fieldsToValidate, getDateRangeTypeKey(settings)];
	} else {
		return [...fieldsToValidate];
	}
};
// gets field for 'sortByXXXX'
const getSortField = (
	settings,
	fieldsToValidate = [`reportType`, `filterBy`, `dateRangeType`]
) => {
	// sorts without sub options (ie RadioButton, CustomDropdown etc)
	const noSubOptions = [
		`By ADL`,
		`By Shift`,
		`By Unit Type`,
		`By Floor Unit`,
		`Was Completed`,
		`By Exception Type`,
	];
	if (isEmptyVal(settings?.sortBy)) return [...fieldsToValidate];

	if (
		!isEmptyVal(settings?.sortBy) &&
		!noSubOptions.includes(settings.sortBy)
	) {
		return [...fieldsToValidate, getSortByKey(settings)];
	} else {
		return [...fieldsToValidate];
	}
};

// wrapper around ALL field validator checks
const getFields = (
	settings,
	fieldsToValidate = [`reportType`, `filterBy`, `dateRangeType`]
) => {
	const withFilters = getFilterField(settings, fieldsToValidate);
	const withDates = getDateField(settings, withFilters);
	const withSorts = getSortField(settings, withDates);

	return removeDupsAndFalsey([...withSorts]);
};

// report picker validator
// enables/disables button in report picker based off user selections
// ✓ Fixed/Updated Validator Util
const validateFields = (settings, fields = []) => {
	if (!hasShift(settings)) return false;

	const isValid = fields.every((x) => !isEmptyVal(settings[x]));
	return isValid;
};

// gets fields to validate for 'ServicePlanReport' picker
const getSvcPlanFields = (settings, fieldsToValidate = []) => {
	const withDates = getDateField(settings, [...fieldsToValidate]);
	return removeDuplicates([...withDates]);
};
// checks required fields have selections - ServicePlanReport specific
const validateSvcPlanFields = (settings, listOfFields = []) => {
	return listOfFields.every((field) => {
		if (!isEmptyVal(settings[field])) {
			return true;
		} else {
			return false;
		}
	});
};

// PASSWORD VALIDATORS //
/**
 * Calculates a password's approx. strength score.
 * - Validation methods:
 * - Counts unique letters
 * - Uppercase & lowercase
 * - Numbers
 * - Special characters/non-word characters
 *
 * @returns {Number} - Returns numeric score of password strength starting at 0. higher score is more secure password.
 */
const calcPasswordScore = (val, minLength = 6) => {
	let score = 0;
	if (!val) return score;

	// award every unique letter until 5 repetitions
	let letters = {};
	for (let i = 0; i < val.length; i++) {
		letters[val[i]] = (letters[val[i]] || 0) + 1;
		score += 5.0 / letters[val[i]];
	}

	// formats minLength regex
	const min = new RegExp(`^.{${minLength},}$`);

	// bonus points for mixing it up
	const variations = {
		hasNum: /\d/.test(val),
		hasLower: /[a-z]/.test(val),
		hasUpper: /[A-Z]/.test(val),
		hasNonWord: /\W/.test(val),
		hasMinLength: min.test(val),
	};

	// iterates thru keys in 'variations' & runs each test
	// each 'true' gives 1pt, each 'false' gives 0pt
	let variationCount = 0;
	for (let test in variations) {
		variationCount += variations[test] === true ? 1 : 0;
	}
	score += (variationCount - 1) * 10;

	return parseInt(score);
};

/**
 * @param {Number} score - The numeric strength score from 'calcPasswordScore'
 * Runs password score util & determines strength label
 * @returns {String} - Returns the strength type (ie 'Strong', 'Weak', 'Poor', etc.)
 */
const getPasswordStrength = (score) => {
	switch (true) {
		case score > 105: {
			return `Very Strong`;
		}
		case score > 100: {
			return `Strong`;
		}
		case score > 80: {
			return `Good`;
		}
		case score > 70: {
			return `Moderate`;
		}
		case score > 50: {
			return `Fair`;
		}
		case score > 30: {
			return `Weak`;
		}
		case score > 10: {
			return `Poor`;
		}
		case score > 5: {
			return `Very Poor`;
		}
		default:
			return "";
	}
};

//////////////////////////////////////////////////////////////////////////////
////////////////////////////// MISC VALIDATORS //////////////////////////////
//////////////////////////////////////////////////////////////////////////////

const testUsername = (
	val,
	patterns = { ...PATTERNS_USERNAME },
	errorMsgs = {}
) => {
	// run tests...
	// get errors, if any
	const keys = Object.keys(patterns);
	const results = keys.every((key) => patterns[key].test(val));
	const errors = !results ? getErrors(val, keys) : [];

	// gets keys of failed tests
	function getErrors(val, keys) {
		return keys.map((key) => {
			const passes = patterns[key].test(val);
			if (!passes) {
				return errorMsgs[key];
			} else {
				return null;
			}
		});
	}
	// check if valid email format
	if (!results && isValidEmail(val)) {
		return { isValid: true, errors: [] };
	}

	return {
		isValid: results,
		errors: errors.filter(Boolean),
	};
};

const isValidEmail = (val = "") => {
	if (isEmptyVal(val)) return false;
	return emailReg.test(val);
};

const testEmail = (val) => {
	const isValid = isValidEmail(val);
	const msg = !isValid ? `MUST be a valid email address.` : "";
	return {
		isValid,
		errors: [msg],
	};
};

//////////////////////////////////////////////////////////////////////////////
///////////////////////////// PATTERN MATCHER(S) /////////////////////////////
//////////////////////////////////////////////////////////////////////////////

/**
 * @description - A light 'wrapper' around String.prototype.test()
 * @param {String} val - A string to test.
 * @param {RegExp} pattern - A regex pattern to match/test for.
 * @returns {Boolean} - Returns true|false
 */
const matchesPattern = (val, pattern) => {
	const newPattern = new RegExp(pattern);
	const isMatch = newPattern.test(val);
	return isMatch;
};

/**
 * @description - Util that tests that two password entries are identical via regex.
 * @param {String} val - A string to test with.
 * @param {String} password - The 'original' password to match/test against.
 * @returns {Boolean} - Returns true|false
 */
const matchesPassword = (val, password) => {
	const pattern = new RegExp(password);
	const isMatch = pattern.test(val);
	return isMatch;
};
/**
 * @description - Tests that a string meets a minimum length.
 * @param {String} val - A string to test.
 * @param {Number} minLength - The length in characters to test for.
 * @returns {Boolean} - Returns true|false
 */
const matchesMinLength = (val, minLength = 6) => {
	return val.length >= minLength;
};

/**
 * Returns all regex match groups for a string value.
 * @param {String} val - Target string to test
 * @param {RegExp} pattern - RegExp to match for.
 * @returns {Object} - Returns an object of regex matches if found.
 */
const getMatchGroups = (val, pattern) => {
	if (matchesPattern(val, pattern)) {
		const matches = pattern.exec(val);
		const groups = matches?.groups ?? {};
		return { ...groups };
	} else {
		return {};
	}
};

export { matchesPattern, matchesPassword, matchesMinLength, getMatchGroups };

// validates a list of keys in an object
export { isValid };

export { emailReg, emailRegGroup };

// SORT VALIDATION HELPERS
// helpers for finding keys by name
// regex
export { sortByReg, sortTypeReg, sortPrefix };
export { getAllSorts, resetSortsOnly };
// recurring change validators
export {
	changedLock,
	changedDateRange,
	changedDays,
	changedShifts,
	changedRecurringType,
	changedSettings,
	// custom validator
	checkForChanges,
};

// validation utils
export {
	getDateField,
	getDateRangeField,
	getSortField,
	getFilterField,
	// field validators
	getSvcPlanFields,
	getFields,
	getSortByKey,
	getFilterByKey,
	getDateRangeTypeKey,
	hasShift,
};

// field validators
export { validateSvcPlanFields, validateFields };

// email validator utils
export { isValidEmail, testUsername, testEmail };

// password validators
export { calcPasswordScore, getPasswordStrength };
