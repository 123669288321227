import * as Sentry from "@sentry/react";
import {
	isToday,
	format,
	isPast,
	distanceInWordsToNow,
	differenceInDays,
	distanceInWords,
	startOfDay,
	endOfDay,
	startOfMonth,
	endOfMonth,
	startOfQuarter,
	endOfQuarter,
	subDays,
	getTime,
	getQuarter,
	getYear,
	startOfYear,
	endOfYear,
	isSameMonth,
	subYears,
	isWithinRange,
	subMonths,
	isThisMonth,
	subWeeks,
	startOfWeek,
	subQuarters,
	isValid,
	isTomorrow,
	isAfter,
	isYesterday,
	isFuture,
} from "date-fns";
import { isEmptyVal } from "./utils_types";
import { isScheduledTask } from "./utils_tasks";

/**
 * Timezones:
 * - Zones have been updated w/ their respective abbreviations, as best as possible.
 * - Zones have been sorted alphabetically by name (ie. 'Afg', 'Bethelhem'...)
 */
const timezones = [
	{
		name: "Afghanistan Standard Time",
		zone: "(GMT+04:30) Kabul",
	},
	{
		name: "Alaskan Standard Time",
		zone: "(GMT-09:00) Alaska",
	},
	{
		name: "Arab Standard Time",
		zone: "(GMT+03:00) Kuwait, Riyadh",
	},
	{
		name: "Arabian Standard Time",
		zone: "(GMT+04:00) Abu Dhabi, Muscat",
	},
	{
		name: "Arabic Standard Time",
		zone: "(GMT+03:00) Baghdad",
	},
	{
		name: "Argentina Standard Time",
		zone: "(GMT-03:00) Buenos Aires",
	},
	{
		name: "Atlantic Standard Time",
		zone: "(GMT-04:00) Atlantic Time (Canada)",
	},
	{
		name: "AUS Central Standard Time",
		zone: "(GMT+09:30) Darwin",
	},
	{
		name: "AUS Eastern Standard Time",
		zone: "(GMT+10:00) Canberra, Melbourne, Sydney",
	},
	{
		name: "Azerbaijan Standard Time",
		zone: "(GMT+04:00) Baku",
	},
	{
		name: "Azores Standard Time",
		zone: "(GMT-01:00) Azores",
	},
	{
		name: "Canada Central Standard Time",
		zone: "(GMT-06:00) Saskatchewan",
	},
	{
		name: "Cape Verde Standard Time",
		zone: "(GMT-01:00) Cape Verde Is.",
	},
	{
		name: "Caucasus Standard Time",
		zone: "(GMT+04:00) Yerevan",
	},
	{
		name: "Cen. Australia Standard Time",
		zone: "(GMT+09:30) Adelaide",
	},
	{
		name: "Central America Standard Time",
		zone: "(GMT-06:00) Central America",
	},
	{
		name: "Central Asia Standard Time",
		zone: "(GMT+06:00) Astana, Dhaka",
	},
	{
		name: "Central Brazilian Standard Time",
		zone: "(GMT-04:00) Manaus",
	},
	{
		name: "Central Europe Standard Time",
		zone: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
	},
	{
		name: "Central European Standard Time",
		zone: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
	},
	{
		name: "Central Pacific Standard Time",
		zone: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
	},
	{
		name: "Central Standard Time",
		zone: "(GMT-06:00) Central Time (US & Canada)",
	},
	{
		name: "Central Standard Time (Mexico)",
		zone: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
	},
	{
		name: "China Standard Time",
		zone: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
	},
	{
		name: "Dateline Standard Time",
		zone: "(GMT-12:00) International Date Line West",
	},
	{
		name: "E. Africa Standard Time",
		zone: "(GMT+03:00) Nairobi",
	},
	{
		name: "E. Australia Standard Time",
		zone: "(GMT+10:00) Brisbane",
	},
	{
		name: "E. Europe Standard Time",
		zone: "(GMT+02:00) Minsk",
	},
	{
		name: "E. South America Standard Time",
		zone: "(GMT-03:00) Brasilia",
	},
	{
		name: "Eastern Standard Time",
		zone: "(GMT-05:00) Eastern Time (US & Canada)",
	},
	{
		name: "Egypt Standard Time",
		zone: "(GMT+02:00) Cairo",
	},
	{
		name: "Ekaterinburg Standard Time",
		zone: "(GMT+05:00) Ekaterinburg",
	},
	{
		name: "Fiji Standard Time",
		zone: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
	},
	{
		name: "FLE Standard Time",
		zone: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
	},
	{
		name: "Georgian Standard Time",
		zone: "(GMT+03:00) Tbilisi",
	},
	{
		name: "GMT Standard Time",
		zone: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London",
	},
	{
		name: "Greenland Standard Time",
		zone: "(GMT-03:00) Greenland",
	},
	{
		name: "Greenwich Standard Time",
		zone: "(GMT) Monrovia, Reykjavik",
	},
	{
		name: "GTB Standard Time",
		zone: "(GMT+02:00) Athens, Bucharest, Istanbul",
	},
	{
		name: "Hawaiian Standard Time",
		zone: "(GMT-10:00) Hawaii",
	},
	{
		name: "India Standard Time",
		zone: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
	},
	{
		name: "Iran Standard Time",
		zone: "(GMT+03:30) Tehran",
	},
	{
		name: "Israel Standard Time",
		zone: "(GMT+02:00) Jerusalem",
	},
	{
		name: "Jordan Standard Time",
		zone: "(GMT+02:00) Amman",
	},
	{
		name: "Korea Standard Time",
		zone: "(GMT+09:00) Seoul",
	},
	{
		name: "Mauritius Standard Time",
		zone: "(GMT+04:00) Port Louis",
	},
	{
		name: "Mid-Atlantic Standard Time",
		zone: "(GMT-02:00) Mid-Atlantic",
	},
	{
		name: "Middle East Standard Time",
		zone: "(GMT+02:00) Beirut",
	},
	{
		name: "Montevideo Standard Time",
		zone: "(GMT-03:00) Montevideo",
	},
	{
		name: "Morocco Standard Time",
		zone: "(GMT) Casablanca",
	},
	{
		name: "Mountain Standard Time",
		zone: "(GMT-07:00) Mountain Time (US & Canada)",
	},
	{
		name: "Mountain Standard Time (Mexico)",
		zone: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
	},
	{
		name: "Myanmar Standard Time",
		zone: "(GMT+06:30) Yangon (Rangoon)",
	},
	{
		name: "N. Central Asia Standard Time",
		zone: "(GMT+06:00) Almaty, Novosibirsk",
	},
	{
		name: "Namibia Standard Time",
		zone: "(GMT+02:00) Windhoek",
	},
	{
		name: "Nepal Standard Time",
		zone: "(GMT+05:45) Kathmandu",
	},
	{
		name: "New Zealand Standard Time",
		zone: "(GMT+12:00) Auckland, Wellington",
	},
	{
		name: "Newfoundland Standard Time",
		zone: "(GMT-03:30) Newfoundland",
	},
	{
		name: "North Asia East Standard Time",
		zone: "(GMT+08:00) Irkutsk, Ulaan Bataar",
	},
	{
		name: "North Asia Standard Time",
		zone: "(GMT+07:00) Krasnoyarsk",
	},
	{
		name: "Pacific SA Standard Time",
		zone: "(GMT-04:00) Santiago",
	},
	{
		name: "Pacific Standard Time",
		zone: "(GMT-08:00) Pacific Time (US & Canada)",
	},
	{
		name: "Pacific Standard Time (Mexico)",
		zone: "(GMT-08:00) Tijuana, Baja California",
	},
	{
		name: "Pakistan Standard Time",
		zone: "(GMT+05:00) Islamabad, Karachi",
	},
	{
		name: "Romance Standard Time",
		zone: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
	},
	{
		name: "Russian Standard Time",
		zone: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
	},
	{
		name: "SA Eastern Standard Time",
		zone: "(GMT-03:00) Georgetown",
	},
	{
		name: "SA Pacific Standard Time",
		zone: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
	},
	{
		name: "SA Western Standard Time",
		zone: "(GMT-04:00) La Paz",
	},
	{
		name: "Samoa Standard Time",
		zone: "(GMT-11:00) Midway Island, Samoa",
	},
	{
		name: "SE Asia Standard Time",
		zone: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
	},
	{
		name: "Singapore Standard Time",
		zone: "(GMT+08:00) Kuala Lumpur, Singapore",
	},
	{
		name: "South Africa Standard Time",
		zone: "(GMT+02:00) Harare, Pretoria",
	},
	{
		name: "Sri Lanka Standard Time",
		zone: "(GMT+05:30) Sri Jayawardenepura",
	},
	{
		name: "Taipei Standard Time",
		zone: "(GMT+08:00) Taipei",
	},
	{
		name: "Tasmania Standard Time",
		zone: "(GMT+10:00) Hobart",
	},
	{
		name: "Tokyo Standard Time",
		zone: "(GMT+09:00) Osaka, Sapporo, Tokyo",
	},
	{
		name: "Tonga Standard Time",
		zone: "(GMT+13:00) Nuku'alofa",
	},
	{
		name: "US Eastern Standard Time",
		zone: "(GMT-05:00) Indiana (East)",
	},
	{
		name: "US Mountain Standard Time",
		zone: "(GMT-07:00) Arizona",
	},
	{
		name: "Venezuela Standard Time",
		zone: "(GMT-04:30) Caracas",
	},
	{
		name: "Vladivostok Standard Time",
		zone: "(GMT+10:00) Vladivostok",
	},
	{
		name: "W. Australia Standard Time",
		zone: "(GMT+08:00) Perth",
	},
	{
		name: "W. Central Africa Standard Time",
		zone: "(GMT+01:00) West Central Africa",
	},
	{
		name: "W. Europe Standard Time",
		zone: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
	},
	{
		name: "West Asia Standard Time",
		zone: "(GMT+05:00) Tashkent",
	},
	{
		name: "West Pacific Standard Time",
		zone: "(GMT+10:00) Guam, Port Moresby",
	},
	{
		name: "Yakutsk Standard Time",
		zone: "(GMT+09:00) Yakutsk",
	},
];
/**
 * Legacy's timezone formatted list:
 * - Format: "(<zone>) <area>"
 */
const legacyTimezones = [
	"(GMT) Casablanca",
	"(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
	"(GMT) Monrovia, Reykjavik",
	"(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
	"(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
	"(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
	"(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
	"(GMT+01:00) West Central Africa",
	"(GMT+02:00) Amman",
	"(GMT+02:00) Athens, Bucharest, Istanbul",
	"(GMT+02:00) Beirut",
	"(GMT+02:00) Cairo",
	"(GMT+02:00) Harare, Pretoria",
	"(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
	"(GMT+02:00) Jerusalem",
	"(GMT+02:00) Minsk",
	"(GMT+02:00) Windhoek",
	"(GMT+03:00) Baghdad",
	"(GMT+03:00) Kuwait, Riyadh",
	"(GMT+03:00) Moscow, St. Petersburg, Volgograd",
	"(GMT+03:00) Nairobi",
	"(GMT+03:00) Tbilisi",
	"(GMT+03:30) Tehran",
	"(GMT+04:00) Abu Dhabi, Muscat",
	"(GMT+04:00) Baku",
	"(GMT+04:00) Port Louis",
	"(GMT+04:00) Yerevan",
	"(GMT+04:30) Kabul",
	"(GMT+05:00) Ekaterinburg",
	"(GMT+05:00) Islamabad, Karachi",
	"(GMT+05:00) Tashkent",
	"(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
	"(GMT+05:30) Sri Jayawardenepura",
	"(GMT+05:45) Kathmandu",
	"(GMT+06:00) Almaty, Novosibirsk",
	"(GMT+06:00) Astana, Dhaka",
	"(GMT+06:30) Yangon (Rangoon)",
	"(GMT+07:00) Bangkok, Hanoi, Jakarta",
	"(GMT+07:00) Krasnoyarsk",
	"(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
	"(GMT+08:00) Irkutsk, Ulaan Bataar",
	"(GMT+08:00) Kuala Lumpur, Singapore",
	"(GMT+08:00) Perth",
	"(GMT+08:00) Taipei",
	"(GMT+09:00) Osaka, Sapporo, Tokyo",
	"(GMT+09:00) Seoul",
	"(GMT+09:00) Yakutsk",
	"(GMT+09:30) Adelaide",
	"(GMT+09:30) Darwin",
	"(GMT+10:00) Brisbane",
	"(GMT+10:00) Canberra, Melbourne, Sydney",
	"(GMT+10:00) Guam, Port Moresby",
	"(GMT+10:00) Hobart",
	"(GMT+10:00) Vladivostok",
	"(GMT+11:00) Magadan, Solomon Is., New Caledonia",
	"(GMT+12:00) Auckland, Wellington",
	"(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
	"(GMT+13:00) Nuku'alofa",
	"(GMT-01:00) Azores",
	"(GMT-01:00) Cape Verde Is.",
	"(GMT-02:00) Mid-Atlantic",
	"(GMT-03:00) Brasilia",
	"(GMT-03:00) Buenos Aires",
	"(GMT-03:00) Georgetown",
	"(GMT-03:00) Greenland",
	"(GMT-03:00) Montevideo",
	"(GMT-03:30) Newfoundland",
	"(GMT-04:00) Atlantic Time (Canada)",
	"(GMT-04:00) La Paz",
	"(GMT-04:00) Manaus",
	"(GMT-04:00) Santiago",
	"(GMT-04:30) Caracas",
	"(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
	"(GMT-05:00) Eastern Time (US & Canada)",
	"(GMT-05:00) Indiana (East)",
	"(GMT-06:00) Central America",
	"(GMT-06:00) Central Time (US & Canada)",
	"(GMT-06:00) Guadalajara, Mexico City, Monterrey",
	"(GMT-06:00) Saskatchewan",
	"(GMT-07:00) Arizona",
	"(GMT-07:00) Chihuahua, La Paz, Mazatlan",
	"(GMT-07:00) Mountain Time (US & Canada)",
	"(GMT-08:00) Pacific Time (US & Canada)",
	"(GMT-08:00) Tijuana, Baja California",
	"(GMT-09:00) Alaska",
	"(GMT-10:00) Hawaii",
	"(GMT-11:00) Midway Island, Samoa",
	"(GMT-12:00) International Date Line West",
];

const primaryTimezones = [
	"Alaskan Standard Time",
	"Central Standard Time",
	"Eastern Standard Time",
	"Morocco Standard Time",
	"Mountain Standard Time",
	"Pacific Standard Time",
	"Pacific Standard Time (Mexico)",
	"US Mountain Standard Time",
];

const getTimeZoneNames = (timezones = []) => {
	return timezones.map(({ name }) => name);
};

export const months = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

const isValidDate = (date) => {
	return isValid(date);
};

// returns an array of each date type: [year, month, day, hour, mins, secs, ms]
const extractDateTypes = (date = new Date()) => {
	return new Date(date)
		.toISOString()
		.split(/[^0-9]/)
		.slice(0, -1);
};

// '07:30 AM' => '7:30 AM'
const removeLeadingZero = (time) => {
	if (isEmptyVal(time)) return;
	const [hrs, mins] = time.split(":");
	const [newMins, tod] = mins.split(" ");
	return {
		hrs: Number(hrs).toString(),
		mins: newMins,
		tod,
	};
};

///////////////////////////////////////////////////////////////////////////
//////////////////////// TIMEZONE CONVERSION UTILS ////////////////////////
///////////////////////////////////////////////////////////////////////////

// converts times in 24hr format to 12hr format (ie 'meridiem' format)
const convertToMeridiem = (time) => {
	const numTime = Number(time);
	if (numTime > 12) {
		return numTime - 12;
	}
	return numTime;
};

// ✅ Working Conversion Util
/**
 * @description - Extracts the UTC hours & mins from a UTC date & anchors them to a custom date in local time.
 * @param {Date} utcDate - A UTC date string, typically derives from an 'ADLCareTask', 'FacilityShiftTime' or 'AssessmentShiftTime' record.
 * @param {Date} anchorDate - An "anchor date" used to anchor a timestamp to a custom day & date.
 * @returns {Date} - Returns a converted datetime in the user's local timezone.
 * - Updated 8/7/2020 @ 11:07 AM
 */
const convertUTCToLocal = (utcDate, anchorDate = new Date()) => {
	const localAnchor = new Date(anchorDate);
	// create temp date & extract UTC hrs & mins
	const temp = new Date(utcDate);
	const utcHrs = temp.getUTCHours();
	const utcMins = temp.getUTCMinutes();
	// use UTC time settings to anchor to desired date instance
	localAnchor.setHours(utcHrs);
	localAnchor.setMinutes(utcMins);
	localAnchor.setSeconds(0);
	return localAnchor;
};

// converts utc date to local date
const utcToLocal = (utcDate) => {
	const localDate = new Date(utcDate).toString();
	return localDate;
};

// enables custom 'locale' & 'timeZone' & returns time string
const convertToLocaleTime = (date, options = { timeZone: "UTC" }) => {
	return date.toLocaleTimeString("en-US", options);
};
// enables custom 'locale' & 'timeZone' & returns date string
const convertToLocaleDate = (date, options = { timeZone: "UTC" }) => {
	return date.toLocaleDateString("en-US", options);
};

// convert seconds to milliseconds
const convertSecsToMs = (secs) => {
	return secs * 1000;
};
const convertSecsToMins = (secs) => {
	return secs / 60;
};

// convert minutes to milliseconds
const convertMinsToMs = (mins) => {
	return mins * 60000;
};
// convert mins to secs
const convertMinsToSecs = (mins) => {
	return mins * 60;
};
// converts mins to hours
const convertMinsToHours = (mins) => {
	return mins / 60;
};

// convert days to hours
const convertDaysToHours = (days) => {
	// '+' converts string to number via coercion
	return +days * 24;
};
// convert days to mins
const convertDaysToMins = (days) => {
	// '+' converts string to number via coercion
	return +days * 24 * 60;
};

// converts hours to mins
const convertHoursToMins = (hours) => {
	return hours * 60;
};
// convert hours to secs
const convertHoursToSecs = (hours) => {
	return hours * 3600;
};
const convertHoursToMs = (hours) => {
	return hours * 3600000;
};

// convert ms to secs
const convertMsToSecs = (ms) => {
	return ms / 1000;
};
// converts milliseconds to minutes
const convertMsToMins = (ms) => {
	return ms / 60000;
};
// convert ms to hours
const convertMsToHours = (ms) => {
	return ms / 3600000;
};

///////////////////////////////////////////////////////////////////////////
/////////////////////// DATE/TIME FORMATTING UTILS ///////////////////////
///////////////////////////////////////////////////////////////////////////

/**
 * Converts a UTC date string to local time, then formats it using the date-fns' library's "format(date, targetFormat)" util fn.
 * @param {Date} utcDate - A UTC date string.
 * @param {String} targetFormat - A string-form identifier for the desired target format for the converted date.
 * @returns {String} - Returns a formatted date string
 */
const convertAndFormatUtcToLocal = (
	utcDate,
	targetFormat = "M/D/YYYY h:mm A"
) => {
	if (isEmptyVal(utcDate)) return "";

	const localTime = utcToLocal(utcDate);
	const formatted = format(localTime, targetFormat);

	return formatted;
};

// returns date string (ie 03/24/2020 2:34 PM)
const getDateTimeStamp = (date = new Date()) => {
	return format(getTime(date), "MM/DD/YYYY h:mm");
};
// returns time string (ie 2:34 PM)
const getTimeStampOnly = (date = new Date()) => {
	return format(date, "h:mm A");
};

// formats an ISO/UTC time string into 'hh:mm A' format
// used for the Shift Times UI
const formatShiftTimes = (time) => {
	if (isEmptyVal(time)) return "XX:XX AM|PM";
	const local = utcToLocal(time);
	return format(local, "h:mm A");
};

// format => 'Sun, Jan 13 2020'
const formatDate = (date = null) => {
	if (!date) return "No date";
	const day = format(date, "ddd");
	const dayDate = format(date, "D");
	const month = format(date, "MMM");
	const year = format(date, "YYYY");
	return `${day}, ${month} ${dayDate} ${year}`;
};
// formats => 'Sunday, Jan 13 2020'
const formatWithFullDay = (date = new Date()) => {
	if (!date) return "No date";
	const day = format(date, "dddd");
	const monthDay = format(date, "Do");
	const month = format(date, "MMM");
	const year = format(date, "YYYY");
	return `${day}, ${month} ${monthDay} ${year}`;
};

// used specifically for handling the LOA return date.
// DOES NOT accept an actual date but rather a stringifyied date
const formatReturnDate = (loaList) => {
	const [current] = loaList;
	const { ReturnDate } = current;
	const weekDay = format(new Date(ReturnDate), "ddd"); // dddd for full weekDay
	const month = format(new Date(ReturnDate), "MMMM");
	const day = format(new Date(ReturnDate), "Do");
	const year = format(new Date(ReturnDate), "YYYY");
	return `${weekDay} ${month}, ${day} ${year}`;
};

// adds padded '0' & formats => '09:25 AM'
const formatTime = (time) => {
	let hours = time.getHours();
	let mins = time.getMinutes();
	let timeOfDay = "AM";
	if (hours > 12) {
		hours = hours - 12;
		timeOfDay = "PM";
	}
	if (mins < 10) {
		mins = "0" + mins;
	}
	return `${hours}:${mins} ${timeOfDay}`;
};

// returns string: 3 days ago, 4 hours ago...
const formatTimeToNow = (date) => {
	if (!isToday(date)) {
		return `${distanceInWords(date, new Date())}`;
	}
	return "";
};

// returns date boolean - wraps the 'isPast' date-fns helper
const isPastDue = (dueDate) => {
	if (isPast(dueDate)) return true;
	return false;
};

// date to now in words (ie '3 hours ago', '12 days ago')
const formatPastDate = (date) => {
	if (!isPast(date)) return "";
	return `${distanceInWordsToNow(date)} ago`;
};

// difference, in days, til today's date (ie '2 days' OR 'today')
const formatDifferenceInDays = (date) => {
	if (!isToday(date)) {
		return `${differenceInDays(date)} days`;
	}
	return "today";
};
const formatDateInWords = (date) => {
	const inPast = isPast(date);
	let desc;
	if (inPast) {
		desc = `${distanceInWordsToNow(date)} ago`;
		return desc;
	} else {
		desc = `${distanceInWordsToNow(date)} from now`;
		return desc;
	}
};
const formatDateInWordsToNow = (date) => {
	const dateDesc = distanceInWordsToNow(date);

	return dateDesc;
};

// matches day ("Monday", "Tuesday")
const matchDayOfWeek = (dateStr) => {
	const dayOfWeek = format(new Date(), "dddd");
	if (dayOfWeek === dateStr) {
		return true;
	}
	return false;
};

// matches day & date (ie "Monday" & "12/19/2019")
const matchDayAndDate = (day, dateStr) => {
	const dayOfWeek = format(new Date(), "dddd");
	if (dayOfWeek === day && isToday(dateStr)) {
		return true;
	}
	return false;
};

// returns 0-6 (ie. "Sunday" = 0, "Monday" = 1, ...)
const getZeroBasedDayOfWeek = (day = new Date()) => {
	const dayOfWeek = format(day, "d");
	return dayOfWeek;
};

const checkForPastDue = (task) => {
	// if unscheduled task
	if (!isScheduledTask(task)) {
		return isPast(task.EntryDate)
			? formatPastDate(task.EntryDate)
			: formatDate(task.EntryDate);
	}
	return isPast(task.TrackDate)
		? formatPastDate(task.TrackDate)
		: formatDifferenceInDays(task.TrackDate);
};

// updated 4/14/2020 - used in <TimePicker/>
const formatNum = (num) => {
	if (num < 10 && num.length !== 2) {
		return `0${num}`;
	}
	return num.toString();
};

// uses regex to extract 'year', 'month' & 'date' from ISOString (eg "1927-02-14T00:00:00Z" etc)
// via coercion returns each field as a number
/**
 * Extracts year, month and date as string then converts each to a number.
 * @param {Date} dob - A date in ISOString format.
 * @returns {Object} - Returns object w/ 'year', 'month' and 'date' as number.
 */
const extractDateUnitsFromISO = (dob) => {
	const reg =
		/^(?<year>[0-9]{4})-(?<month>[0-9]{1,})-(?<date>[0-9]{1,})T([0-9]{1,3}.*)/gm;
	const matches = reg.exec(dob);
	const { year, month, date } = matches.groups;

	return {
		year: +year,
		month: +month,
		date: +date,
	};
};

// accpets '12:35 AM' or '03:15 PM' & extracts the hours, mins and tod
const extractTimeUnitsFromString = (str) => {
	const reg = /^((?<hrs>\d{2}):(?<mins>\d{2})\s(?<tod>AM|PM))/gm;
	const matches = reg.exec(str);
	const { hrs, mins, tod } = matches?.groups;

	return {
		hrs,
		mins,
		tod,
	};
};

// accepts '03' or '12' (ie. 0-12)
const convertHoursStringToMilitary = (hoursStr, tod) => {
	switch (tod) {
		case "AM": {
			const num = Number(hoursStr);
			return num;
		}
		case "PM": {
			const rawNum = Number(hoursStr);
			const milNum = rawNum + 12;
			return milNum;
		}
		default:
			return new Error("INVALID TIME-OF-DAY");
	}
};

// extracts date of birth fields as numbers then formats a raw string ('MM/DD/YYYY')
const getDateOfBirth = (dob) => {
	if (!dob || isEmptyVal(dob)) return "Unknown";
	const { month, date, year } = extractDateUnitsFromISO(dob);

	return `${month}/${date}/${year}`;
};

const getFormattedIsoDate = (iso) => {
	if (!iso || isEmptyVal(iso)) return "Unknown";
	const { month, date, year } = extractDateUnitsFromISO(iso);

	return `${month}/${date}/${year}`;
};

/**
 * Creates human-readable string description of date, relative to now
 * @param {Date} date - Due date instance
 * @returns {String} - Returns string description of date, relative to now
 */
const getDateDescRelativeToNow = (date) => {
	const today = isToday(date);
	const tmrw = isTomorrow(date);
	const yesterday = isYesterday(date);
	// alternatives desc(s)
	const past = isPast(date);
	const future = isFuture(date);

	const distance = distanceInWordsToNow(date);

	switch (true) {
		// today
		case today: {
			return `Today`;
		}
		// tomorrow
		case tmrw: {
			return `Tomorrow`;
		}
		// yesterday
		case yesterday: {
			return `Yesterday`;
		}
		// is past, older than yesterday
		case past && !yesterday: {
			const desc = `${distance} ago`;

			return desc;
		}
		// is future, farther than tmrw
		case future && !tmrw: {
			const desc = `${distance} from now`;

			return desc;
		}
		default: {
			return distance;
		}
	}
};

////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// DATE PICKER HELPERS ///////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

// handles "By Month" selection
// deps: months array
const getRangeFromMonth = (selection) => {
	if (isEmptyVal(selection)) return;
	const mo = selection.split(" ")[0].trim();
	const yr = Number(selection.split(" ")[1].trim());

	const monthIdx = months.indexOf(mo);
	const monthStart = format(
		startOfMonth(new Date(yr, monthIdx, 1)),
		"MM/DD/YYYY"
	);
	const monthEnd = format(endOfMonth(new Date(yr, monthIdx, 1)), "MM/DD/YYYY");
	return {
		startDate: monthStart,
		endDate: monthEnd,
	};
};

// handles "By Quarter" selection
const getRangeFromQuarter = (selection) => {
	if (isEmptyVal(selection)) return;
	const qtr = selection.split(" ")[0].trim();
	const yr = Number(selection.split(" ")[1].trim());
	if (qtr === "Q1") {
		return {
			startDate: format(startOfQuarter(new Date(yr, 0, 1)), "MM/DD/YYYY"),
			endDate: format(endOfQuarter(new Date(yr, 0, 1)), "MM/DD/YYYY"),
		};
	}
	if (qtr === "Q2") {
		return {
			startDate: format(startOfQuarter(new Date(yr, 3, 1)), "MM/DD/YYYY"),
			endDate: format(endOfQuarter(new Date(yr, 3, 1)), "MM/DD/YYYY"),
		};
	}
	if (qtr === "Q3") {
		return {
			startDate: format(startOfQuarter(new Date(yr, 6, 1)), "MM/DD/YYYY"),
			endDate: format(endOfQuarter(new Date(yr, 6, 1)), "MM/DD/YYYY"),
		};
	}
	if (qtr === "Q4") {
		return {
			startDate: format(startOfQuarter(new Date(yr, 9, 1)), "MM/DD/YYYY"),
			endDate: format(endOfQuarter(new Date(yr, 9, 1)), "MM/DD/YYYY"),
		};
	}
	throw new Error("❌ Oops. Invalid quarter given", qtr);
};

const getRangeFromLastQuarter = () => {
	const curQtr = getQuarter(new Date());
	if (curQtr === 1) {
		// 1st qtr handler
		const lastQtrAndYr = `Q4 ${getYear(new Date()) - 1}`;
		const { startDate, endDate } = getRangeFromQuarter(lastQtrAndYr);

		return {
			startDate,
			endDate,
		};
	} else {
		// 2nd-4th qtr handler
		const lastQtrAndYr = `Q${curQtr - 1} ${getYear(new Date())}`;
		const { startDate, endDate } = getRangeFromQuarter(lastQtrAndYr);
		return {
			startDate,
			endDate,
		};
	}
};

// handles "Last 30 Days" selection
const getRangeFromLast30Days = () => {
	return {
		startDate: format(subDays(new Date(), 30), "MM/DD/YYYY"),
		endDate: format(new Date(), "MM/DD/YYYY"),
	};
};

// handles "Specific Date" selection
const getRangeFromDate = (selection) => {
	if (isEmptyVal(selection)) return;
	return {
		startDate: format(startOfDay(selection), "MM/DD/YYYY"),
		endDate: format(endOfDay(selection), "MM/DD/YYYY"),
	};
};

// converts dates for the reports' model params
const getStartAndEndDates = (vals) => {
	if (isEmptyVal(vals.dateRangeType)) return { startDate: "", endDate: "" };

	switch (vals.dateRangeType) {
		case "Custom Range": {
			const { startDate, endDate } = vals;
			return {
				startDate,
				endDate,
			};
		}
		case "By Month": {
			const { byMonth } = vals;
			const { startDate, endDate } = getRangeFromMonth(byMonth);

			return {
				startDate,
				endDate,
			};
		}
		case "By Quarter": {
			const { startDate, endDate } = getRangeFromQuarter(vals.byQuarter);
			return {
				startDate,
				endDate,
			};
		}
		case "By Year": {
			const { byYear } = vals;
			return {
				startDate: format(startOfYear(new Date(byYear, 1)), "MM/DD/YYYY"),
				endDate: format(endOfYear(new Date(byYear, 1)), "MM/DD/YYYY"),
			};
		}
		case "By Day":
		case "By Date": {
			const date = isEmptyVal(vals?.byDay) ? vals?.byDate : vals?.byDay;
			const { startDate, endDate } = getRangeFromDate(date);
			return {
				startDate,
				endDate,
			};
		}
		case "Last 30 days": {
			const { startDate, endDate } = getRangeFromLast30Days();
			return {
				startDate,
				endDate,
			};
		}
		case "Last year": {
			const curYr = getYear(new Date());
			const lastYr = subYears(new Date(curYr, 0, 1), 1);

			const startDate = startOfYear(lastYr);
			const endDate = endOfYear(lastYr);
			return {
				startDate,
				endDate,
			};
		}
		case "Last quarter": {
			const { startDate, endDate } = getRangeFromLastQuarter();
			return {
				startDate,
				endDate,
			};
		}
		case "Today": {
			const start = startOfDay(new Date()).toISOString();
			const end = endOfDay(new Date()).toISOString();
			return {
				startDate: start,
				endDate: end,
				// INCORRECT FORMAT???
				// startDate: format(startOfDay(new Date()), "M/DD/YYYY"),
				// endDate: format(endOfDay(new Date()), "M/DD/YYYY"),
			};
		}
		default:
			return Sentry.captureMessage(
				`getStartAndEndDates() util failed: ${vals.dateRangeType}
				(empty value provided)
				`
			);
	}
};

// parses selection from <QuarterPicker/>
// 'Q1 2019' => { startDate: 01/01/2019, endDate: 03/31/2019 }
const parseQuarter = (selection) => {
	if (isEmptyVal(selection)) return;
	const qtr = selection.split(" ")[0].trim();
	const yr = Number(selection.split(" ")[1].trim());
	if (qtr === "Q1") {
		return {
			startDate: format(startOfQuarter(new Date(yr, 0, 1)), "MM/DD/YYYY"),
			endDate: format(endOfQuarter(new Date(yr, 0, 1)), "MM/DD/YYYY"),
		};
	}
	if (qtr === "Q2") {
		return {
			startDate: format(startOfQuarter(new Date(yr, 3, 1)), "MM/DD/YYYY"),
			endDate: format(endOfQuarter(new Date(yr, 3, 1)), "MM/DD/YYYY"),
		};
	}
	if (qtr === "Q3") {
		return {
			startDate: format(startOfQuarter(new Date(yr, 6, 1)), "MM/DD/YYYY"),
			endDate: format(endOfQuarter(new Date(yr, 6, 1)), "MM/DD/YYYY"),
		};
	}
	if (qtr === "Q4") {
		return {
			startDate: format(startOfQuarter(new Date(yr, 9, 1)), "MM/DD/YYYY"),
			endDate: format(endOfQuarter(new Date(yr, 9, 1)), "MM/DD/YYYY"),
		};
	}
	throw new Error("❌ Oops. Invalid quarter given", qtr);
};

// parses selection from <MonthPicker/> into a start & end date
// 'Jun 2019' => { startDate: 06/01/2019, endDate: 06/31/2019 }
const parseMonth = (selection) => {
	if (isEmptyVal(selection)) return;

	const mo = selection.split(" ")[0].trim();
	const yr = Number(selection.split(" ")[1].trim());

	const monthIdx = months.indexOf(mo);
	const monthStart = format(
		startOfMonth(new Date(yr, monthIdx, 1)),
		"MM/DD/YYYY"
	);
	const monthEnd = format(endOfMonth(new Date(yr, monthIdx, 1)), "MM/DD/YYYY");

	return {
		startDate: monthStart,
		endDate: monthEnd,
	};
};

// returns a date range desc in text form (ie 'from 02/14/2020 thru 06/14/2020')
const getDateRangeDesc = (settings) => {
	switch (settings.dateRangeType) {
		case "Custom Range": {
			return ` from ${settings.startDate} thru ${settings.endDate} `;
		}
		case "By Month": {
			return `for ${settings.byMonth} `;
		}
		case "By Quarter": {
			return `for ${settings.byQuarter} `;
		}
		case "By Year": {
			return `for ${settings.byYear} `;
		}
		case "By Date": {
			return `for ${settings.byDate} `;
		}
		case "Last 30 days": {
			return `for the last 30 days `;
		}
		case "Last year": {
			return `for the last year `;
		}
		case "Last quarter": {
			return `for the last quarter `;
		}
		case "Today": {
			return `for today `;
		}
		default:
			throw new Error("❌ Oops. Invalid 'dateRangeType' value.");
	}
};

// checks if two dates are within the same month
// used for various date picker components
const checkForSameMonth = (day, monthToMatch = new Date()) => {
	return Boolean(isSameMonth(day, monthToMatch));
};

// checks if a date is outside a given range
const isOutsideRange = (targetDate, rangeStart, rangeEnd) => {
	return !isWithinRange(targetDate, rangeStart, rangeEnd);
};

/**
 * @description - Used to determine whether to restrict a date's availabiliity or not, given a date range. Used in <DatePicker/> and <DateRangePicker/>
 * @param {Date} targetDate - The date to test whether it should be restricted or available for selection, given a range.
 * @param {Date} rangeStart - Start date of 'allowed' range.
 * @param {Date} rangeEnd - End date of 'allowed' range.
 */
const isRestricted = (targetDate, rangeStart, rangeEnd) => {
	return isOutsideRange(targetDate, rangeStart, rangeEnd);
};

/**
 * @description - Used to determine whether to restrict a month's availability or not, given a date range. Used in <MonthPicker/>
 * @param {String} targetMonth - A string-form month and year (ie 'Jul 2020', 'Sep 2020' etc)
 * @param {Date} rangeStart - Start date of 'allowed' range.
 * @param {Date} rangeEnd - End date of 'allowed' range.
 * @returns {Boolean} - Returns 'true' if month should be restricted or disabled.
 */
const isMonthRestricted = (targetMonth, rangeStart, rangeEnd) => {
	const { startDate: monthStart, endDate: monthEnd } = parseMonth(targetMonth);

	// check if both start and end of month are in range,
	// that determines if month should be shown,
	// if either monthStart/monthEnd fail, restrict the month
	const isStartInRange = isWithinRange(monthStart, rangeStart, rangeEnd);
	const isEndInRange = isWithinRange(monthEnd, rangeStart, rangeEnd);

	// test for current month, if true, then don't restrict
	const isMonthSpillOver = isThisMonth(monthStart) || isThisMonth(monthEnd);

	return (!isStartInRange || !isEndInRange) && !isMonthSpillOver;
};

/**
 * @description - Accepts the 'RestrictionValue' from the app's settings record and calculates the available range of dates.
 * @param {Number} rangeVal - The 'RestrictionValue' in months to base the available date range on.
 * @returns {Object} - Returns the 'rangeStart' and 'rangeEnd' as date instances.
 */
const calculateRestrictionRange = (rangeVal = 3) => {
	const startMonth = subMonths(new Date(), rangeVal);
	const rangeStart = startOfMonth(startMonth);
	const rangeEnd = new Date();
	return {
		rangeStart,
		rangeEnd,
	};
};

// ##TODOS:
// - Create handlers for 'RestrictionUnit' types:
// 		✓ - Month
// 		✓ - Week
// 		✓ - Day
// 		✓ - Year
// 		✓ - Quarter
// 		X - Time
// 		X - Hours
// 		X - Minutes
const calculateMonthRestriction = (rangeVal = 3) => {
	const startMonth = subMonths(new Date(), rangeVal);
	const rangeStart = startOfMonth(startMonth);
	const rangeEnd = new Date();
	return {
		rangeStart,
		rangeEnd,
	};
};
const calculateWeekRestriction = (rangeVal = 12) => {
	const startWeek = subWeeks(new Date(), rangeVal);
	const rangeStart = startOfWeek(startWeek);
	const rangeEnd = new Date();
	return {
		rangeStart,
		rangeEnd,
	};
};
const calculateDayRestriction = (rangeVal = 30) => {
	const startDay = subDays(new Date(), rangeVal);
	const rangeStart = startOfDay(startDay);
	const rangeEnd = new Date();
	return {
		rangeStart,
		rangeEnd,
	};
};
const calculateYearRestriction = (rangeVal = 1) => {
	const startYr = subYears(new Date(), rangeVal);
	const rangeStart = startOfYear(startYr);
	const rangeEnd = new Date();

	return {
		rangeStart,
		rangeEnd,
	};
};
const calculateQuarterRestriction = (rangeVal = 2) => {
	const startQtr = subQuarters(new Date(), rangeVal);
	const rangeStart = startOfQuarter(startQtr);
	const rangeEnd = new Date();
	return {
		rangeStart,
		rangeEnd,
	};
};
const calculateTimeRestriction = (rangeVal = 12) => {
	// ##TODOS:
	// - Figure out how to handle time restrictions
	const rangeStart = "";
	const rangeEnd = "";
	return {
		rangeStart,
		rangeEnd,
	};
};
const calculateHourRestriction = (rangeVal = 8) => {
	// ##TODOS:
	// - Figure out how to handle hour restrictions
	const rangeStart = "";
	const rangeEnd = "";
	return {
		rangeStart,
		rangeEnd,
	};
};
const calculateMinutesRestriction = (rangeVal = 60) => {
	// ##TODOS:
	// - Figure out how to handle minute restrictions
	const rangeStart = "";
	const rangeEnd = "";
	return {
		rangeStart,
		rangeEnd,
	};
};

// TIMEZONES //
export { timezones, primaryTimezones, legacyTimezones, getTimeZoneNames };

// LOCAL HELPERS (IE SPECIFIC TO ONLY THIS FILE) //
export {
	isPastDue,
	matchDayOfWeek,
	matchDayAndDate,
	getZeroBasedDayOfWeek,
	checkForPastDue,
};

// DATE FORMATTING HELPERS //
export {
	getDateDescRelativeToNow,
	getDateOfBirth,
	getFormattedIsoDate,
	getDateTimeStamp,
	getTimeStampOnly,
	formatNum,
	formatReturnDate,
	formatWithFullDay,
	formatDate,
	formatTime,
	formatTimeToNow,
	formatPastDate,
	formatDateInWords,
	formatDateInWordsToNow,
	formatDifferenceInDays,
	extractDateTypes,
	extractTimeUnitsFromString,
	convertHoursStringToMilitary,
	removeLeadingZero,
	formatShiftTimes,
	// time units converters
	convertMinsToMs,
	convertSecsToMs,
	convertSecsToMins,
	convertMsToSecs,
	convertMsToMins,
	convertMsToHours,
	convertHoursToMins,
	convertHoursToSecs,
	convertHoursToMs,
	convertDaysToHours,
	convertDaysToMins,
	convertMinsToHours,
	convertMinsToSecs,
	// convert date formats
	convertToMeridiem,
	convertUTCToLocal,
	convertToLocaleTime,
	convertToLocaleDate,
	utcToLocal,
	convertAndFormatUtcToLocal,
	// checks if date type
	isValidDate,
};

// DATE PICKER UTILS //
export {
	getRangeFromDate,
	getRangeFromLast30Days,
	getRangeFromMonth,
	getRangeFromLastQuarter,
	getRangeFromQuarter,
	getStartAndEndDates,
	// parsing date selections
	parseQuarter,
	parseMonth,
	// range restriction utils
	checkForSameMonth,
	isOutsideRange,
	isRestricted,
	isMonthRestricted,
	// range calculation utils
	calculateRestrictionRange,
	calculateDayRestriction,
	calculateHourRestriction,
	calculateMinutesRestriction,
	calculateMonthRestriction,
	calculateQuarterRestriction,
	calculateTimeRestriction,
	calculateWeekRestriction,
	calculateYearRestriction,
};

// DATE TRANSFORMS TO WORDS //
export { getDateRangeDesc };
