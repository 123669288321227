import React from "react";
import styles from "../../css/residentinfo/ResidentPanel.module.scss";
import { PropTypes } from "prop-types";
import { formatResName } from "../../helpers/utils_residents";
import { isEmptyVal, isEmptyObj } from "../../helpers/utils_types";
import { addEllipsis } from "../../helpers/utils_processing";

const isArchived = (profile) => {
	if (profile.Archive) {
		return true;
	} else {
		return false;
	}
};

// REQUIRED PROPS:
// - profile: {Object}
// - contacts: {Array}

const ResidentPanel = ({
	profile = {},
	contacts = [],
	currentResident = {},
	isProfileLoaded = false,
	children,
}) => {
	const withResidentProps = React.Children.map(children, (child, i) => {
		return React.cloneElement(child, {
			profile: profile,
			contacts: contacts,
			currentResident: currentResident,
		});
	});

	if (!isProfileLoaded) {
		return null;
	}
	return (
		<article className={styles.ResidentPanel}>
			<section className={styles.ResidentPanel_top}>
				<h4
					className={styles.ResidentPanel_top_title}
					title={formatResName(currentResident)}
				>
					{!isEmptyVal(currentResident.ResidentID)
						? addEllipsis(formatResName(currentResident), 35)
						: "No resident loaded"}
				</h4>
				{isArchived(profile) && (
					<span className={styles.ResidentPanel_archived}>Archived</span>
				)}
			</section>
			<section className={styles.ResidentPanel_content}>
				{withResidentProps}
			</section>
		</article>
	);
};

export default ResidentPanel;

ResidentPanel.defaultProps = {
	currentResident: {},
	profile: {},
	contacts: [],
};

ResidentPanel.propTypes = {
	profile: PropTypes.object.isRequired,
	contacts: PropTypes.arrayOf(PropTypes.object),
	currentResident: PropTypes.object.isRequired,
	children: PropTypes.any.isRequired,
};
