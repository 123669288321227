import React, { useState } from "react";
import styles from "../../css/daily/RepeatDesc.module.scss";
import { PropTypes } from "prop-types";
// helpers
import {
	isRecurring,
	getRecurringDetails,
} from "../../helpers/utils_repeatingTasks";
import { isEmptyArray } from "../../helpers/utils_types";
import {
	RECURRING_TYPES as recurringTypes,
	DAYS_SLICED as weekDays,
} from "../../helpers/utils_options";
import { isScheduledTask } from "../../helpers/utils_tasks";
import { format } from "date-fns";
// components
import DateRangePickerSM from "../shared/DateRangePickerSM";
import CustomDropdownSM from "../shared/CustomDropdownSM";
import { generateID } from "../../helpers/utils_processing";

const ShiftIndicator = ({
	shift,
	val,
	toggleShift,
	isDisabled = false,
	customStyles = {},
}) => {
	const name = `isRecurring${shift}`;
	return (
		<button
			className={val ? styles.ShiftIndicator_isActive : styles.ShiftIndicator}
			onClick={() => toggleShift(name)}
			disabled={isDisabled}
			style={customStyles}
			type="button"
		>
			<div
				className={
					val
						? styles.ShiftIndicator_shift_isActive
						: styles.ShiftIndicator_shift
				}
			>
				{shift}
			</div>
		</button>
	);
};

ShiftIndicator.defaultProps = {
	isDisabled: false,
};
ShiftIndicator.propTypes = {
	shift: PropTypes.string.isRequired,
	val: PropTypes.bool.isRequired,
	toggleShift: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool,
};

export { ShiftIndicator };

const DayIndicator = ({ day, val, toggleDay, isDisabled = false }) => {
	const name = `isRecurring${day}`;
	return (
		<button
			className={val ? styles.DayIndicator_isActive : styles.DayIndicator}
			onClick={() => toggleDay(name)}
			disabled={isDisabled}
		>
			<div
				className={
					val ? styles.DayIndicator_day_isActive : styles.DayIndicator_day
				}
			>
				{day}
			</div>
		</button>
	);
};

DayIndicator.defaultProps = {
	isDisabled: false,
};
DayIndicator.propTypes = {
	day: PropTypes.string.isRequired,
	val: PropTypes.bool.isRequired,
	toggleDay: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool,
};

export { DayIndicator };

const RepeatDesc = ({
	task,
	vals = {},
	handleSettings,
	handleDateRange,
	handleRecurrences,
	isLocked = false,
}) => {
	// ONLY show settings IF task is recurring or 'Add Repeat' is clicked
	const [showSettings, setShowSettings] = useState(isRecurring(task));

	const { recurringType, recurringDates } = getRecurringDetails(task);

	if (!showSettings || isScheduledTask(task)) {
		return (
			<div className={styles.RepeatDesc_noRepeat}>
				<div className={styles.RepeatDesc_noRepeat_msg}>
					This task does NOT repeat.
				</div>
				{!isScheduledTask(task) && (
					<button
						className={styles.RepeatDesc_noRepeat_addRepeat}
						onClick={() => setShowSettings(true)}
					>
						Add Repeat
					</button>
				)}
			</div>
		);
	}
	return (
		<div className={styles.RepeatDesc}>
			{/* DATE RANGE */}
			<div className={styles.RepeatDesc_section}>
				<div className={styles.RepeatDesc_section_title}>Date Range:</div>
				<DateRangePickerSM
					startName="startDate"
					endName="endDate"
					handleSelection={handleDateRange}
					focusMode={true}
					rangeVals={{ startDate: vals.startDate, endDate: vals.endDate }}
					isDisabled={isLocked}
				/>
			</div>
			{/* RECURRING TYPE (ie daily, weekly etc) */}
			<div className={styles.RepeatDesc_section}>
				<div className={styles.RepeatDesc_section_title}>Repeats:</div>
				<CustomDropdownSM
					name="recurringType"
					id="recurringType"
					setSelection={handleSettings}
					selection={vals?.recurringType || recurringType}
					options={recurringTypes}
					isDisabled={isLocked}
				/>
			</div>
			{/* RECURRENCES - DAYS (ie Sun-Sat) */}
			<div className={styles.RepeatDesc_section}>
				<div className={styles.RepeatDesc_section_title}>Occurs On:</div>
				<div className={styles.RepeatDesc_section_days}>
					{!isEmptyArray(weekDays) &&
						weekDays.map((day, idx) => (
							<DayIndicator
								day={day}
								val={vals?.[`isRecurring${day}`]}
								toggleDay={handleRecurrences}
								isDisabled={isLocked}
								key={`${generateID(7)}_${day}_${idx}`}
							/>
						))}
				</div>
			</div>
			{/* RECURRENCES - SHIFTS (ie AM-NOC) */}
			<div className={styles.RepeatDesc_section}>
				<div className={styles.RepeatDesc_section_title}>
					Scheduled For Which Shift(s):
				</div>
				<div className={styles.RepeatDesc_section_shifts}>
					{[`AM`, `PM`, `NOC`].map((shift, idx) => (
						<ShiftIndicator
							shift={shift}
							key={`${shift}+${idx}`}
							val={vals?.[`isRecurring${shift}`]}
							toggleShift={handleRecurrences}
							isDisabled={isLocked}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default RepeatDesc;

RepeatDesc.defaultProps = {
	vals: {},
	isLocked: false,
};

RepeatDesc.propTypes = {
	task: PropTypes.object.isRequired,
	vals: PropTypes.object.isRequired,
	handleSettings: PropTypes.func.isRequired,
	handleDateRange: PropTypes.func.isRequired,
	handleRecurrences: PropTypes.func.isRequired,
	isLocked: PropTypes.bool,
};
