import React from "react";
import styles from "../../css/reportsviewer/ReportViewerNotesCell.module.scss";
import { PropTypes } from "prop-types";
import { format } from "date-fns";
import { orange } from "../../helpers/utils_styles";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { SCHEDULED_ID, UNSCHEDULED_ID } from "../../helpers/utils_tasks";
// components
import ReportViewerTableCell from "./ReportViewerTableCell";

// const strike = `line-through ${orange[700]}`;
const strike = {
	textDecoration: `line-through ${orange[700]}`,
	"-webkit-text-decoration": `line-through ${orange[700]}`,
	WebkitTextDecoration: `line-through ${orange[700]}`,
};
const noStrike = {
	textDecoration: "none",
	"-webkit-text-decoration": "none",
	WebkitTextDecoration: "none",
};

const getCenterCss = (cellData = []) => {
	const hasNoNote = isEmptyArray(cellData);

	if (hasNoNote) {
		return {
			justifyContent: "center",
		};
	} else {
		return {};
	}
};

const getNoteCSS = (taskNote) => {
	const isStrikeOut = taskNote?.IsStrikeOut || taskNote?.isStrikeOut;

	if (isStrikeOut) {
		return {
			...strike,
			opacity: ".4",
		};
	} else {
		return {
			...noStrike,
			opacity: "1",
		};
	}
};

const getKey = (taskNote, idx) => {
	const id = taskNote?.[SCHEDULED_ID] ?? taskNote?.[UNSCHEDULED_ID];

	const key = `ENTRY-${id}--${idx}`;

	return key;
};

// reads from task note record & appends timestamp & user's name to end of each note
const mergeNotesMetaData = (taskNote) => {
	const {
		// note creation fields
		EntryDate: entryDate,
		EntryUserFirstName: entryFirstName,
		EntryUserLastName: entryLastName,
		InitialBy: initialBy,
		TaskNotes: notes,
		// strike-out fields
		IsStrikeOut: isStrikeOut,
		StrikeOutUserFirstName: strikeOutByFirstName,
		StrikeOutUserLastName: strikeOutByLastName,
		StrikeOutDate: strikeOutDate,
	} = taskNote;

	const entry = format(entryDate, "M/D/YYYY h:mm:ss A");

	// entry username (ie. 'Steven Gore')
	const entryStamp = !isEmptyVal(initialBy)
		? initialBy
		: `${entryFirstName} ${entryLastName}`;
	// strike-out by username (ie. 'Steven Gore')
	const strikeStamp =
		!isEmptyVal(strikeOutByFirstName) && !isEmptyVal(strikeOutByLastName)
			? `${strikeOutByFirstName} ${strikeOutByLastName}`
			: initialBy;

	let newNotes = `${notes}\n\n`;
	// add entry date
	newNotes += `- Created on ${entry} (${initialBy})\n\n`;
	// add strikeout, if applicable;
	if (isStrikeOut) {
		const strikeDate = format(strikeOutDate, "M/D/YYYY h:mm:ss A");
		const strikeBy = `${strikeOutByFirstName} ${strikeOutByLastName}`;
		// const strikeBy = `${userStamp}`;

		newNotes += `- Strike-Out on ${strikeDate} (${strikeBy})`;
		return newNotes;
	} else {
		return newNotes;
	}
};

// single note entry
const NotesEntry = ({ taskNote = {} }) => {
	return (
		<div className={styles.NotesEntry}>
			<div className={styles.NotesEntry_content} style={getNoteCSS(taskNote)}>
				{mergeNotesMetaData(taskNote)}
			</div>
		</div>
	);
};

const ReportViewerNotesCell = ({
	cellData,
	handleCell,
	maxLength = 500,
	customStyles = {},
}) => {
	return (
		<ReportViewerTableCell handleCell={handleCell}>
			<div
				className={styles.ReportViewerNotesCell}
				style={getCenterCss(cellData)}
			>
				{isEmptyArray(cellData) && (
					<div className={styles.ReportViewerNotes_cell_empty}>
						No notes found.
					</div>
				)}
				{!isEmptyArray(cellData) &&
					cellData.map((taskNote, idx) => (
						<NotesEntry key={getKey(taskNote, idx)} taskNote={taskNote} />
					))}
			</div>
		</ReportViewerTableCell>
	);
};

export default ReportViewerNotesCell;

ReportViewerNotesCell.defaultProps = {};

ReportViewerNotesCell.propTypes = {};
