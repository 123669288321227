import ReactGA from "react-ga";
import { format } from "date-fns";
import { isEmptyVal } from "./utils_types";

// provides list of domains/hostnames to remove from tracking
const doNotTrack = () => {
	const domainsToIgnore = [
		`localhost`,
		`127.0.0.1`,
		`trackertest.aladvantage.com`,
	];
	const localDomain = `localhost` || `127.0.0.1`;
	const { hostname } = window.location;
	return domainsToIgnore.includes(hostname) || hostname === localDomain;
};

const generateUserID = (username, date = new Date()) => {
	const timestamp = format(date, "M/D/YYYY h:mm A");
	const uid = `${username}_at_${timestamp}`;
	return uid;
};

const hasAtSign = (val) => {
	const reg = /@/gm;
	return reg.test(val);
};

/**
 * Sends a user state to Sentry and records a message.
 * - Records which page the user accessed and when.
 * - Creates unique 'UserId' to maintain & identify a single user's session(s).
 */
const sendPageTracking = (username, options = {}) => {
	const obscuredUser = obscureEmail(username);
	// avoid tracking on local host
	if (doNotTrack()) return;
	ReactGA.set({ userId: "GENERATED-USER-ID-HERE" });
	ReactGA.event({
		category: "User",
		action: `${obscuredUser} visited ${options?.pagename}`,
	});
	ReactGA.pageview(options.path);
};

const obscureEmail = (email) => {
	if (isEmptyVal(email))
		return `Unknown user logged in at ${format(Date.now(), "M/D/YYYY h:mm A")}`;
	// if username is NOT email just return username
	if (!hasAtSign(email)) return email;
	const user = email.split("@")[0];
	const domain = email.split("@")[1];
	domain.replace("@", "");
	return `${user} from ${domain}`;
};

// user tracking uid
export { generateUserID };

export { obscureEmail };

// send tracking
export { sendPageTracking };
