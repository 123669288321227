import React from "react";
import styles from "../../css/residentinfo/ResidentServicePlan.module.scss";
import { PropTypes } from "prop-types";
import ResidentSvcPlanTable from "./ResidentSvcPlanTable";

const ResidentServicePlan = ({
	currentUser,
	currentFacility,
	currentResident,
	residentProfile = {},
}) => {
	return (
		<div className={styles.ResidentServicePlan}>
			<ResidentSvcPlanTable
				key={`SVC-PLAN-${residentProfile?.servicePlans?.length}`}
				svcPlanEntries={residentProfile.servicePlans}
			/>
		</div>
	);
};

export default ResidentServicePlan;

ResidentServicePlan.defaultProps = {};

ResidentServicePlan.propTypes = {};
