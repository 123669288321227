import React, { useState, useEffect } from "react";
import styles from "../../css/notes/NoteInput.module.scss";
import { PropTypes } from "prop-types";

const NoteInput = ({
	id,
	name,
	placeholder,
	val = "",
	handleChange,
	handleBlur = null,
	handleSubmit = null,
	required = false,
	maxChar = "none",
	readOnly = false,
	disabled = false,
	enableCharCount = false,
	autoFocus = false,
	inputRef = null,
}) => {
	const [charCount, setCharCount] = useState(0);

	useEffect(() => {
		setCharCount(val.length);
	}, [val]);
	return (
		<div className={styles.NoteInput}>
			<textarea
				ref={inputRef}
				value={val}
				name={name}
				id={id}
				disabled={disabled}
				readOnly={readOnly}
				placeholder={placeholder}
				onChange={handleChange}
				onBlur={handleBlur}
				onSubmit={handleSubmit}
				className={styles.NoteInput_input}
				maxLength={maxChar}
				required={required}
				autoFocus={autoFocus}
			/>
			{enableCharCount && (
				<div
					className={
						charCount === maxChar
							? styles.outOfChars
							: styles.NoteInput_charCount
					}
				>{`${charCount} / ${maxChar}`}</div>
			)}
		</div>
	);
};

export default NoteInput;

NoteInput.defaultProps = {};

NoteInput.propTypes = {};
