import React, { useContext } from "react";
import styles from "../../css/views/SettingsView.module.scss";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../../state/GlobalStateContext";

// ##TODOS - REQUIREMENTS:
// - Decide what to place on this page

const SettingsView = ({ history }) => {
	const { state, dispatch } = useContext(GlobalStateContext);
	return (
		<article className={styles.SettingsView}>
			{/*  */}
			{/*  */}
			{/*  */}
		</article>
	);
};

export default SettingsView;

SettingsView.defaultProps = {};

SettingsView.propTypes = {
	history: PropTypes.object,
};
