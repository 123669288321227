import React from "react";
import styles from "../../css/reports/ReportsDashboard.module.scss";
import { PropTypes } from "prop-types";
import { useRouteMatch } from "react-router-dom";
import { isEmptyArray } from "../../helpers/utils_types";
import { REPORTS_CONFIG as reports } from "../../helpers/utils_config";

// components
import ReportsCard from "../../components/reports/ReportsCard";

const ReportsDashboard = ({ history, children }) => {
	const match = useRouteMatch();

	return (
		<article className={styles.ReportsDashboard}>
			<section className={styles.ReportsDashboard_heading}>
				<h2 className={styles.ReportsDashboard_heading_title}>
					Select a Report
				</h2>
				<h4
					className={styles.ReportsDashboard_heading_subtitle}
					style={{ margin: "3rem 0 4rem 0" }}
				>
					Reports Dashboard
				</h4>
			</section>
			<section className={styles.ReportsDashboard_reports}>
				{/* REPORT CARDS NOW READ FROM LIST */}
				{!isEmptyArray(reports) &&
					[...reports.filter((x) => !x.hide)].map((report, idx) => (
						<ReportsCard
							key={report.name + idx}
							name={report?.name}
							route={`${match.url}${report?.route}`}
							icon={report?.icon}
							previewSrc={report?.previewSrc}
							isDisabled={report?.isDisabled}
						>
							<p>{report.desc}</p>
						</ReportsCard>
					))}
			</section>
		</article>
	);
};

export default ReportsDashboard;

ReportsDashboard.defaultProps = {};

ReportsDashboard.propTypes = {
	history: PropTypes.object.isRequired,
	children: PropTypes.any,
};
