import React, { useEffect, useState, useContext } from "react";
import styles from "../css/app/Message.module.scss";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../state/AuthContext";
import { getDerivedAuth } from "../helpers/utils_auth";
import { isEmptyObj } from "../helpers/utils_types";
import { hasParams } from "../helpers/utils_params";
import Spinner from "../components/shared/Spinner";

const ProtectedRoute = ({ component: Component, ...rest }) => {
	const { authData, setAuthData } = useContext(AuthContext);
	const [checkingForAuth, setCheckingForAuth] = useState(true);

	/**
	 * Enables 'hot-link' support from Legacy App w/ session validation
	 * and dervived auth state.
	 */
	const handleReferrerAuth = async () => {
		const session = await getDerivedAuth(window.location);

		if (!isEmptyObj(session)) {
			setAuthData({
				...authData,
				username: session?.user?.username,
				token: session?.user?.token,
				isAuthenticated: true,
			});
			return setCheckingForAuth(false);
		} else {
			return setCheckingForAuth(false);
		}
	};

	/**
	 * Checks for query params:
	 * - If NO params; setCheckingForAuth(false)
	 * - If YES params; handleReferrerAuth()
	 */
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (!hasParams()) {
			setCheckingForAuth(false); // ← DO NOT REMOVE EVER!!!
		}

		handleReferrerAuth();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (checkingForAuth) {
		return (
			<div className={styles.Message}>
				<Spinner />
				<p className={styles.Message_text}>Redirecting...please wait...</p>
			</div>
		);
	}
	return (
		<Route
			{...rest}
			render={(props) =>
				authData?.isAuthenticated ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: "/",
							state: { from: props?.location },
						}}
					/>
				)
			}
		/>
	);
};

export default ProtectedRoute;
