import React, { useState } from "react";
import styles from "../../css/timeview/TimeViewTaskList.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import {
	MOCK_FACILITY_LIST as allResidents,
	MOCK_RESIDENT_LIST as singleResident,
} from "../../helpers/utils_timeview";
import { sortDateAscByKey } from "../../helpers/utils_processing";
import { isEmptyArray } from "../../helpers/utils_types";
import { useForm } from "../../utils/useForm";
import TimeViewTaskListEntry from "./TimeViewTaskListEntry";
import SearchInput from "./SearchInput";

const advancedSearch = (val, options = []) => {
	val = val?.toLowerCase();

	return options.filter((option) => {
		// checks: 'Resident', 'ADL' and 'Task' fields
		if (
			option?.["ResidentName"]?.toLowerCase().includes(val) ||
			option?.["ResidentName"]?.toLowerCase().startsWith(val) ||
			option?.["ADL"]?.toLowerCase().includes(val) ||
			option?.["ADL"]?.toLowerCase().startsWith(val) ||
			option?.["TaskName"]?.toLowerCase().includes(val)
		) {
			return option;
		} else {
			return null;
		}
	});
};

const NoTasksFound = ({ msg }) => {
	return (
		<div className={styles.NoTasksFound}>
			<div className={styles.NoTasksFound_msg}>{msg}</div>
		</div>
	);
};

const TimeViewTaskList = ({
	tasks = [...allResidents],
	currentUser = {},
	currentFacility = {},
}) => {
	// new task fields
	const { formState, setFormState, handleChange, handleCheckbox, handleReset } =
		useForm({
			isLocked: false,
			newTaskName: "",
			newTaskCategory: "",
			newTaskNotes: "",
			newTaskShift: "",
			scheduledDate: new Date(),
		});
	const [searchVal, setSearchVal] = useState("");
	const [taskList, setTaskList] = useState([
		...sortDateAscByKey("ScheduledDate", tasks),
	]);

	const handleSearch = (e) => {
		const { value } = e.target;
		setSearchVal(value);
		// sort search results by due time
		setTaskList([
			...sortDateAscByKey("ScheduledDate", [...advancedSearch(value, tasks)]),
		]);
	};

	const clearSearch = (e) => {
		setSearchVal("");
		setTaskList([...sortDateAscByKey("ScheduledDate", tasks)]);
	};

	if (isEmptyArray(tasks)) {
		return <NoTasksFound msg="No tasks found." />;
	}
	return (
		<div className={styles.TimeViewTaskList}>
			<div className={styles.TimeViewTaskList_settings}>
				{/* CONTROLS: SHOW MORE/LESS */}
				<div className={styles.TimeViewTaskList_settings_search}>
					<SearchInput
						name="taskSearch"
						id="taskSearch"
						searchVal={searchVal}
						handleSearch={handleSearch}
						placeholder="Search by resident, ADL, task or time..."
					/>
					<button
						type="button"
						onClick={clearSearch}
						className={styles.TimeViewTaskList_settings_search_btn}
					>
						<svg className={styles.TimeViewTaskList_settings_search_btn_icon}>
							<use xlinkHref={`${sprite}#icon-clearclose`}></use>
						</svg>
					</button>
				</div>
			</div>
			<div className={styles.TimeViewTaskList_directions}>
				Click or select any task below to view the "Advanced" window.
			</div>
			<div className={styles.TimeViewTaskList_list}>
				{!isEmptyArray(taskList) &&
					taskList.map((task, idx) => (
						<TimeViewTaskListEntry
							key={`TV-TASK-${idx}`}
							task={task}
							currentUser={currentUser}
							currentFacility={currentFacility}
						/>
					))}
			</div>
		</div>
	);
};

export default TimeViewTaskList;

TimeViewTaskList.defaultProps = {};

TimeViewTaskList.propTypes = {};
