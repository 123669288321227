import React from "react";
import styles from "../../css/adlshiftreport/AdlShiftResidentRow.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { enforceNameLength } from "../../helpers/utils_residents";

const AdlShiftResidentRow = ({ adlShiftRecord = {} }) => {
	const { ResidentID } = adlShiftRecord;
	const { Shifts } = adlShiftRecord;
	const [am, pm, noc] = Shifts;

	return (
		<section className={styles.AdlShiftResidentRow}>
			<div className={styles.AdlShiftResidentRow_cell}>
				<div
					className={styles.AdlShiftResidentRow_cell_name}
					title={enforceNameLength(adlShiftRecord, 50)}
				>
					<div className={styles.AdlShiftResidentRow_cell_name_resident}>
						{enforceNameLength(adlShiftRecord, 15)}
					</div>
					<div className={styles.AdlShiftResidentRow_cell_name_id}>
						{ResidentID}
					</div>
				</div>
			</div>
			{/* AM */}
			<div className={styles.AdlShiftResidentRow_shiftCell}>
				<div className={styles.AdlShiftResidentRow_shiftCell_shift}>
					<svg className={styles.AdlShiftResidentRow_shiftCell_shift_icon}>
						<use
							xlinkHref={`${sprite}#icon-${
								am.IsEnable ? "check_circle" : "remove"
							}`}
						></use>
					</svg>
				</div>
			</div>
			{/* PM */}
			<div className={styles.AdlShiftResidentRow_shiftCell}>
				<div className={styles.AdlShiftResidentRow_shiftCell_shift}>
					<svg className={styles.AdlShiftResidentRow_shiftCell_shift_icon}>
						<use
							xlinkHref={`${sprite}#icon-${
								pm.IsEnable ? "check_circle" : "remove"
							}`}
						></use>
					</svg>
				</div>
			</div>
			{/* NOC */}
			<div className={styles.AdlShiftResidentRow_shiftCell}>
				<div className={styles.AdlShiftResidentRow_shiftCell_shift}>
					<svg className={styles.AdlShiftResidentRow_shiftCell_shift_icon}>
						<use
							xlinkHref={`${sprite}#icon-${
								noc.IsEnable ? "check_circle" : "remove"
							}`}
						></use>
					</svg>
				</div>
			</div>
			{/*  */}
			{/*  */}
		</section>
	);
};

export default AdlShiftResidentRow;

AdlShiftResidentRow.defaultProps = {
	adlShiftRecord: {},
};

AdlShiftResidentRow.propTypes = {
	adlShiftRecord: PropTypes.shape({
		AssessmentCategoryId: PropTypes.number.isRequired,
		ResidentId: PropTypes.number.isRequired,
		FirstName: PropTypes.string.isRequired,
		LastName: PropTypes.string.isRequired,
		Shifts: PropTypes.arrayOf(
			PropTypes.shape({
				Shift: PropTypes.string.isRequired,
				AssessmentShiftId: PropTypes.number.isRequired,
				IsEnable: PropTypes.bool.isRequired,
			})
		),
	}),
};
