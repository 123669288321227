import React from "react";
import styles from "../../css/daily/DailySummaryCardToolTip.module.scss";
import { PropTypes } from "prop-types";
import { getResidentName } from "../../helpers/utils_residents";

const DailySummaryCardToolTip = ({ currentResident = {} }) => {
	return (
		<aside className={styles.DailySummaryCardToolTip}>
			<h6 className={styles.DailySummaryCardToolTip_residentName}>
				{getResidentName(currentResident)}
			</h6>
		</aside>
	);
};

export default DailySummaryCardToolTip;

DailySummaryCardToolTip.defaultProps = {};

DailySummaryCardToolTip.propTypes = {
	currentResident: PropTypes.object.isRequired,
};
