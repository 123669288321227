import React, { useState } from "react";
import styles from "../../css/residenttable/ResidentRow.module.scss";
import { PropTypes } from "prop-types";
import { formatResName } from "../../helpers/utils_residents";
import { addEllipsis } from "../../helpers/utils_processing";
import ResidentCheckbox from "./ResidentCheckbox";

const ifIsSelected = (selectedList, id) => {
	if (!selectedList.includes(id)) {
		return false;
	}
	return true;
};

const ResidentRow = ({
	residentData = {},
	selectedList = [],
	addToSelected,
}) => {
	const { ResidentID, FloorUnit, RoomNum } = residentData;
	const [isSelected, setIsSelected] = useState(
		ifIsSelected(selectedList, ResidentID)
	);

	const selectResident = (e) => {
		setIsSelected(!isSelected);
	};

	return (
		<div
			className={
				ifIsSelected(selectedList, ResidentID)
					? styles.ResidentRow_isSelected
					: styles.ResidentRow
			}
		>
			<div className={styles.ResidentRow_cellSelection}>
				<ResidentCheckbox
					name={ResidentID}
					id={ResidentID}
					label={ifIsSelected(selectedList, ResidentID) ? "Selected" : "Select"}
					val={ifIsSelected(selectedList, ResidentID)}
					handleCheckbox={selectResident}
					handleClick={addToSelected}
				/>
			</div>
			<div className={styles.ResidentRow_cell}>
				<div className={styles.ResidentRow_cell_label}>
					{addEllipsis(formatResName(residentData), 30)}
				</div>
			</div>
			<div className={styles.ResidentRow_cell}>
				<div className={styles.ResidentRow_cell_label}>{FloorUnit}</div>
			</div>
			<div className={styles.ResidentRow_cell}>
				<div className={styles.ResidentRow_cell_label}>{RoomNum}</div>
			</div>
		</div>
	);
};

export default ResidentRow;

ResidentRow.defaultProps = {
	residentData: {},
	selectedList: [],
};

ResidentRow.propTypes = {
	residentData: PropTypes.object.isRequired,
	selectedList: PropTypes.arrayOf(PropTypes.number).isRequired,
	allSelected: PropTypes.bool.isRequired,
	addToSelected: PropTypes.func.isRequired,
};
