import React from "react";
import styles from "../../css/reportsviewer/ReportViewerTableBody.module.scss";
import { PropTypes } from "prop-types";

const ReportViewerTableBody = ({ children }) => {
	return (
		<section className={styles.ReportViewerTableBody}>
			<div className={styles.ReportViewerTableBody_rows}>{children}</div>
		</section>
	);
};

export default ReportViewerTableBody;

ReportViewerTableBody.defaultProps = {};

ReportViewerTableBody.propTypes = {
	children: PropTypes.any,
};
