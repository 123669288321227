import React, { useState } from "react";
import styles from "../../css/adlshiftreport/AdlShiftReport.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import AdlSelector from "./AdlSelector";
import AdlShiftReportHeadings from "./AdlShiftReportHeadings";
import AdlShiftReportBody from "./AdlShiftReportBody";
import AdlShiftResidentRow from "./AdlShiftResidentRow";
import TextInput from "../shared/TextInput";
import Spinner from "../shared/Spinner";

const AdlShiftReport = ({
	isLoading = false,
	adlShiftRecords = [],
	categories = [],
	adlSelection,
	handleAdlSelection,
	handlePrint,
	handleSave,
}) => {
	const [showOptions, setShowOptions] = useState(false);
	const [residentSearch, setResidentSearch] = useState("");
	const [filteredRecords, setFilteredRecords] = useState([...adlShiftRecords]);
	const [isSearching, setIsSearching] = useState(false);

	// search by resident "LastName"
	const handleResidentSearch = (e) => {
		const { value } = e.target;
		setResidentSearch(value);
		setIsSearching(true);
		if (isEmptyVal(value)) {
			setIsSearching(false);
			return setFilteredRecords([...adlShiftRecords]);
		}
		return setFilteredRecords([
			...adlShiftRecords.filter((res) =>
				res.LastName.toLowerCase().startsWith(residentSearch)
			),
		]);
	};

	return (
		<article className={styles.AdlShiftReport}>
			<section className={styles.AdlShiftReport_top}>
				<h2 className={styles.AdlShiftReport_top_title}>
					ADL Shift Report - Current Settings
				</h2>
				<div className={styles.AdlShiftReport_top_settings}>
					<svg
						className={styles.AdlShiftReport_top_settings_saveIcon}
						onClick={handleSave}
					>
						<use xlinkHref={`${sprite}#icon-save11`}></use>
					</svg>
					<svg
						className={styles.AdlShiftReport_top_settings_printIcon}
						onClick={handlePrint}
					>
						<use xlinkHref={`${sprite}#icon-local_print_shopprint`}></use>
					</svg>
				</div>
			</section>
			<section className={styles.AdlShiftReport_selector}>
				<AdlSelector
					categories={categories}
					adlSelection={adlSelection}
					handleAdlSelection={handleAdlSelection}
				/>
			</section>
			<section className={styles.AdlShiftReport_options}>
				<div
					className={styles.AdlShiftReport_options_show}
					onClick={() => setShowOptions(!showOptions)}
				>
					<span className={styles.AdlShiftReport_options_show_text}>
						{showOptions ? "Hide " : "Show "} Search
					</span>
					<svg className={styles.AdlShiftReport_options_show_icon}>
						<use
							xlinkHref={`${sprite}#icon-view-${showOptions ? "hide" : "show"}`}
						></use>
					</svg>
				</div>
				{showOptions && (
					<TextInput
						name="residentSearch"
						id="residentSearch"
						placeholder="Search by resident last name..."
						val={residentSearch}
						handleChange={handleResidentSearch}
					/>
				)}
			</section>

			<section className={styles.AdlShiftReport_content}>
				<AdlShiftReportHeadings />
				{isLoading ? (
					<Spinner />
				) : (
					<AdlShiftReportBody>
						{adlShiftRecords && !isSearching
							? adlShiftRecords.map((record, index) => (
									<AdlShiftResidentRow
										key={`${record.ResidentID}___${index}`}
										adlShiftRecord={record}
									/>
							  ))
							: filteredRecords &&
							  isSearching &&
							  filteredRecords.map((record, index) => (
									<AdlShiftResidentRow
										key={`${record.ResidentID}___${index}`}
										adlShiftRecord={record}
									/>
							  ))}
					</AdlShiftReportBody>
				)}
			</section>
		</article>
	);
};

export default AdlShiftReport;

AdlShiftReport.defaultProps = {
	residents: [],
	categories: [],
};

AdlShiftReport.propTypes = {
	residents: PropTypes.arrayOf(
		PropTypes.shape({
			FirstName: PropTypes.string.isRequired,
			LastName: PropTypes.string.isRequired,
			ResidentID: PropTypes.number.isRequired,
			FloorUnit: PropTypes.string.isRequired,
			RoomNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		})
	).isRequired,
	categories: PropTypes.arrayOf(PropTypes.object).isRequired,
	handlePrint: PropTypes.func.isRequired,
	handleSave: PropTypes.func.isRequired,
};
