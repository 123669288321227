/**
 * "UserTypes":
 * - List of string user types for updating a user's type.
 */
const USER_TYPES = [
	// `Administrator`, // OLD NAMING
	`Executive Administrator`, // NEW NAMING
	`Facility Administrator`,
	`Super User`,
	`Contractor`,
	`Manager`,
	`Staff`,
	`MedTech`,
	`Read-Only User`, // enable after jose's changes
];

const USER_TYPES_BY_ID = {
	1: {
		name: "SuperUser",
		desc: "Internal account user type",
		id: 1,
	},
	2: {
		name: "Staff",
		desc: "Staff user type",
		id: 2,
	},
	3: {
		name: "Manager",
		desc: "Manager user type",
		id: 3,
	},
	4: {
		name: "Contractor",
		desc: "Contractor user type",
		id: 4,
	},
	5: {
		name: "Executive Administrator",
		desc: "Executive Administrator user type",
		id: 5,
	},
	6: {
		name: "FacilityAdministrator",
		desc: "Facility Administrator user type",
		id: 6,
	},
	7: {
		name: "MedTech",
		desc: "Med Tech user type",
		id: 7,
	},
	8: {
		name: "ReadOnlyUser",
		desc: "Read only allow user type",
		id: 8,
	},
};

/**
 * Checks for 'Super-User' user type/permissions.
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'Super-User' user type.
 */
const isSuperUser = (currentUser) => {
	const hasStatus = currentUser?.isSuperUser ?? currentUser?.superUser ?? false;
	return hasStatus;
};
/**
 * Checks for 'Facility-Admin' user type/permissions.
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'Facility-Admin' user type.
 */
const isFacilityAdmin = (currentUser) => {
	const hasStatus = currentUser?.isFacilityAdmin ?? false;
	return hasStatus;
};
/**
 * Checks for 'Regional-Admin' user type/permissions.
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'Regional-Admin' user type.
 */
const isRegionalAdmin = (currentUser) => {
	const hasStatus = currentUser?.isRegionalAdmin ?? false;
	return hasStatus;
};
/**
 * Checks for 'Administrator' user type/permissions. (includes: 'Regional', 'Facility' and generic 'Admin' user types.)
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'Administrator' user type.
 */
const isAdmin = (currentUser) => {
	const hasStatus =
		isFacilityAdmin(currentUser) || isRegionalAdmin(currentUser);
	return hasStatus;
};
/**
 * Checks for 'Standard' user type/permissions.
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'Standard' user type.
 */
const isStandardUser = (currentUser) => {
	const hasStandardStatus =
		!isSuperUser(currentUser) &&
		!isAdmin(currentUser) &&
		!isMedTechRestricted(currentUser);

	return hasStandardStatus;
};
/**
 * Checks for 'Med-Tech-Restricted' user type/permissions.
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'Med-Tech-Restricted' user type.
 */
const isMedTechRestricted = (currentUser) => {
	const hasStatus = currentUser?.isMedTechRestricted ?? false;
	return hasStatus;
};
/**
 * Checks for 'READ-ONLY' user type/permissions.
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'READ-ONLY' user type.
 */
const isReadOnly = (currentUser) => {
	return currentUser?.isReadOnly ?? false;
};
/**
 * Checks whether a user has 'ADMIN-LEVEL' or above user permissions.
 * @param {Object} currentUser - Current user object
 * @returns {Boolean} - Returns true|false
 */
const isAdminLevel = (currentUser) => {
	const adminLevel =
		isAdmin(currentUser) ||
		isFacilityAdmin(currentUser) ||
		isRegionalAdmin(currentUser) ||
		isSuperUser(currentUser);

	return adminLevel;
};

// USER TYPE CHECKERS

// USER-TYPE CHECKER UTILS //

/**
 * Checks ALL relevant user records for 'Super-User' permissions.
 * @param {Object} advUser - 'ADVUSER' record, if available.
 * @param {Object} userProfile - 'UserProfile' record, if available.
 * @param {Object} userMerge - 'UserMerges' record, if available.
 * @returns {Boolean} - Returns true|false for super user permissions.
 *
 * ##TODOS:
 * - Deprecate use of 'ADVUSER' at a later date
 */
const hasSuperUserPerms = (advUser, userProfile) => {
	const isSuperViaAdv = advUser?.superUser;
	const isSuperViaProfile =
		userProfile?.UserTypeID === 1 || userProfile?.UserTypeID === 1;

	// checks ALL record types
	const isSuperUser = isSuperViaAdv || isSuperViaProfile;

	return isSuperUser;
};
/**
 * Determines if a user has 'Regional Administrator' permissions.
 * - Defined by: 'Facility Administrator' status and access to multiple facilities.
 * @param {Object} advUser - 'ADVUSER' record
 * @param {Object} userProfile - 'UserProfile' record
 * @param {Object[]} userFacilities - User's facility records.
 */
const hasRegionalAdminPerms = (advUser, userProfile, userFacilities = []) => {
	const isRegionalProfile =
		(userProfile?.userTypeID === 6 || userProfile?.UserTypeID === 6) &&
		userFacilities?.length > 1;

	return isRegionalProfile;
};
/**
 * Checks ALL relevant user records for 'FacilityAdmin' permissions.
 * @param {Object} advUser - 'ADVUSER' record, if available.
 * @param {Object} userProfile - 'UserProfile' record, if available.
 * @returns {Boolean} - Returns true|false for super user permissions.
 *
 * ##TODOS:
 * - Deprecate use of 'ADVUSER' at a later date
 *    - Consider replacing 'ADVUSER' usage w/ 'UserTypeID' in 'UserProfile'
 */
const hasFacilityAdminPerms = (advUser, userProfile) => {
	const isAdminViaAdv = advUser?.bitFacilityAdministrator ?? false;
	const isAdminViaProfile =
		userProfile?.userTypeID === 6 || userProfile?.UserTypeID === 6
			? true
			: false;
	// checks ALL record types
	const isFacilityAdmin = isAdminViaAdv || isAdminViaProfile;

	return isFacilityAdmin;
};
/**
 * Checks ALL relevant user records for 'ALA-Admin' permissions. (now known as an 'Executive Administrator' or 'Executive Admin')
 * @param {Object} advUser - 'ADVUSER' record, if available.
 * @returns {Boolean} - Returns true|false for super user permissions.
 *
 * ##TODOS:
 * - Deprecate use of 'ADVUSER' at a later date
 *    - Consider replacing 'ADVUSER' usage w/ 'UserTypeID' in 'UserProfile'
 */
const hasAlaAdminPerms = (advUser, userProfile) => {
	const isAlaViaAdv = advUser?.alaAdmin;
	const isAdminViaInfra = userProfile?.UserTypeID === 5;

	const isALA = isAlaViaAdv || isAdminViaInfra;

	return isALA;
};
/**
 * Checks ALL relevant user records for 'ALA-Admin' permissions.
 * @param {Object} advUser - 'ADVUSER' record, if available.
 * @param {Object} userProfile - 'UserProfile' record.
 * @returns {Boolean} - Returns true|false for super user permissions.
 *
 */
const hasMedTechPerms = (advUser, userProfile) => {
	const isMedProfile =
		userProfile?.userTypeID === 7 || userProfile?.UserTypeID === 7
			? true
			: false;
	const isMedAdv = advUser?.MedTechRestrictedAccess ? true : false;

	return isMedProfile || isMedAdv;
};
/**
 * Checks ALL relevant user records for 'READ-ONLY' permissions.
 * @param {Object} userProfile - 'UserProfile' record.
 * @returns {Boolean} - Returns true|false for super user permissions.
 *
 */
const hasReadOnlyPerms = (userProfile) => {
	const typeID = userProfile?.UserTypeID ?? userProfile?.userTypeID;
	const isReadOnlyType = typeID === 8;
	return isReadOnlyType;
};
/**
 * Determines if user has standard permissions
 * @param {Object} advUser - 'ADVUSER' record, if available
 * @param {Object} userProfile - 'UserProfile' record.
 */
const hasStandardPerms = (advUser, userProfile) => {
	// super, admin, med-tech
	const definedTypes = [1, 6, 7];
	const nonType = !definedTypes.includes(
		userProfile?.userTypeID ?? userProfile?.UserTypeID
	)
		? true
		: false;

	return nonType;
};

// wrapper around user type
const getUsersUserTypeAndPerms = ({
	advUser = {},
	userLogin = {},
	userProfile = {},
	userCommunities = [],
}) => {
	// from 'userProfile'
	const userTypeID = userProfile?.UserTypeID; // number
	const clientType = USER_TYPES_BY_ID[userTypeID]; // object
	const typeName = clientType?.name; // string
	// from 'advUser'
	const isALA = advUser?.alaAdmin ?? false;
	const isFac = advUser?.bitFacilityAdministrator ?? false;
	const isSuper = advUser?.superUser ?? false;
	const isMed = advUser?.MedTechRestrictedAccess ?? false;

	const isSuperUserType = userTypeID === 1 || isSuper;
	const isEAUserType = userTypeID === 5 || isALA;
	const isFAUserType = userTypeID === 6 || isFac;
	const isMTUserType = userTypeID === 7 || isMed;
	const isROUserType = userTypeID === 8;
	// standard user (non-admin & non-read-only)
	const isSUUserType = [2, 3, 4].includes(userTypeID) && !isROUserType;

	const userTypeInfo = {
		isSuperUser: isSuperUserType,
		isExecutiveAdmin: isEAUserType,
		isFacilityAdmin: isFAUserType,
		isMedTechRestricted: isMTUserType,
		isReadOnly: isROUserType,
		isStandardUser: isSUUserType,
	};

	return {
		...userTypeInfo,
		name: typeName,
	};
};

export { USER_TYPES, USER_TYPES_BY_ID };

// user type utils
export {
	// Checks for specific user type booleans
	isSuperUser,
	isFacilityAdmin,
	isRegionalAdmin,
	isAdmin,
	isStandardUser,
	isMedTechRestricted,
	isReadOnly,
	// ADMIN LEVEL GENERIC CHECKER
	isAdminLevel,
	// Checks for specific user type permissions
	hasSuperUserPerms,
	hasRegionalAdminPerms,
	hasFacilityAdminPerms,
	hasAlaAdminPerms,
	hasMedTechPerms,
	hasReadOnlyPerms,
	hasStandardPerms,
};

export { getUsersUserTypeAndPerms };
