import React, { useEffect, useState, useCallback } from "react";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { useAlerts, initialSettings } from "../../utils/useAlerts";
import { useRecents } from "../../utils/useRecents";
import { useScrollPosition, config } from "../../utils/useScrollPosition";
import {
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
} from "../../helpers/utils_types";
import {
	getReportDescByName,
	getDailyReportDesc,
	getReportDesc,
	getReportDescByType,
} from "../../helpers/utils_descriptions";
import {
	hasFacility,
	getFacilityDay,
	getCurrentFacilityStr,
	getCurrentResidentStr,
	formatAndSortFacilities,
} from "../../helpers/utils_facility";
import {
	getShiftIDsFromSettings,
	createReportModel,
	getDailyCompletions,
	getHistoricalCompletions,
} from "../../helpers/utils_reports";
import {
	formatAndSortResidents,
	getResidentObj,
} from "../../helpers/utils_residents";
import {
	completionCols as cols,
	getCompletionReportRows,
} from "../../helpers/utils_table";
import {
	getFileBlob,
	createBlob,
	createURL,
	getFileID,
} from "../../helpers/utils_files";
import {
	amendHistoricalConfig,
	COMPLETION_CONFIG,
} from "../../helpers/utils_config";
import { updateRecentsModel } from "../../helpers/utils_recentlyViewed";
import { generateID } from "../../helpers/utils_processing";
import {
	getDateRangeTypeKey,
	getFilterByKey,
	getFields,
} from "../../helpers/utils_validation";
import { calculateRestrictionRange } from "../../helpers/utils_dates";
import { format } from "date-fns";
import { sendPageTracking } from "../../helpers/utils_tracking";
import { mergeDailyResidentData } from "../../helpers/utils_residentData";
import { populateState } from "../../helpers/utils_initialResources";
// components
import ReportViewer from "../../components/reports/ReportViewer";
import ReportTypeController from "../../components/reports/ReportTypeController";
import ConfigurablePicker from "../../components/reportpicker/ConfigurablePicker";
import ReportsViewerController from "../../components/reportsviewer/ReportsViewerController";

const hasLoadedReport = (reportType = `Daily`, dailyData, historySrc) => {
	switch (true) {
		case reportType === `Daily`: {
			const hasLoaded = !isEmptyArray(dailyData);
			return hasLoaded;
		}
		case reportType === `Historical`: {
			const hasLoaded = !isEmptyVal(historySrc);
			return hasLoaded;
		}
		case isEmptyVal(reportType): {
			return false;
		}
		default:
			return false;
	}
};

// initial input values
const initialValState = {
	reportType: "Daily",
	filterBy: "",
	filterByResident: "",
	filterByFacility: "",
	filterByADL: "",
	shiftAM: false,
	shiftPM: false,
	shiftNOC: false,
	dateRangeType: "",
	byQuarter: "",
	byMonth: "",
	byDate: "",
	byDateRange: "",
	startDate: "",
	endDate: "",
	sortBy: "",
	sortByResident: "",
	sortByADL: "",
	sortByTaskDescription: "",
	sortByStaff: "",
	sortByShift: "",
	sortByFloorUnit: "",
	sortByExceptionType: "",
	sortByIsPastDue: "",
	// other options
	showStrikeOuts: true,
	hideNotes: false,
	useTimeZoneOverride: false,
	selectTz: "",
};

const CompletionReport = ({
	globalState,
	currentFacility,
	currentResident,
	currentUser,
	residents = [],
	residentsByFacility,
	adlCategories = [],
	dispatch,
}) => {
	useScrollPosition(config);
	const { cache, addItem, removeItem } = useRecents(
		"Recents-CompletionReport", // key
		{ recents: [], timestamp: Date.now() } // state
	);
	const { formState, setFormState, handleCheckbox, handleReset } = useForm({
		...initialValState,
	});
	const { values } = formState;

	const { dispatchAlert, AlertsHandler } = useAlerts({
		...initialSettings,
		expiry: 2000,
		msg: {
			heading: "Success!",
			subheading: `You've changed facilities`,
		},
	});
	// local state
	const [selectedFacility, setSelectedFacility] = useState(
		getCurrentFacilityStr(currentFacility)
	);
	const [selectedResident, setSelectedResident] = useState(
		getCurrentResidentStr(currentResident)
	);
	const [showReportPicker, setShowReportPicker] = useState(false);
	const [facilityData, setFacilityData] = useState({});
	const [dailyReportData, setDailyReportData] = useState(null); //previously '[]'
	const [isLoadingReport, setIsLoadingReport] = useState(false);
	const [src, setSrc] = useState("");
	// date-range restriction (from ALADVSystem)
	const [rangeRestriction, setRangeRestriction] = useState(
		calculateRestrictionRange(3)
	);

	const { shifts: shiftTimes } = currentFacility;

	const COMPLETION_FILTER_OPTIONS = [
		{
			name: "filterByResident",
			id: "Resident",
			label: "Choose a resident",
			placeholder: "Resident...",
			options: [...formatAndSortResidents(currentFacility.residents)],
		},
		{
			name: "filterByFacility",
			id: "Facility",
			label: "Choose a facility",
			placeholder: "Facility...",
			options: [...formatAndSortFacilities(currentUser.facilities)],
		},
		{
			name: "filterByADL",
			id: "ADL",
			label: "Choose a ADL",
			placeholder: "ADL...",
			options: [...adlCategories],
		},
	];

	const getFieldsToValidate = (values) => {
		const fields = getFields(values, [
			`reportType`,
			`filterBy`,
			getFilterByKey(values),
			`dateRangeType`,
			getDateRangeTypeKey(values),
		]);
		return fields;
	};

	const rerunReport = async (recentsModel) => {
		const { token } = currentUser;
		if (recentsModel.type === `Daily`) {
			setIsLoadingReport(true);
			return rerunDailyReport(recentsModel.data);
		} else {
			setIsLoadingReport(true);
			setFormState({
				...formState,
				values: {
					...values,
					reportType: `Historical`,
				},
			});
			return getReportSrc(token, recentsModel.id);
		}
	};

	const rerunDailyReport = async (recentsVals) => {
		const { token } = currentUser;
		const { facilityID } = recentsVals;

		const shiftIDs = getShiftIDsFromSettings(recentsVals);
		const reportData = await getDailyCompletions(token, facilityID, shiftIDs);

		if (!isEmptyArray(reportData)) {
			setFormState({
				...formState,
				values: {
					...values,
					recentsVals,
				},
			});
			setIsLoadingReport(false);
			return setDailyReportData([...reportData]);
		} else {
			setDailyReportData(reportData);
			return setIsLoadingReport(false);
		}
	};

	// fetches file blob from server, tranforms into pdf blob, sets local 'src' state
	const getReportSrc = async (token, fileID) => {
		const blob = await getFileBlob(token, fileID);
		const pdfBlob = createBlob(blob, "application/pdf");
		const pdfURL = createURL(pdfBlob);

		setSrc(pdfURL);
		return setIsLoadingReport(false);
	};

	// uses SMART table UI
	const getDailyReport = async () => {
		if (!hasFacility(selectedFacility)) {
			return dispatchAlert("warning", {
				headings: `Please select a facility!`,
			});
		}

		const { token } = currentUser;
		const { facilityID } = currentFacility;
		// get facility scheduled: startOfAM - endofNOC
		const { startDate, endDate } = getFacilityDay(shiftTimes);

		const shiftIDs = getShiftIDsFromSettings(values);
		const reportData = await getDailyCompletions(
			token,
			facilityID,
			shiftIDs,
			startDate,
			endDate
		);

		// add recently viewed entry
		const recentsModel = updateRecentsModel({
			id: generateID(3),
			type: `Daily`,
			name: `Daily Completion Report`,
			desc: getDailyReportDesc(values, `Completion`),
			dateCreated: format(new Date(), "M/D/YYYY h:mm A"),
			data: { ...values, facilityID },
		});
		addItem(recentsModel);

		if (!isEmptyArray(reportData)) {
			setIsLoadingReport(false);
			return setDailyReportData([...reportData]);
		} else {
			setDailyReportData(reportData);
			return setIsLoadingReport(false);
		}
	};
	// uses PDF mirror
	const getHistoricalReport = async () => {
		if (!hasFacility(selectedFacility)) {
			setIsLoadingReport(false);
			return dispatchAlert("warning", {
				heading: `Please select a facility.`,
			});
		}

		const { token } = currentUser;
		const { facilityID } = currentFacility;

		const reportModel = createReportModel(
			values,
			facilityID,
			currentUser?.facilities
		);

		console.log("reportModel", reportModel);

		const fileRegistry = await getHistoricalCompletions(token, reportModel);
		// add recently viewed entry
		addItem({
			id: getFileID(fileRegistry),
			type: `Historical`,
			name: `Historical Completion Report`,
			desc: getReportDesc(values, `Completion Report`),
			dateCreated: format(new Date(), "M/D/YYYY h:mm A"),
			data: { ...reportModel, facilityID },
		});

		setIsLoadingReport(false);
		if (isEmptyObj(fileRegistry)) return;
		const { FileRegistryID } = fileRegistry;

		return await getReportSrc(token, FileRegistryID);
	};

	const createReport = async () => {
		switch (true) {
			case !hasFacility(selectedFacility): {
				dispatchAlert(`warn`, {
					heading: `No facility selected!`,
				});
				return setShowReportPicker(false);
			}
			case values.reportType === `Daily`: {
				setShowReportPicker(false);
				setIsLoadingReport(true);
				return await getDailyReport();
			}
			case values.reportType === `Historical`: {
				setShowReportPicker(false);
				setIsLoadingReport(true);
				return await getHistoricalReport();
			}

			default:
				return;
		}
	};

	const cancelReport = (e) => {
		handleReset(e);
		setShowReportPicker(false);
	};
	// global store is updated from side-effect (ie 'useEffect')
	// not needed; facility is loaded via 'setFacility'
	// only used for dispatching an alert
	const changeFacility = (selectedVal) => {
		return dispatchAlert("info", {
			heading: `Changed Facility`,
			subheading: `Loaded ${selectedVal}.`,
		});
	};

	/**
	 * Extracts resident from 'selectedResident' state.
	 * Inits 'LOADING' state
	 * Fires off request, then syncs resident data to global store
	 */
	// ✓ - ADDED 9/23/2021 at 8:49 AM
	const loadResident = useCallback(async () => {
		if (isEmptyVal(selectedResident)) return alert("Select a resident");
		if (!selectedResident.includes("~ ALA ID:")) return;

		const { token } = currentUser;
		// match resident record from ID
		const curResident = getResidentObj(
			residentsByFacility,
			currentFacility?.facilityID ?? currentUser.facilityID, // 'null' causes empty 'selectedFacility'
			selectedResident
		);

		dispatch({ type: "LOADING" });

		const merged = await mergeDailyResidentData(
			token,
			curResident.ResidentID,
			new Date()
		);
		const oldState = { ...globalState };
		const newState = populateState(merged, oldState);
		// sync to state
		return dispatch({
			type: "SUCCESS",
			data: {
				newState: newState,
			},
		});
	});

	// sends tracking to Sentry.io
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		sendPageTracking(currentUser?.username, {
			pagename: `CompletionReport`,
			path: `/dashboard/reports/completion`,
		});

		return () => {
			isMounted = false;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ReportTypeController
				reportTitle="Completion Report"
				reportDesc={getReportDescByType(`Completion`, values)}
				reportSettings={values}
				setFacilityData={setFacilityData}
				reportType={values.reportType}
				currentUser={currentUser}
				currentFacility={currentFacility}
				residents={residents}
				residentsByFacility={residentsByFacility}
				selectedFacility={selectedFacility}
				setSelectedFacility={setSelectedFacility}
				setSelectedResident={setSelectedResident}
				selectedResident={selectedResident}
				changeFacility={changeFacility}
				changeResident={loadResident}
				recentlyViewedList={cache.recents}
				removeRecentsItem={removeItem}
				rerunReport={rerunReport}
				cancelReport={cancelReport}
				dispatch={dispatch}
				handleReportsPicker={() => setShowReportPicker(true)}
				hasLoaded={hasLoadedReport(values.reportType, dailyReportData, src)}
			>
				<ReportsViewerController
					hasLoaded={hasLoadedReport(values.reportType, dailyReportData, src)}
					key={values.reportType === `Daily` ? dailyReportData : src}
					// noReportData={isEmptyArray(dailyReportData) || isEmptyVal(src)}
					noReportData={isEmptyArray(dailyReportData) && isEmptyVal(src)}
					showSMARTTable={values.reportType === `Daily`}
					reportType={values.reportType}
					isLoading={isLoadingReport}
					embedSrc={src}
					tableData={{
						size: `FULL`,
						title: `${values.reportType} Completion Report`,
						cols: [...cols],
						data: [...getCompletionReportRows(dailyReportData)],
						enableActions: true,
					}}
				/>
			</ReportTypeController>

			{/* REPORT PICKER */}
			<ConfigurablePicker
				reportName={`Completion Report`}
				formState={formState}
				setFormState={setFormState}
				handleCheckbox={handleCheckbox}
				handleReset={handleReset}
				currentFacility={currentFacility}
				currentUser={currentUser}
				adls={adlCategories}
				showPicker={showReportPicker}
				closeModal={() => setShowReportPicker(false)}
				createReport={createReport}
				cancelReport={cancelReport}
				fieldsToValidate={getFieldsToValidate(values)}
				config={amendHistoricalConfig(
					{ ...COMPLETION_CONFIG },
					"filterOptions",
					COMPLETION_FILTER_OPTIONS
				)}
				dateRangeRestrictions={{
					isActive: true,
					rangeStart: rangeRestriction.rangeStart,
					rangeEnd: rangeRestriction.rangeEnd,
				}}
			/>

			{AlertsHandler}
		</>
	);
};

export default CompletionReport;

CompletionReport.defaultProps = {
	residents: [],
	adlCategories: [],
};

CompletionReport.propTypes = {
	currentFacility: PropTypes.object,
	currentResident: PropTypes.object,
	currentUser: PropTypes.object,
	residents: PropTypes.arrayOf(PropTypes.object),
	residentsByFacility: PropTypes.object,
	adlCategories: PropTypes.array,
	dispatch: PropTypes.func,
};
