import React, { useState, useEffect } from "react";
import styles from "../../css/pastdue/PastDuePanel.module.scss";
import sprite from "../../assets/icons/carets-arrows.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal, isEmptyArray } from "../../helpers/utils_types";
import { useForm } from "../../utils/useForm";
import {
	sortPastDueRecords,
	getCustomRangePastDue,
} from "../../helpers/utils_pastdue";
import { generateID } from "../../helpers/utils_processing";
import { getStartAndEndDates } from "../../helpers/utils_dates";
// components
import TextInput from "../shared/TextInput";
import PastDueList from "./PastDueList";
import PastDueController from "./PastDueController";
import ButtonSM from "../shared/ButtonSM";
import Spinner from "../shared/Spinner";
import Placeholder from "../shared/Placeholder";

const viewMoreStyles = {
	borderRadius: "5rem",
	marginTop: "2rem",
	marginBottom: "2rem",
	boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
};

const getEntryKey = (record, idx) => {
	const residentID = record?.Resident?.[0]?.ResidentID;

	const key = `PAST-DUE-LIST-${residentID}-${idx}`;

	return key;
};

const PastDuePanel = ({
	records = [],
	isLoading,
	setIsLoading,
	hasNoRecords = false,
	viewMore,
	currentUser = {},
	facilityID,
	shiftTimes = [],
	dateRangeRestrictions = {},
}) => {
	const [showControls, setShowControls] = useState(false);
	const [sortedRecords, setSortedRecords] = useState(
		sortPastDueRecords(records)
	);
	const [search, setSearch] = useState("");
	const { formState, setFormState, handleReset } = useForm({
		dateRangeType: "",
		startDate: "",
		endDate: "",
		byDay: "",
		byWeek: "",
		byMonth: "",
		byQuarter: "",
	});
	const { values } = formState;
	// number of rows current fetched from database

	const handleRangeSettings = (name, val) => {
		setFormState({
			...formState,
			values: {
				...formState.values,
				[name]: val,
			},
		});
	};

	// handles date range picker(SM)
	const handleDateRange = ({ startDate, endDate }) => {
		setFormState({
			...formState,
			values: {
				...formState.values,
				startDate: startDate,
				endDate: endDate,
			},
		});
	};

	const handleSearch = (e) => {
		const { value } = e.target;
		setSearch(value);
		setSortedRecords(
			sortPastDueRecords(records).filter((entry) =>
				entry.Resident[0].ResidentLastName.toLowerCase().startsWith(
					search.toLowerCase()
				)
			)
		);
	};
	const applySettings = async (e) => {
		setIsLoading(true);
		const { token } = currentUser;
		const { startDate, endDate } = getStartAndEndDates(values);
		const rangeRecords = await getCustomRangePastDue(token, facilityID, {
			startDate,
			endDate,
		});
		if (!isEmptyArray(rangeRecords)) {
			setIsLoading(false);
			return setSortedRecords(sortPastDueRecords(rangeRecords));
		} else {
			setIsLoading(false);
			return setSortedRecords([]);
		}
	};
	// clears custom settings (ie custom options)
	const clearSettings = (e) => {
		handleReset(e);
	};

	useEffect(() => {
		if (isEmptyVal(search)) {
			return setSortedRecords(sortPastDueRecords(records));
		}
	}, [records, search]);

	return (
		<>
			<article className={styles.PastDuePanel}>
				<section className={styles.PastDuePanel_controls}>
					<span onClick={() => setShowControls(!showControls)}>
						Change Options
					</span>
				</section>
				{showControls && (
					<PastDueController
						vals={values}
						handleSettings={handleRangeSettings}
						handleDateRange={handleDateRange}
						clearSettings={clearSettings}
						applySettings={applySettings}
						dateRangeRestrictions={dateRangeRestrictions}
					/>
				)}
				<section className={styles.PastDuePanel_search}>
					<TextInput
						key={`PAST-DUE-REPORT-SEARCH`}
						name="searchByResident"
						id="searchByResident"
						label="Search by Resident Last Name"
						placeholder="resident's last name..."
						val={search}
						handleChange={handleSearch}
					/>
				</section>
				<section className={styles.PastDuePanel_entries}>
					{isLoading && <Spinner />}
					{!isLoading && hasNoRecords && (
						<Placeholder size="MD" msg="No past due tasks found." color="red" />
					)}
					{sortedRecords &&
						sortedRecords.map((entry, index) => (
							<>
								<hr />
								<PastDueList
									key={getEntryKey(entry, index)}
									record={entry}
									currentUser={currentUser}
									shiftTimes={shiftTimes}
								/>
							</>
						))}
					<div style={{ display: "flex", justifyContent: "center" }}>
						<ButtonSM handleClick={viewMore} customStyles={viewMoreStyles}>
							<div className={styles.PastDuePanel_viewMore}>
								<svg className={styles.PastDuePanel_viewMore_icon}>
									<use xlinkHref={`${sprite}#icon-arrow_drop_down`}></use>
								</svg>{" "}
								<span>View More Results</span>
							</div>
						</ButtonSM>
					</div>
				</section>
			</article>
		</>
	);
};

export default PastDuePanel;

PastDuePanel.defaultProps = {
	records: [],
	currentUser: {},
	shiftTimes: [],
	hasNoRecords: false,
};

PastDuePanel.propTypes = {
	records: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	hasNoRecords: PropTypes.bool.isRequired,
	viewMore: PropTypes.func.isRequired,
	currentUser: PropTypes.object.isRequired,
	shiftTimes: PropTypes.array.isRequired,
};
