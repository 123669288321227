import React from "react";
import styles from "../../css/adlschedule/AdlTable.module.scss";
import { PropTypes } from "prop-types";
import AdlTableHeading from "./AdlTableHeading";

// REQUIREMENTS:
// 1. FOR EVERY SHIFT COLUMN THERE'S A SHIFT, THERE CAN BE MULTIPLE COLUMNS (IE FOR DAYS ETC) IN A SPECIFIC SHIFT COLUMN

const AdlTable = ({
	tableHeading = `Resident's Adl Shift Schedule`,
	editCount = 0,
	totalCount,
	clearSelection,
	saveSelection,
	children,
}) => {
	const withProps = React.Children.map(children, (child, i) => {
		return React.cloneElement(child, {
			editCount: editCount,
			totalCount: totalCount,
		});
	});
	return (
		<article className={styles.AdlTable}>
			<AdlTableHeading
				heading={tableHeading}
				editCount={editCount}
				totalCount={totalCount}
				clearSelection={clearSelection}
				saveSelection={saveSelection}
			/>
			{withProps}
		</article>
	);
};

export default AdlTable;

AdlTable.defaultProps = {
	shiftHeadings: [],
	tableHeading: `Resident's Adl Shift Schedule`,
	editCount: 0,
};

AdlTable.propTypes = {
	tableHeading: PropTypes.string,
	editCount: PropTypes.number.isRequired,
	totalCount: PropTypes.number.isRequired,
	children: PropTypes.any.isRequired,
};
