import React from "react";
import styles from "../../css/reports/ReportDesc.module.scss";
import { PropTypes } from "prop-types";

const ReportDesc = ({ reportName, reportDesc }) => {
	return (
		<div className={styles.ReportDesc}>
			<div className={styles.ReportDesc_label}>
				<div className={styles.ReportDesc_label_item}>{reportName}:</div>
				<div className={styles.ReportDesc_label_subItem}>{reportDesc}</div>
			</div>
		</div>
	);
};

export default ReportDesc;

ReportDesc.defaultProps = {};

ReportDesc.propTypes = {
	reportName: PropTypes.string.isRequired,
	reportDesc: PropTypes.string.isRequired,
};
