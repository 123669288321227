import React from "react";
import styles from "../../css/calendar/CalendarNav.module.scss";
import sprite from "../../assets/icons/carets-arrows.svg";
import { PropTypes } from "prop-types";
import { format } from "date-fns";
import { featureFlags } from "../../helpers/utils_features";

const defaultWeek = {
	disablePrev: true,
	disableNext: true,
};

const CalendarNav = ({
	currentWeek, // not used???
	currentMonth,
	currentYear,
	getPrevWeek,
	getNextWeek,
	changeMonth,
	weekControls = { ...defaultWeek },
}) => {
	return (
		<nav className={styles.CalendarNav}>
			<ul className={styles.CalendarNav_list}>
				<li className={styles.CalendarNav_list_item}>
					{featureFlags?.enableCalendarNav && (
						<button
							className={styles.CalendarNav_list_item_btn}
							onClick={getPrevWeek}
							disabled={weekControls?.disablePrev}
						>
							<svg className={styles.CalendarNav_list_item_btn_arrow}>
								<use xlinkHref={`${sprite}#icon-angle-left`}></use>
							</svg>
						</button>
					)}
				</li>
				<li className={styles.CalendarNav_list_item}>
					<h4 className={styles.CalendarNav_list_item_month}>
						<button
							className={styles.CalendarNav_list_item_month_btn}
							onClick={changeMonth}
						>
							<b>{`${format(currentMonth.monthStart, "MMMM")}`}</b>{" "}
						</button>
						<b> {currentYear}</b>
					</h4>
				</li>
				<li className={styles.CalendarNav_list_item}>
					{featureFlags?.enableCalendarNav && (
						<button
							className={styles.CalendarNav_list_item_btn}
							onClick={getNextWeek}
							disabled={weekControls?.disableNext}
						>
							<svg className={styles.CalendarNav_list_item_btn_arrow}>
								<use xlinkHref={`${sprite}#icon-angle-right`}></use>
							</svg>
						</button>
					)}
				</li>
			</ul>
		</nav>
	);
};

export default CalendarNav;

CalendarNav.defaultProps = {};

CalendarNav.propTypes = {
	currentWeek: PropTypes.shape({
		weekStart: PropTypes.instanceOf(Date).isRequired,
		weekEnd: PropTypes.instanceOf(Date).isRequired,
		days: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
	}).isRequired,
	currentMonth: PropTypes.shape({
		monthStart: PropTypes.instanceOf(Date).isRequired,
		monthEnd: PropTypes.instanceOf(Date).isRequired,
		days: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
	}).isRequired,
	currentYear: PropTypes.number.isRequired,
	getNextWeek: PropTypes.func.isRequired,
	getPrevWeek: PropTypes.func.isRequired,
	changeMonth: PropTypes.func.isRequired,
};
