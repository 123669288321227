import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { isEmptyObj, isEmptyArray } from "../../helpers/utils_types";
import { saveResidentAdlScheduleMany } from "../../helpers/utils_adlSchedule";
import { AdlShiftScheduleModel } from "../../helpers/utils_models";
import styles from "../../css/adlschedule/AdlShiftTile.module.scss";
import Checkbox from "../shared/Checkbox";

// NEW REQUIREMENTS: INCLUDES 2 SEPARATE SCENARIOS:
// 1. UPDATING SETTINGS FOR A SINGLE RESIDENT
// 		1a. SHOW THE EXISTING SETTINGS(IF AVAILABLE) FROM THE DB
// 2. UPDATE SETTINGS FOR MULTIPLE RESIDENTS
// 		2a. SHOW A BLANK TABLE(WITH NOT EXISTING SELECTIONS) AND APPLY EACH CHANGE UNIVERSALLY!!!
//
// REQUIRED PROPS
// 1. "token" - from user
const getModel = (shiftModel = {}, adl, shift) => {
	if (isEmptyObj(shiftModel)) {
		const base = new AdlShiftScheduleModel();
		base.setCategoryID(adl);
		base.setShiftID(shift);
		base.setProp("Notes", `${adl} - ${shift}`);
		return base.getModel();
	}
	return { ...shiftModel };
};

const AdlShiftTile = ({
	adl,
	shift,
	isDisabled = false,
	shiftModel = {},
	selectedResidents,
	currentUser,
}) => {
	const { AssessmentCategoryId, Name: AdlName } = adl;
	const [isSelected, setIsSelected] = useState(false);
	const [updatedModel, setUpdatedModel] = useState(
		getModel(shiftModel, AdlName, shift)
	);

	const handleAdlShift = (e) => {
		const { checked } = e.target;
		if (isEmptyArray(selectedResidents)) {
			return saveAdlShiftChange(updatedModel);
		}
		setIsSelected(checked);
		toggleShiftModel(updatedModel, checked);
	};

	const toggleShiftModel = (model, isSelected) => {
		const newModel = {
			...model,
			ShiftMon: isSelected,
			ShiftTue: isSelected,
			ShiftWed: isSelected,
			ShiftThu: isSelected,
			ShiftFri: isSelected,
			ShiftSat: isSelected,
			ShiftSun: isSelected,
		};
		setUpdatedModel(newModel);
		return saveAdlShiftChange(newModel);
	};

	const saveAdlShiftChange = async (model) => {
		const { token } = currentUser;
		if (isEmptyArray(selectedResidents))
			return alert("Please select 1 more residents first.");
		const success = await saveResidentAdlScheduleMany(
			token,
			selectedResidents,
			[model]
		);

		if (success) {
			return success;
		} else {
			return success;
		}
	};

	const clearSelectionHandler = (e) => {
		setIsSelected(false);
	};

	return (
		<section
			className={styles.AdlShiftTile}
			title={isDisabled ? "Disabled" : ""}
		>
			<Checkbox
				name={`${AssessmentCategoryId}${shift}`}
				id={`${AssessmentCategoryId}${shift}`}
				isDisabled={isDisabled}
				val={updatedModel.ShiftMon}
				handleCheckbox={(e) => {
					handleAdlShift(e);
				}}
			/>
		</section>
	);
};

export default AdlShiftTile;

AdlShiftTile.defaultProps = {};

AdlShiftTile.propTypes = {
	adl: PropTypes.shape({
		AssessmentCategoryId: PropTypes.number.isRequired, // a.k.a. "AssessmentCategoryId"
		Name: PropTypes.string.isRequired,
	}),
	shift: PropTypes.string.isRequired,
	selectedResidents: PropTypes.arrayOf(PropTypes.number).isRequired,
	currentUser: PropTypes.shape({
		token: PropTypes.string.isRequired,
	}),
};
