import React, { useContext } from "react";
import { PropTypes } from "prop-types";
import { useIdleTimer } from "react-idle-timer";
import { AuthContext, initialAuthState } from "../state/AuthContext";
import { GlobalStateContext } from "../state/GlobalStateContext";
import { convertMinsToMs, convertMsToMins } from "../helpers/utils_dates";
import { format } from "date-fns";
import { withRouter } from "react-router-dom";

// ##TODOS:
// - Consider triggering a dialog UI:
//    - Ask user whether they wanna stay logged in?
//    - If no response, after 30 secs, then force logout
//    - If user confirms, then refresh the auth token

// ##TODOS:
// - Add 'clearAuth' util that resets localStorage, auth store & global store
// - Add route-prevention until 'checkLogin' state is set to false
// 		- Resource: https://stackoverflow.com/questions/44632420/react-router-v4-login-check-after-hitting-refresh-on-browser

// 30 mins of inactivity
const timeout = convertMinsToMs(30);

const AuthTimeout = ({ history, children }) => {
	const { setAuthData } = useContext(AuthContext);
	const { dispatch } = useContext(GlobalStateContext);

	// force logout after 30 mins of idle
	const handleOnIdle = (e) => {
		// console.log(`User is IDLE:`, e);
		console.log(`Last active:`, getLastActiveTime());
		// reset auth & store
		setAuthData({ ...initialAuthState });
		history.replace({
			pathname: "/",
			state: {
				wasLoggedOut: true,
			},
		});
		dispatch({ type: "FORCE_LOGOUT" });
	};

	const handleOnActive = (e) => {
		// console.log(`User is ACTIVE:`, e);
		console.log(
			`Time remaining (in mins)? `,
			convertMsToMins(getRemainingTime())
		);
	};

	const handleOnAction = (e) => {
		// console.log(`User did something:`, e);
	};

	const { getRemainingTime, getLastActiveTime } = useIdleTimer({
		timeout: timeout,
		onIdle: handleOnIdle,
		onActive: handleOnActive,
		onAction: handleOnAction,
		debounce: 800,
	});

	return <>{children}</>;
};

export default withRouter(AuthTimeout);

AuthTimeout.defaultProps = {};

AuthTimeout.propTypes = {
	children: PropTypes.any,
};
