import React from "react";
import styles from "../../css/adlschedule/AdlColumnHeading.module.scss";
import { PropTypes } from "prop-types";

const AdlColumnHeading = ({ adl = {}, isDisabled = false }) => {
	return (
		<div
			className={
				isDisabled
					? styles.AdlColumnHeading_isDisabled
					: styles.AdlColumnHeading
			}
			title={isDisabled ? "NO CHANGES ALLOWED" : ""}
		>
			<span>{adl.Name}</span>
		</div>
	);
};

export default AdlColumnHeading;

AdlColumnHeading.defaultProps = {
	adl: {},
	isDisabled: false,
};

AdlColumnHeading.propTypes = {
	adl: PropTypes.object.isRequired,
	isDisabled: PropTypes.bool.isRequired,
};
