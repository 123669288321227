import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import styles from "../../css/reportpicker/ReportSortPicker.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import FormSection from "../forms/FormSection";
import RadioButton from "../shared/RadioButton";
import ReportSortOptions from "./ReportSortOptions";
import Divider from "../forms/Divider";

const getSort = (settings) => {
	if (isEmptyVal(settings.sortBy)) return "";
	const { sortBy } = settings;
	const trimmed = sortBy.replace(" ", "");
	return `sort${trimmed}`;
};

/**
 * Purpose: handles the 'sortBy' radio buttons
 * - Options: 'By Resident', 'By Staff', 'By Shift', 'By ADL'
 */

const hideSortPicker = (disableSorts, sorts, reportType) => {
	if (disableSorts) return true;
	if (isEmptyArray(sorts)) return true;
	if (reportType === `Daily`) return true;

	return false;
};

const ReportSortPicker = ({
	settings,
	sorts = [],
	sortOptions = {},
	handleSortBy,
	handleSortOptions,
	disableSorts = false,
	removeSorting,
}) => {
	if (hideSortPicker(disableSorts, sorts, settings.reportType)) {
		return null;
	}
	return (
		<>
			<FormSection
				icon="settings"
				title="Select Sorting Option(s)"
				hoverText="This is how the data will be organized."
			>
				{!isEmptyArray(sorts) &&
					sorts.map((sort, idx) => (
						<RadioButton
							key={`${sort.id}--${idx}`}
							name="sortBy"
							id={sort.id} // unique identifier
							label={sort.label}
							val={settings.sortBy === sort.id}
							handleSelection={handleSortBy}
						/>
					))}
				<ReportSortOptions
					settings={settings}
					sortOptions={sortOptions}
					handleSortOptions={handleSortOptions}
				/>
				<div className={styles.ReportSortPicker_removeAll}>
					<div
						type="reset"
						className={styles.ReportSortPicker_removeAll_btn}
						onClick={removeSorting}
					>
						<svg className={styles.ReportSortPicker_removeAll_btn_icon}>
							<use xlinkHref={`${sprite}#icon-clearclose`}></use>
						</svg>
						Remove Sorting
					</div>
				</div>
			</FormSection>
			<Divider />
		</>
	);
};

export default React.memo(ReportSortPicker);

ReportSortPicker.defaultProps = {
	sorts: [],
};

ReportSortPicker.propTypes = {
	settings: PropTypes.object,
	sorts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.string,
			val: PropTypes.oneOfType[(PropTypes.string, PropTypes.any)],
			handleSortBy: PropTypes.func,
		})
	),
	removeSorting: PropTypes.func.isRequired,
};
