import React from "react";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import styles from "../../css/reports/RecentlyViewedReports.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import RecentlyViewedReportCard from "./RecentlyViewedReportCard";

const recentsDescription = `No recent reports.\n Each report created will show up here for quick availability.\n Please create a report.`;

const RecentlyViewedReports = ({
	rerunReport,
	removeItem,
	handleReportsPicker,
	recentlyViewedList = [],
	emptyMsg = recentsDescription,
}) => {
	return (
		<>
			<section className={styles.RecentlyViewedReports_heading}>
				<div className={styles.RecentlyViewedReports_heading_title}>
					Recently Viewed Reports
				</div>
			</section>
			<section className={styles.RecentlyViewedReports}>
				{isEmptyArray(recentlyViewedList) && (
					<div className={styles.RecentlyViewedReports_empty}>
						<p className={styles.RecentlyViewedReports_empty_msg}>{emptyMsg}</p>
						<button
							className={styles.RecentlyViewedReports_empty_btn}
							onClick={handleReportsPicker}
						>
							<svg className={styles.RecentlyViewedReports_empty_btn_icon}>
								<use xlinkHref={`${sprite}#icon-add_chart`}></use>
							</svg>
							<span>Select Settings</span>
						</button>
					</div>
				)}
				{!isEmptyArray(recentlyViewedList) &&
					recentlyViewedList.map((entry, index) => (
						<RecentlyViewedReportCard
							reportDetails={entry}
							key={`Report__${entry.id}__${index}`}
							rerunReport={() => rerunReport(entry)}
							removeItem={() => removeItem(entry.id)}
						/>
					))}
			</section>
		</>
	);
};

export default RecentlyViewedReports;

RecentlyViewedReports.defaultProps = {
	recentlyViewedList: [],
	emptyMsg: `No recent reports. \n Each report created will show up here for quick availability. \n Please create a report.`,
};

RecentlyViewedReports.propTypes = {
	recentlyViewedList: PropTypes.arrayOf(PropTypes.object),
	rerunReport: PropTypes.func.isRequired,
	handleReportsPicker: PropTypes.func.isRequired,
	removeItem: PropTypes.func.isRequired,
};
