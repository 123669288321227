import React, { useEffect, useCallback } from "react";
import styles from "../../css/reports/ReportTypeController.module.scss";
import { PropTypes } from "prop-types";
import * as Sentry from "@sentry/react";
// helpers
import { isEmptyVal } from "../../helpers/utils_types";

import {
	matchFacilityByName,
	getFacilityInfo,
	hasFacility,
} from "../../helpers/utils_facility";
import ALASelector from "../app/ALASelector";
import ReportInfoPanel from "./ReportInfoPanel";

// CONSIDERATIONS
// - include ALASelector, then use 'children' prop to place whatever content is needed inside

// creates a unique key: <DAILY|HISTORICAL> - <REPORT-NAME> - SELECTOR
const getKey = (type, title) => {
	const newTitle = title.replace(/\s/g, "-").toUpperCase();
	return `${type}-${newTitle}-SELECTOR`;
};

const ReportTypeController = ({
	reportTitle,
	reportType = "Daily",
	currentUser = {},
	residentsByFacility = {},
	residents = [],
	selectedFacility,
	setSelectedFacility,
	selectedResident,
	setSelectedResident,
	changeFacility,
	changeResident,
	setFacilityData,
	dispatch,
	recentlyViewedList = [],
	reportDesc,
	rerunReport,
	removeRecentsItem,
	handleReportsPicker,
	hasLoaded = false,
	reportSettings = {},
	children,
}) => {
	// fetches raw 'FACILITY' table data
	const fetchFacilityInfo = async () => {
		const { token, facilityID } = currentUser;
		const facilityData = await getFacilityInfo(token, facilityID);
		if (facilityData) {
			return setFacilityData(facilityData);
		}
		return fetchFacilityInfo();
	};

	// sets 'currentFacility' obj when 'selectedResident' changes
	const setFacility = useCallback(() => {
		if (isEmptyVal(selectedFacility)) return;
		const facilityRecord = matchFacilityByName(
			selectedFacility,
			currentUser?.facilities
		);
		const facilityResidents = residentsByFacility[facilityRecord.FacilityId];

		dispatch({
			type: "SET_FACILITY",
			data: {
				facilityRecord: facilityRecord,
				facilityResidents: facilityResidents,
			},
		});
	}, [currentUser, dispatch, residentsByFacility, selectedFacility]);

	// sets 'currentFacility' in global store when it changes
	useEffect(() => {
		setFacility();
	}, [setFacility]);

	// fetches facility info "on mount"
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (hasFacility(selectedFacility)) {
			fetchFacilityInfo();
		}
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Sentry.ErrorBoundary>
			<div className={styles.ReportTypeController}>
				{currentUser?.facilities?.length > 1 && (
					<article className={styles.ReportTypeController_selector}>
						<ALASelector
							key={getKey(reportType, reportTitle)}
							isAdmin={currentUser?.isAdmin}
							disableResident={true}
							facilities={currentUser?.facilities}
							residents={residents}
							defaultFacility={selectedFacility}
							defaultResident={selectedResident}
							syncFacility={setSelectedFacility}
							syncResident={setSelectedResident}
							loadFacility={changeFacility}
							loadResident={changeResident}
						/>
					</article>
				)}
				<article className={styles.ReportTypeController_about}>
					<div className={styles.ReportTypeController_about_title}>
						{reportTitle}
					</div>
				</article>
				<article className={styles.ReportTypeController_settingsPanel}>
					<ReportInfoPanel
						reportTitle={reportTitle}
						reportSettings={reportSettings}
						recentlyViewedList={recentlyViewedList}
						reportDesc={reportDesc}
						rerunReport={rerunReport}
						removeRecentsItem={removeRecentsItem}
						handleReportsPicker={handleReportsPicker}
						hasLoaded={hasLoaded}
					/>
				</article>
				<article className={styles.ReportTypeController_reportPanel}>
					{children}
				</article>
			</div>
		</Sentry.ErrorBoundary>
	);
};

export default ReportTypeController;

ReportTypeController.defaultProps = {
	reportType: "Daily",
	currentUser: {},
	currentFacility: {},
	residentsByFacility: {},
	recentlyViewedList: [],
};

ReportTypeController.propTypes = {
	reportType: PropTypes.string.isRequired,
	reportTitle: PropTypes.string.isRequired,
	reportDesc: PropTypes.string,
	currentUser: PropTypes.object,
	currentFacility: PropTypes.object,
	residentsByFacility: PropTypes.object,
	recentlyViewedList: PropTypes.array,
	selectedFacility: PropTypes.string.isRequired,
	selectedResident: PropTypes.string.isRequired,
	setSelectedFacility: PropTypes.func.isRequired,
	setSelectedResident: PropTypes.func.isRequired,
	changeFacility: PropTypes.func.isRequired,
	setFacilityData: PropTypes.func.isRequired,
	dispatch: PropTypes.func.isRequired,
	rerunReport: PropTypes.func.isRequired,
	removeRecentsItem: PropTypes.func.isRequired,
	children: PropTypes.any,
};
