import React from "react";
import styles from "../../css/app/UpdateButton.module.scss";
import { PropTypes } from "prop-types";

const UpdateButton = ({
	btnText = `Click to Update`,
	initUpdate,
	isDisabled = false,
}) => {
	return (
		<button
			type="button"
			onClick={initUpdate}
			disabled={isDisabled}
			className={styles.UpdateButton}
		>
			{btnText}
		</button>
	);
};

export default UpdateButton;

UpdateButton.defaultProps = {
	isDisabled: false,
	btnText: `Click to Update`,
};

UpdateButton.propTypes = {
	initUpdate: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool,
};
