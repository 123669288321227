import React from "react";
import styles from "../../css/forms/FormSection.module.scss";
import { PropTypes } from "prop-types";
import { hasProp } from "../../helpers/utils_types";
import sprite from "../../assets/icons/modals-complete.svg";
import sprite2 from "../../assets/icons/buttons.svg";
// components
import InfoIcon from "../shared/InfoIcon";

const padding = {
	marginLeft: ".5rem",
};

const ICONS = {
	checkbox: "check_box",
	checkCircle: "check_circle",
	checkBlank: "check_box_outline_blank",
	chart: "insert_chartpollassessment",
	chartOutlined: "insert_chart_outlined",
	license: "drivers-license",
	lightbulb: "light-bulb",
	calendarCheck: "event_available",
	calendarBusy: "event_busy",
	calendarNote: "event_note",
	building: "location_city",
	error: "error1",
	errorOutlined: "error_outline",
	warning: "warningreport_problem",
	brochure: "new_releases",
	help: "live_help",
	edit: "createmode_editedit",
	signature: "gesture",
	plus: "add2",
	close: "clearclose",
	alarm: "access_alarmalarm",
	clock: "access_timequery_builderschedule",
	cloud: "cloud5",
	silverware: "restaurant",
	print: "local_print_shopprint",
	user: "person",
	userOutlined: "person_outlineperm_identity",
	userCircle: "account_circle",
	userSquare: "account_box",
	recurring: "historyrestore",
	trash: "delete",
	inbox: "all_inbox",
	timer: "timer1",
	alert: "notifications1",
	alertOutlined: "notifications-outline",
	photo: "photo1",
	save: "save-disk",
	show: "view-show",
	hide: "view-hide",
	info: "info11",
	infoOutline: "info_outline",
	infoCircle: "info-with-circle",
	firstAid: "local_hospital",
	settings: "tune",
	note: "assignment",
	flag: "flag",
	tag: "tag",
	notAllowed: "not_interesteddo_not_disturb",
};
const ICONS2 = {
	lock: "lock11",
};

const getSprite = (icon, iconsObj) => {
	if (!hasProp(iconsObj, icon)) {
		return sprite2;
	} else {
		return sprite;
	}
};
const getIcon = (icon, iconsObj) => {
	if (!hasProp(iconsObj, icon)) {
		return ICONS2[icon];
	} else {
		return ICONS[icon];
	}
};

const FormSection = ({
	title,
	exampleText = null,
	icon = "checkbox",
	hoverText,
	hideInfoIcon = false,
	isDisabled = false,
	isHidden = false,
	titleColor,
	iconColor,
	children,
}) => {
	if (isHidden) {
		return null;
	}
	return (
		<fieldset className={styles.FormSection} disabled={isDisabled}>
			<h4 className={styles.FormSection_heading}>
				<svg className={styles.FormSection_heading_icon}>
					<use
						xlinkHref={`${getSprite(icon, ICONS)}#icon-${getIcon(icon, ICONS)}`}
						fill={iconColor}
					></use>
				</svg>
				<span style={{ color: titleColor }}>
					{title} {exampleText && <i>({exampleText})</i>}
				</span>
				{!hideInfoIcon && (
					<InfoIcon size="XSM" hoverText={hoverText} customStyles={padding} />
				)}
			</h4>
			<div className={styles.FormSection_content}>{children}</div>
		</fieldset>
	);
};

export default FormSection;

FormSection.defaultProps = {
	exampleText: null,
	icon: "checkbox",
	hideInfoIcon: false,
	isDisabled: false,
};

FormSection.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	exampleText: PropTypes.string,
	icon: PropTypes.string,
	hoverText: PropTypes.string,
	hideInfoIcon: PropTypes.bool,
	iconColor: PropTypes.string,
	isDisabled: PropTypes.bool,
	children: PropTypes.any,
};
