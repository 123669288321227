import React from "react";
import styles from "../../css/calendar/ShiftDetailsTask.module.scss";
import sprite from "../../assets/icons/repeat.svg";
import { PropTypes } from "prop-types";
// helpers
import { getTaskName, getTaskDesc } from "../../helpers/utils_tasks";
import { statusHandler } from "../../helpers/utils_styles";
import { getShiftName } from "../../helpers/utils_shifts";
// components
import StatusBadge from "../shared/StatusBadge";

// ##TODOS:
// - Add 'PAST-DUE' status circle as option
// - Show entire 'taskName'
// - Include 'Recurring' task UI indicator

const ShiftDetailsTask = ({
	day,
	task = {},
	taskIndex,
	isSelected = false,
	currentFacility = {},
	changeSelected,
}) => {
	const { shifts } = currentFacility;
	return (
		<div
			className={
				isSelected
					? styles.ShiftDetailsTask_isSelected
					: styles.ShiftDetailsTask
			}
			title={isSelected ? "You're currently viewing this task's details" : ""}
			onClick={() => changeSelected(task, taskIndex)}
		>
			<span className={styles.ShiftDetailsTask_taskIndex}>{taskIndex}</span>
			<div className={styles.ShiftDetailsTask_name}>
				<span className={styles.ShiftDetailsTask_name_label}>
					{getTaskName(task, 100)}
				</span>
				<span className={styles.ShiftDetailsTask_name_desc}>
					{getTaskDesc(task, 100)}
				</span>
			</div>
			<div className={styles.ShiftDetailsTask_shift}>
				<span className={styles.ShiftDetailsTask_shift_text}>
					{getShiftName(task.AssessmentShiftId)}
				</span>
			</div>
			<section className={styles.ShiftDetailsTask_bottom}>
				<StatusBadge size="XSM" status={statusHandler(task, day, shifts)}>
					{statusHandler(task, day, shifts)}
				</StatusBadge>
				{true && (
					<span className={styles.ShiftDetailsTask_bottom_recurring}>
						<svg className={styles.ShiftDetailsTask_bottom_recurring_icon}>
							<use xlinkHref={`${sprite}#icon-cycle`}></use>
						</svg>
					</span>
				)}
			</section>
		</div>
	);
};

export default ShiftDetailsTask;

ShiftDetailsTask.defaultProps = {
	isSelected: false,
	task: {},
};

ShiftDetailsTask.propTypes = {
	day: PropTypes.instanceOf(Date).isRequired,
	isSelected: PropTypes.bool.isRequired,
	task: PropTypes.object.isRequired,
};
