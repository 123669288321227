import React from "react";
import styles from "../../css/views/ActualTimeReport.module.scss";
import { PropTypes } from "prop-types";

/**
 * ❌ - NOT CURRENTLY IN USE!!!
 * @description - Client-side 'report' detailing the how long a task takes. Includes the 'projected time' and 'actual time'
 */

const ActualTimeReport = ({ children }) => {
	return (
		<div className={styles.ActualTimeReport}>
			<h4 className="viewTitle">Actual Time Report</h4>
			{children}
		</div>
	);
};

export default ActualTimeReport;

ActualTimeReport.defaultProps = {};

ActualTimeReport.propTypes = {
	children: PropTypes.any,
};
