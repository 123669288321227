import React from "react";
import styles from "../../css/daily/FiltersPanel.module.scss";
import { PropTypes } from "prop-types";
// helpers
import { isEmptyArray } from "../../helpers/utils_types";
import {
	generateID,
	capitalizeAll,
	capitalizeADLs,
} from "../../helpers/utils_processing";
// components
import CheckboxSM from "../shared/CheckboxSM";

// TODOS:
// - Wire up 'clearAll' ADLs handler
// - Look into 'AdlFiltersPanel' collisions

const getVal = (allVals, optionName, clearAllVal, selectAll) => {
	const optionVal = allVals[optionName];
	if (clearAllVal) {
		return false;
	}
	if (selectAll) {
		return true;
	}
	return optionVal;
};
// formats the filter 'title'
// (ie 'adls' => 'ADLs' & 'status' => 'STATUS')
const handleTitle = (title) => {
	if (title.toLowerCase() === "adls") {
		return capitalizeADLs(title);
	} else {
		return capitalizeAll(title);
	}
};

// sectioned filters UI
const FiltersPanelGroup = ({
	title,
	vals = {},
	selectAllVal,
	clearAllVal,
	filters = [],
	handleFilters,
}) => {
	return (
		<section className={styles.FiltersPanelGroup}>
			<div className={styles.FiltersPanelGroup_top}>
				<span className={styles.FiltersPanelGroup_top_title}>
					Show/Hide {handleTitle(title)}
				</span>
			</div>
			<div className={styles.FiltersPanelGroup_options}>
				{!isEmptyArray(filters) &&
					filters.map((option, idx) => (
						<div
							className={styles.FiltersPanelGroup_options_option}
							key={generateID()}
						>
							<CheckboxSM
								id={option}
								name={option}
								label={option}
								handleCheckbox={handleFilters}
								key={`${generateID(10)}--${option}--${idx}`}
								val={getVal(vals, option, clearAllVal, selectAllVal)}
							/>
						</div>
					))}
			</div>
		</section>
	);
};

FiltersPanelGroup.defaultProps = {
	vals: {},
	filters: [],
};
FiltersPanelGroup.propTypes = {
	title: PropTypes.string,
	vals: PropTypes.object.isRequired,
	selectAllVal: PropTypes.bool.isRequired,
	filters: PropTypes.arrayOf(PropTypes.string).isRequired,
	handleFilters: PropTypes.func.isRequired,
};

export { FiltersPanelGroup };

// FILTERS' DATA MODEL
const FILTERS = {
	adls: [],
	status: [],
	// ...any other filters appear here...
};

const FiltersPanel = ({
	allFilters = {},
	vals = {},
	selectAllVal,
	clearAllVal,
	handleFilters,
	handleSelectAll,
	handleClearAll,
}) => {
	const filterTypes = Object.keys(allFilters);

	return (
		<aside className={styles.FiltersPanel}>
			<div className={styles.FiltersPanel_inner}>
				{!isEmptyArray(filterTypes) &&
					filterTypes.map((type, idx) => (
						<FiltersPanelGroup
							key={generateID(5) + type + idx}
							title={type}
							vals={vals}
							selectAllVal={selectAllVal}
							filters={allFilters[type]}
							handleFilters={handleFilters}
							handleSelectAll={handleSelectAll}
						/>
					))}
				<div className={styles.FiltersPanel_inner_selections}>
					<button
						className={styles.FiltersPanel_inner_selections_selectAll}
						onClick={handleSelectAll}
					>
						Select All
					</button>
					<button
						className={styles.FiltersPanel_inner_selections_clearAll}
						onClick={handleClearAll}
					>
						Clear All ADLs
					</button>
				</div>
			</div>
		</aside>
	);
};

export default FiltersPanel;

FiltersPanel.defaultProps = {
	vals: {},
	allFilters: {},
};

FiltersPanel.propTypes = {
	vals: PropTypes.object.isRequired,
	selectAllVal: PropTypes.bool.isRequired,
	allFilters: PropTypes.object.isRequired,
	handleFilters: PropTypes.func.isRequired,
	handleSelectAll: PropTypes.func.isRequired,
};
