import React, { useContext } from "react";
import styles from "../../css/dashboard/Dashboard.module.scss";
import { Switch } from "react-router-dom";
import { isEmptyArray } from "../../helpers/utils_types";
import { ROUTES_CONFIG as routesConfig, routesHandler } from "../routes/Routes";
import { GlobalStateContext } from "../../state/GlobalStateContext";

/**
 * Only handles the routes not w/ custom handling by user type.
 *
 * Supports:
 * - ADMIN ACCESS
 * - NON-ADMIN ACCESS
 * - SUPER-USER ACCESS
 */

const Dashboard = (props) => {
	const { state } = useContext(GlobalStateContext);
	const { user } = state;
	return (
		<section className={styles.Dashboard}>
			<div className={styles.Dashboard_content}>
				<Switch>
					{/* DASHBOARD ROUTES */}
					{!isEmptyArray(routesConfig) && [
						...routesHandler(routesConfig, user, props),
					]}
				</Switch>
			</div>
		</section>
	);
};

export default Dashboard;

Dashboard.defaultProps = {};

Dashboard.propTypes = {};
