import React, { useState } from "react";
import styles from "../../css/reports/ReportViewer.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import ReportPane from "./ReportPane";
import Table from "../tables/Table";
import Embed from "../shared/Embed";
import Placeholder from "../shared/Placeholder";

// TODOS:
// 1. Add handling for 'No Report Records Found.'
// 		1a. Add custom UI indicator for no report data

const emptySCSS = {
	display: "flex",
	justifyContent: "center",
	textAlign: "center",
};

const getSearchPlaceholder = (cols) => {
	let text = `Search by `;
	cols.map((col) => {
		text += `${col}, `;
	});
	return text;
};

/**
 * Default table data structure, passed from props:
 * - "defaultData.cols": table columns (array of strings)
 * - "defaultData.data": raw table data, unprocessed (array of objects)
 * - "defaultData.title": table title (string)
 * - "defaultData.size": defines table's size prop (string). Options: FULL, XLG, LG, MD, SM, XSM
 * - "defaultData.enableActions": enables filtering, sorting & custom search. (boolean)
 */
const defaultData = {
	cols: [],
	data: [],
	title: "",
	size: "FULL",
	enableActions: true,
};

const ReportViewer = ({
	reportType = `Daily`,
	embedSrc = ``,
	embedType = `application/pdf`,
	isLoading,
	noReportData = false,
	hasLoaded = false,
	tableData = {},
	showSMARTTable = `Daily`,
	showMirror = `Historical`,
}) => {
	const [msg, setMsg] = useState(
		// !hasLoaded ? `Please select a report` : `No report data found.`
		noReportData ? `No report data found.` : `Please select a report`
	);

	return (
		<div className={styles.ReportViewer}>
			{showSMARTTable && (
				<ReportPane
					key={`Daily-${tableData.data?.length}`}
					size={tableData.size}
					title={tableData.title}
					isLoading={isLoading}
					loadingMsg={`Loading daily report data...`}
				>
					{!isEmptyArray(tableData.data) ? (
						<Table
							cols={tableData.cols}
							data={tableData.data}
							size={tableData.size}
							title={tableData.title}
							enableActions={tableData.enableActions}
							searchPlaceholder={getSearchPlaceholder(tableData?.cols)}
						/>
					) : (
						<div className={styles.ReportViewer_placeholder}>
							<Placeholder
								size="MD"
								msg={msg}
								color="red"
								customStyles={emptySCSS}
							/>
						</div>
					)}
				</ReportPane>
			)}

			{reportType === showMirror && (
				<ReportPane
					key={`Historical-${tableData.title}`}
					size={tableData.size}
					title={tableData.title}
					isLoading={isLoading}
					loadingMsg={`Loading historical report data...`}
				>
					{!isEmptyVal(embedSrc) ? (
						<Embed type={embedType} src={embedSrc} />
					) : (
						<div className={styles.ReportViewer_placeholder}>
							<Placeholder
								size="MD"
								msg={msg}
								color="red"
								customStyles={emptySCSS}
							/>
						</div>
					)}
				</ReportPane>
			)}
		</div>
	);
};

export default ReportViewer;

ReportViewer.defaultProps = {
	reportType: `Daily`,
	embedType: `application/pdf`,
	noReportData: false,
	tableData: {},
};

ReportViewer.propTypes = {
	reportType: PropTypes.string.isRequired,
	embedSrc: PropTypes.string,
	embedType: PropTypes.string,
	noReportData: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]), // should ONLY be boolean tho
	tableData: PropTypes.shape({
		title: PropTypes.string,
		size: PropTypes.string,
		cols: PropTypes.arrayOf(PropTypes.string),
		data: PropTypes.arrayOf(PropTypes.object),
		enableActions: PropTypes.bool,
	}),
};
