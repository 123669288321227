import React, { useEffect, useRef } from "react";
import styles from "../../css/shared/MobileModal.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";
import { useLockBodyScroll } from "../../utils/useLockBodyScroll";

const MobileModal = ({ title, closeModal, children }) => {
	const mobileRef = useRef();
	const { isOutside } = useOutsideClick(mobileRef);
	const userEscaped = useKeyboardShortcut(["Escape"]);
	useLockBodyScroll();

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside || userEscaped) {
			return closeModal();
		}
		return () => {
			isMounted = false;
		};
	}, [isOutside, closeModal, userEscaped]);

	return (
		<aside className={styles.MobileModal} ref={mobileRef}>
			<section className={styles.MobileModal_top}>
				<h4 className={styles.MobileModal_top_title}>{title}</h4>
				<svg className={styles.MobileModal_top_icon} onClick={closeModal}>
					<use xlinkHref={`${sprite}#icon-clearclose`}></use>
				</svg>
			</section>
			<section className={styles.MobileModal_main}>{children}</section>
		</aside>
	);
};

export default MobileModal;

MobileModal.defaultProps = {};

MobileModal.propTypes = {};
