import React from "react";
import styles from "../../css/pages/LoginPage.module.scss";
import logoA from "../../assets/images/BRAND/logoA.png";
import appLogo from "../../assets/images/BRAND/appLogo-NoShadow.svg";
import sprite from "../../assets/icons/modals-complete.svg";
// helpers
import { SERVICES } from "../../helpers/utils_apps";
// components
import { isDevOrTest } from "../../helpers/utils_env";
import { blue } from "../../helpers/utils_styles";
// components
import ButtonSM from "../shared/ButtonSM";
import { getServiceUrlByEnv } from "../../helpers/utils_params";

const customCSS = {
	redirect: {
		padding: ".7rem 1.6rem",
		backgroundColor: "transparent",
		color: blue[700],
		fontSize: "1.3rem",
		fontWeight: "500",
	},
};

const SHOW_APP_LOGO = true;

const Message = ({ children }) => {
	return (
		<aside className={styles.Message}>
			<svg className={styles.Message_icon}>
				<use xlinkHref={`${sprite}#icon-check_circle`}></use>
			</svg>
			<div className={styles.Message_msg}>{children}</div>
		</aside>
	);
};

const showLogoutMsg = (history) => {
	const wasLoggedOut = history?.location?.state?.wasLoggedOut;
	return wasLoggedOut;
};

const LoginPage = ({ history, children }) => {
	// handles redirects to: "Legacy" or "AdminPortal"
	const redirectToApp = (appName) => {
		const appUrl = getServiceUrlByEnv(appName);

		window.open(appUrl);
	};

	return (
		<>
			<div className={styles.LoginPage}>
				<div className={styles.LoginPage_brandLogo}>
					<img
						src={logoA}
						alt="AL Advantage: Care Tracker Logo"
						className={styles.LoginPage_brandLogo_img}
						onClick={() => redirectToApp("SeniorCareVB")}
						title={`Go to EHR: Care Manager`}
					/>
				</div>
				{SHOW_APP_LOGO && (
					<div className={styles.LoginPage_banner}>
						<div className={styles.LoginPage_banner_wrapper}>
							<img
								src={appLogo}
								alt="Care Tracker App Logo"
								className={styles.LoginPage_banner_wrapper_appLogo}
							/>
							<span className={styles.LoginPage_banner_wrapper_appName}>
								Care Tracker
							</span>
						</div>
					</div>
				)}

				<section className={styles.LoginPage_content}>{children}</section>

				<section className={styles.LoginPage_extra}>
					<ButtonSM
						customStyles={customCSS.redirect}
						handleClick={() => redirectToApp("AdminPortal")}
					>
						Trying to go to the ALA Portal?
					</ButtonSM>
				</section>

				{showLogoutMsg(history) && <Message>Your session has ended!</Message>}
			</div>
		</>
	);
};

// export default withRouter(LoginPage); // REMOVED 6-10-2021
export default LoginPage;
