import React, { useState } from "react";
import styles from "../../css/residenttable/ResidentTableHeading.module.scss";
import sprite from "../../assets/icons/resident-table.svg";
import { PropTypes } from "prop-types";

const ResidentTableHeading = ({ heading, selectSort }) => {
	const [isSorted, setIsSorted] = useState(false); // triggers the rotate transition/transform
	return (
		<div className={styles.ResidentTableHeading}>
			<div
				className={styles.ResidentTableHeading_heading}
				title={`Sort by ${heading}`}
				onClick={() => {
					setIsSorted(!isSorted);
					selectSort(heading);
				}} // handleSorting(heading) ???
			>
				<span className={styles.ResidentTableHeading_heading_title}>
					{heading}
				</span>
				<svg
					className={
						isSorted
							? styles.ResidentTableHeading_heading_icon_rotated
							: styles.ResidentTableHeading_heading_icon
					}
				>
					<use xlinkHref={`${sprite}#icon-filter_list`}></use>
				</svg>
			</div>
		</div>
	);
};

export default ResidentTableHeading;

ResidentTableHeading.defaultProps = {};

ResidentTableHeading.propTypes = {
	heading: PropTypes.string.isRequired,
	selectSort: PropTypes.func.isRequired,
};
