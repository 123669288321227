import React, { useState } from "react";
import styles from "../../css/pastdue/PastDueList.module.scss";
import { PropTypes } from "prop-types";
import { useAlerts, initialSettings } from "../../utils/useAlerts";
import { isEmptyObj } from "../../helpers/utils_types";
import { getTaskIdType, isScheduledTask } from "../../helpers/utils_tasks";
import {
	getPastDueResident,
	getPastDueScheduled,
	getPastDueUnscheduled,
	excludeCompleted,
	countPastDue,
	noPastDueTasks,
} from "../../helpers/utils_pastdue";
import { getShiftID, getCurrentShift } from "../../helpers/utils_shifts";
import {
	markCompleteTracking,
	savePastDueTracking,
	getPastDueTracking,
} from "../../helpers/utils_updates";
// components
import ResidentPhoto from "../app/ResidentPhoto";
import PastDueEntry from "./PastDueEntry";
// import ResidentPhotoAsync from "../app/ResidentPhotoAsync";

// ##TODOS:
// - Remove the 'early return' in "markComplete()" to allow for proper updates!!!
// 		- Work on this testing this with requests later today after several tasks have become past due!!!

const getPastDueResidentID = (record) => {
	const { Resident } = record;
	const obj = Resident?.[0] ?? {};

	return obj?.ResidentID;
};

const PastDueList = ({ record, currentUser = {}, shiftTimes = [] }) => {
	const { AlertsHandler, dispatchAlert } = useAlerts({ ...initialSettings });
	const [collapseList, setCollapseList] = useState(true);
	const [pastDueTasks, setPastDueTasks] = useState({
		scheduled: [...getPastDueScheduled(record)],
		unscheduled: [...getPastDueUnscheduled(record)],
	});

	// filters out newly completed task & re-sets local task's state
	const removeCompleted = (completedTask) => {
		const { scheduled, unscheduled } = pastDueTasks;

		if (isScheduledTask(completedTask)) {
			const { AssessmentTrackingTaskId: completedID } = completedTask;

			return setPastDueTasks({
				...pastDueTasks,
				scheduled: [...excludeCompleted(completedID, scheduled)],
			});
		} else {
			const { AssessmentUnscheduleTaskId: completedID } = completedTask;
			return setPastDueTasks({
				...pastDueTasks,
				unscheduled: [...excludeCompleted(completedID, unscheduled)],
			});
		}
	};

	const markComplete = async (task) => {
		const { token } = currentUser;
		const matchingRecord = await getPastDueTracking(token, task);

		if (!isEmptyObj(matchingRecord)) {
			const shiftID = getShiftID(getCurrentShift(shiftTimes));
			const updatedRecord = markCompleteTracking(matchingRecord, shiftID);
			await savePastDueTracking(token, updatedRecord);

			return removeCompleted(task);
		} else {
			return dispatchAlert("error", {
				heading: `Changes were not saved.`,
				subheading: `Please try again.`,
			});
		}
	};
	const editTask = async (task) => {
		return task;
	};

	console.log("record", record);

	if (noPastDueTasks(pastDueTasks)) {
		return (
			<h2 className="subtitle">
				No Past Due tasks found for <b>{getPastDueResident(record)}</b>
			</h2>
		);
	}
	return (
		<>
			<div className={styles.PastDueList}>
				{/* RESIDENT PHOTO & NAME */}
				<section className={styles.PastDueList_heading}>
					{/* <ResidentPhoto alt="Resident image" imgSize="SM" imgSrc={} /> */}
					<ResidentPhoto alt="Resident image" imgSize="SM" />
					{/* <ResidentPhotoAsync
						currentUser={currentUser}
						residentID={getPastDueResidentID(record)}
						imgAlt="Past Due Resident"
						imgSize="SM"
					/> */}
					<h2 className={styles.PastDueList_heading_title}>
						{getPastDueResident(record)}:
					</h2>
					{/* PAST DUE COUNT */}
					<div className={styles.PastDueList_heading_count}>
						{countPastDue(pastDueTasks)} past due tasks
					</div>
				</section>
				{/* COLLAPSE/EXPAND BUTTON */}
				<section className={styles.PastDueList_collapseList}>
					<button
						className={styles.PastDueList_collapseList_btn}
						onClick={() => setCollapseList(!collapseList)}
					>
						Click to {collapseList ? "expand" : "collapse"}{" "}
						<b>{getPastDueResident(record)}'s</b> Tasks
					</button>
				</section>
				{/* SCHEDULED PAST DUE LIST */}
				{!collapseList && (
					<section className={styles.PastDueList_entries}>
						{/* scheduled  */}
						{pastDueTasks?.scheduled &&
							[...pastDueTasks?.scheduled].map((entry, index) => (
								<PastDueEntry
									currentUser={currentUser}
									shiftTimes={shiftTimes}
									task={entry}
									taskIndex={index + 1}
									markComplete={() => markComplete(entry)}
									editTask={() => editTask(entry)}
									key={entry[getTaskIdType(entry)] + `_${index}`}
								/>
							))}
						{/* UNSCHEDULED PAST DUE LIST */}
						{pastDueTasks?.unscheduled &&
							[...pastDueTasks?.unscheduled].map((entry, index) => (
								<PastDueEntry
									currentUser={currentUser}
									shiftTimes={shiftTimes}
									task={entry}
									taskIndex={getPastDueScheduled(record).length + index + 1}
									markComplete={() => markComplete(entry)}
									editTask={() => editTask(entry)}
									key={entry[getTaskIdType(entry)] + `_${index}`}
								/>
							))}
					</section>
				)}
			</div>

			{/* ALERTS HANDLER UI */}
			{AlertsHandler}
		</>
	);
};

export default PastDueList;

PastDueList.defaultProps = {
	currentUser: {},
	shiftTimes: [],
};

PastDueList.propTypes = {
	record: PropTypes.shape({
		PastDueScheduleTask: PropTypes.arrayOf(PropTypes.object),
		PastDueUnScheduleTask: PropTypes.arrayOf(PropTypes.object),
		Resident: PropTypes.arrayOf(
			PropTypes.shape({
				ResidentID: PropTypes.number.isRequired,
				ResidentFirstName: PropTypes.string.isRequired,
				ResidentLastName: PropTypes.string.isRequired,
				FacilityID: PropTypes.string.isRequired,
			})
		),
	}),
	currentUser: PropTypes.object.isRequired,
	shiftTimes: PropTypes.array.isRequired,
};
