import React, { useContext } from "react";
import styles from "../../css/views/AlertsDashboard.module.scss";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../../state/GlobalStateContext";

// ##TODOS:
// - Add ADL alerts table
// 		- Enable turning on/off alerts by ADL category
// 		- Alerts include: 'past due', 'exception', 'due date'

const AlertsDashboard = ({ history }) => {
	const { state } = useContext(GlobalStateContext);
	const { user } = state;

	return (
		<div className={styles.AlertsDashboard}>
			<header className={styles.AlertsDashboard_header}>
				<h2 className={styles.AlertsDashboard_header_title}>Today's Alerts</h2>
			</header>
			<main className={styles.AlertsDashboard_main}>
				{/* UNREAD ALERTS */}
				{/* READ ALERTS */}
				{/* SAVED ALERTS */}
			</main>
		</div>
	);
};

export default AlertsDashboard;

AlertsDashboard.defaultProps = {};

AlertsDashboard.propTypes = {
	history: PropTypes.object,
};
