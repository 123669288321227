import packageJSON from "../../package.json";

/**
 * Accepts a set of date values and formats into a formatted date YYYY-MM-DD or YYYY-MM
 * @param {Object} releaseDateVals - An object of date values: 'year', 'month' and 'day' (optional)
 */
const getReleaseDate = (packageJSON = {}) => {
	const {
		release_details: { release_date },
	} = packageJSON;
	return `${release_date}`;
};

/**
 * Accesses the 'version' property in the package.json.
 */
const getPKGVersion = (json) => {
	const { version } = json;
	return version;
};

// formats the 'Version' number
const getVersion = (pkgVersion, releaseDate) => {
	let version = `Version: `;
	version += `${pkgVersion} `;
	version += releaseDate;
	return version;
};

// version release logger util
const logVersion = (version) => {
	console.log(
		`%cRelease - ${version}`,
		"font-size: 16px;font-weight:600;padding: 12px;color: #5c75ea;"
	);
};

const releaseDate = getReleaseDate(packageJSON);
const pkgVersion = getPKGVersion(packageJSON);

const versionRelease = getVersion(pkgVersion, releaseDate);

// ENV VARIABLE
const REACT_APP_VERSION_RELEASE = versionRelease;

// logs version in prod
export { logVersion };

export { versionRelease };

export { REACT_APP_VERSION_RELEASE };
