import React from "react";
import styles from "../../css/residenttable/ResidentTableHeadings.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import ResidentTableHeading from "./ResidentTableHeading";
import Checkbox from "../shared/Checkbox";

const ResidentTableHeadings = ({
	headings = [],
	allSelected = false,
	selectSort,
	handleSelectAll,
}) => {
	return (
		<section className={styles.ResidentTableHeadings}>
			<div className={styles.ResidentTableHeadings_selectAll}>
				<Checkbox
					name="selectAll"
					id="selectAll"
					val={allSelected}
					handleCheckbox={handleSelectAll}
				/>
				<div className={styles.ResidentTableHeadings_selectAll_label}>
					Select All
				</div>
			</div>
			{!isEmptyArray(headings) &&
				headings.map((heading, index) => (
					<ResidentTableHeading
						selectSort={() => selectSort(heading)}
						key={`${heading}_${index}`}
						heading={heading}
					/>
				))}
		</section>
	);
};

export default ResidentTableHeadings;

ResidentTableHeadings.defaultProps = {};

ResidentTableHeadings.propTypes = {
	headings: PropTypes.arrayOf(PropTypes.string).isRequired,
	allSelected: PropTypes.bool.isRequired,
	selectSort: PropTypes.func.isRequired,
	handleSelectAll: PropTypes.func.isRequired,
};
