import React, { useState } from "react";
import styles from "../../css/reportsviewer/ReportsViewerController.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
// components
import Embed from "../shared/Embed";
import ReportPane from "../reports/ReportPane";
import Placeholder from "../shared/Placeholder";
import ReportViewerTable from "../reportsviewer/ReportViewerTable";

const emptySCSS = {
	display: "flex",
	justifyContent: "center",
	textAlign: "center",
};

const getSearchPlaceholder = (cols) => {
	let text = `Search by `;
	cols.map((col) => {
		text += `${col}, `;
	});
	return text;
};

const ReportsViewerController = ({
	reportType = `Daily`,
	embedSrc = ``,
	embedType = `application/pdf`,
	isLoading,
	noReportData = false,
	hasLoaded = false,
	tableData = {},
	showSMARTTable = `Daily`,
	showMirror = `Historical`,
}) => {
	const [msg, setMsg] = useState(
		// !hasLoaded ? `Please select a report` : `No report data found.`
		noReportData ? `No report data found.` : `Please select a report`
	);

	return (
		<div className={styles.ReportsViewerController}>
			{/* DAILY REPORT */}
			{showSMARTTable && (
				<ReportPane
					key={`Daily-${tableData.data?.length}`}
					size={tableData.size}
					title={tableData.title}
					isLoading={isLoading}
					loadingMsg={`Loading daily report data...`}
				>
					{/* SHOW DAILY REPORT UI */}
					{!isEmptyArray(tableData?.data) && (
						<ReportViewerTable
							cols={tableData.cols}
							data={tableData.data}
							size={tableData.size}
							title={tableData.title}
							enableActions={tableData.enableActions}
							searchPlaceholder={getSearchPlaceholder(tableData?.cols)}
						/>
					)}
					{/* SHOW EMPTY DATA PLACEHOLDER */}
					{isEmptyArray(tableData?.data) && (
						<div className={styles.ReportsViewerController_placeholder}>
							<Placeholder
								size="MD"
								msg={msg}
								color="red"
								customStyles={emptySCSS}
							/>
						</div>
					)}
				</ReportPane>
			)}

			{/* HISTORICAL REPORT */}
			{reportType === showMirror && (
				<ReportPane
					key={`Historical-${tableData.title}`}
					size={tableData.size}
					title={tableData.title}
					isLoading={isLoading}
					loadingMsg={`Loading historical report data...`}
				>
					{!isEmptyVal(embedSrc) ? (
						<Embed type={embedType} src={embedSrc} />
					) : (
						<div className={styles.ReportsViewerController_placeholder}>
							<Placeholder
								size="MD"
								msg={msg}
								color="red"
								customStyles={emptySCSS}
							/>
						</div>
					)}
				</ReportPane>
			)}
		</div>
	);
};

export default ReportsViewerController;

ReportsViewerController.defaultProps = {};

ReportsViewerController.propTypes = {};
