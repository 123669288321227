import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import styles from "../../css/daily/CreateTaskForm.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
// utils
import { isEmptyVal } from "../../helpers/utils_types";
import { RECURRING_TYPES as recurringTypes } from "../../helpers/utils_options";
import { hasRecurringType } from "../../helpers/utils_repeatingTasks";
import { red, themeColors } from "../../helpers/utils_styles";
import { noResidentLoaded } from "../../helpers/utils_residents";
import { sortAndFormatAdls } from "../../helpers/utils_categories";
import { formatDate } from "../../helpers/utils_dates";

import { ShiftIndicator } from "./RepeatDesc";
import ResidentHeader from "../app/ResidentHeader";
import TextInput from "../shared/TextInput";
import Textarea from "../shared/Textarea";
import CustomCheckbox from "../shared/CustomCheckbox";
import ButtonSM from "../shared/ButtonSM";
import CustomDropdown from "../shared/CustomDropdown";
import CustomDropdownSM from "../shared/CustomDropdownSM";
import DateRangePickerSM from "../shared/DateRangePickerSM";
import RepeatTaskModal from "./RepeatTaskModal";
import Placeholder from "../shared/Placeholder";
import FormSection from "../forms/FormSection";
import Divider from "../forms/Divider";
import { isScheduledTask } from "../../helpers/utils_tasks";
import { featureFlags } from "../../helpers/utils_features";
import DueDatePicker from "./DueDatePicker";

// ##TODOS:
// - Add 'Spinner' to 'isSubmitting' state & grey out form

const customCSS = {
	createTaskBtn: {
		backgroundColor: themeColors.main.main,
		fontSize: "1.5rem",
		padding: "1rem 1.8rem",
		marginRight: "1rem",
	},
	cancelTaskBtn: {
		backgroundColor: "transparent",
		color: themeColors.main.blackBlue,
		border: "1px solid #ccc",
		fontSize: "1.5rem",
		padding: "1rem 1.8rem",
	},
	shiftBtn: {
		margin: "0 1rem",
	},
	divider: {
		margin: "2rem 0",
		opacity: ".4",
	},
};

const noSelections = (vals = {}) => {
	const { newTaskName, newTaskCategory } = vals;
	return isEmptyVal(newTaskCategory) || isEmptyVal(newTaskName);
};
const noAdlSelected = (vals = {}) => {
	const { newTaskCategory } = vals;
	return isEmptyVal(newTaskCategory);
};

/**
 * Show task repeat settings if:
 * - User is admin type or above
 * - Non-admins can still create tasks and schedule them for a specific shift, but not add repeats
 */
const showRepeatFeature = (currentUser) => {
	const { isAdmin, isExecutiveAdmin, isFacilityAdmin, isSuperUser } =
		currentUser;
	const isAdminType =
		isAdmin || isExecutiveAdmin || isFacilityAdmin || isSuperUser;

	return isAdminType;
};
/**
 * Show task lock settings if:
 * - User is admin type or above ONLY
 */
const showLockTaskFeature = (currentUser) => {
	const isAdmin = currentUser?.isAdmin ?? false;
	const isExecutiveAdmin = currentUser?.isExecutiveAdmin ?? false;
	const isFacilityAdmin = currentUser?.isFacilityAdmin ?? false;
	const isSuperUser = currentUser?.isSuperUser ?? false;
	const isAdminType =
		isAdmin || isExecutiveAdmin || isFacilityAdmin || isSuperUser;

	return isAdminType;
};

const CreateTaskForm = ({
	isSubmitting = false,
	categories = [],
	vals = {},
	createTask,
	cancelTask,
	cancelRepeat,
	handleChange,
	handleCheckbox,
	handleDateRange,
	handleDueDate,
	handleDueTime,
	handleRecurrences,
	handleCycleOptions,
	handleSettings,
	currentResident = {},
	currentUser = {},
	currentFacility = {},
}) => {
	const [showRepeat, setShowRepeat] = useState(false);
	const [repeatDescription, setRepeatDescription] = useState(""); // move into parent; pass to updateNewTaskModel

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (hasRecurringType(vals.recurringType)) {
			return setShowRepeat(true);
		}

		return () => {
			isMounted = false;
		};
	}, [vals.recurringType]);

	return (
		<article className={styles.CreateTaskForm}>
			<section className={styles.CreateTaskForm_resident}>
				<ResidentHeader
					currentResident={currentResident}
					currentUser={currentUser}
				/>
			</section>
			{currentResident?.isLOA && (
				<Placeholder
					size="MD"
					color="red"
					msg={`Resident is on a Leave Of Absence`}
				/>
			)}
			{!noResidentLoaded(currentResident) && !currentResident?.isLOA && (
				<form className={styles.CreateTaskForm_form}>
					<fieldset
						className={styles.CreateTaskForm_form_formFields}
						disabled={isSubmitting}
					>
						<section className={styles.CreateTaskForm_top}>
							<h2 className={styles.CreateTaskForm_top_title}>
								Create a New Task
							</h2>
							<div className={styles.CreateTaskForm_top_date}>
								<svg className={styles.CreateTaskForm_top_date_icon}>
									<use xlinkHref={`${sprite}#icon-event_note`}></use>
								</svg>
								<span>{formatDate(new Date())}</span>
							</div>
						</section>
						<Divider customStyles={customCSS.divider} />
						{/* TASK NAME - TEXT INPUT */}
						<FormSection
							title="Task Name"
							icon="signature"
							hoverText="Enter a name for the task."
						>
							<TextInput
								name="newTaskName"
								id="newTaskName"
								val={vals.newTaskName}
								handleChange={handleChange}
								placeholder="Helped resident with..."
							/>
						</FormSection>

						<Divider customStyles={customCSS.divider} />

						{/* TASK CATEGORY - CUSTOM DROPDOWN */}
						<FormSection
							title="Categorize"
							icon="building"
							hoverText="Select an ADL for the task."
						>
							<div className={styles.CreateTaskForm_adl}>
								<CustomDropdown
									name="newTaskCategory"
									id="newTaskCategory"
									placeholder="Select ADL..."
									selection={vals.newTaskCategory}
									setSelection={handleSettings}
									options={categories && sortAndFormatAdls(categories)}
								/>
							</div>
							{noAdlSelected(vals) && (
								<span className={styles.CreateTaskForm_required}>
									Please select an ADL
								</span>
							)}
						</FormSection>

						<Divider customStyles={customCSS.divider} />

						{/* TASK NOTES - TEXTAREA */}
						<FormSection
							title="Add a Note Or Comment"
							icon="note"
							hoverText="Add a description or note."
						>
							<Textarea
								name="newTaskNotes"
								id="newTaskNotes"
								placeholder="Enter a note or comment..."
								val={vals.newTaskNotes}
								handleChange={handleChange}
								enableCharCount={true}
								maxChar={250}
							/>
						</FormSection>
						<Divider customStyles={customCSS.divider} />

						{featureFlags?.enableDueDate && (
							<>
								<FormSection
									title="Due Date/Time:"
									icon="clock"
									hoverText="Add a due date and time."
								>
									<DueDatePicker
										vals={vals}
										currentUser={currentUser}
										currentFacility={currentFacility}
										handleDueDate={handleDueDate}
										handleDueTime={handleDueTime}
									/>
									{/*  */}
								</FormSection>
								<Divider customStyles={{ opacity: ".2" }} />
							</>
						)}

						{/* REPEAT/RECURRING TYPE */}
						{showRepeatFeature(currentUser) && (
							<FormSection
								title="Repeat Task"
								icon="recurring"
								hoverText="Select when the task repeats."
							>
								<div className={styles.CreateTaskForm_repeat}>
									<span className={styles.CreateTaskForm_repeat_label}>
										Repeat:{" "}
									</span>

									<CustomDropdownSM
										name="recurringType"
										id="recurringType"
										selection={vals.recurringType}
										setSelection={handleSettings}
										inputSize={12}
										options={recurringTypes}
									/>
									{/* UPDATE SETTINGS BUTTON */}
									{hasRecurringType(vals.recurringType) && (
										<button
											className={styles.CreateTaskForm_repeat_updateBtn}
											onClick={(e) => {
												e.preventDefault();
												setShowRepeat(true);
											}}
										>
											Update Settings
										</button>
									)}
									{/* REPEAT TASK MODAL */}
									{showRepeat && (
										<RepeatTaskModal
											vals={vals}
											saveRepeat={() => setShowRepeat(false)}
											cancelRepeat={cancelRepeat}
											closeRepeatWindow={() => setShowRepeat(false)}
											getRepeatDescText={setRepeatDescription} // text description in form
											repeatType={vals.recurringType}
											handleSettings={handleSettings}
											handleCycleOptions={handleCycleOptions}
											handleDaySelections={handleCheckbox}
										/>
									)}
								</div>
								<div className={styles.CreateTaskForm_repeat_rangePicker}>
									<DateRangePickerSM
										label="Select a start and end date"
										startName="startDate"
										endName="endDate"
										rangeVals={vals}
										handleSelection={handleDateRange}
										focusMode={true}
									/>
								</div>
								<div className={styles.CreateTaskForm_repeat_desc}>
									{!isEmptyVal(repeatDescription) && (
										<span>{repeatDescription}</span>
									)}
								</div>
							</FormSection>
						)}
						<Divider customStyles={customCSS.divider} />

						{/* SHIFT SELECTION */}
						<FormSection
							title="Schedule task for one or more Shift(s)"
							icon="timer"
							hoverText="Selecting more than one shift will create multiple task occurrences."
						>
							<div className={styles.CreateTaskForm_shifts}>
								<ShiftIndicator
									shift="AM"
									key={`AM__NewTask`}
									val={vals.isRecurringAM}
									toggleShift={handleRecurrences}
									isDisabled={vals.isLocked}
									customStyles={customCSS.shiftBtn}
								/>
								<ShiftIndicator
									shift="PM"
									key={`PM__NewTask`}
									val={vals.isRecurringPM}
									toggleShift={handleRecurrences}
									isDisabled={vals.isLocked}
									customStyles={customCSS.shiftBtn}
								/>
								<ShiftIndicator
									shift="NOC"
									key={`NOC__NewTask`}
									val={vals.isRecurringNOC}
									toggleShift={handleRecurrences}
									isDisabled={vals.isLocked}
									customStyles={customCSS.shiftBtn}
								/>
							</div>
						</FormSection>
						<Divider customStyles={customCSS.divider} />

						{/* LOCK TASK SECTION */}
						{showLockTaskFeature(currentUser) && (
							<FormSection
								title="Lock Task"
								icon="lock"
								iconColor={red[600]}
								titleColor={red[600]}
								hoverText="Locking a task disables non-admins from changing it."
							>
								<div className={styles.CreateTaskForm_lockTask}>
									<p className={styles.CreateTaskForm_lockTask_desc}>
										Locking a task will prevent unauthorized changes to a task
										from any "NON-ADMIN" user. Only users with "ADMIN"
										access/permissions are able to make changes to a "locked"
										task.
									</p>
								</div>
								<div className={styles.CreateTaskForm_lockTask_checkbox}>
									<CustomCheckbox
										name="isLocked"
										id="isLocked"
										label={vals.isLocked ? `Locked Task` : `Lock this task`}
										val={vals.isLocked}
										handleCheckbox={handleCheckbox}
									/>
								</div>
							</FormSection>
						)}

						<Divider customStyles={customCSS.divider} />

						{/* CREATE-TASK/CANCEL BUTTONS */}
						<section className={styles.CreateTaskForm_actions}>
							<ButtonSM
								isDisabled={noSelections(vals)}
								handleClick={createTask}
								customStyles={customCSS.createTaskBtn}
							>
								<span>{isSubmitting ? "Saving..." : "Create Task"}</span>
							</ButtonSM>
							<ButtonSM
								handleClick={cancelTask}
								customStyles={customCSS.cancelTaskBtn}
							>
								<span>Cancel</span>
							</ButtonSM>
						</section>
					</fieldset>
				</form>
			)}
		</article>
	);
};

export default CreateTaskForm;

CreateTaskForm.defaultProps = {
	vals: {},
	categories: {},
	currentResident: {},
};

CreateTaskForm.propTypes = {
	categories: PropTypes.array.isRequired,
	vals: PropTypes.object.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	createTask: PropTypes.func.isRequired,
	cancelTask: PropTypes.func.isRequired,
	cancelRepeat: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleCheckbox: PropTypes.func.isRequired,
	handleSettings: PropTypes.func.isRequired,
	handleCycleOptions: PropTypes.func.isRequired,
	handleDateRange: PropTypes.func.isRequired,
	currentResident: PropTypes.object.isRequired,
};
