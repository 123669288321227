import React from "react";
import styles from "../../css/icons/AppIcon.module.scss";
import appLogo from "../../assets/images/BRAND/appLogo.svg";

const AppIcon = (props) => {
	return (
		<div className={styles.AppIcon}>
			<img
				src={appLogo}
				alt="ALA Care Tracker Logo"
				className={styles.AppIcon_img}
			/>
		</div>
	);
};

export default AppIcon;
