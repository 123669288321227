import React from "react";
import styles from "../../css/pastdue/PastDueController.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { RANGE_TYPES } from "../../helpers/utils_options";
import { themeColors } from "../../helpers/utils_styles";
import CustomDropdown from "../shared/CustomDropdown";
import DateRangePickerSM from "../shared/DateRangePickerSM";
import DatePickerSM from "../shared/DatePickerSM";
import MonthPicker from "../shared/MonthPicker";
import QuarterPicker from "../shared/QuarterPicker";
import ButtonSM from "../shared/ButtonSM";

// REQUIREMENTS:
// 1. ENABLE VARIOUS ACTIONS: PRINT(PDF), CHANGE TYPE OF DATA
// 2. ENABLE PAST DUE RANGES: DAILY, WEEKLY, MONTHLY ETC.

const applyBtn = {
	padding: "1rem 1.2rem",
	borderRadius: "5rem",
	backgroundColor: themeColors.main.main,
	color: "#ffffff",
	fontSize: "1.3rem",
	marginRight: ".5rem",
};
const clearBtn = {
	padding: ".8rem 1.2rem",
	borderRadius: "5rem",
	backgroundColor: "transparent",
	border: "1px solid #ccc",
	color: "#333",
	fontSize: "1.3rem",
};

const PastDueController = ({
	vals,
	handleSettings,
	handleDateRange,
	applySettings,
	clearSettings,
	dateRangeRestrictions = {},
}) => {
	return (
		<section className={styles.PastDueController}>
			<h4 className={styles.PastDueController_title}>Report Options</h4>
			<div className={styles.PastDueController_row}>
				<CustomDropdown
					id="dateRangeType"
					name="dateRangeType"
					placeholder="Select Date Range"
					selection={vals.dateRangeType}
					setSelection={handleSettings}
					options={RANGE_TYPES}
				/>
			</div>
			<div className={styles.PastDueController_row}>
				{vals.dateRangeType === "Custom Range" && (
					<DateRangePickerSM
						id="dateRange"
						startName="startDate"
						endName="endDate"
						rangeVals={vals}
						placeholder="Select a date range"
						handleSelection={handleDateRange}
						focusMode={true}
						restrictions={dateRangeRestrictions}
					/>
				)}
				{vals.dateRangeType === "By Day" && (
					<DatePickerSM
						id="byDay"
						name="byDay"
						val={vals.byDay}
						placeholder="Select a date"
						handleDate={handleSettings}
						focusMode={true}
						restrictions={dateRangeRestrictions}
					/>
				)}
				{vals.dateRangeType === "By Month" && (
					<MonthPicker
						id="byMonth"
						name="byMonth"
						placeholder="Select a month"
						val={vals.byMonth}
						handleMonth={handleSettings}
						focusMode={true}
						restrictions={dateRangeRestrictions}
					/>
				)}
				{vals.dateRangeType === "By Quarter" && (
					<QuarterPicker
						name="byQuarter"
						id="byQuarter"
						val={vals.byQuarter}
						placeholder="Select quarter"
						handleQuarter={handleSettings}
						focusMode={true}
						restrictions={dateRangeRestrictions}
					/>
				)}
			</div>
			<div className={styles.PastDueController_controls}>
				<ButtonSM handleClick={applySettings} customStyles={applyBtn}>
					<span>Apply Settings</span>
				</ButtonSM>
				<ButtonSM handleClick={clearSettings} customStyles={clearBtn}>
					<svg className={styles.PastDueController_controls_icon}>
						<use xlinkHref={`${sprite}#icon-clearclose`}></use>
					</svg>
					<span>Clear Settings</span>
				</ButtonSM>
			</div>
		</section>
	);
};

export default PastDueController;

PastDueController.defaultProps = {};

PastDueController.propTypes = {
	vals: PropTypes.object.isRequired, // formState values
	handleSettings: PropTypes.func.isRequired, // dropdowns and date pickers
	handleDateRange: PropTypes.func.isRequired, // date range custom picker handler
	applySettings: PropTypes.func.isRequired, // handles initializing request
	clearSettings: PropTypes.func.isRequired, // clears current settings, reverts to defaults
};
