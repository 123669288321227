import React from "react";
import styles from "../../css/daily/CommunityInfo.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import { getShiftName, processShiftTimes } from "../../helpers/utils_shifts";
import { format } from "date-fns";

// TODOS:
// - Add Community details: ✓
//    - address ✓
//    - ED (executive director) ✓
// - Add Community shift times ✓
//    - Start/End Times: 'AM', 'PM', 'NOC' ✓
// 		- Fix 'ShiftTimes' to extract ONLY the UTC hours & mins, then format it hh:mm A ✓

const CommunityInfo = ({ communityInfo = {} }) => {
	return (
		<aside className={styles.CommunityInfo}>
			<section className={styles.CommunityInfo_section}>
				<h2 className={styles.CommunityInfo_section_title}>
					{communityInfo?.communityName}: Shifts
				</h2>
			</section>
			<section className={styles.CommunityInfo_section}>
				{!isEmptyArray(communityInfo?.shifts) &&
					communityInfo.shifts.map((shift, idx) => (
						<div
							className={styles.CommunityInfo_section_shift}
							key={`${shift.AssessmentShiftId}__${idx}`}
						>
							<div className={styles.CommunityInfo_section_shift_name}>
								{getShiftName(shift?.AssessmentShiftId)}
							</div>
							<div className={styles.CommunityInfo_section_shift_times}>
								{format(processShiftTimes(shift).startTime, "h:mm A")} -{" "}
								{format(processShiftTimes(shift).endTime, "h:mm A")}
							</div>
						</div>
					))}
			</section>
		</aside>
	);
};

export default CommunityInfo;

CommunityInfo.defaultProps = {
	communityInfo: {},
};

CommunityInfo.propTypes = {
	communityInfo: PropTypes.shape({
		communityName: PropTypes.string.isRequired,
		facilityID: PropTypes.string.isRequired,
		parentID: PropTypes.string,
		address: PropTypes.shape({
			street: PropTypes.string,
			city: PropTypes.string,
			state: PropTypes.string,
			zip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		residents: PropTypes.array,
		shifts: PropTypes.arrayOf(PropTypes.object).isRequired,
		exceptions: PropTypes.arrayOf(PropTypes.object),
	}),
};
