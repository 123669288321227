import React from "react";
import styles from "../../css/reports/ReportDateHandler.module.scss";
import { PropTypes } from "prop-types";
import DatePickerSM from "../shared/DatePickerSM";
import YearPicker from "../shared/YearPicker";
import MonthPicker from "../shared/MonthPicker";
import QuarterPicker from "../shared/QuarterPicker";
import DateRangePickerSM from "../shared/DateRangePickerSM";

const ReportDateHandler = ({
	settings,
	handleSettings,
	handleDateRange,
	restrictions = {},
}) => {
	return (
		<section className={styles.ReportDateHandler}>
			{settings.dateRangeType === "By Month" && (
				<MonthPicker
					name="byMonth"
					id="byMonth"
					placeholder="Select a month"
					val={settings.byMonth}
					handleMonth={handleSettings}
					focusMode={true}
					restrictions={restrictions}
				/>
			)}
			{settings.dateRangeType === "By Quarter" && (
				<QuarterPicker
					name="byQuarter"
					id="byQuarter"
					placeholder="Select a quarter"
					val={settings.byQuarter}
					handleQuarter={handleSettings}
					focusMode={true}
					restrictions={restrictions}
				/>
			)}
			{settings.dateRangeType === "By Year" && (
				<YearPicker
					name="byYear"
					id="byYear"
					placeholder="2029..."
					val={settings.byYear}
					handleYear={handleSettings}
					restrictions={restrictions}
				/>
			)}
			{settings.dateRangeType === "By Date" && (
				<DatePickerSM
					name="byDate"
					id="byDate"
					placeholder="Select a date"
					val={settings.byDate}
					handleDate={handleSettings}
					focusMode={true}
					restrictions={restrictions}
				/>
			)}
			{settings.dateRangeType === "Custom Range" && (
				<DateRangePickerSM
					startName="startDate"
					endName="endDate"
					rangeVals={settings}
					handleSelection={handleDateRange}
					focusMode={true}
					restrictions={restrictions}
				/>
			)}
		</section>
	);
};

export default ReportDateHandler;

ReportDateHandler.defaultProps = {
	restrictions: {
		isActive: false,
		rangeStart: "",
		rangeEnd: "",
	},
};

ReportDateHandler.propTypes = {
	settings: PropTypes.object.isRequired,
	handleSettings: PropTypes.func.isRequired,
	restrictions: PropTypes.shape({
		isActive: PropTypes.bool,
		rangeStart: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.instanceOf(Date),
		]),
		rangeEnd: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.instanceOf(Date),
		]),
	}),
};
