import React, { useState } from "react";
import styles from "../../css/timeview/TimeViewTaskListEntry.module.scss";
import adlSprite from "../../assets/icons/adlIcons.svg";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import { addEllipsis } from "../../helpers/utils_processing";
import { format } from "date-fns";
import {
	getIconName,
	getIconNameFromStr,
	green,
	orange,
	purple,
	yellow,
} from "../../helpers/utils_styles";
import { useForm } from "../../utils/useForm";
import {
	getTaskDueTime,
	isCompleted,
	isLocked,
	isNotComplete,
	isPastDueByTimeOrShift,
} from "../../helpers/utils_tasks";
import {
	getDefaultDay,
	getDefaultShift,
	getRecurringStartAndEnd,
	getRecurringTypeFromID,
	isRecurring,
} from "../../helpers/utils_repeatingTasks";
import { checkCategoryNaming } from "../../helpers/utils_categories";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import TimeViewResidentPhoto from "./TimeViewResidentPhoto";
import MobileModal from "../shared/MobileModal";
import TimeViewAdvancedOptions from "./TimeViewAdvancedOptions";

// REQUIREMENTS:
// Task Info:
//    - Task Name/Desc
//    - ADL Category
//    - ADL Category Icon
//    - Due Date/Time
// Task's Resident Info:
//    - Resident Photo
//    - Resident Name
//    - Resident's Room Number

const getStatusStyles = (task, wasUpdated, shiftTimes = []) => {
	const taskDueTime = getTaskDueTime(task, new Date(), shiftTimes);

	// due date/time anchored to today's date
	const dueDate = taskDueTime;

	// return styles.TimeViewTaskListEntry_isCompleted;

	if (isCompleted(task)) {
		return wasUpdated
			? `${styles.TimeViewTaskListEntry_isCompleted} ${styles.wasUpdated}`
			: styles.TimeViewTaskListEntry_isCompleted;
		// } else if (isPastDue(task, task?.TaskDate ?? new Date(), shiftTimes)) {
		// } else if (isPastDue(task, dueDate, shiftTimes)) { // WORKING SHIFT ONLY VERSION
	} else if (isPastDueByTimeOrShift(task, dueDate, shiftTimes)) {
		// WORKING SHIFT OR DATE VERSION
		return wasUpdated
			? `${styles.TimeViewTaskListEntry_isPastDue} ${styles.wasUpdated}`
			: styles.TimeViewTaskListEntry_isPastDue;
	} else {
		return wasUpdated
			? `${styles.TimeViewTaskListEntry_isNotCompleted} ${styles.wasUpdated}`
			: styles.TimeViewTaskListEntry_isNotCompleted;
	}
};

const getStatusCss = (
	task = {},
	vals = {},
	wasUpdated = false,
	shiftTimes = []
) => {
	const taskDueTime = getTaskDueTime(task, new Date(), shiftTimes);

	// due date/time anchored to today's date
	const dueDate = taskDueTime;

	switch (true) {
		// case false: {
		case isCompleted(task) || vals?.markComplete: {
			return {
				borderLeft: `4px solid ${green[600]}`,
				opacity: ".5",
			};
		}
		// case !true: {
		case isPastDueByTimeOrShift(task, dueDate, shiftTimes): {
			return {
				borderLeft: `4px solid ${purple[700]}`,
				opacity: "1",
			};
		}
		// case true: {
		case isNotComplete(task, dueDate, shiftTimes): {
			return {
				borderLeft: `4px solid ${yellow[500]}`,
				opacity: "1",
			};
		}

		default:
			return {
				borderLeft: `4px solid ${yellow[500]}`,
				opacity: "1",
			};
	}
};

const getTaskADLName = (task) => {
	const rawAdl = task?.ADL ?? task?.ADLCategory;
	const adl = checkCategoryNaming(rawAdl);

	return isEmptyVal(adl) ? "Other" : adl;
};

// used for quick actions: Mark Complete/Not-Complete, Add Exception etc
const MenuIcon = ({ handleMenu }) => {
	return (
		<button type="button" onClick={handleMenu} className={styles.MenuIcon}>
			<svg className={styles.MenuIcon_icon}>
				<use xlinkHref={`${sprite}#icon-dots-three-horizontal`}></use>
			</svg>
		</button>
	);
};

const AdvancedButton = ({ handleAdvanced }) => {
	return (
		<button
			type="button"
			onClick={handleAdvanced}
			className={styles.AdvancedButton}
		>
			<span className={styles.AdvancedButton_text}>Advanced</span>
		</button>
	);
};

const CompletedIcon = ({ isCompleted = false }) => {
	if (!isCompleted) {
		return null;
	}
	return (
		<div className={styles.CompletedIcon}>
			<svg className={styles.CompletedIcon_icon}>
				<use xlinkHref={`${sprite}#icon-check_circle`}></use>
			</svg>
		</div>
	);
};

const ResidentName = ({ task = {} }) => {
	const { ResidentName } = task;
	return (
		<div className={styles.ResidentName} title={ResidentName}>
			{addEllipsis(ResidentName, 25)}
		</div>
	);
};
const TaskADL = ({ task }) => {
	return <div className={styles.TaskADL}>{getTaskADLName(task)}</div>;
};
const TaskName = ({ task = {} }) => {
	return (
		<div className={styles.TaskName} title={task?.TaskName}>
			{/* {addEllipsis(task?.TaskName, 33)} */}
			{task?.TaskName}
		</div>
	);
};
const TaskTime = ({ task = {} }) => {
	return (
		<div
			className={styles.TaskTime}
			title={`Due ${format(task?.ScheduledDate, "M/D/YYYY h:mm A")}`}
		>
			{format(task?.ScheduledDate, "h:mm A")}
		</div>
	);
};

const TaskInfo = ({ task = {} }) => {
	return (
		<div className={styles.TaskInfo}>
			<div className={styles.TaskInfo_name}>
				<TaskName task={task} />
				<TaskADL task={task} />
			</div>

			<div className={styles.TaskInfo_time}>
				<TaskTime task={task} />
			</div>
		</div>
	);
};

const TimeViewTaskListEntry = ({
	task = {},
	currentUser = {},
	currentFacility = {},
}) => {
	const { formState, setFormState, handleChange, handleCheckbox } = useForm({
		reassess: false,
		reassessNotes: "",
		shift: "", // when shift was completed (ie statusing)
		taskNotes: "",
		syncNotesVal: "", // only used to track notes in children
		signature: "",
		markComplete: isCompleted(task),
		isLocked: isLocked(task),
		recurringType: getRecurringTypeFromID(task?.AssessmentRecurringId),
		isRecurring: isRecurring(task),
		recurringCycle: "1",
		recurringCycleOption: "",
		isRecurringAM: getDefaultShift("AM", task),
		isRecurringPM: getDefaultShift("PM", task),
		isRecurringNOC: getDefaultShift("NOC", task),
		isRecurringSun: getDefaultDay("Sun", task),
		isRecurringMon: getDefaultDay("Mon", task),
		isRecurringTue: getDefaultDay("Tue", task),
		isRecurringWed: getDefaultDay("Wed", task),
		isRecurringThu: getDefaultDay("Thu", task),
		isRecurringFri: getDefaultDay("Fri", task),
		isRecurringSat: getDefaultDay("Sat", task),
		startDate: getRecurringStartAndEnd(task).startDate,
		endDate: getRecurringStartAndEnd(task).endDate,
		// NEW TASK NOTES FIELDS
		trackingID: task?.AssessmentTrackingId ?? 0,
		taskID:
			task?.AssessmentTrackingTaskId ?? task?.AssessmentUnscheduleTaskId ?? 0,
	});
	const { values, touched } = formState;
	const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
	const [showMenu, setShowMenu] = useState(false);

	const initAdvanced = (e) => {
		e.preventDefault();
		setShowAdvancedOptions(true);
		console.log(`Advanced was clicked!`);
	};
	const initMenu = () => {
		setShowMenu(true);
		console.log(`Menu was clicked!`);
	};

	const handleQuickComplete = async () => {
		const current = values?.markComplete;

		setFormState({
			...formState,
			values: {
				...values,
				markComplete: !current,
			},
			touched: {
				...touched,
				markComplete: true,
			},
		});
	};

	return (
		<>
			<div
				className={styles.TimeViewTaskListEntry}
				style={getStatusCss(task, values, false, currentFacility?.shifts)}
				onClick={initAdvanced}
			>
				<div className={styles.TimeViewTaskListEntry_photo}>
					<TimeViewResidentPhoto
						task={task}
						residentImage={{ src: null, alt: "No image" }}
						residentInfo={task}
					/>
					<ResidentName task={task} />
				</div>
				<div className={styles.TimeViewTaskListEntry_details}>
					<TaskInfo task={task} />
				</div>
				<div className={styles.TimeViewTaskListEntry_actions}>
					<MenuIcon handleMenu={initMenu} />
					{/* <AdvancedButton handleAdvanced={initAdvanced} /> */}
				</div>
			</div>

			{showAdvancedOptions && (
				<MobileModal
					title={`Advanced Options`}
					closeModal={() => setShowAdvancedOptions(false)}
				>
					<TimeViewAdvancedOptions
						task={task}
						currentUser={currentUser}
						currentFacility={currentFacility}
					/>
				</MobileModal>
			)}
		</>
	);
};

export default TimeViewTaskListEntry;

TimeViewTaskListEntry.defaultProps = {};

TimeViewTaskListEntry.propTypes = {};
