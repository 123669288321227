import React, { useRef, useEffect } from "react";
import styles from "../../css/notes/TaskNoteContent.module.scss";
import { PropTypes } from "prop-types";
import { blueGrey, orange, red } from "../../helpers/utils_styles";
// components
import NoteInput from "./NoteInput";
import ButtonSM from "../shared/ButtonSM";
import { isEmptyVal } from "../../helpers/utils_types";

const customCSS = {
	cancel: {
		padding: ".2rem 1.2rem",
		backgroundColor: "transparent",
		fontSize: "1.5rem",
		fontWeight: "600",
		color: red[600],
		borderRadius: ".5rem",
	},
	save: {
		padding: ".2rem 1.2rem",
		backgroundColor: blueGrey[800],
		fontSize: "1.5rem",
		fontWeight: "600",
		color: "#ffffff",
		marginRight: "1rem",
		borderRadius: ".5rem",
	},
};

const strike = `line-through ${orange[700]}`;

const NoteContent = ({ vals = {}, isStrikeOut = false, note = "" }) => {
	const noteRef = useRef();

	const strikeOutCSS = {
		textDecoration: !isStrikeOut ? "none" : strike,
		opacity: !isStrikeOut ? "1" : ".4",
	};

	// Sets 'el.innerText' in order to maintain formatting of note
	// - supports lists
	// - multi-line notes
	// - supports strike-through; even for multi-line notes
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		// store readable reference to element ref
		let newNote = noteRef?.current;

		if (newNote) {
			newNote.innerText = note;
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.NoteContent}>
			<div
				className={styles.NoteContent_note}
				style={strikeOutCSS}
				ref={noteRef}
			>
				{/* PREVIOUS USAGE - KEEP FOR FALLBACK */}
				{/* {note} */}
			</div>
		</div>
	);
};

const TaskNoteContent = ({
	inputRef = null,
	taskNote = {},
	vals = {},
	taskNoteVal,
	handleTaskNote,
	isEditing = false,
	isSaving = false,
	saveEditTaskNote,
	cancelEditTaskNote,
}) => {
	return (
		<div className={styles.TaskNoteContent}>
			<div className={styles.TaskNoteContent_content}>
				{!isEditing && (
					<NoteContent
						vals={vals}
						note={vals?.notes}
						isStrikeOut={vals?.isStrikeOut}
					/>
				)}
				{isEditing && (
					<NoteInput
						key={`EXISTING-EDITABLE-TASK-NOTE`}
						id="notes"
						name="notes"
						val={vals?.notes}
						handleChange={handleTaskNote}
					/>
				)}
			</div>
			{isEditing && (
				<div className={styles.TaskNoteContent_actions}>
					<ButtonSM
						isDisabled={isSaving}
						handleClick={saveEditTaskNote}
						customStyles={customCSS.save}
					>
						{isSaving ? "Saving..." : "Save"}
					</ButtonSM>
					<ButtonSM
						handleClick={cancelEditTaskNote}
						customStyles={customCSS.cancel}
					>
						Cancel
					</ButtonSM>
				</div>
			)}
		</div>
	);
};

export default TaskNoteContent;

TaskNoteContent.defaultProps = {};

TaskNoteContent.propTypes = {};
