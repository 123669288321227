import React from "react";
import { PropTypes } from "prop-types";
import { red, themeColors } from "../../helpers/utils_styles";
import { formatShiftTimes } from "../../helpers/utils_dates";
import styles from "../../css/adlschedule/EditShiftForm.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import sprite2 from "../../assets/icons/checks.svg";
// COMPONENTS
import TimePicker from "../shared/TimePicker";
import ButtonSM from "../shared/ButtonSM";
import { isEmptyVal } from "../../helpers/utils_types";

const saveBtn = {
	backgroundColor: themeColors.main.main,
	color: "#ffffff",
	borderRadius: ".5rem",
	padding: "1rem 1.2rem",
	fontSize: "1.6rem",
	fontWeight: "600",
};
const cancelBtn = {
	backgroundColor: "transparent",
	color: red[600],
	borderRadius: ".5rem",
	padding: "1rem 1.2rem",
	fontSize: "1.6rem",
	fontWeight: "600",
	marginRight: "1rem",
};

const disableBtn = (shiftTimeVals = {}) => {
	const { startTime, endTime } = shiftTimeVals;
	const isEmpty = isEmptyVal(startTime) || isEmptyVal(endTime);

	return isEmpty;
};

const EditShiftForm = ({
	shift,
	shiftName,
	shiftTimeVals,
	currentShiftTimes = {},
	allShifts = [],
	handleShiftTimes,
	cancelChanges,
	saveChanges,
}) => {
	return (
		<form className={styles.EditShiftForm}>
			<section className={styles.EditShiftForm_section}>
				<div className={styles.EditShiftForm_section_current}>
					Current Shift Times: <b>{currentShiftTimes?.startTime}</b> -{" "}
					<b>{currentShiftTimes?.endTime}</b>
				</div>
			</section>
			<section className={styles.EditShiftForm_section}>
				<div className={styles.EditShiftForm_section_heading}>
					<svg className={styles.EditShiftForm_section_heading_icon}>
						<use xlinkHref={`${sprite}#icon-update`}></use>
					</svg>
					<h2 className={styles.EditShiftForm_section_heading_title}>
						Set the <b>start time</b> for the {shiftName} shift:
					</h2>
				</div>
			</section>
			{/* START TIME - TIMEPICKER */}
			<section className={styles.EditShiftForm_content}>
				<TimePicker
					label={`${shiftName} Start Time`}
					name={`${shiftName}ShiftStart`}
					id={`${shiftName}ShiftStart`}
					placeholder="Select shift start"
					val={shiftTimeVals[`${shiftName}ShiftStart`]}
					handleTime={handleShiftTimes}
					enableSecs={false}
				/>
			</section>
			<hr style={{ opacity: ".5", margin: "2rem 0" }} />
			<section className={styles.EditShiftForm_section}>
				<div className={styles.EditShiftForm_section_heading}>
					<svg className={styles.EditShiftForm_section_heading_icon}>
						<use xlinkHref={`${sprite}#icon-update`}></use>
					</svg>
					<h2 className={styles.EditShiftForm_section_heading_title}>
						Set the <b>end time</b> for the {shiftName} shift:
					</h2>
				</div>
			</section>
			{/* END TIME - TIMEPICKER */}
			<section className={styles.EditShiftForm_content}>
				<TimePicker
					label={`${shiftName} End Time`}
					name={`${shiftName}ShiftEnd`}
					id={`${shiftName}ShiftEnd`}
					placeholder="Select shift end"
					val={shiftTimeVals[`${shiftName}ShiftEnd`]}
					handleTime={handleShiftTimes}
					enableSecs={false}
				/>
			</section>
			<hr style={{ opacity: ".5", margin: "2rem 0" }} />
			<section className={styles.EditShiftForm_section}>
				<div className={styles.EditShiftForm_section_heading}>
					<svg className={styles.EditShiftForm_section_heading_icon}>
						<use xlinkHref={`${sprite2}#icon-check-circle`}></use>
					</svg>
					<h2 className={styles.EditShiftForm_section_heading_title}>
						Updated Shift Times:
					</h2>
				</div>
			</section>
			<section className={styles.EditShiftForm_content}>
				<div className={styles.EditShiftForm_content_descriptions}>
					<span className={styles.EditShiftForm_content_descriptions_desc}>
						New {shiftName} Start Time:{" "}
						<b>{shiftTimeVals[`${shiftName}ShiftStart`]}</b>
					</span>
					<span className={styles.EditShiftForm_content_descriptions_desc}>
						New {shiftName} Ending Time:{" "}
						<b>{shiftTimeVals[`${shiftName}ShiftEnd`]}</b>
					</span>
				</div>
				<div className={styles.EditShiftForm_content_warning}>
					<svg className={styles.EditShiftForm_content_warning_icon}>
						<use xlinkHref={`${sprite}#icon-error1`}></use>
					</svg>
					<div className={styles.EditShiftForm_content_warning_notice}>
						<h4 className={styles.EditShiftForm_content_warning_notice_title}>
							WARNING:
						</h4>
						<p className={styles.EditShiftForm_content_warning_notice_msg}>
							Shift time changes will be applied to ALL resident's tasks for the
							currently selected facility ONLY. This may change when a task is
							classified as 'Past Due'. Please use caution in applying shift
							time changes.
						</p>
					</div>
				</div>
			</section>
			<section className={styles.EditShiftForm_actions}>
				<ButtonSM customStyles={cancelBtn} handleClick={cancelChanges}>
					<span>Cancel</span>
				</ButtonSM>
				<ButtonSM
					// isDisabled={disableBtn(shiftTimeVals)}
					customStyles={saveBtn}
					handleClick={saveChanges}
				>
					<span>Save Changes</span>
				</ButtonSM>
			</section>
		</form>
	);
};

export default EditShiftForm;

EditShiftForm.defaultProps = {};

EditShiftForm.propTypes = {
	shift: PropTypes.object.isRequired,
	shiftName: PropTypes.string.isRequired,
	handleShiftTimes: PropTypes.func.isRequired,
};
