import React, { useState, useEffect, useContext, useCallback } from "react";
import styles from "../../css/views/DailyView.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import * as Sentry from "@sentry/react";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../../state/GlobalStateContext";
import { useForm } from "../../utils/useForm";
import { useScrollPosition, config } from "../../utils/useScrollPosition";
import { useAlerts, initialSettings } from "../../utils/useAlerts";
import {
	isEmptyObj,
	isEmptyVal,
	isEmptyArray,
} from "../../helpers/utils_types";
import {
	formatLOANotice,
	noResidentLoaded,
	getResidentID,
	getResidentObj,
	formatResidentOnly,
} from "../../helpers/utils_residents";
import {
	findTasksByADL,
	isAllComplete,
	getDefaultFacility,
	getDefaultResident,
	getDefaultUnitType,
} from "../../helpers/utils_tasks";
import {
	saveUnscheduledUpdates,
	createTaskPerShift,
} from "../../helpers/utils_unscheduled";
// added
import { mergeDailyResidentData } from "../../helpers/utils_residentData"; // added
import {
	hasTargetParams,
	loadDefaultStateFromParams,
	populateState,
} from "../../helpers/utils_initialResources";
import { matchFacilityByName } from "../../helpers/utils_facility";
import { STATUS_TYPES as statusTypes } from "../../helpers/utils_options";
import {
	formatAdlName,
	getAdlName,
	sortAdlsByName,
	checkAdlNaming,
} from "../../helpers/utils_categories";
import { getStatusFilters } from "../../helpers/utils_status";
import { hasParams } from "../../helpers/utils_params";
import { featureFlags } from "../../helpers/utils_features";
import { format } from "date-fns";

import ScrollToTop from "../../components/app/ScrollToTop";
import Spinner from "../../components/shared/Spinner";
import FloatingButton from "../../components/shared/FloatingButton";
import FloatingFiltersButton from "../../components/daily/FloatingFiltersButton";
import ModalFull from "../../components/shared/ModalFull";
import DailySummaryCard from "../../components/daily/DailySummaryCard";
import CreateTaskForm from "../../components/daily/CreateTaskForm";
import CommunityInfo from "../../components/daily/CommunityInfo";
import AllCompletedBanner from "../../components/daily/AllCompletedBanner";
import UpdateBanner from "../../components/app/UpdateBanner";
import CommunityShifts from "../../components/daily/CommunityShifts";
import ResidentCard from "../../components/app/ResidentCard";
// selector components
import ALASelector from "../../components/app/ALASelector";
import ALAUpdatedSelector from "../../components/app/ALAUpdatedSelector";
import TimeViewTaskList from "../../components/timeview/TimeViewTaskList";
import DailyMultiViews from "./DailyMultiViews";

// - Create "Refresh to Update Banner & Button"

// ##TODOS (THESE ARE DELAYED UNTIL LATER):
// - Entire view re-renders when creating a task, which removes ALL current card filters back to default
// - Add 'auto-load' feature, for auto-populating resident's tasks
// - Enable refresh support for auto-loading resident from external URL:
// 		- Enable 'hot-linking' ✓
// 		- Enable refresh support
// 		- Store authToken in url query param
// - Enable 'scrollToTop()' call on mount ✓

const initialFilters = {
	Ambulation: true,
	Bathing: true,
	Dressing: true,
	Grooming: true,
	Health: true,
	Laundry: true,
	Meals: true,
	MedAssist: true,
	Mental: true,
	SpecialCare: true,
	Toileting: true,
	Transfers: true,
	Other: true,
	// STATUS FILTERS
	COMPLETE: true,
	EXCEPTIONS: true,
	"NOT-COMPLETE": true,
	"PAST-DUE": true,
};

// checks if user navigated to the 'DailyView' from an authenticated route or not
const fromAuthedRoute = (history) => {
	const {
		location: {
			state: { from },
		},
	} = history;

	return from !== "/";
};

// check is global state & local state (ie ALASelector) are in sync
const isSameResident = (selectedVal, currentResident = {}) => {
	if (isEmptyVal(selectedVal) || isEmptyObj(currentResident)) return false;

	const id = getResidentID(selectedVal);
	const { ResidentID } = currentResident;
	if (id === ResidentID) {
		return true;
	}
	return false;
};

const shouldShowCards = (
	selectedResident,
	currentResident,
	isLOA,
	isLoading
) => {
	const shouldShow =
		isSameResident(selectedResident, currentResident) &&
		!noResidentLoaded(currentResident) &&
		!isLOA &&
		!isLoading;

	return shouldShow;
};

// determines when to render the <ResidentCard/>
// if on the <DailyView/> route and a resident is selected (not necessarily loaded)
const renderResidentCard = (
	selectedResident,
	currentResident,
	residentMeds,
	currentUser
) => {
	if (
		!isEmptyVal(currentResident?.ResidentID) &&
		isSameResident(selectedResident, currentResident)
	) {
		return (
			<ResidentCard
				hasSelection={!isEmptyVal(selectedResident)}
				currentResident={currentResident}
				currentUser={currentUser}
				residentDetails={currentResident}
				meds={residentMeds}
			/>
		);
	} else {
		return null;
	}
};

// NEW ALASelector handler
const renderALASelector = (
	// isLoading,
	facilities,
	residents,
	isAdmin,
	loadResident,
	syncFacility,
	syncResident,
	defaultFacility,
	defaultResident,
	defaultUnitType
) => {
	if (!isEmptyArray(facilities)) {
		// show selector & has residents
		return (
			// <ALASelector
			// 	key={`DAILY-VIEW-SELECTOR`}
			// 	facilities={facilities}
			// 	residents={residents}
			// 	isAdmin={isAdmin}
			// 	loadResident={loadResident}
			// 	syncFacility={syncFacility}
			// 	syncResident={syncResident}
			// 	defaultFacility={defaultFacility}
			// 	defaultResident={defaultResident}
			// 	disableResident={false} // previously not added??? Added 4/30/2021 at 3:55 PM
			// />
			<ALAUpdatedSelector
				key={`DAILY-VIEW-SELECTOR`}
				facilities={facilities}
				residents={residents}
				isAdmin={isAdmin}
				loadResident={loadResident}
				syncFacility={syncFacility}
				syncResident={syncResident}
				defaultFacility={defaultFacility}
				defaultResident={defaultResident}
				defaultUnitType={defaultUnitType}
				disableResident={false} // previously not added??? Added 4/30/2021 at 3:55 PM
			/>
		);
	} else if (isEmptyArray(facilities) && isEmptyArray(residents)) {
		// } else if (isLoading) {
		// no available residents - loading state
		return <Spinner />;
	} else {
		// empty resident list - success state - empty data
		return null;
	}
};

// NEW CommunityInfo handler "View Community Shifts" btn
// - Updated 2/11/2021 at 9:43 AM
// - Enabled 'featureFlag' to show/hide this feature from config
const renderCommunityInfo = (isEnabled = false, data = {}) => {
	const { currentFacility, facilityVal, isAdmin } = data;
	// disabled via 'FEATURE FLAGS'
	if (!isEnabled) return null;

	if (
		isEmptyVal(currentFacility?.communityName) ||
		(isAdmin && isEmptyVal(facilityVal))
	) {
		return null;
	}
	return <CommunityShifts communityInfo={currentFacility} />;
};

const DailyView = ({ history }) => {
	const { state, dispatch } = useContext(GlobalStateContext);
	const { app, globals, user } = state;
	const { isLoading } = app;
	const {
		loa,
		categories,
		currentResident,
		currentFacility, // added
		currentUnitType,
		residents, // added
		residentsByFacility, // added
		scheduledTasks,
		trackingTasks,
		unscheduledTasks,
		unscheduledTasksRaw,
	} = globals;
	const { facilities } = user;
	const { isLOA } = currentResident;
	const { formState, setFormState, handleChange, handleCheckbox, handleReset } =
		useForm({
			isLocked: false,
			newTaskName: "",
			newTaskCategory: "",
			newTaskNotes: "",
			newTaskShift: "",
			isRecurring: false,
			recurringType: "Never", // daily, weekly, etc.
			recurringCycle: "1", // every "2" weeks, every 2 months etc.
			recurringCycleOption: "",
			startDate: format(new Date(), "MM/DD/YYYY"),
			endDate: "",
			isRecurringSun: false,
			isRecurringMon: false,
			isRecurringTue: false,
			isRecurringWed: false,
			isRecurringThu: false,
			isRecurringFri: false,
			isRecurringSat: false,
			isRecurringAM: false,
			isRecurringPM: false,
			isRecurringNOC: false,
			// DUE DATE/TIME
			scheduledDueDate: format(new Date(), "MM/DD/YYYY"),
			// scheduledTime: format(new Date(), "hh:mm A"),
			scheduledDueTime: "03:00 PM",
			// // ADL/STATUS FILTERS
			...initialFilters,
		});
	const { values, touched } = formState;
	useScrollPosition(config);

	const [selectedResident, setSelectedResident] = useState(() => {
		return getDefaultResident(currentResident);
	});
	const [selectedFacility, setSelectedFacility] = useState(() => {
		return getDefaultFacility(currentFacility);
	});
	const [selectedUnitType, setSelectedUnitType] = useState(() => {
		return getDefaultUnitType(currentUnitType);
	});

	const { dispatchAlert, AlertsHandler } = useAlerts({ ...initialSettings });
	const [showCommunityInfo, setShowCommunityInfo] = useState(false);
	const [showNewTaskModal, setShowNewTaskModal] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false); // submitting to server boolean
	// initial loading staste
	const [doneForToday, setDoneForToday] = useState(() => {
		return isAllComplete([...scheduledTasks, ...unscheduledTasks]);
	});

	const [selectAllFilters, setSelectAllFilters] = useState(true);
	const [clearAllFilters, setClearAllFilters] = useState(false);
	const [statusFilters, setStatusFilters] = useState([]);

	// update banner - not-in-use!!!
	const [showUpdateBanner, setShowUpdateBanner] = useState(false);

	const handleDueDate = (name, date) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: date,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};
	const handleDueTime = (name, time) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: time,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};

	const handleRadioButtons = (val) => {
		setFormState({
			...formState,
			values: {
				...formState.values,
				recurringCycleOption: val,
			},
		});
	};
	// handles custom dropdowns
	const handleSettings = (name, val) => {
		const { values } = formState;
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};
	// handles date range picker
	const handleDateRange = ({ startDate, endDate }) => {
		setFormState({
			...formState,
			values: {
				...formState.values,
				startDate,
				endDate,
			},
		});
	};
	// handles shift selections
	const handleRecurrences = (name) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: !values[name],
			},
		});
	};
	const initNewTaskWindow = (e) => {
		setShowNewTaskModal(true);
	};
	// now re-fetches resident 'day' upon successful task creation
	const createTask = async (e) => {
		const { values } = formState;
		const { ResidentID: residentID } = currentResident;
		const { token, userID } = user;

		const pendingQueue = createTaskPerShift(residentID, userID, values);
		const [success] = await saveUnscheduledUpdates(token, pendingQueue); // updated at 9:57 AM, might need to remove

		// start submit state - triggers spinner
		setIsSubmitting(true);

		// consider re-fetching Unscheduled Tasks here...
		if (success) {
			setIsSubmitting(false);
			setShowNewTaskModal(false);

			loadResident();
			dispatchAlert("success", {
				heading: "Success!",
				subheading: "New task was created.",
			});
			return handleReset(e);
		} else {
			// trigger alert & clear form state
			dispatchAlert("error", {
				heading: "Error!",
				subheading: "Oops. 'Create Task' failed. Please try again.",
			});
			return handleReset(e);
		}
	};
	const cancelTask = (e) => {
		handleReset(e);
		setShowNewTaskModal(false);
	};
	// clear ALL repeating-related values.
	const cancelRepeat = (e) => {
		setFormState({
			...formState,
			values: {
				...formState.values,
				isRecurring: false,
				recurringSelection: "Never", // REMOVE??? - duplicate of 'recurringType'???
				recurringType: "Never", // daily, weekly, etc.
				recurringCycle: "1", // every "2" weeks, every 2 months etc.
				recurringCycleOption: "",
				startDate: "",
				endDate: "",
				isRecurringSun: false,
				isRecurringMon: false,
				isRecurringTue: false,
				isRecurringWed: false,
				isRecurringThu: false,
				isRecurringFri: false,
				isRecurringSat: false,
			},
		});
	};

	/**
	 * Extracts resident from 'selectedResident' state.
	 * Inits 'LOADING' state
	 * Fires off request, then syncs resident data to global store
	 */
	const loadResident = useCallback(async () => {
		if (isEmptyVal(selectedResident)) return alert("Select a resident");
		if (!selectedResident.includes("~ ALA ID:")) return;

		const { token } = user;
		// match resident record from ID
		const curResident = getResidentObj(
			residentsByFacility,
			currentFacility?.facilityID ?? user.facilityID, // 'null' causes empty 'selectedFacility'
			selectedResident
		);

		dispatch({ type: "LOADING" });

		const merged = await mergeDailyResidentData(
			token,
			curResident.ResidentID,
			new Date()
		);
		const oldState = { ...state };
		const newState = populateState(merged, oldState);
		// sync to state
		return dispatch({
			type: "SUCCESS",
			data: {
				newState: newState,
			},
		});
	});
	// handle filters' checkboxes
	const handleFilters = (e) => {
		const { checked } = e.target;
		if (!checked) {
			setSelectAllFilters(false);
			return handleCheckbox(e);
		} else {
			return handleCheckbox(e);
		}
	};
	// select ALL filters
	const handleSelectAll = (e) => {
		setSelectAllFilters(true);
		setClearAllFilters(false);
		return setFormState({
			...formState,
			values: {
				...values,
				...initialFilters,
			},
		});
	};
	// clear ALL (ADL) filters
	const handleClearAll = (e) => {
		setClearAllFilters(!clearAllFilters);
		setSelectAllFilters(false);
		return setFormState({
			...formState,
			values: {
				...values,
				Ambulation: false,
				Bathing: false,
				Dressing: false,
				Grooming: false,
				Health: false,
				Laundry: false,
				Meals: false,
				MedAssist: false,
				Mental: false,
				SpecialCare: false,
				StatusChecks: false,
				Toileting: false,
				Transfers: false,
				Other: false,
			},
		});
	};

	// sets 'currentFacility' obj when 'selectedResident' changes
	const setFacility = useCallback(() => {
		if (isEmptyVal(selectedFacility)) return;
		const facilityRecord = matchFacilityByName(selectedFacility, facilities);
		const facilityResidents = residentsByFacility[facilityRecord.FacilityId];

		dispatch({
			type: "SET_FACILITY",
			data: {
				facilityRecord: facilityRecord,
				facilityResidents: facilityResidents,
			},
		});
	}, [dispatch, facilities, residentsByFacility, selectedFacility]);

	const syncResAndFacility = useCallback(() => {
		const { facility, resident } = loadDefaultStateFromParams(
			facilities,
			residents
		);
		// ##TODOS:
		// - Consider replacing set states below w/ dispatch to global state
		// - Replace with 'setResAndFacility' from above
		// - Dispatching to the global store, should then precipitate local state from the store???? Maybe???

		setSelectedFacility(() => {
			return facility?.CommunityName;
		});
		setSelectedResident(() => {
			return formatResidentOnly(resident);
		});
	}, [facilities, residents]);

	// used to sync residnet & facility from hot-link
	//  not fully operational.
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (
			hasTargetParams(window.location, "residentID") &&
			hasTargetParams(window.location, "facilityID")
		) {
			syncResAndFacility();
		}
		return () => {
			isMounted = false;
		};
	}, [facilities, residents, syncResAndFacility]);

	// sets 'currentFacility' in global store when it changes
	useEffect(() => {
		setFacility();
	}, [selectedFacility, setFacility]);

	// update's 'statusFilters' list when changed
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		getStatusFilters(values, setStatusFilters);

		return () => {
			isMounted = false;
		};
	}, [values]);

	// extract URL params & fetch & set local state
	// load resident & tasks from authed page
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (!isEmptyVal(selectedResident) && fromAuthedRoute(history)) {
			loadResident();
		}
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// watches tasks and renders '<AllCompletedBanner/>', if applicable
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (!isEmptyArray(scheduledTasks)) {
			setDoneForToday(() => {
				if (isEmptyVal(selectedResident)) return false;
				return isAllComplete([...scheduledTasks, ...unscheduledTasks]);
			});
		}
		return () => {
			isMounted = false;
		};
	}, [scheduledTasks, unscheduledTasks, selectedResident]);

	return (
		<>
			<Sentry.ErrorBoundary
				fallback={<div>There was an error</div>}
				dialogOptions={{
					user: { ...user },
					title: "Oops. There was an error.",
					labelName: "Daily View",
				}}
			>
				{/* COMMUNITY INFO BUTTON */}
				{renderCommunityInfo(featureFlags.enableCommunityInfo, {
					currentFacility: currentFacility,
					selectedFacility: selectedFacility,
					isAdmin: user?.isAdmin,
				})}
				{/* ALA SELECTOR */}
				{renderALASelector(
					// isLoading, // global store
					facilities,
					residents,
					user?.isAdmin || facilities?.length > 1,
					loadResident,
					setSelectedFacility,
					setSelectedResident,
					selectedFacility,
					selectedResident
					// selectedUnitType
				)}
				{/* RESIDENT CARD - INFO/MEDS */}
				{renderResidentCard(
					selectedResident,
					currentResident,
					currentResident?.Meds,
					user
				)}

				<div className={styles.DailyView}>
					<h1 className={styles.DailyView_viewTitle}>Today's Care Tasks</h1>
					{isLOA && (
						<h2 className={styles.DailyView_LOA}>{formatLOANotice(loa)}</h2>
					)}
					<section className={styles.DailyView_content}>
						{isLoading && <Spinner />}
						{doneForToday && (
							<AllCompletedBanner
								key={doneForToday}
								taskCount={[...scheduledTasks, ...unscheduledTasks]?.length}
							/>
						)}

						<div className={styles.DailyView_content_cards}>
							{shouldShowCards(
								selectedResident,
								currentResident,
								isLOA,
								isLoading
							) &&
								[...sortAdlsByName(categories)].map((adl, index) => (
									<DailySummaryCard
										reloadResident={loadResident}
										showCard={values[checkAdlNaming(getAdlName(adl))]}
										activeStatusFilters={statusFilters}
										facilityExceptions={currentFacility.exceptions}
										dispatch={dispatch}
										currentUser={user}
										currentFacility={currentFacility}
										currentResident={currentResident}
										trackingTasks={trackingTasks}
										unscheduledTasksRaw={unscheduledTasksRaw}
										hasUpdated={app.hasUpdated}
										category={adl}
										day={new Date()}
										key={`${adl.TaskId}_${adl.AdlCategoryId}_${index}`}
										allTasks={findTasksByADL(
											[...scheduledTasks, ...unscheduledTasks],
											adl.AdlCategoryType
										)}
										isCompleted={isAllComplete(
											findTasksByADL(
												[...scheduledTasks, ...unscheduledTasks],
												adl.AdlCategoryType
											)
										)}
									/>
								))}
						</div>

						{featureFlags?.enableDueDate && (
							<div className={styles.DailyView_content_list}>
								{/* {!isEmptyVal(currentResident?.ResidentID) && ( */}
								<TimeViewTaskList
									currentUser={user}
									currentFacility={currentFacility}
								/>
								{/* )} */}
							</div>
						)}
					</section>
				</div>

				{/*  REMOVE THIS LATER */}
				{/*  REMOVE THIS LATER */}
				{/*  REMOVE THIS LATER */}
				{/* <DailyMultiViews /> */}

				{/* CREATE TASK BUTTON */}
				<FloatingButton size="LG" handleClick={initNewTaskWindow}>
					<svg className={styles.CreateTaskIcon}>
						<use xlinkHref={`${sprite}#icon-plus21`}></use>
					</svg>
				</FloatingButton>

				{/* ADL/STATUS FILTERS BUTTON */}
				<FloatingFiltersButton
					vals={values}
					selectAllVal={selectAllFilters}
					clearAllVal={clearAllFilters}
					handleFilters={handleFilters}
					handleSelectAll={handleSelectAll}
					handleClearAll={handleClearAll}
					filters={{
						adls: [...formatAdlName(categories)],
						status: [...statusTypes],
					}}
				/>

				{showNewTaskModal && (
					<ModalFull
						title="New Task"
						closeModal={() => setShowNewTaskModal(false)}
					>
						<CreateTaskForm
							categories={categories}
							currentResident={currentResident}
							currentFacility={currentFacility}
							currentUser={user}
							isSubmitting={isSubmitting}
							vals={values}
							createTask={createTask}
							cancelTask={cancelTask}
							cancelRepeat={cancelRepeat}
							handleChange={handleChange}
							handleCheckbox={handleCheckbox}
							handleSettings={handleSettings}
							handleDateRange={handleDateRange}
							handleDueDate={handleDueDate}
							handleDueTime={handleDueTime}
							handleRecurrences={handleRecurrences}
							handleCycleOptions={handleRadioButtons}
						/>
					</ModalFull>
				)}

				{showCommunityInfo && (
					<ModalFull
						title="Community Shifts"
						closeModal={() => setShowCommunityInfo(false)}
					>
						<CommunityInfo communityInfo={currentFacility} />
					</ModalFull>
				)}

				{showUpdateBanner && (
					<UpdateBanner closeBanner={() => setShowUpdateBanner(false)} />
				)}
				{/* SCROLL TO TOP BUTTON */}
				<ScrollToTop />
				{/* ALERTS UI - V2 */}
				{AlertsHandler}
			</Sentry.ErrorBoundary>
		</>
	);
};

export default DailyView;

DailyView.defaultProps = {};

DailyView.propTypes = {
	history: PropTypes.object,
};
