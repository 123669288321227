import React from "react";
import styles from "../../css/widget/FeedbackError.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";

const FeedbackError = ({ msg, tryAgain }) => {
	return (
		<div className={styles.FeedbackError}>
			<div className={styles.FeedbackError}>
				<svg className={styles.FeedbackError_icon}>
					<use xlinkHref={`${sprite}#icon-error_outline`}></use>
				</svg>
				<div className={styles.FeedbackError_msg}>{msg}</div>
				<button onClick={tryAgain} className={styles.FeedbackError_tryAgainBtn}>
					Try Again?
				</button>
			</div>
		</div>
	);
};

export default FeedbackError;

FeedbackError.defaultProps = {};

FeedbackError.propTypes = {
	msg: PropTypes.string,
	tryAgain: PropTypes.func,
};
