/**
 * Setup React ENV variables
 * Resource found here: https://medium.com/@trekinbami/using-environment-variables-in-react-6b0a99d83cf5
 */

import { envLogger } from "./utils_processing";

const REACT_APP_MAINTENANCE_MODE = false;

/**
 * Azure Blob Endpoint: https://aladvservicestore.blob.core.windows.net/devalastorage ???
 * - Ex. https://aladvservicestore.blob.core.windows.net/devalastorage/Repository/5d25c21b-883d-402d-abae-7cac7053fdc0/images/137580/c0911d53-8863-4f7b-92a8-4fec190300da.png?sv=2020-04-08&st=2021-06-01T18%3A10%3A10Z&se=2021-06-01T18%3A40%3A10Z&sr=b&sp=r&sig=8FxA8SUGq2vBmCuc9e7cVphw1mTTrRHp9yfmvB624nQ%3D
 * - Is this the route needed to fetch file downloads now???
 */

/**
 * Update Endpoint & ENV Variables 6/2 at 8:00 AM
 * Azure ENV Endpoint: https://ala-api.azurewebsites.net
 */
const REACT_APP_ENV_AUTH = {
	development: {
		base: "https://localhost:5500/alaservices/v1/",
		user: "x-dev-user",
		password: "J99Hf2i3eY#2pqBj234tD2@H$%",
	},
	// Update 'production' endpoint & web-service creds as of 6/18/2021 at 8:15 AM.
	production: {
		base: "https://api.aladvantage.com/alaservices/v1/",
		user: "x-prod-user",
		password: "7U*hg%53^D*@bq-d@k8f2L$^fd4j",
	},
	testing: {
		base: "https://apitest.aladvantage.com/alaservices/v1/",
		user: "x-test-user",
		password: "M9hf^%2HHf3^$(sn@Kd23p#hsq",
	},
};

const {
	development: dev,
	production: prod,
	testing: test,
} = REACT_APP_ENV_AUTH;

// current environment
const REACT_APP_CURRENT_ENV_NAME = "prod";
const REACT_APP_CURRENT_ENV = prod;

// checks if is local host testing
const isLocalhost = () => {
	return Boolean(
		window.location.hostname === "localhost" ||
			// [::1] is the IPv6 localhost address.
			window.location.hostname === "[::1]" ||
			// 127.0.0.0/8 are considered localhost for IPv4.
			window.location.hostname.match(
				/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
			)
	);
};

// returns true for 'development' or 'test' but NOT 'localhost'
const isDevOrTest = () => {
	if (isLocalhost()) return false;
	const env = process.env.NODE_ENV;
	const envOverride = REACT_APP_CURRENT_ENV_NAME;

	// Args: 'custom env', 'nodeEnv', 'maintenanceMode'
	envLogger(REACT_APP_CURRENT_ENV_NAME, env);
	return env === "development" || env === "test" || envOverride === "test";
};

/**
 * Determines the current environment's alias name and returns it as a string.
 * @returns {String} - Returns the current environment's name alias (eg. 'test', 'prod', 'dev' or 'local')
 */
const getEnvName = () => {
	if (isLocalhost()) return "local";
	if (isDevOrTest()) return "test";
	return "prod";
};

export { dev, prod, test };

export {
	REACT_APP_CURRENT_ENV as currentEnv,
	REACT_APP_CURRENT_ENV_NAME as currentEnvName,
	REACT_APP_MAINTENANCE_MODE as currentEnvMaintenanceMode,
};

export { isDevOrTest, isLocalhost, getEnvName };
