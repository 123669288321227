import React, { useState, useEffect } from "react";
import styles from "../../css/adlschedule/AdlScheduleSettings.module.scss";
import { PropTypes } from "prop-types";
import { sortShifts } from "../../helpers/utils_shifts";
import { isEmptyArray } from "../../helpers/utils_types";
import {
	findShiftRecordsByADL,
	getAdlShiftChanges,
} from "../../helpers/utils_adlSchedule";

import { getCategoryID } from "../../helpers/utils_categories";
// COMPONENTS //
import AdlShiftSettingsPanel from "./AdlShiftSettingsPanel";
import AdlTable from "../../components/adlschedule/AdlTable";
import AdlColumnHeadings from "../../components/adlschedule/AdlColumnHeadings";
import AdlRow from "../../components/adlschedule/AdlRow";
import AdlTableBody from "../../components/adlschedule/AdlTableBody";
import AdlShiftReport from "../adlshiftreport/AdlShiftReport";
import AdlRows from "../../components/adlschedule/AdlRows";
// other Shift-related components
import ShiftHeadings from "../../components/adlschedule/ShiftHeadings";
import ResidentTable from "../../components/residenttable/ResidentTable";
import ModalFull from "../../components/shared/ModalFull";
import Spinner from "../shared/Spinner";

// updated - 5/30/2020 3:03 PM
const headings = ["Name", "Floor Unit", "Room #"];

// mock example - custom disabled ADLs
const disabledADLs = ["Bathing", "Laundry", "StatusChecks", "Health"];

const AdlScheduleSettings = ({
	categories = [],
	shifts = [],
	residents = [],
	adlShiftSchedules = [], // might not be needed??? - ONLY USED FOR SINGLE RESIDENT CHANGES!!!
	currentUser = {},
	currentFacility = {},
	isLoadingSettings = false,
	showSettings = false,
}) => {
	const [showResidentSelectionTable, setShowResidentSelectionTable] =
		useState(false);
	const [showReportModal, setShowReportModal] = useState(false);
	const [selectedResidents, setSelectedResidents] = useState([]);
	// adl shift report states
	const [isLoadingReport, setIsLoadingReport] = useState(false);
	const [adlShiftRecords, setAdlShiftRecords] = useState([]);
	const [adlSelection, setAdlSelection] = useState("Ambulation");

	const clearSelection = (e) => {
		// not needed, changes are cleared from <AdlShiftTile/>
	};
	const saveSelection = (e) => {
		// not needed, changes are saved from <AdlShiftTile/>
	};

	const getSelectedResidents = (listOfResidentIDs = []) => {
		setSelectedResidents(listOfResidentIDs);
	};

	// custom dropdown handler/setter
	const handleAdlSelection = (name, option) => {
		setAdlSelection(option);
	};

	// fetch adl shift records "onmount"...
	// ...and anytime the "adlSelection" state value changes
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		// fetches AdlShift records "onMount" & anytime "adlSelection" changes
		const fetchRecords = async () => {
			setIsLoadingReport(true);
			const { token, facilityID: facilityIDFromUser } = currentUser;
			const facID = currentFacility?.facilityID ?? facilityIDFromUser;

			const changes = await getAdlShiftChanges(
				token,
				facID,
				getCategoryID(adlSelection)
			);

			console.log("changes", changes);

			if (!isEmptyArray(changes)) {
				setIsLoadingReport(false);
				return setAdlShiftRecords([...changes]);
			} else {
				setIsLoadingReport(false);
				return setAdlShiftRecords([]);
			}
		};

		fetchRecords();
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adlSelection]);

	return (
		<>
			<AdlShiftSettingsPanel
				openResidentTable={setShowResidentSelectionTable}
				openShiftReport={setShowReportModal}
			/>

			{/* ADL SCHEDULE SETTINGS TABLE */}
			{showSettings && (
				<article className={styles.AdlScheduleSettings}>
					{/* TABLE W/ MAIN SETTINGS PANEL */}
					<AdlTable
						editCount={selectedResidents.length}
						totalCount={residents.length}
						saveSelection={saveSelection}
						clearSelection={clearSelection}
					>
						{/* TOP X-AXIS */}
						<ShiftHeadings
							shiftHeadings={sortShifts(shifts)}
							isLoadingSettings={isLoadingSettings}
						/>
						{/* LEFT Y-AXIS */}
						{isLoadingSettings ? (
							<Spinner />
						) : (
							<AdlTableBody>
								<AdlColumnHeadings
									adlHeadings={categories}
									disabledADLs={disabledADLs}
									isLoadingSettings={isLoadingSettings}
								/>
								<AdlRows isLoading={isLoadingSettings}>
									{!isEmptyArray(categories) &&
										categories.map((adl, index) => (
											<AdlRow
												key={`${adl.Name}_${adl.AdlId}_${
													adl.AssessmentCategoryId
												}_${index * 2}`}
												adl={adl}
												disabledADLs={disabledADLs}
												shiftRecords={
													!isEmptyArray(adlShiftSchedules)
														? findShiftRecordsByADL(adl.Name, adlShiftSchedules)
														: []
												}
												selectedResidents={selectedResidents}
												currentUser={currentUser}
											/>
										))}
								</AdlRows>
							</AdlTableBody>
						)}
					</AdlTable>
				</article>
			)}

			{showResidentSelectionTable && (
				<ModalFull
					title="Select Multiple Residents (bulk edit)"
					closeModal={() => setShowResidentSelectionTable(false)}
				>
					<ResidentTable
						title="Resident Table"
						residents={currentFacility?.residents}
						alreadySelected={selectedResidents}
						selectedResidents={selectedResidents}
						headings={headings}
						closeModal={() => setShowResidentSelectionTable(false)}
						getSelectedResidents={getSelectedResidents}
					/>
				</ModalFull>
			)}

			{showReportModal && (
				<ModalFull
					title="Adl Shift Report"
					closeModal={() => setShowReportModal(false)}
				>
					<AdlShiftReport
						isLoading={isLoadingReport}
						categories={categories}
						adlShiftRecords={adlShiftRecords}
						adlSelection={adlSelection}
						handleAdlSelection={handleAdlSelection}
					/>
				</ModalFull>
			)}
		</>
	);
};

export default AdlScheduleSettings;

AdlScheduleSettings.defaultProps = {
	categories: [],
	shifts: [],
	currentUser: {},
	residents: [],
};

AdlScheduleSettings.propTypes = {
	categories: PropTypes.arrayOf(
		PropTypes.shape({
			Name: PropTypes.string.isRequired, // ie "Dressing", "Bathing" (~ AdlCategoryType)
			AssessmentCategoryId: PropTypes.number.isRequired,
		})
	),
	shifts: PropTypes.arrayOf(
		PropTypes.shape({
			AssessmentFacilityShiftId: PropTypes.number.isRequired,
			AssessmentShiftId: PropTypes.number.isRequired,
			ModifiedDate: PropTypes.instanceOf([PropTypes.Date]),
			StartTime: PropTypes.instanceOf([PropTypes.Date]),
			EndTime: PropTypes.instanceOf([PropTypes.Date]),
			IsActive: PropTypes.bool.isRequired,
			FacilityId: PropTypes.string.isRequired,
		})
	),
	residents: PropTypes.arrayOf(
		PropTypes.shape({
			FirstName: PropTypes.string.isRequired,
			LastName: PropTypes.string.isRequired,
			ResidentID: PropTypes.number.isRequired,
		})
	),
	currentResident: PropTypes.shape({
		FirstName: PropTypes.string,
		LastName: PropTypes.string,
		ResidentID: PropTypes.number,
	}),
	currentUser: PropTypes.shape({
		token: PropTypes.string.isRequired,
	}).isRequired,
};
