import React from "react";
import styles from "../../css/forms/FormActions.module.scss";
import { PropTypes } from "prop-types";
// helpers
import { isEmptyObj } from "../../helpers/utils_types";
import { main } from "../../helpers/utils_styles";
// components
import ButtonSM from "../shared/ButtonSM";

const saveDefaults = {
	borderRadius: "5rem",
	fontWeight: "600",
	backgroundColor: main.blackBlue,
	color: "#ffffff",
	marginRight: ".5rem",
};
const cancelDefaults = {
	borderRadius: "5rem",
	backgroundColor: "transparent",
	fontWeight: "600",
	color: main.blackBlue,
	border: "1px solid #ccc",
};

const FormActions = ({
	saveTxt,
	cancelTxt,
	saveHandler,
	cancelHandler,
	saveCSS = {},
	cancelCSS = {},
	customStyles = {},
	isDisabled = false,
	hide = false,
	children = null,
}) => {
	const handleSave = (e) => {
		e.preventDefault();
		e.stopPropagation();
		e.nativeEvent.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		return saveHandler(e);
	};
	const handleCancel = (e) => {
		e.preventDefault();
		e.stopPropagation();
		e.nativeEvent.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		return cancelHandler(e);
	};

	if (hide) {
		return null;
	}
	return (
		<div className={styles.FormActions} style={customStyles}>
			{!children && (
				<>
					<ButtonSM
						type="button"
						isDisabled={isDisabled}
						handleClick={handleSave}
						customStyles={isEmptyObj(saveCSS) ? saveDefaults : saveCSS}
					>
						<span>{saveTxt}</span>
					</ButtonSM>
					<ButtonSM
						type="button"
						handleClick={handleCancel}
						customStyles={isEmptyObj(cancelCSS) ? cancelDefaults : cancelCSS}
					>
						<span>{cancelTxt}</span>
					</ButtonSM>
				</>
			)}
		</div>
	);
};

export default FormActions;

FormActions.defaultProps = {
	customStyles: {},
	saveCSS: {},
	cancelCSS: {},
	hide: false,
	children: null,
};

FormActions.propTypes = {
	saveTxt: PropTypes.string,
	cancelTxt: PropTypes.string,
	saveHandler: PropTypes.func,
	cancelHandler: PropTypes.func,
	saveCSS: PropTypes.object,
	cancelCSS: PropTypes.object,
	customStyles: PropTypes.object,
	children: PropTypes.any,
};
