import React from "react";
import styles from "../../css/reportsviewer/ReportViewerTableHeadings.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import ReportViewerTableHeading from "./ReportViewerTableHeading";

const ReportViewerTableHeadings = ({
	headings = [],
	sortType,
	selectAllVal,
	enableRowSelection = false,
	handleHeading,
	handleSelectAll,
}) => {
	return (
		<header className={styles.ReportViewerTableHeadings}>
			<div className={styles.ReportViewerTableHeadings_content}>
				{enableRowSelection && (
					<div className={styles.ReportViewerTableHeadings_content_selectAll}>
						<input
							type="checkbox"
							name="selectAll"
							id="selectAll"
							checked={selectAllVal}
							className={
								styles.ReportViewerTableHeadings_content_selectAll_checkbox
							}
							onChange={handleSelectAll}
						/>
						<label
							htmlFor="selectAll"
							className={
								styles.ReportViewerTableHeadings_content_selectAll_label
							}
						>
							Select All
						</label>
					</div>
				)}
				{!isEmptyArray(headings) &&
					headings.map((heading, idx) => (
						<ReportViewerTableHeading
							key={`${heading}_${idx}`}
							heading={heading}
							sortType={sortType}
							handleHeading={() => handleHeading(heading)}
						/>
					))}
			</div>
		</header>
	);
};

export default ReportViewerTableHeadings;

ReportViewerTableHeadings.defaultProps = {
	headings: [],
};

ReportViewerTableHeadings.propTypes = {
	headings: PropTypes.arrayOf(PropTypes.string).isRequired,
	handleHeading: PropTypes.func.isRequired,
};
