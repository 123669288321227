import React from "react";
import styles from "../../css/residenttable/ResidentTableBody.module.scss";
import { PropTypes } from "prop-types";

const ResidentTableBody = ({ children }) => {
	return <section className={styles.ResidentTableBody}>{children}</section>;
};

export default ResidentTableBody;

ResidentTableBody.defaultProps = {};

ResidentTableBody.propTypes = {
	handleSelection: PropTypes.func,
};
