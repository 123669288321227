import React from "react";
import styles from "../../css/views/DailyResidentView.module.scss";
import { PropTypes } from "prop-types";

const DailyResidentView = () => {
	return (
		<div className={styles.DailyResidentView}>
			<header className={styles.DailyResidentView_header}>
				<h1 className={styles.DailyResidentView_header_title}>
					Resident Cards
				</h1>
			</header>
			{/*  */}
			{/*  */}
			{/*  */}
		</div>
	);
};

export default DailyResidentView;

DailyResidentView.defaultProps = {};

DailyResidentView.propTypes = {};
