import React, { useState, useContext, useEffect, useCallback } from "react";
import styles from "../../css/views/PastDueReport.module.scss";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../../state/GlobalStateContext";
import { useAlerts, initialSettings } from "../../utils/useAlerts";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { getDailyPastDue } from "../../helpers/utils_pastdue";
import { getGenericCount } from "../../helpers/utils_generic";
import { startOfDay, endOfDay } from "date-fns";
import { hasFacility, matchFacilityByName } from "../../helpers/utils_facility";
import {
	getDefaultResident,
	getDefaultFacility,
} from "../../helpers/utils_tasks";
import { sendPageTracking } from "../../helpers/utils_tracking";
import { calculateRestrictionRange } from "../../helpers/utils_dates";
// components
import PastDuePanel from "../../components/pastdue/PastDuePanel";
import ALASelector from "../../components/app/ALASelector";

const idxIncrement = 70; // previously 25

const PastDueReport = ({ history }) => {
	const { state, dispatch } = useContext(GlobalStateContext);
	const { AlertsHandler, dispatchAlert } = useAlerts({ ...initialSettings });
	const { user, globals } = state;
	const { currentFacility, currentResident, residentsByFacility, residents } =
		globals;
	const [pastDueCount, setPastDueCount] = useState(null);
	// fetches 0-25 records (ie. residents)
	const [currentIndex, setCurrentIndex] = useState(70); // previously 25
	const [pastDueRecords, setPastDueRecords] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [rangeRestriction, setRangeRestriction] = useState(
		// restricts date range to 3 month windows from today (eg. today - 3mo.)
		calculateRestrictionRange(3)
	);

	const [selectedFacility, setSelectedFacility] = useState(
		getDefaultFacility(currentFacility)
	);
	const [selectedResident, setSelectedResident] = useState(
		getDefaultResident(currentResident)
	);

	// checks for records and kills 'loading' state
	const handleRecords = (records = []) => {
		if (isEmptyArray(records)) {
			setIsLoading(false);
			setPastDueRecords(records);
		} else {
			setIsLoading(false);
			return setPastDueRecords(records);
		}
	};

	const getPastDueRecords = async () => {
		const { token } = user;
		const { facilityID } = currentFacility;
		setIsLoading(true);

		const start = startOfDay(new Date()).toISOString(); // todays date
		const end = endOfDay(new Date()).toISOString(); // todays date

		console.log("start", start);
		console.log("end", end);

		const records = await getDailyPastDue(
			token,
			facilityID,
			start,
			end,
			0,
			100
		);

		return handleRecords(records);
	};

	// used for fetching more records after initial fetch.
	// fetches in increments of 25 rows from database
	const getMorePastDueRecords = async (index) => {
		const { token, facilityID } = user;
		setIsLoading(true);

		const startDate = startOfDay(new Date()).toISOString();
		const endDate = endOfDay(new Date()).toISOString();

		const records = await getDailyPastDue(
			token,
			facilityID,
			startDate,
			endDate,
			index,
			index + idxIncrement
		);

		if (!isEmptyArray(records)) {
			setIsLoading(false);
			return setPastDueRecords(records);
		} else {
			return setIsLoading(false);
		}
	};

	// runs every time the route is mounted (ie <PastDueView/>)
	const getInitialPastDue = () => {
		if (!hasFacility(selectedFacility)) {
			return setIsLoading(false);
		}
		getPastDueRecords();
		getPastDueCount();
		setCurrentIndex(currentIndex + idxIncrement);
	};

	// gets total count of past due
	const getPastDueCount = async () => {
		const { token } = user;
		const { facilityID } = currentFacility;
		const params = {
			facilityId: facilityID,
		};
		const count = await getGenericCount(token, params, "residents");
		return setPastDueCount(count);
	};

	const viewMorePastDue = () => {
		setCurrentIndex(currentIndex + idxIncrement);
		return getMorePastDueRecords(currentIndex);
	};

	// only used for dispatching an alert
	// global store is updated from side-effect (ie 'useEffect')
	const changeFacility = (selectedVal) => {
		// not needed; facility is loaded via 'setFacility'
		getInitialPastDue();
		return dispatchAlert("info", {
			heading: `Changed Facility`,
			subheading: `Loaded ${selectedVal}.`,
		});
	};

	// sets 'currentFacility' obj when 'selectedResident' changes
	const setFacility = useCallback(() => {
		if (isEmptyVal(selectedFacility)) return;
		const facilityRecord = matchFacilityByName(
			selectedFacility,
			user?.facilities
		);
		const facilityResidents = residentsByFacility[facilityRecord.FacilityId];

		dispatch({
			type: "SET_FACILITY",
			data: {
				facilityRecord: facilityRecord,
				facilityResidents: facilityResidents,
			},
		});
	}, [dispatch, residentsByFacility, selectedFacility, user]);

	// sets 'currentFacility' in global store when it changes
	useEffect(() => {
		setFacility();
	}, [setFacility]);

	// fetches TOTAL number of past due records for facility, 'onMount' ONLY
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		getInitialPastDue();
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// handles changing from isLoading state to "loaded" state
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (!isEmptyArray(pastDueRecords)) {
			return setIsLoading(false);
		}
		return () => {
			isMounted = false;
		};
	}, [pastDueRecords]);

	// sends tracking to Sentry.io
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		sendPageTracking(user?.username, {
			pagename: `PastDueReport`,
			path: `/dashboard/reports/pastdue`,
		});

		return () => {
			isMounted = false;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={styles.PastDueReport}>
				<section className={styles.PastDueReport_selector}>
					<ALASelector
						key={`PAST-DUE-REPORT-SELECTOR`}
						isAdmin={user?.isAdmin}
						disableResident={true}
						facilities={user?.facilities}
						residents={residents}
						defaultFacility={selectedFacility}
						defaultResident={selectedResident}
						syncFacility={setSelectedFacility}
						syncResident={setSelectedResident}
						loadFacility={changeFacility}
					/>
				</section>
				<header className={styles.PastDueReport_header}>
					<h4 className={styles.PastDueReport_header_title}>Past Due Report</h4>
				</header>
				<PastDuePanel
					records={pastDueRecords}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					hasNoRecords={isEmptyArray(pastDueRecords)}
					viewMore={viewMorePastDue}
					currentUser={user}
					facilityID={currentFacility?.facilityID}
					shiftTimes={currentFacility?.shifts}
					dateRangeRestrictions={rangeRestriction}
				/>
			</div>

			{/* ALERTS UI */}
			{AlertsHandler}
		</>
	);
};

export default PastDueReport;

PastDueReport.defaultProps = {};

PastDueReport.propTypes = {
	history: PropTypes.object,
};
