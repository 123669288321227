import { currentEnv } from "./utils_env";
import { scheduledTasks, unscheduledTasks } from "./utils_endpoints";
import {
	ScheduledTaskNoteModel,
	UnscheduledTaskNoteModel,
} from "./utils_models";
import {
	SCHEDULED_ID,
	UNSCHEDULED_ID,
	isScheduledTask,
	isUnscheduledTask,
} from "./utils_tasks";
import { format, isEqual, isSameMinute } from "date-fns";
import { isEmptyArray, isEmptyVal } from "./utils_types";
import { formatDateInWordsToNow } from "./utils_dates";
import { isAdminLevel } from "./utils_userTypes";
import {
	groupBy,
	sortAlphaAscByKey,
	sortDateAscByKey,
	sortNumDescByKey,
} from "./utils_processing";

// TASK NOTE REQUEST UTILS //

/**
 * Fetches ALL task note records for a given tracking task.
 * @param {String} token - Auth token
 * @param {Number} taskID - Numeric task id ('AssessmentTrackingTaskId')
 * @returns {Array} - An array of task note records
 */
const getScheduledTaskNotesByTaskID = async (token, taskID) => {
	let url = currentEnv.base + scheduledTasks.get.taskNotes;
	url += "?" + new URLSearchParams({ index: 0, rows: 100 });
	url += "&" + new URLSearchParams({ AssessmentTrackingTaskId: taskID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Fetches ALL task note records for a given unscheduled task.
 * @param {String} token - Auth token
 * @param {Number} taskID - Numeric task id ('AssessmentUnscheduleTaskId')
 * @returns {Array} - An array of task note records
 */
const getUnscheduledTaskNotesByTaskID = async (token, taskID) => {
	let url = currentEnv.base + unscheduledTasks.get.taskNotes;
	url += "?" + new URLSearchParams({ index: 0, rows: 100 });
	url += "&" + new URLSearchParams({ AssessmentUnscheduleTaskId: taskID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Determines task type, then fetches all that task's task note records.
 * @param {String} token - Auth token
 * @param {Object} task - A task record (scheduled or unscheduled task record)
 * @returns {Array} - Returns an array of task note records, or an empty array
 */
const getTaskNotesByTaskID = async (token, task = {}) => {
	switch (true) {
		case isScheduledTask(task): {
			const taskID = task[SCHEDULED_ID];
			const allNotes = await getScheduledTaskNotesByTaskID(token, taskID);
			return allNotes;
		}
		case isUnscheduledTask(task): {
			const taskID = task[UNSCHEDULED_ID];
			const allNotes = await getUnscheduledTaskNotesByTaskID(token, taskID);
			return allNotes;
		}
		default:
			return [];
	}
};
/**
 * Saves one or more tracking task note records.
 * @param {String} token - Auth token
 * @param {Array} taskNotes - An array of tracking task note records.
 * @returns {Array} - An array of task note record IDs
 */
const saveScheduledTaskNotes = async (token, taskNotes = []) => {
	let url = currentEnv.base + scheduledTasks.save.taskNotesMany;

	// checks/makes sure 'taskNotes' is an array or not
	const allNotes = Array.isArray(taskNotes) ? taskNotes : [taskNotes];

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(allNotes),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Saves one or more unscheduled task note records.
 * @param {String} token - Auth token
 * @param {Array} taskNotes - An array of unscheduled task note records.
 * @returns {Array} - An array of task note record IDs
 */
const saveUnscheduledTaskNotes = async (token, taskNotes = []) => {
	let url = currentEnv.base + unscheduledTasks.save.taskNotesMany;

	// checks/makes sure 'taskNotes' is an array or not
	const allNotes = Array.isArray(taskNotes) ? taskNotes : [taskNotes];

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(allNotes),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Deletes a single 'AssessmentTrackingTaskNote' record
 * @param {String} token - Auth token
 * @param {Number} noteID - A numeric 'AssessmentTrackingTaskNoteId' value.
 * @returns {Boolean} - Returns whether deletion was successful
 */
const deleteScheduledTaskNote = async (token, noteID) => {
	let url = currentEnv.base + scheduledTasks.delete.taskNote;
	url += "?" + new URLSearchParams({ AssessmentTrackingTaskNoteId: noteID });

	try {
		const request = await fetch(url, {
			method: "DELETE",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Deletes a single 'AssessmentUnscheduleTaskNote' record
 * @param {String} token - Auth token
 * @param {Number} noteID - A numeric 'AssessmentUnscheduleTaskNoteId' value.
 * @returns {Boolean} - Returns whether deletion was successful
 */
const deleteUnscheduledTaskNote = async (token, noteID) => {
	let url = currentEnv.base + unscheduledTasks.delete.taskNote;
	url += "?" + new URLSearchParams({ AssessmentUnscheduleTaskNoteId: noteID });

	try {
		const request = await fetch(url, {
			method: "DELETE",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Deletes a single 'AssessmentTrackingTaskNote' record
 * @param {String} token - Auth token
 * @param {Object} taskNote - An object 'AssessmentTrackingTaskNote' record.
 * @returns {Boolean} - Returns whether deletion was successful
 */
const virtualDeleteScheduledTaskNote = async (token, taskNote = {}) => {
	let url = currentEnv.base + scheduledTasks.virtualDelete.taskNote;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ ...taskNote }),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Deletes a single 'AssessmentUnscheduleTaskNote' record
 * @param {String} token - Auth token
 * @param {Object} taskNote - An object 'AssessmentUnscheduleTaskNote' record.
 * @returns {Boolean} - Returns whether deletion was successful
 */
const virtualDeleteUnscheduledTaskNote = async (token, taskNote = {}) => {
	let url = currentEnv.base + unscheduledTasks.virtualDelete.taskNote;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ ...taskNote }),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Deletes a single taskNote record. (Supports 'scheduled' and 'unscheduled' task notes)
 * @param {String} token - Auth token
 * @param {Object} taskNote - An object taskNote record.
 * @returns {Boolean} - Returns whether deletion was successful
 */
const virtualDeleteTaskNote = async (token, taskNote = {}) => {
	switch (true) {
		case isScheduledTask(taskNote): {
			const wasDeleted = await virtualDeleteScheduledTaskNote(token, taskNote);
			return wasDeleted;
		}
		case isUnscheduledTask(taskNote): {
			const wasDeleted = await virtualDeleteUnscheduledTaskNote(
				token,
				taskNote
			);
			return wasDeleted;
		}

		default:
			return "VIRTUAL DELETE FAILED";
	}
};

/**
 * Utility that wraps both task note types' request handlers. Supports all types and multiple note records
 * @param {String} token - Auth token
 * @param {Object} taskNote - A formatted task note record (ie. 'AssessmentTrackingTaskNote' or 'AssessmentUnscheduleTaskNote')
 * @returns {Boolean} - Returns true|false
 */
const saveTaskNotes = async (token, taskNote = {}) => {
	switch (true) {
		case isScheduledTask(taskNote): {
			const wasSaved = await saveScheduledTaskNotes(token, taskNote);
			return wasSaved;
		}
		case isUnscheduledTask(taskNote): {
			const wasSaved = await saveUnscheduledTaskNotes(token, taskNote);
			return wasSaved;
		}

		default:
			return null;
	}
};
/**
 * Deletes a single task note. Supports 'scheduled' and 'unscheduled' task note types.
 * @param {String} token - Auth token
 * @param {Object} taskNote - Client-formatted task note record
 * @returns {Boolean} - Returns true|false
 */
const deleteTaskNote = async (token, taskNote = {}) => {
	switch (true) {
		// scheduled
		case !isEmptyVal(taskNote?.trackingID): {
			const wasDeleted = await deleteScheduledTaskNote(token, {
				AssessmentTrackingTaskNoteId: taskNote?.noteID,
				IsActive: false,
			});
			return wasDeleted;
		}
		// unscheduled
		case isEmptyVal(taskNote?.trackingID): {
			const wasDeleted = await deleteUnscheduledTaskNote(token, {
				AssessmentUnscheduleTaskNoteId: taskNote?.noteID,
				IsActive: false,
			});
			return wasDeleted;
		}
		default:
			return null;
	}
};

// creates a new task note for a scheduled task
const createNewScheduledTaskNote = async (token, newTaskNoteVals = {}) => {
	const rawModel = updateScheduledTaskNoteModel(newTaskNoteVals);
	const notesArray = [rawModel];
	const wasSaved = await saveScheduledTaskNotes(token, notesArray);

	return wasSaved;
};
// creates a new task note for an unscheduled task
const createNewUnscheduledTaskNote = async (token, newTaskNoteVals = {}) => {
	const rawModel = updateUnscheduledTaskNoteModel(newTaskNoteVals);
	const notesArray = [rawModel];
	const wasSaved = await saveUnscheduledTaskNotes(token, notesArray);

	return wasSaved;
};

// TASK NOTE PROCESSING UTILS //

// TASK NOTE MODEL UTILS //
const updateScheduledTaskNoteModel = (vals = {}) => {
	const base = new ScheduledTaskNoteModel(vals);
	return base.getModel();
};
const updateUnscheduledTaskNoteModel = (vals = {}) => {
	const base = new UnscheduledTaskNoteModel(vals);

	return base.getModel();
};

// creates truncated task note record for server, to be applied
const prepareStrikeOutUpdate = (clientTaskNote = {}, vals = {}) => {
	switch (true) {
		// scheduled
		case !isEmptyVal(clientTaskNote?.trackingID): {
			const {
				noteID,
				trackingID,
				taskID,
				isStrikeOut,
				strikeOutByUserId,
				strikeOutDate,
			} = vals;

			const newTaskNote = {
				AssessmentTrackingId: trackingID,
				AssessmentTrackingTaskId: taskID,
				AssessmentTrackingTaskNoteId: noteID,
				IsStrikeOut: isStrikeOut,
				StrikeOutByUserId: strikeOutByUserId,
				StrikeOutDate: strikeOutDate,
			};

			return newTaskNote;
		}
		// unscheduled
		case isEmptyVal(clientTaskNote?.trackingID): {
			const { noteID, taskID, isStrikeOut, strikeOutByUserId, strikeOutDate } =
				vals;

			const newTaskNote = {
				AssessmentUnscheduleTaskNoteId: noteID,
				AssessmentUnscheduleTaskId: taskID,
				IsStrikeOut: isStrikeOut,
				StrikeOutByUserId: strikeOutByUserId,
				StrikeOutDate: strikeOutDate,
			};

			return newTaskNote;
		}

		default:
			return;
	}
};
// creates truncated task note record for server, to be applied for deletion
const prepareVirtualDelete = (clientTaskNote) => {
	switch (true) {
		// scheduled
		case !isEmptyVal(clientTaskNote?.trackingID): {
			const { noteID, trackingID, taskID } = clientTaskNote;

			const newTaskNote = {
				AssessmentTrackingId: trackingID,
				AssessmentTrackingTaskId: taskID,
				AssessmentTrackingTaskNoteId: noteID,
				IsActive: false,
			};

			return newTaskNote;
		}
		// unscheduled
		case isEmptyVal(clientTaskNote?.trackingID): {
			const { noteID, taskID } = clientTaskNote;

			const newTaskNote = {
				AssessmentUnscheduleTaskNoteId: noteID,
				AssessmentUnscheduleTaskId: taskID,
				IsActive: false,
			};

			return newTaskNote;
		}

		default:
			return;
	}
};

/**
 * Applies updated values to task note model record. For existing notes
 * @param {Object} taskNote - A client-formatted task note record.
 * @param {Object} newVals - An object task note values to be applied
 * @returns {Object} - Returns updated task note model (server-formatted)
 */
const updateExistingTaskModel = (taskNote = {}, newVals = {}) => {
	switch (true) {
		case isScheduledTask(taskNote): {
			const noteModel = updateScheduledTaskNoteModel({
				...taskNote,
				...newVals,
			});
			return noteModel;
		}
		case isUnscheduledTask(taskNote): {
			const noteModel = updateUnscheduledTaskNoteModel({
				...taskNote,
				...newVals,
			});
			return noteModel;
		}

		default:
			return;
	}
};
/**
 * Applies updated note values to client & server models & returns them.
 * @param {Object} task - A server-formatted task record.
 * @param {Object} newVals - Object of updated note values.
 * @returns {Object} - Returns 'client' & 'server' data models w/ applied models
 */
const initAndUpdateExistingTaskNote = (task = {}, newVals = {}) => {
	const { client, server } = initAndUpdateNewTaskNote(task, newVals);

	return { client, server };
};

// SERVER-MODELS //

// CLIENT-MODELS //
// creates 'scheduled task note' model client-formatted
const initAndUpdateScheduledTaskNote = (task = {}, vals = {}) => {
	const trackingID = task?.AssessmentTrackingId ?? vals?.trackingID ?? 0;
	const taskID = task?.AssessmentTrackingTaskId ?? vals?.taskID ?? 0;

	const created = vals?.createdDate ?? new Date().toISOString();

	const newNote = {
		noteID: vals?.noteID ?? 0,
		trackingID: trackingID,
		taskID: taskID,
		notes: vals?.newNote ?? vals?.notes,
		entryDate: created,
		entryByUserId: vals?.entryByUserId,
		initialBy: vals?.initialBy,
		isActive: true,
		createdDate: created,
		createdBy: vals?.createdBy,
		isStrikeOut: vals?.isStrikeOut,
		strikeOutByUserId: vals?.strikeOutByUserId,
		strikeOutDate: vals?.strikeOutDate,
		// MAY NOT NEED THESE FIELDS???
		modifiedDate: created,
		modifiedBy: vals?.modifiedBy,
	};

	return { ...newNote };
};
// creates 'unscheduled task note' model client-formatted
const initAndUpdateUnscheduledTaskNote = (task = {}, vals = {}) => {
	// const { AssessmentUnscheduleTaskId: taskID } = task;
	const taskID = task?.AssessmentUnscheduleTaskId ?? vals?.taskID ?? 0;

	const created = vals?.createdDate ?? new Date().toISOString();

	const newNote = {
		noteID: 0,
		taskID: taskID,
		notes: vals?.newNote ?? vals?.notes,
		entryDate: created,
		entryByUserId: vals?.entryByUserId,
		initialBy: vals?.initialBy,
		isActive: true,
		createdDate: created,
		createdBy: vals?.createdBy,
		isStrikeOut: vals?.isStrikeOut,
		strikeOutByUserId: vals?.strikeOutByUserId,
		strikeOutDate: vals?.strikeOutDate,
		// MAY NOT NEED THESE FIELDS???
		modifiedDate: created,
		modifiedBy: vals?.modifiedBy,
	};

	return { ...newNote };
};

// creates client & server formatted task note model(s)
const initAndUpdateNewTaskNote = (task = {}, vals = {}) => {
	switch (true) {
		case isScheduledTask(task): {
			const clientNote = initAndUpdateScheduledTaskNote(task, vals);
			const serverNote = updateScheduledTaskNoteModel(vals);

			return {
				client: clientNote,
				server: serverNote,
			};
		}

		case isUnscheduledTask(task): {
			const clientNote = initAndUpdateUnscheduledTaskNote(task, vals);
			const serverNote = updateUnscheduledTaskNoteModel(vals);
			return {
				client: clientNote,
				server: serverNote,
			};
		}

		default:
			return { client: null, server: null };
	}
};

////////////////////////////////////////////////////////////////////////
////////////////////// TASK NOTE PROCESSING UTILS //////////////////////
////////////////////////////////////////////////////////////////////////

/**
 * Checks whether 'EntryDate' & 'ModifiedDate' are different and if task has been 'striked'
 * - Converts both dates to string form (eg. 'M/D/YYYY h:mm A') & compares them
 * - Then checks whether note was struck-out
 * @param {Object} taskNote - Task note entry either client-formatted or server record
 * @returns {Boolean} - Returns true|false
 */
const wasNoteEdited = (taskNote = {}) => {
	// was struck-through
	const wasStruck = wasNoteStrikedOut(taskNote);

	return wasStruck;
};
const wasNoteStrikedOut = (taskNote = {}) => {
	const isStrikeOut = taskNote?.isStrikeOut ?? taskNote?.IsStrikeOut;

	return isStrikeOut;
};

const getTaskNoteUserInitials = (taskNote) => {
	const initialBy = taskNote?.initialBy ?? taskNote?.InitialBy;
	const names = initialBy?.split(" ");
	const firstName = names?.[0] ?? "U";
	const lastName = names?.[1] ?? "N";
	const first = firstName.slice(0, 1);
	const last = lastName.slice(0, 1);

	return `${first?.toUpperCase()}${last?.toUpperCase()}`;
};
const getTaskNoteUserName = (taskNote) => {
	const initialBy = taskNote?.initialBy ?? taskNote?.InitialBy;

	return initialBy;
};
// gets edited date & formats as 'M/D/YYYY h:mm A'
const getTaskNoteEditedDate = (taskNote) => {
	const modifiedDate = taskNote?.modifiedDate ?? taskNote?.ModifiedDate;

	return format(modifiedDate, "M/D/YYYY h:mm A");
};
const getWasEditedText = (taskNote) => {
	const wasEdited = wasNoteEdited(taskNote);
	const wasStruck = wasNoteStrikedOut(taskNote);

	switch (true) {
		case wasEdited && !wasStruck: {
			return `Edited `;
		}
		case wasEdited && wasStruck: {
			return `Striked-Out `;
		}

		default:
			return ``;
	}
};
const getTaskNoteStrikeOutDate = (taskNote) => {
	const strikeOutDate = taskNote?.strikeOutDate ?? taskNote?.StrikeOutDate;
	const cleanDate = format(strikeOutDate, "M/D/YYYY h:mm:ss A");
	return cleanDate;
};
// returns strike-out by userID
const getTaskNoteStrikeOutBy = (taskNote) => {
	const strikedBy = taskNote?.strikeOutByUserId ?? taskNote?.StrikeOutByUserId;

	return strikedBy;
};
const getTaskNoteCreatedDateInWords = (taskNote) => {
	const createdDate = taskNote?.createdDate ?? taskNote?.CreatedDate;
	const dateInWords = formatDateInWordsToNow(createdDate);

	return `${dateInWords} ago`;
};
const isTaskNoteEditable = (currentUser) => {
	const adminType = isAdminLevel(currentUser);

	return adminType;
};
const getNotesCreatedDate = (taskNote) => {
	const createdDate = taskNote?.createdDate ?? taskNote?.CreatedDate;
	const formatted = format(createdDate, "M/D/YYYY h:mm:ss A");

	return formatted;
};
// processing
// const getNewTaskNotesMap = (tasks = [], )

// formats 'Scheduled Task Note' record for the client
const processScheduledTaskNote = (scheduledTaskNote = {}) => {
	const {
		AssessmentTrackingTaskNoteId: noteID,
		AssessmentTrackingId: trackingID,
		AssessmentTrackingTaskId: taskID,
		Notes: notes,
		EntryDate: entryDate,
		EntryUserId: entryByUserId,
		InitialBy: initialBy,
		IsActive: isActive,
		CreatedDate: createdDate,
		CreatedBy: createdBy,
		ModifiedBy: modifiedByUserId,
		ModifiedDate: modifiedDate,
		IsStrikeOut: isStrikeOut,
		StrikeOutByUserId: strikeOutByUserId,
		StrikeOutDate: strikeOutDate,
	} = scheduledTaskNote;

	const clientNote = {
		noteID,
		trackingID,
		taskID,
		notes,
		entryDate,
		entryByUserId,
		initialBy,
		isActive,
		createdDate,
		createdBy,
		modifiedByUserId,
		modifiedDate,
		isStrikeOut,
		strikeOutByUserId,
		strikeOutDate,
	};

	return { ...clientNote };
};
// formats 'Unscheduled Task Note' record for the client
const processUnscheduledTaskNote = (unscheduledTaskNote = {}) => {
	const {
		AssessmentUnscheduleTaskNoteId: noteID,
		AssessmentUnscheduleTaskId: taskID,
		Notes: notes,
		EntryDate: entryDate,
		EntryUserId: entryByUserId,
		InitialBy: initialBy,
		IsActive: isActive,
		CreatedDate: createdDate,
		CreatedBy: createdBy,
		ModifiedBy: modifiedByUserId,
		ModifiedDate: modifiedDate,
		IsStrikeOut: isStrikeOut,
		StrikeOutByUserId: strikeOutByUserId,
		StrikeOutDate: strikeOutDate,
	} = unscheduledTaskNote;

	const clientNote = {
		noteID,
		taskID,
		notes,
		entryDate,
		entryByUserId,
		initialBy,
		isActive,
		createdDate,
		createdBy,
		modifiedByUserId,
		modifiedDate,
		isStrikeOut,
		strikeOutByUserId,
		strikeOutDate,
	};

	return { ...clientNote };
};
// determines whether task note is scheduled/unscheduled & processes/formats it
const processTaskNote = (taskNote = {}) => {
	switch (true) {
		case isScheduledTask(taskNote): {
			const clientNote = processScheduledTaskNote(taskNote);
			return clientNote;
		}
		case isUnscheduledTask(taskNote): {
			const clientNote = processUnscheduledTaskNote(taskNote);
			return clientNote;
		}
		default:
			return;
	}
};
// processes all task notes for a given task into client-format
const processTaskNotes = (allTaskNotes = []) => {
	if (isEmptyArray(allTaskNotes)) return [];
	const clientNotes = [...allTaskNotes].map((note) => processTaskNote(note));

	return clientNotes;
};

// processes and sorts all task notes & purges virtual deleted notes
const processAndSortTaskNotes = (sortKey = "entryDate", allTaskNotes = []) => {
	if (isEmptyArray(allTaskNotes)) return [];
	const clientNotes = [...allTaskNotes].map((note) => processTaskNote(note));
	const sortedNotes = sortDateAscByKey(sortKey, clientNotes);
	// remove inactive
	const purgedNotes = sortedNotes.filter((note) => note?.isActive);
	console.log("purgedNotes", purgedNotes);

	return sortedNotes;
};

// TASK NOTES FOR REPORTS UTILS //

// taskNotes: scheduled and unscheduled merged together
const generateTaskNotesMapByTaskID = (
	scheduledNotes = [],
	unscheduledNotes = []
) => {
	const groupedScheduled = groupBy(scheduledNotes, (x) => x[SCHEDULED_ID]);
	const groupedUnscheduled = groupBy(
		unscheduledNotes,
		(x) => x[UNSCHEDULED_ID]
	);

	return {
		scheduledNotes: groupedScheduled,
		unscheduledNotes: groupedUnscheduled,
	};
};

export {
	// fetch task notes
	getScheduledTaskNotesByTaskID,
	getUnscheduledTaskNotesByTaskID,
	getTaskNotesByTaskID,
	// save task notes
	saveTaskNotes,
	saveScheduledTaskNotes,
	saveUnscheduledTaskNotes,
	// delete a task note
	deleteTaskNote,
	deleteScheduledTaskNote,
	deleteUnscheduledTaskNote,
	// virtual delete a task note
	virtualDeleteTaskNote,
	virtualDeleteScheduledTaskNote,
	virtualDeleteUnscheduledTaskNote,
};

export { createNewScheduledTaskNote, createNewUnscheduledTaskNote };

export {
	// NEW NOTES MODEL UPDATERS
	initAndUpdateScheduledTaskNote,
	initAndUpdateUnscheduledTaskNote,
	initAndUpdateNewTaskNote,
	// EXISTING NOTES MODEL UPDATERS
	initAndUpdateExistingTaskNote,
	updateScheduledTaskNoteModel,
	updateUnscheduledTaskNoteModel,
	updateExistingTaskModel,
	prepareStrikeOutUpdate,
	prepareVirtualDelete,
};

export {
	wasNoteEdited,
	wasNoteStrikedOut,
	isTaskNoteEditable,
	getWasEditedText,
	getNotesCreatedDate,
	getTaskNoteUserName,
	getTaskNoteEditedDate,
	getTaskNoteUserInitials,
	getTaskNoteStrikeOutDate,
	getTaskNoteStrikeOutBy,
	getTaskNoteCreatedDateInWords,
};

// TASK NOTE PROCESSING UTILS //
export {
	processScheduledTaskNote,
	processUnscheduledTaskNote,
	processTaskNote,
	processTaskNotes,
	processAndSortTaskNotes,
};

// TASK NOTES REPORTS UTILS //
export { generateTaskNotesMapByTaskID };
