import React, { useState } from "react";
import { PropTypes } from "prop-types";
import FormSection from "../forms/FormSection";
import Divider from "../forms/Divider";
import CustomDropdown from "../shared/CustomDropdown";

/**
 * Purpose: handles the 'reportType' value (ie 'Daily', 'Historical').
 */

const ReportTypePicker = ({
	name = `reportType`,
	selection,
	setSelection,
	options = [`Daily`, `Historical`],
	placeholder = `Select report type...`,
}) => {
	return (
		<>
			<FormSection
				icon="lightbulb"
				title="Choose the Report Type"
				hoverText="Is the report for today or a date range?"
			>
				<CustomDropdown
					name={name}
					id={name}
					selection={selection}
					setSelection={setSelection}
					options={options}
					placeholder={placeholder}
				/>
			</FormSection>
			<Divider />
		</>
	);
};

export default ReportTypePicker;

ReportTypePicker.defaultProps = {
	name: `reportType`,
	options: [`Daily`, `Historical`],
	placeholder: `Select report type...`,
};

ReportTypePicker.propTypes = {
	name: PropTypes.string,
	selection: PropTypes.string,
	setSelection: PropTypes.func,
	options: PropTypes.array,
	placeholder: PropTypes.string,
};
