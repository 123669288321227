import React from "react";
import styles from "../../css/calendar/CalendarHeading.module.scss";
import { PropTypes } from "prop-types";
import { format, isToday } from "date-fns";
import { checkForSameMonth } from "../../helpers/utils_dates";

// uses the endOfMonth val and compares to the current day in the array
const sameMonthStyles = (day, currentMonthToMatch) => {
	if (checkForSameMonth(day, currentMonthToMatch)) {
		return isToday(day)
			? styles.CalendarHeading_headings_item_isToday
			: styles.CalendarHeading_headings_item;
	} else {
		return styles.CalendarHeading_headings_item_diffMonth;
	}
};

const getTodayDateStyles = (day) => {
	if (isToday(day)) {
		return styles.CalendarHeading_headings_item_date_isToday;
	} else {
		return styles.CalendarHeading_headings_item_date;
	}
};
const getTodayDayStyles = (day) => {
	if (isToday(day)) {
		return styles.CalendarHeading_headings_item_day_isToday;
	} else {
		return styles.CalendarHeading_headings_item_day;
	}
};

const CalendarHeading = ({ currentWeek, currentMonth }) => {
	// adds custom styles to days that are NOT in the most "visible" month (ie month w/ most visible days)
	const renderWeekdays = (currentDays) => {
		return currentDays.map((day, index) => {
			return (
				<div
					className={sameMonthStyles(day, currentMonth.monthEnd)}
					key={`${format(day, "dddd")}_${index}`}
				>
					<span
						className={getTodayDateStyles(day)}
						key={`${format(day, "DDDD")}_${index}`}
					>
						{format(day, "D")}
					</span>
					<span
						className={getTodayDayStyles(day)}
						key={`${format(day, "DDDo")}_${index}`}
					>
						{format(day, "ddd")}
					</span>
				</div>
			);
		});
	};

	return (
		<header className={styles.CalendarHeading}>
			<section className={styles.CalendarHeading_headings}>
				<div className={styles.CalendarHeading_headings_category}>ADL</div>
				{renderWeekdays(currentWeek.days)}
			</section>
		</header>
	);
};

export default CalendarHeading;

CalendarHeading.defaultProps = {};

CalendarHeading.propTypes = {
	currentWeek: PropTypes.shape({
		weekStart: PropTypes.instanceOf(Date).isRequired,
		weekEnd: PropTypes.instanceOf(Date).isRequired,
		days: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
	}).isRequired,
	currentMonth: PropTypes.shape({
		monthStart: PropTypes.instanceOf(Date).isRequired,
		monthEnd: PropTypes.instanceOf(Date).isRequired,
		days: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
	}).isRequired,
};
