import React, { useContext, useEffect } from "react";
import styles from "../../css/app/AuthenticatedView.module.scss";
import { PropTypes } from "prop-types";
import { AuthContext } from "../../state/AuthContext";
import { GlobalStateContext } from "../../state/GlobalStateContext";
import {
	getInitialResource,
	syncResourceToState,
} from "../../helpers/utils_initialResources";
import { hasSession } from "../../helpers/utils_auth";
import { sendPageTracking } from "../../helpers/utils_tracking";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import Navbar from "../../components/app/Navbar";
import DashboardNav from "../../components/dashboard/DashboardNav";
import HelpLegend from "../../components/app/HelpLegend";
import Sidetab from "../../components/app/Sidetab";
import Dashboard from "../../components/dashboard/Dashboard";

// ##TODOS:
// - Implement helper to check IF 'state' is populate ✓
// - Implement helper to re-fetch resources, upon page refresh ✓
// - Create logic to determine IF data needs to be re-fetched ✓
// AUTH STATE TODOS: ✓
// - Wire up 'onMount' requests that fetch "initial resources" from url params ✓
// 		- Extract URL params: ✓
//	 		- Extract 'facilityID' ✓
// 			- Extract 'residentID' ✓
// 			- Extract 'securityToken' ✓
// 		- Fetch "pre-resource" data: ✓
// 			- Fetch 'getSessionDetails()' ✓
// 		- Then invoke standard 'getInitialResources()' ✓

const AuthenticatedView = ({ history }) => {
	const { authData, setAuthData } = useContext(AuthContext);
	const { state, dispatch } = useContext(GlobalStateContext);
	const { user, app } = state;

	// fetches facilities (by user); ALL residents; user profile upon authentication
	const handleInitialResource = async () => {
		dispatch({ type: "LOADING" }); // TEST ADDITION - REMOVE LATER IF NEEDED!!!!
		const resource = await getInitialResource(authData); // REFACTORED ON 4/1/2021
		return syncResourceToState({ ...resource, authData }, state, dispatch);
	};

	// fetch residents/facilities on mount
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		handleInitialResource();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// handles re-fetching 'initialResource' upon page-refresh or re-mount
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		const rehydrateResource = async (authData) => {
			const resource = await getInitialResource(authData);
			return syncResourceToState({ ...resource, authData }, state, dispatch);
		};

		if (hasSession(app, authData)) {
			rehydrateResource(authData);
		}

		return () => {
			isMounted = false;
		};
	}, [app, authData, dispatch, state]);

	// log user, upon authentication
	// logs username & timestamp, at time of login
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		sendPageTracking(authData.username, {
			pagename: `AuthenticatedView`,
			path: `/dashboard/daily`,
		});

		return () => {
			isMounted = false;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={styles.AuthenticatedView}>
				<Navbar
					resetAuth={setAuthData}
					currentUser={state.user}
					dispatch={dispatch}
					hasLoadedFacility={
						!isEmptyVal(state?.globals.currentFacility?.facilityID)
					}
				/>

				<section className={styles.AuthenticatedView_nav}>
					{/* NEEDS TO BE DEBUGGED!!! (ie. when to show/hide buttons) */}
					{/* <DashboardNav backLabel="Back to Reports" /> */}
				</section>

				<section className={styles.AuthenticatedView_dashboard}>
					<main className={styles.AuthenticatedView_dashboard_main}>
						<Dashboard />
					</main>
				</section>
			</div>

			{/* FLOATING CONTROLS */}
			<Sidetab currentUser={user} />
			<HelpLegend />
		</>
	);
};

export default AuthenticatedView;

AuthenticatedView.defaultProps = {};

AuthenticatedView.propTypes = {
	history: PropTypes.object.isRequired,
};
