import React, { useState, useContext, useEffect, useCallback } from "react";
import { PropTypes } from "prop-types";
import styles from "../../css/views/AdlScheduleView.module.scss";
import * as Sentry from "@sentry/react";
import { useAlerts, initialSettings } from "../../utils/useAlerts";
import { GlobalStateContext } from "../../state/GlobalStateContext";
import {
	getResidentStrFromDaily,
	getFacilityStrFromDaily,
} from "./CalendarView";
import {
	getFacilityCategories, // 'AssessmentCategories'
	matchFacilityByName,
	getAdlShiftsByFacilityID,
} from "../../helpers/utils_facility";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { getShiftTimes } from "../../helpers/utils_shifts";
// COMPONENTS
import AdlScheduleSettings from "../../components/adlschedule/AdlScheduleSettings";
import ALASelector from "../../components/app/ALASelector";
import ErrorDialog from "../../components/errors/ErrorDialog";

const adlIdx = 0;
const adlCount = 15; // previously: 50

const showAdlShiftSettings = (selectedFacility, adlData) => {
	if (isEmptyVal(selectedFacility)) return false;
	if (isEmptyArray(adlData.adls)) return false;
	if (isEmptyArray(adlData.shifts)) return false;
	return true;
};

const AdlScheduleView = () => {
	const { state, dispatch } = useContext(GlobalStateContext);
	const { globals, user } = state;
	const { facilities } = user;
	const { currentResident, currentFacility, residents, residentsByFacility } =
		globals;
	const { shifts: facilityShifts } = currentFacility;
	const { AlertsHandler, dispatchAlert } = useAlerts({ ...initialSettings });

	const [selectedFacility, setSelectedFacility] = useState(
		getFacilityStrFromDaily(currentFacility)
	);
	const [selectedResident, setSelectedResident] = useState(
		getResidentStrFromDaily(currentResident)
	);
	const [isLoadingSettings, setIsLoadingSettings] = useState(false);
	const [adlData, setAdlData] = useState({
		adls: [],
		shifts: [],
	});

	const loadAdlsAndShifts = async () => {
		setIsLoadingSettings(true);
		return await getAdlsAndShifts();
	};

	const getAdlsAndShifts = async () => {
		if (isEmptyVal(selectedFacility)) return [];
		const { token } = user;
		const { facilityID } = currentFacility;
		// ??? create a request that fetches by facility!!!
		const adls = await getFacilityCategories(token, adlIdx, adlCount);
		const shifts = getAdlShiftsByFacilityID(facilityID, facilities);

		if (!isEmptyArray(adls)) {
			setIsLoadingSettings(false);
			return setAdlData({
				adls,
				shifts,
			});
		} else {
			setIsLoadingSettings(false);
			return setAdlData({
				adls: [],
				shifts: [],
			});
		}
	};

	// only used for dispatching an alert
	// global store is updated from side-effect (ie 'useEffect')
	const changeFacility = (selectedVal) => {
		// not needed; facility is loaded via 'setFacility'
		loadAdlsAndShifts();
		return dispatchAlert("info", {
			heading: `Changed Facility`,
			subheading: `Loaded ${selectedVal}.`,
		});
	};

	// ##TODOS:
	// - Look into where 'shifts' are being pulled from

	// fetches & sets adls and adl shift records
	const setAdlsAndShifts = useCallback(async () => {
		const { token, facilityID: facilityIDFromUser } = user;
		const facID = currentFacility?.facilityID ?? facilityIDFromUser;

		setIsLoadingSettings(true);
		// fetch both in parallel
		const [adlCategories, adlShifts] = await Promise.all([
			// getFacilityCategories(token, 0, 50),
			getFacilityCategories(token, adlIdx, adlCount),
			getShiftTimes(token, facID),
		]);

		// checks if 'currentFacility.shifts' exists;
		// if NOT, then falls back to fetched 'adlShifts'
		if (!isEmptyArray(adlCategories)) {
			setIsLoadingSettings(false);
			return setAdlData({
				adls: adlCategories.filter((x) => x.Name !== "All"),
				shifts: isEmptyArray(facilityShifts) ? adlShifts : facilityShifts, // adlShifts
			});
		} else {
			setIsLoadingSettings(false);
			return setAdlData({
				adls: [],
				shifts: [],
			});
		}
	}, [currentFacility, facilityShifts, user]);

	// sets 'currentFacility' obj when 'selectedResident' changes
	const setFacility = useCallback(() => {
		if (isEmptyVal(selectedFacility)) return;
		const facilityRecord = matchFacilityByName(selectedFacility, facilities);
		const facilityResidents = residentsByFacility[facilityRecord.FacilityId];

		dispatch({
			type: "SET_FACILITY",
			data: {
				facilityRecord: facilityRecord,
				facilityResidents: facilityResidents,
			},
		});
	}, [dispatch, facilities, residentsByFacility, selectedFacility]);

	// fetches 'facility' categories(ie adls), not by resident
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (!isEmptyVal(currentFacility?.facilityID)) {
			setAdlsAndShifts();
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// sets 'currentFacility' in global store when it changes
	// side-effect 'watcher'
	useEffect(() => {
		setFacility();
	}, [selectedFacility, setFacility]);

	return (
		<>
			<Sentry.ErrorBoundary fallback={ErrorDialog}>
				<ALASelector
					key={`ADL-SCHEDULE-SELECTOR`}
					isAdmin={user?.isAdmin}
					disableResident={true}
					facilities={facilities}
					residents={residents}
					syncFacility={setSelectedFacility}
					syncResident={setSelectedResident}
					defaultFacility={selectedFacility}
					defaultResident={selectedResident}
					// loadFacility={loadAdlsAndShifts}
					loadFacility={changeFacility}
				/>
				<br style={{ margin: "2rem 0" }} />
				<div className={styles.AdlScheduleView}>
					<div className={styles.AdlScheduleView_title}>ADL Shift Settings</div>
					<p className={styles.AdlScheduleView_desc}>
						Choose which shifts you'd like a resident's tasks to be scheduled
						for.
					</p>
				</div>
				<br style={{ margin: "2rem 0" }} />

				<AdlScheduleSettings
					categories={adlData.adls}
					shifts={adlData.shifts}
					residents={residents}
					isLoadingSettings={isLoadingSettings}
					currentUser={user}
					currentResident={currentResident}
					currentFacility={currentFacility}
					showSettings={showAdlShiftSettings(selectedFacility, adlData)}
				/>

				{/* ALERTS UI */}
				{AlertsHandler}
			</Sentry.ErrorBoundary>
		</>
	);
};

export default AdlScheduleView;

AdlScheduleView.defaultProps = {};

AdlScheduleView.propTypes = {};
