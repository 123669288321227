import React, { useState } from "react";
import styles from "../../css/reportsviewer/ReportViewerTableActionsPanel.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";

const ReportViewerTableActionsPanel = ({
	title = `Actions`,
	vals = {},
	searchName = `searchTable`,
	searchPlaceholder = `Search by last name...`,
	handleSearch,
}) => {
	const [showActions, setShowActions] = useState(false);

	return (
		<section className={styles.ReportViewerTableActionsPanel}>
			<div className={styles.ReportViewerTableActionsPanel_top}>
				{showActions && (
					<span className={styles.ReportViewerTableActionsPanel_top_title}>
						{title}
					</span>
				)}
				<div className={styles.ReportViewerTableActionsPanel_top_settings}>
					<button
						className={styles.ReportViewerTableActionsPanel_top_settings_btn}
						onClick={() => setShowActions(!showActions)}
					>
						{showActions ? "Hide" : "Show"} Actions
					</button>
				</div>
			</div>
			{showActions && (
				<div className={styles.ReportViewerTableActionsPanel_inner}>
					<div className={styles.ReportViewerTableActionsPanel_inner_search}>
						<svg
							className={styles.ReportViewerTableActionsPanel_inner_search_icon}
						>
							<use xlinkHref={`${sprite}#icon-search2`}></use>
						</svg>
						<input
							type="text"
							name={searchName}
							id={searchName}
							value={vals[searchName]}
							onChange={handleSearch}
							placeholder={searchPlaceholder}
							className={
								styles.ReportViewerTableActionsPanel_inner_search_input
							}
							autoComplete="off"
						/>
					</div>
				</div>
			)}
		</section>
	);
};

export default ReportViewerTableActionsPanel;

ReportViewerTableActionsPanel.defaultProps = {
	title: `Actions`,
	vals: {},
	searchName: `searchTable`,
	searchPlaceholder: `Search...`,
};

ReportViewerTableActionsPanel.propTypes = {
	title: PropTypes.string,
	vals: PropTypes.object,
	searchName: PropTypes.string,
	searchPlaceholder: PropTypes.string,
	handleSearch: PropTypes.func,
};
