import React, { useState } from "react";
import styles from "../../css/daily/DailySummaryCardHeading.module.scss";
import adlSprite from "../../assets/icons/adlIcons.svg";
import { PropTypes } from "prop-types";
import { checkCategoryNaming } from "../../helpers/utils_categories";
import { replaceNullWithMsg } from "../../helpers/utils_processing";
import { format } from "date-fns";
import { getIconName } from "../../helpers/utils_styles";
// components
import ModalSM from "../shared/ModalSM";
import ADLNotes from "./ADLNotes";

// TO REPLACE DailySummaryCard's "top" section

const DailySummaryCardHeading = ({ category, day, isCompleted = false }) => {
	const [showADLNotes, setShowADLNotes] = useState(false);
	const [newNotes, setNewNotes] = useState("");

	// not in use!!!
	const addNewNotes = (e) => {
		e.preventDefault();
		const { value } = e.target;
		setNewNotes(value);
	};
	// not in use!!!
	const saveNewNotes = (e) => {
		e.preventDefault();
	};
	// not in use!!!
	const cancelNewNotes = (e) => {
		e.preventDefault();
		setShowADLNotes(false);
	};

	return (
		<>
			<section className={styles.DailySummaryCardHeading}>
				<div className={styles.DailySummaryCardHeading_adl}>
					<svg
						className={styles.DailySummaryCardHeading_adl_icon}
						// style={getIconCSS(category)}
					>
						<use xlinkHref={`${adlSprite}#icon-${getIconName(category)}`}></use>
					</svg>
				</div>
				<div className={styles.DailySummaryCardHeading_heading}>
					<div className={styles.DailySummaryCardHeading_heading_adl}>
						{checkCategoryNaming(category.AdlCategoryType)}
					</div>
					<time className={styles.DailySummaryCardHeading_heading_title}>
						{format(day, "MMM. Do, YYYY")}
					</time>
				</div>
				<div
					className={styles.DailySummaryCardHeading_notes}
					onClick={() => setShowADLNotes(true)}
				>
					View ADL Notes
				</div>

				{isCompleted && (
					<aside className={styles.DailySummaryCardHeading_completedBadge}>
						<span>All Complete!</span>
					</aside>
				)}
			</section>

			{showADLNotes && (
				<ModalSM title="ADL Notes" closeModal={() => setShowADLNotes(false)}>
					<ADLNotes
						adl={category}
						handleChange={addNewNotes}
						readOnlyNotes={replaceNullWithMsg(
							category.AdlNotes,
							"No notes found."
						)}
						newNotes={newNotes}
						saveNewNotes={saveNewNotes}
						cancelNewNotes={cancelNewNotes}
					/>
				</ModalSM>
			)}
		</>
	);
};

export default DailySummaryCardHeading;

DailySummaryCardHeading.defaultProps = {
	isCompleted: false,
};

DailySummaryCardHeading.propTypes = {
	category: PropTypes.object.isRequired,
	day: PropTypes.instanceOf(Date).isRequired,
	isCompleted: PropTypes.bool.isRequired,
};
