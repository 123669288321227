import React from "react";
import styles from "../../css/shared/CustomCheckbox.module.scss";
import { PropTypes } from "prop-types";

const CustomCheckbox = ({
	label,
	name,
	id,
	val,
	isDisabled = false,
	handleCheckbox,
}) => {
	return (
		<div className={styles.CustomCheckbox}>
			<input
				type="checkbox"
				name={name}
				id={id}
				checked={val}
				onChange={handleCheckbox}
				disabled={isDisabled}
				className={styles.CustomCheckbox_checkbox}
			/>
			<label htmlFor={id} className={styles.CustomCheckbox_label}>
				{label}
			</label>
		</div>
	);
};

export default CustomCheckbox;

CustomCheckbox.defaultProps = {
	isDisabled: false,
};

CustomCheckbox.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	val: PropTypes.bool.isRequired,
	isDisabled: PropTypes.bool,
	handleCheckbox: PropTypes.func.isRequired,
};
