import React from "react";
import styles from "../../css/notes/TaskNotesList.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import { isTaskNoteEditable } from "../../helpers/utils_notes";
import TaskNotesEntry from "./TaskNotesEntry";

const TaskNotesList = ({
	task = {},
	taskNotes = [],
	currentUser = {},
	deleteTaskNote,
}) => {
	return (
		<div className={styles.TaskNotesList}>
			<div className={styles.TaskNotesList_inner}>
				{!isEmptyArray(taskNotes) &&
					taskNotes.map((note, idx) => (
						<TaskNotesEntry
							key={`EXISTING-NOTE-${note?.noteID}-${idx}`}
							task={task}
							taskNote={note}
							isEditable={isTaskNoteEditable(currentUser)}
							currentUser={currentUser}
							deleteTaskNote={() => deleteTaskNote(note)}
						/>
					))}
			</div>
		</div>
	);
};

export default TaskNotesList;

TaskNotesList.defaultProps = {};

TaskNotesList.propTypes = {};
