import React, { useState, useEffect, useContext, useCallback } from "react";
import styles from "../../css/views/CalendarView.module.scss";
import {
	isEmptyObj,
	isEmptyVal,
	isEmptyArray,
} from "../../helpers/utils_types";
import { GlobalStateContext } from "../../state/GlobalStateContext";
import {
	formatResidentOnly,
	getResidentID,
	matchResidentByID,
} from "../../helpers/utils_residents";
import { matchFacilityByName } from "../../helpers/utils_facility";
import { fetchWeeklyTasks } from "../../helpers/utils_residentData";
import { sendPageTracking } from "../../helpers/utils_tracking";
// components
import Calendar from "../../components/calendar/Calendar";
import ALASelector from "../../components/app/ALASelector";
import CommunityShifts from "../../components/daily/CommunityShifts";
import { featureFlags } from "../../helpers/utils_features";

// REQUIREMENTS:
// 1. CONSIDER INITIALIZING GLOBAL STATE CONTEXT HERE INSTEAD OF IN THE <Calendar/>
// 2. USE "currentResident" FROM GLOBAL STATE, ALONG W/ SAME <ResidentDropdown/>

// - Updated 2/11/2021 at 10:01 AM
// - Disabled 'CommunityInfo' via feature flag until 'shifts' is resolved
const renderCommunityInfo = (isEnabled = false, data = {}) => {
	const { currentFacility, facilityVal, isAdmin } = data;
	// disabled via 'FEATURE FLAGS'
	if (!isEnabled) return null;

	if (
		isEmptyVal(currentFacility?.communityName) ||
		(isAdmin && isEmptyVal(facilityVal))
	) {
		return null;
	}
	return <CommunityShifts communityInfo={currentFacility} />;
};

const getSelectedResidentID = (residentFromStore, localResident) => {
	const { ResidentID } = residentFromStore;
	const residentID = getResidentID(localResident);
	const storeID = Number(ResidentID);
	const localID = Number(residentID);
	if (isNaN(localID)) return storeID;
	if (!isEmptyVal(localID) && localID !== storeID) {
		return localID;
	}
	if (localID === storeID) {
		return localID;
	}
};

const getResidentFromDailyView = (currentResident) => {
	if (!isEmptyObj(currentResident) && !isEmptyVal(currentResident.ResidentID)) {
		return currentResident;
	} else {
		return {};
	}
};

export const getResidentStrFromDaily = (globalResident) => {
	if (isEmptyObj(globalResident)) return "";
	return formatResidentOnly(globalResident);
};

export const getFacilityStrFromDaily = (globalFacility) => {
	if (isEmptyObj(globalFacility)) return "";
	return globalFacility.communityName;
};

const CalendarView = () => {
	const {
		state: { user, globals },
		dispatch,
	} = useContext(GlobalStateContext);
	const {
		currentResident: residentFromState,
		categories,
		currentFacility,
		residents: allResidents,
		residentsByFacility,
	} = globals;
	const { facilities } = user;
	// const { residents } = currentFacility;

	const [localResident, setLocalResident] = useState(
		getResidentStrFromDaily(residentFromState)
	);
	const [localFacility, setLocalFacility] = useState(
		getFacilityStrFromDaily(currentFacility)
	);
	const [adls, setAdls] = useState();
	const [currentResident, setCurrentResident] = useState(
		getResidentFromDailyView(residentFromState)
	);
	// component-specific local state
	const [weeklyTasks, setWeeklyTasks] = useState({
		lastWeek: [],
		currentWeek: [],
		nextWeek: [],
	}); // consider an object with lastWeek, currentWeek, nextWeek inside...
	const [isLoading, setIsLoading] = useState(false);

	// find resident record, set local/global state, fetch weekly dataset
	const loadResident = async (selectedRes) => {
		const residentID = getResidentID(selectedRes);
		const residentRecord = matchResidentByID(residentID, allResidents);

		setCurrentResident(residentRecord);
		dispatch({
			type: "LOAD_RESIDENT_FROM_CALENDAR",
			data: {
				residentRecord: residentRecord,
			},
		});

		return getWeeklyTasks();
	};

	// fetches from GetResidentWeekXXXX
	const getWeeklyTasks = async () => {
		setIsLoading(true);

		const { token } = user;
		const residentID = getSelectedResidentID(currentResident, localResident);
		const weeklyData = await fetchWeeklyTasks(token, residentID, 0);

		// checks if tasks were fetched
		if (!isEmptyArray(weeklyData.scheduledTasks)) {
			setIsLoading(false);
			setAdls([...weeklyData.categories]);
			return setWeeklyTasks({
				lastWeek: [...weeklyData.scheduledTasksHistory],
				currentWeek: [
					...weeklyData.scheduledTasks,
					...weeklyData.unscheduledTasks,
				],
				nextWeek: [...weeklyData.scheduledTasksFuture],
			});
		} else {
			// handle failure case here???
			return setIsLoading(false);
		}
	};

	// sets 'currentFacility' obj when 'selectedResident' changes
	const setFacility = useCallback(() => {
		if (isEmptyVal(localFacility)) return;
		const facilityRecord = matchFacilityByName(localFacility, facilities);
		const facilityResidents = residentsByFacility[facilityRecord.FacilityId];

		dispatch({
			type: "SET_FACILITY",
			data: {
				facilityRecord: facilityRecord,
				facilityResidents: facilityResidents,
			},
		});
	}, [dispatch, facilities, localFacility, residentsByFacility]);

	// sets 'currentFacility' in global store when it changes
	useEffect(() => {
		setFacility();
	}, [setFacility]);

	// fetch current week's tasks "onMount"
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (!isEmptyVal(localResident)) {
			// runs "onMount", fetches calendar resources
			getWeeklyTasks();
		}
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// sends tracking to Sentry.io
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		sendPageTracking(user?.username, {
			pagename: `CalendarView`,
			path: `/dashboard/calendar`,
		});
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<article className={styles.CalendarView}>
			{/* COMMUNITY SHIFTS */}
			<header className={styles.CalendarView_shifts}>
				{renderCommunityInfo(featureFlags?.enableCommunityInfo, {
					currentFacility: currentFacility,
					facilityVal: localFacility,
					isAdmin: user?.isAdmin,
				})}
			</header>
			<section className={styles.CalendarView_header}>
				<ALASelector
					key={`CALENDARVIEW-SELECTOR`}
					isAdmin={user.isAdmin}
					facilities={facilities}
					residents={allResidents}
					defaultFacility={currentFacility?.communityName}
					defaultResident={formatResidentOnly(residentFromState)}
					syncFacility={setLocalFacility}
					syncResident={setLocalResident}
					loadResident={loadResident}
				/>
			</section>
			<section className={styles.CalendarView_panel}>
				<div className={styles.CalendarView_panel_heading}>
					<h2 className={styles.CalendarView_panel_heading_title}>
						<b>Calendar:</b> Weekly Tasks for a Resident
					</h2>
				</div>
			</section>
			<section className={styles.CalendarView_calendar}>
				<Calendar
					categories={!isEmptyArray(categories) ? categories : adls}
					currentUser={user}
					currentResident={currentResident}
					currentFacility={currentFacility}
					weeklyTasks={weeklyTasks}
					isLoading={isLoading}
				/>
			</section>
		</article>
	);
};

export default CalendarView;

CalendarView.defaultProps = {};

CalendarView.propTypes = {};
