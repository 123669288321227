import React from "react";
import styles from "../../css/reportpicker/ReportShiftPicker.module.scss";
import { PropTypes } from "prop-types";
import ToggleSwitch from "../shared/ToggleSwitch";
import FormSection from "../forms/FormSection";
import { isEmptyArray } from "../../helpers/utils_types";
import Divider from "../forms/Divider";
import { generateID } from "../../helpers/utils_processing";

// actual shift option: 'AM', 'PM', 'NOC'
const ReportShiftOption = ({ settings, shift, handleShifts }) => {
	return (
		<div className={styles.ReportShiftOption}>
			<ToggleSwitch
				size="SM"
				name={shift.name}
				id={shift.id}
				val={settings[shift.name]}
				handleToggle={handleShifts}
			/>
			<label htmlFor={shift.id} className={styles.ReportShiftOption_label}>
				{shift.label}
			</label>
		</div>
	);
};

export { ReportShiftOption };

ReportShiftOption.defaultProps = {};

ReportShiftOption.propTypes = {
	settings: PropTypes.object.isRequired,
	shift: PropTypes.shape({
		name: PropTypes.string,
		id: PropTypes.string,
		label: PropTypes.string,
	}),
	handleShifts: PropTypes.func.isRequired,
};

const hideShiftPicker = (shifts) => {
	if (isEmptyArray(shifts)) return true;
	return false;
};

const ReportShiftPicker = ({ shifts = [], settings, handleShifts }) => {
	if (hideShiftPicker(shifts)) {
		return null;
	}
	return (
		<>
			<FormSection
				icon="checkbox"
				title="Choose One or More Shifts"
				hoverText="Decide the shifts you want data for."
			>
				{!isEmptyArray(shifts) &&
					shifts.map((shift, idx) => (
						<ReportShiftOption
							settings={settings}
							shift={shift}
							handleShifts={handleShifts}
							key={`${generateID(2)}-${shift}-${idx}`}
						/>
					))}
			</FormSection>
			<Divider />
		</>
	);
};

export default ReportShiftPicker;

ReportShiftPicker.defaultProps = {
	shifts: [],
};

ReportShiftPicker.propTypes = {
	settings: PropTypes.object.isRequired,
	shifts: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			id: PropTypes.string,
			label: PropTypes.string,
		})
	),
	handleShifts: PropTypes.func.isRequired,
};
