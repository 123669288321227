import React, { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import styles from "../../css/views/TaskStatusReport.module.scss";
import { PropTypes } from "prop-types";
import { usePrint } from "../../utils/usePrint";
import { useForm } from "../../utils/useForm";
import { useAlerts, initialSettings } from "../../utils/useAlerts";
import { useScrollPosition, config } from "../../utils/useScrollPosition";
import { useRecents } from "../../utils/useRecents";
import { isEmptyVal } from "../../helpers/utils_types";
import { createBlob, createURL } from "../../helpers/utils_files";
import { getFileBlob } from "../../helpers/utils_downloads";
import {
	createReportModel,
	getHistoricalTaskStatus,
} from "../../helpers/utils_reports";
import {
	getDailyReportDesc,
	getReportDescByName,
	getReportDescByType,
} from "../../helpers/utils_descriptions";
import {
	hasFacility,
	formatAndSortFacilities,
} from "../../helpers/utils_facility";
import {
	formatResidentOnly,
	formatAndSortResidents,
	getResidentObj,
} from "../../helpers/utils_residents";
import { completionCols as cols } from "../../helpers/utils_table";
import {
	amendHistoricalConfig,
	TASKSTATUS_CONFIG,
} from "../../helpers/utils_config";
import { updateRecentsModel } from "../../helpers/utils_recentlyViewed";
import { generateID } from "../../helpers/utils_processing";
import { format } from "date-fns";
import {
	getDateRangeTypeKey,
	getFilterByKey,
	getFields,
} from "../../helpers/utils_validation";
import { calculateRestrictionRange } from "../../helpers/utils_dates";
// COMPONENTS
import ReportViewer from "../../components/reports/ReportViewer";
import ReportTypeController from "../../components/reports/ReportTypeController";
import ConfigurablePicker from "../../components/reportpicker/ConfigurablePicker";
import { sendPageTracking } from "../../helpers/utils_tracking";
import { mergeDailyResidentData } from "../../helpers/utils_residentData";
import { populateState } from "../../helpers/utils_initialResources";

const getCurrentResidentStr = (globalResident) => {
	if (isEmptyVal(globalResident?.ResidentID)) return "";
	return formatResidentOnly(globalResident);
};

const getCurrentFacilityStr = (globalFacility) => {
	if (isEmptyVal(globalFacility?.facilityID)) return "";
	return globalFacility?.communityName;
};

// initial input values
const initialValState = {
	reportType: "Historical",
	filterBy: "",
	filterByResident: "",
	filterByFacility: "",
	filterByADL: "",
	shiftAM: false,
	shiftPM: false,
	shiftNOC: false,
	dateRangeType: "",
	byQuarter: "",
	byMonth: "",
	byDate: "",
	byDateRange: "",
	startDate: "",
	endDate: "",
	sortBy: "",
	sortByResident: "",
	sortByADL: "",
	sortByTaskDescription: "",
	sortByStaff: "",
	sortByShift: "",
	sortByFloorUnit: "",
	sortByExceptionType: "",
	sortByIsPastDue: "",
	// other options
	showStrikeOuts: true,
	useTimeZoneOverride: false,
	selectTz: "",
};

const TaskStatusReport = ({
	globalState,
	currentFacility,
	currentResident,
	currentUser,
	residents = [],
	residentsByFacility,
	adlCategories = [],
	dispatch,
}) => {
	useScrollPosition(config);
	const { handlePrint } = usePrint("printTaskStatusReport");
	const { cache, addItem, removeItem } = useRecents(
		"Recents-TaskStatusReport", // key
		{ recents: [], timestamp: Date.now() } // state
	);
	const { formState, setFormState, handleCheckbox, handleReset } = useForm({
		...initialValState,
	});
	const { values } = formState;

	const { dispatchAlert, AlertsHandler } = useAlerts({
		...initialSettings,
		expiry: 2000,
		msg: {
			heading: "Success!",
			subheading: `You've changed facilities`,
		},
	});
	// local state
	const [selectedFacility, setSelectedFacility] = useState(
		getCurrentFacilityStr(currentFacility)
	);
	const [selectedResident, setSelectedResident] = useState(
		getCurrentResidentStr(currentResident)
	);
	const [showReportPicker, setShowReportPicker] = useState(false);
	const [facilityData, setFacilityData] = useState({});
	const [dailyReportData, setDailyReportData] = useState([]);
	const [hasLoadedReport, setHasLoadedReport] = useState(false);
	const [isLoadingReport, setIsLoadingReport] = useState(false);
	const [src, setSrc] = useState("");
	const [rangeRestriction, setRangeRestriction] = useState(
		calculateRestrictionRange(3)
	);

	const TASKSTATUS_FILTER_OPTIONS = [
		{
			name: "filterByResident",
			id: "Resident",
			label: "Choose a resident",
			placeholder: "Resident...",
			options: [...formatAndSortResidents(currentFacility.residents)],
		},
		{
			name: "filterByFacility",
			id: "Facility",
			label: "Choose a facility",
			placeholder: "Facility...",
			options: [...formatAndSortFacilities(currentUser.facilities)],
		},
		{
			name: "filterByADL",
			id: "ADL",
			label: "Choose a ADL",
			placeholder: "ADL...",
			options: [...adlCategories],
		},
	];

	const getFieldsToValidate = (values) => {
		const fields = getFields(values, [
			`reportType`,
			`filterBy`,
			getFilterByKey(values),
			`dateRangeType`,
			getDateRangeTypeKey(values),
		]);
		return fields;
	};

	// ##TODOS:
	// - Wire up 'rerunReport()' & 'recently viewed'
	const rerunReport = async (recentsModel) => {
		// IMPLEMENT RERUN REPORT HERE...
		setIsLoadingReport(false);
		const { token } = currentUser;
		setIsLoadingReport(true);
		return getReportSrc(token, recentsModel.id);
	};
	// fetches file blob from server, tranforms into pdf blob, sets local 'src' state
	const getReportSrc = async (token, fileID) => {
		const blob = await getFileBlob(token, fileID);
		const pdfBlob = createBlob(blob, "application/pdf");
		const pdfURL = createURL(pdfBlob);

		setSrc(pdfURL);
		return setIsLoadingReport(false);
	};
	// uses PDF mirror
	const getHistoricalReport = async () => {
		if (!hasFacility(selectedFacility)) {
			setIsLoadingReport(false);
			return dispatchAlert("warning", {
				heading: `Please select a facility.`,
			});
		}

		const { token } = currentUser;
		const { facilityID } = currentFacility;

		const reportModel = createReportModel(
			values,
			facilityID,
			currentUser?.facilities
		);
		const data = await getHistoricalTaskStatus(token, reportModel);

		const recentsModel = updateRecentsModel({
			id: data?.fileID,
			type: `HistoricalTaskStatusReport`,
			name: `Historical Task Status Report`,
			desc: getReportDescByName(values, `Task Status Report`),
			dateCreated: format(new Date(), "M/D/YYYY h:mm A"),
			data: { ...reportModel },
		});
		addItem(recentsModel);

		setIsLoadingReport(false);
		setHasLoadedReport(true);
		return setSrc(data?.pdfSrc);
	};

	const createReport = async () => {
		setShowReportPicker(false);
		setIsLoadingReport(true);
		return await getHistoricalReport();
	};
	const cancelReport = (e) => {
		handleReset(e);
		setShowReportPicker(false);
	};
	// global store is updated from side-effect (ie 'useEffect')
	// not needed; facility is loaded via 'setFacility'
	// only used for dispatching an alert
	const changeFacility = (selectedVal) => {
		return dispatchAlert("info", {
			heading: `Changed Facility`,
			subheading: `Loaded ${selectedVal}.`,
		});
	};

	/**
	 * Extracts resident from 'selectedResident' state.
	 * Inits 'LOADING' state
	 * Fires off request, then syncs resident data to global store
	 */
	// ✓ - ADDED 9/23/2021 at 8:49 AM
	const loadResident = useCallback(async () => {
		if (isEmptyVal(selectedResident)) return alert("Select a resident");
		if (!selectedResident.includes("~ ALA ID:")) return;

		const { token } = currentUser;
		// match resident record from ID
		const curResident = getResidentObj(
			residentsByFacility,
			currentFacility?.facilityID ?? currentUser.facilityID, // 'null' causes empty 'selectedFacility'
			selectedResident
		);

		dispatch({ type: "LOADING" });

		const merged = await mergeDailyResidentData(
			token,
			curResident.ResidentID,
			new Date()
		);
		const oldState = { ...globalState };
		const newState = populateState(merged, oldState);
		// sync to state
		return dispatch({
			type: "SUCCESS",
			data: {
				newState: newState,
			},
		});
	});

	// sends tracking to Sentry.io
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		sendPageTracking(currentUser?.username, {
			pagename: `TaskStatusReport`,
			path: `/dashboard/reports/taskstatus`,
		});
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Sentry.ErrorBoundary>
				<div className={styles.TaskStatusReport}>
					<ReportTypeController
						reportTitle="Task Status Report"
						reportDesc={getReportDescByType(`Task Status`, values)}
						setFacilityData={setFacilityData}
						reportType={values.reportType}
						currentUser={currentUser}
						currentFacility={currentFacility}
						residents={residents}
						residentsByFacility={residentsByFacility}
						selectedFacility={selectedFacility}
						setSelectedFacility={setSelectedFacility}
						setSelectedResident={setSelectedResident}
						selectedResident={selectedResident}
						changeFacility={changeFacility}
						changeResident={loadResident}
						recentlyViewedList={cache.recents}
						removeRecentsItem={removeItem}
						rerunReport={rerunReport}
						cancelReport={cancelReport}
						dispatch={dispatch}
						handleReportsPicker={() => setShowReportPicker(true)}
						hasLoaded={hasLoadedReport}
					>
						<ReportViewer
							key={src}
							hasLoaded={hasLoadedReport}
							noReportData={isEmptyVal(src)}
							showSMARTTable={false}
							reportType={values.reportType}
							isLoading={isLoadingReport}
							embedSrc={src}
							tableData={{
								size: `FULL`,
								title: `${values.reportType} Task Status Report`,
								cols: [...cols],
								data: [...dailyReportData],
								enableActions: true,
							}}
						/>
					</ReportTypeController>

					{/* REPORT PICKER */}
					<ConfigurablePicker
						reportName={`Task Status Report`}
						reportTypeOpts={[`Historical`]}
						formState={formState}
						setFormState={setFormState}
						handleCheckbox={handleCheckbox}
						handleReset={handleReset}
						currentFacility={currentFacility}
						currentUser={currentUser}
						adls={adlCategories}
						showPicker={showReportPicker}
						closeModal={() => setShowReportPicker(false)}
						createReport={createReport}
						cancelReport={cancelReport}
						fieldsToValidate={getFieldsToValidate(values)}
						config={amendHistoricalConfig(
							{ ...TASKSTATUS_CONFIG },
							"filterOptions",
							[...TASKSTATUS_FILTER_OPTIONS]
						)}
						dateRangeRestrictions={{
							isActive: true,
							rangeStart: rangeRestriction.rangeStart,
							rangeEnd: rangeRestriction.rangeEnd,
						}}
					/>

					{/* ALERTS NOTIFIER UI */}
					{AlertsHandler}
				</div>
			</Sentry.ErrorBoundary>
		</>
	);
};

export default TaskStatusReport;

TaskStatusReport.defaultProps = {};

TaskStatusReport.propTypes = {
	currentFacility: PropTypes.object.isRequired,
	currentResident: PropTypes.object.isRequired,
	currentUser: PropTypes.object.isRequired,
	residents: PropTypes.array.isRequired,
	residentsByFacility: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};
