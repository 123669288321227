import React from "react";
import styles from "../../css/reports/ReportsController.module.scss";
import { PropTypes } from "prop-types";

const ReportsController = ({ title, children }) => {
	return (
		<article className={styles.ReportsController} title={title}>
			<section className={styles.ReportsController_content}>{children}</section>
		</article>
	);
};

export default ReportsController;

ReportsController.defaultProps = {};

ReportsController.propTypes = {
	title: PropTypes.string,
	children: PropTypes.any.isRequired,
};
