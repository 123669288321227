import React from "react";
import styles from "../../css/adlshiftreport/AdlSelector.module.scss";
import { PropTypes } from "prop-types";
import { useMediaQuery } from "../../utils/useMediaQuery";
import CustomDropdown from "../shared/CustomDropdown";

const AdlSelector = ({ adlSelection, handleAdlSelection, categories = [] }) => {
	const matches = useMediaQuery("(max-width: 550px)");
	const customStyles = {
		width: matches ? "30rem" : "40rem",
	};

	return (
		<div className={styles.AdlSelector}>
			<h2 className={styles.AdlSelector_title}>Select ADL</h2>
			<CustomDropdown
				name="adlSelection"
				id="adlSelection"
				placeholder="Select ADL..."
				selection={adlSelection}
				setSelection={handleAdlSelection}
				options={categories.map(({ Name }) => Name)}
				customStyles={customStyles}
			/>
		</div>
	);
};

export default AdlSelector;

AdlSelector.defaultProps = {
	categories: [],
};

AdlSelector.propTypes = {
	adlSelection: PropTypes.string.isRequired,
	handleAdlSelection: PropTypes.func.isRequired,
	categories: PropTypes.arrayOf(PropTypes.object).isRequired,
};
