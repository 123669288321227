import React from "react";
import styles from "../../css/tables/TableRow.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import TableCell from "./TableCell";
import Checkbox from "../shared/Checkbox";

const TableRow = ({
	rowData = {},
	handleCell,
	selectionLabel = "Select",
	selectionVal,
	handleSelectRow,
	enableRowSelection = false,
}) => {
	// grabs keys from row data, to know which fields to render where.
	const cellAccessors = Object.keys(rowData);

	return (
		<div className={styles.TableRow}>
			<div className={styles.TableRow_cells}>
				{enableRowSelection && (
					<Checkbox
						name="selectRow"
						id="selectRow"
						val={selectionVal}
						label={selectionLabel}
						handleCheckbox={handleSelectRow}
					/>
				)}
				{!isEmptyArray(cellAccessors) &&
					cellAccessors.map((dataKey, idx) => (
						<TableCell
							key={`ROW-${dataKey}--${idx}`}
							cellData={rowData[dataKey]}
							maxLength={500}
							handleCell={handleCell}
						/>
					))}
			</div>
		</div>
	);
};

export default TableRow;

TableRow.defaultProps = {
	rowData: {},
	enableRowSelection: false,
};

TableRow.propTypes = {
	rowData: PropTypes.object.isRequired,
	handleCell: PropTypes.func,
	enableRowSelection: PropTypes.bool,
};
