import React from "react";
import styles from "../../css/adlshiftreport/AdlShiftReportBody.module.scss";
import { PropTypes } from "prop-types";

const AdlShiftReportBody = ({ children }) => {
	return <main className={styles.AdlShiftReportBody}>{children}</main>;
};

export default AdlShiftReportBody;

AdlShiftReportBody.defaultProps = {};

AdlShiftReportBody.propTypes = {
	children: PropTypes.any.isRequired,
};
