import React, { useRef, useEffect } from "react";
import styles from "../../css/errors/ErrorDialog.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import closeIcon from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { DIALOG_ICONS as icons, themeColors } from "../../helpers/utils_styles";
import ButtonSM from "../shared/ButtonSM";

const cancelBtn = {
	backgroundColor: "transparent",
	border: "1px solid #ccc",
	color: themeColors.main.blackBlue,
	marginRight: ".5rem",
};
const retryBtn = {
	backgroundColor: themeColors.main.red,
	border: "1px solid #ccc",
	color: "#ffffff",
};

const ErrorDialog = ({
	title = "Error!",
	heading = "Sorry, something went wrong.",
	subheading,
	text,
	icon = "ERROR",
	cancelError,
	retryAction,
	closeModal,
	children,
}) => {
	const dialogRef = useRef();
	const { isOutside } = useOutsideClick(dialogRef);

	// provides fallback if 'cancelError' is undefined; will close modal otherwise
	const cancelErrorHandler = (e) => {
		if (cancelError !== undefined && typeof cancelError === "function") {
			return cancelError(e);
		}
		if (cancelError === undefined && closeModal !== undefined) {
			return closeModal();
		}
	};

	// provides fallback for retryAction handler, if none is supplied
	const retryHandler = (e) => {
		if (retryAction !== undefined && typeof retryAction === "function") {
			return retryAction(e);
		}
		if (retryAction === undefined && closeModal !== undefined) {
			return closeModal();
		}
	};

	// handles closing the modal w/ 'outside clicks'
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside) {
			return closeModal();
		}

		return () => {
			isMounted = false;
		};
	}, [closeModal, isOutside]);

	return (
		<aside className={styles.ErrorDialog} ref={dialogRef}>
			<section className={styles.ErrorDialog_top}>
				<div className={styles.ErrorDialog_top_iconWrapper}>
					<svg className={styles.ErrorDialog_top_iconWrapper_icon}>
						<use xlinkHref={`${sprite}#icon-${icons[icon]}`}></use>
					</svg>
				</div>
				<h2 className={styles.ErrorDialog_top_title}>{title}</h2>
				<svg className={styles.ErrorDialog_top_closeIcon} onClick={closeModal}>
					<use xlinkHref={`${closeIcon}#icon-clearclose`}></use>
				</svg>
			</section>
			<section className={styles.ErrorDialog_inner}>
				<h2 className={styles.ErrorDialog_inner_heading}>{heading}</h2>
				<h6 className={styles.ErrorDialog_inner_subheading}>{subheading}</h6>
				<p className={styles.ErrorDialog_inner_text}>{text}</p>
			</section>
			<section className={styles.ErrorDialog_details}>{children}</section>
			<section className={styles.ErrorDialog_bottom}>
				<ButtonSM customStyles={cancelBtn} handleClick={cancelErrorHandler}>
					<span>Cancel</span>
				</ButtonSM>
				<ButtonSM customStyles={retryBtn} handleClick={retryHandler}>
					<span>Retry</span>
				</ButtonSM>
			</section>
		</aside>
	);
};

export default ErrorDialog;

ErrorDialog.defaultProps = {
	icon: "ERROR",
};

ErrorDialog.propTypes = {
	title: PropTypes.string,
	heading: PropTypes.string,
	subheading: PropTypes.string,
	text: PropTypes.string,
	icon: PropTypes.string.isRequired,
	cancelError: PropTypes.func.isRequired,
	retryAction: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	children: PropTypes.any,
};
