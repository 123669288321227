import React, { useRef, useEffect, useState, useContext } from "react";
import styles from "../../css/app/Navbar.module.scss";
import widget from "../../assets/icons/feedback.svg";
import sprite from "../../assets/icons/navbar.svg";
import sprite2 from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { logout } from "../../helpers/utils_auth";
import { initialAuthState } from "../../state/AuthContext";
import { isEmptyObj, hasProperty, isEmptyVal } from "../../helpers/utils_types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { themeColors } from "../../helpers/utils_styles";
import { removeFromStorage } from "../../helpers/utils_caching";
import { featureFlags } from "../../helpers/utils_features";
// components
import WidgetTrigger from "../widget/WidgetTrigger";
import AppIcon from "../icons/AppIcon";
import { GlobalStateContext } from "../../state/GlobalStateContext";
import {
	addEllipsis,
	enforceStrMaxLength,
} from "../../helpers/utils_processing";

// TODOS:
// - Clear out 'authData' upon logout (ie reset 'authData')
// - Clear out localStorage cache of 'authData'

const activeStyles = {
	backgroundColor: themeColors.main.main,
	color: "#ffffff",
	borderRadius: "5rem",
	padding: ".3rem 1rem",
};

// grabs initials from user's first/last name
const getInitials = (user) => {
	if (isEmptyObj(user)) return "NA";
	if (!hasProperty(user, "firstName")) return "NA";
	if (!hasProperty(user, "lastName")) return "NA";
	if (isEmptyVal(user.firstName)) return "NA";
	if (isEmptyVal(user.lastName)) return "NA";
	const first = user.firstName.slice(0, 1);
	const last = user.lastName.slice(0, 1);
	return `${first}${last}`.toUpperCase();
};

const getUsername = (username) => {
	const user = username.split("@")[0];
	return user;
};

const FeedbackIcon = () => {
	return (
		<div className={styles.FeedbackIcon}>
			<img
				src={widget}
				className={styles.FeedbackIcon_icon}
				alt="Feedback Widget Trigger"
			/>
		</div>
	);
};

// add useOutsideClick to dropdown
const Navbar = ({
	currentUser = {},
	alerts = [],
	hasAlerts = true,
	hasLoadedFacility = false,
	resetAuth,
	history,
	dispatch,
}) => {
	const { state: globalState } = useContext(GlobalStateContext);
	const { app } = globalState;
	// nav states
	const navRef = useRef();
	const { isOutside } = useOutsideClick(navRef); // enables "outside-click" to close menus
	const { isAdmin } = currentUser;

	const [expandDropdown, setExpandDropdown] = useState(false);
	const [showMobileMenu, setShowMobileMenu] = useState(false);

	const handleLogout = (e) => {
		e.preventDefault();
		logout(currentUser.token);
		dispatch({
			type: "RESET",
		});
		removeFromStorage(`AUTH`);
		resetAuth({ ...initialAuthState });
		return history.replace({
			pathname: "/",
			state: {
				from: history.location.pathname,
				wasLoggedOut: true,
			},
		});
	};

	// handles closing menus
	// when clicking outside of the Navbar
	useEffect(() => {
		if (isOutside) {
			setExpandDropdown(false);
			setShowMobileMenu(false);
		}
	}, [isOutside]);

	return (
		<nav className={styles.Navbar} ref={navRef}>
			<fieldset disabled={app?.isLoading} className={styles.Navbar_wrapper}>
				<ul className={styles.Navbar_list}>
					<li
						className={styles.Navbar_list_mobile}
						onClick={() => setShowMobileMenu(!showMobileMenu)}
					>
						<svg className={styles.Navbar_list_mobile_icon}>
							<use xlinkHref={`${sprite}#icon-menu11`}></use>
						</svg>
						{showMobileMenu && (
							<aside className={styles.Navbar_list_mobile_dropdown}>
								<ul className={styles.Navbar_list_mobile_dropdown_list}>
									<h4 className={styles.Navbar_list_mobile_dropdown_heading}>
										Views
									</h4>
									<li className={styles.Navbar_list_mobile_dropdown_list_item}>
										<NavLink
											to={{
												pathname: "/dashboard/daily",
												state: {
													from: history.location.pathname,
												},
											}}
											activeStyle={activeStyles}
										>
											Daily
										</NavLink>
									</li>
									<li className={styles.Navbar_list_mobile_dropdown_list_item}>
										<NavLink
											to={{
												pathname: "/dashboard/calendar",
												state: {
													from: history.location.pathname,
												},
											}}
											activeStyle={activeStyles}
										>
											Calendar
										</NavLink>
									</li>
									{currentUser?.isAdmin && (
										<li
											className={styles.Navbar_list_mobile_dropdown_list_item}
										>
											<NavLink
												to={{
													pathname: "/dashboard/reports",
													state: {
														from: history.location.pathname,
													},
												}}
												activeStyle={activeStyles}
											>
												Reports Dashboard
											</NavLink>
										</li>
									)}
								</ul>

								{currentUser?.isAdmin && (
									<ul className={styles.Navbar_list_mobile_dropdown_list}>
										<h4 className={styles.Navbar_list_mobile_dropdown_heading}>
											Reports
										</h4>
										<li
											className={styles.Navbar_list_mobile_dropdown_list_item}
										>
											<NavLink
												to={{
													pathname: "/dashboard/reports/completion",
													state: {
														from: history.location.pathname,
													},
												}}
												activeStyle={activeStyles}
											>
												Completion Report
											</NavLink>
										</li>
										<li
											className={styles.Navbar_list_mobile_dropdown_list_item}
										>
											<NavLink
												to="/dashboard/reports/exception"
												activeStyle={activeStyles}
											>
												Exception Report
											</NavLink>
										</li>
										<li
											className={styles.Navbar_list_mobile_dropdown_list_item}
										>
											<NavLink
												to="/dashboard/reports/taskcreated"
												activeStyle={activeStyles}
											>
												Task Created Report
											</NavLink>
										</li>
										<li
											className={styles.Navbar_list_mobile_dropdown_list_item}
										>
											<NavLink
												to="/dashboard/reports/reassess"
												activeStyle={activeStyles}
											>
												Reassess Report
											</NavLink>
										</li>
										<li
											className={styles.Navbar_list_mobile_dropdown_list_item}
										>
											<NavLink
												to="/dashboard/reports/taskstatus"
												activeStyle={activeStyles}
											>
												Task Status Report
											</NavLink>
										</li>

										{/* INACTIVE/NOT-CREATED 'ACTUAL TIME REPORT' */}
										{/* <li className={styles.Navbar_list_mobile_dropdown_list_item}>
									<NavLink
										to="/dashboard/reports/actualtime"
										activeStyle={activeStyles}
									>
										Actual Time Report
									</NavLink>
								</li> */}
									</ul>
								)}

								<ul className={styles.Navbar_list_mobile_dropdown_list}>
									<h4 className={styles.Navbar_list_mobile_dropdown_heading}>
										Resident Related
									</h4>
									<li className={styles.Navbar_list_mobile_dropdown_list_item}>
										<NavLink
											to="/dashboard/service-plan"
											activeStyle={activeStyles}
										>
											Service Plan
										</NavLink>
									</li>
								</ul>
							</aside>
						)}
					</li>
					<li className={styles.Navbar_list_item}>
						<AppIcon />
					</li>
					<li className={styles.Navbar_list_item}>
						<NavLink
							to={{
								pathname: "/dashboard/daily",
								state: {
									from: history?.location?.pathname,
								},
							}}
							activeClassName={styles.Navbar_list_item_isActive}
						>
							Daily
						</NavLink>
					</li>
					<li className={styles.Navbar_list_item}>
						<NavLink
							to={{
								pathname: "/dashboard/calendar",
								state: {
									from: history.location.pathname,
								},
							}}
							activeClassName={styles.Navbar_list_item_isActive}
						>
							{app.isLoading ? "" : "Calendar"}
						</NavLink>
					</li>
					<li className={styles.Navbar_list_item}>
						<NavLink
							to={{
								pathname: "/dashboard/reports",
								state: {
									from: history.location.pathname,
								},
							}}
							activeClassName={styles.Navbar_list_item_isActive}
						>
							{app.isLoading ? "" : "Reports"}
						</NavLink>
					</li>

					{/* ALERTS INDICATOR ICON - DISABLED BEHIND FEATURE FLAG */}
					{featureFlags.enableAlerts && (
						<li className={styles.Navbar_list_alert}>
							<svg className={styles.Navbar_list_alert_icon}>
								<use xlinkHref={`${sprite}#icon-notifications_none`}></use>
							</svg>
							{hasAlerts && (
								<div className={styles.Navbar_list_alert_count}>
									<div className={styles.Navbar_list_alert_count_number}>
										{alerts.length}
									</div>
								</div>
							)}
						</li>
					)}

					{/* WIDGET TRIGGER */}
					<li className={styles.Navbar_list_trigger}>
						{hasLoadedFacility && (
							<WidgetTrigger>
								<FeedbackIcon />
							</WidgetTrigger>
						)}
					</li>

					{/* USER BADGE */}
					<li
						className={styles.Navbar_list_user}
						// ##TODOS:
						// - PREVENT CLICKING WHEN APP IS INITIALLY LOADING OR DURING MOUNT LIFE-CYCLES
						onClick={() =>
							app.hasLoaded ? setExpandDropdown(!expandDropdown) : null
						}
					>
						<div className={styles.Navbar_list_user_initials}>
							{isEmptyObj(currentUser) ? "NA" : getInitials(currentUser)}
							{currentUser.isAdmin && (
								<div className={styles.Navbar_list_user_initials_admin}></div>
							)}
						</div>
						{/* RIGHT-HAND CORNER DROPDOWN MENU */}
						{expandDropdown && (
							<aside className={styles.Navbar_list_user_dropdown}>
								<ul className={styles.Navbar_list_user_dropdown_list}>
									{isAdmin && (
										<li className={styles.Navbar_list_user_dropdown_list_admin}>
											<div
												className={
													styles.Navbar_list_user_dropdown_list_admin_indicator
												}
											>
												<span>Admin</span>
											</div>
										</li>
									)}
									{/* USER DETAILS HERE */}
									<li
										className={styles.Navbar_list_user_dropdown_list_item}
										style={{
											wordBreak: "break-word",
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
										}}
									>
										<svg
											className={
												styles.Navbar_list_user_dropdown_list_item_icon
											}
										>
											<use
												xlinkHref={`${sprite2}#icon-person_outlineperm_identity`}
											></use>
										</svg>
										<span title={currentUser?.username}>
											{addEllipsis(currentUser?.username, 15)}
										</span>
									</li>
									<br
										style={{
											borderBottom: "1px solid #eaecef",
											margin: "1rem 0",
										}}
									/>
									<li className={styles.Navbar_list_user_dropdown_list_item}>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href="https://app.aladvantage.com/Account/Login.aspx"
										>
											AL Advantage
										</a>
									</li>
									<li className={styles.Navbar_list_user_dropdown_list_item}>
										<NavLink
											to={{
												pathname: "/dashboard/calendar",
												state: {
													from: history.location.pathname,
												},
											}}
										>
											Calendar
										</NavLink>
									</li>
									<li className={styles.Navbar_list_user_dropdown_list_item}>
										<NavLink
											to={{
												pathname: "/dashboard/reports",
												state: {
													from: history.location.pathname,
												},
											}}
										>
											Reports
										</NavLink>
									</li>

									<li className={styles.Navbar_list_user_dropdown_list_logout}>
										<button
											className={
												styles.Navbar_list_user_dropdown_list_logout_btn
											}
											onClick={handleLogout}
										>
											Logout
										</button>
									</li>
								</ul>
							</aside>
						)}
					</li>
				</ul>
			</fieldset>
		</nav>
	);
};

export default withRouter(Navbar);

Navbar.defaultProps = {
	currentUser: {},
	hasAlerts: false,
	alerts: [],
};
Navbar.propTypes = {
	currentUser: PropTypes.object,
	hasAlerts: PropTypes.bool,
	alerts: PropTypes.array,
};
