import React from "react";
import styles from "../../css/reportpicker/FilterOption.module.scss";
import { PropTypes } from "prop-types";
import CustomDropdown from "../shared/CustomDropdown";

const FilterOption = ({
	settings,
	filterOption,
	filterProps,
	handleFilterOpt,
	filterOptionsList = [],
}) => {
	return (
		<div className={styles.FilterOptions}>
			{settings?.filterBy === filterOption && (
				<div className={styles.ReportPickerFilters_filter}>
					<CustomDropdown
						name={filterProps.name}
						id={filterProps.id}
						label={filterProps.label}
						placeholder={filterProps.placeholder}
						selection={settings[filterProps.name]}
						setSelection={handleFilterOpt}
						options={filterOptionsList}
					/>
				</div>
			)}
		</div>
	);
};

export default FilterOption;

FilterOption.defaultProps = {
	filterOptionsList: [],
};

FilterOption.propTypes = {
	settings: PropTypes.object.isRequired,
	filterProps: PropTypes.shape({
		name: PropTypes.string,
		id: PropTypes.string, // 'Resident', 'Facility', 'ADL' etc
		label: PropTypes.string,
		placeholder: PropTypes.string,
		val: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
		handleFilterOpt: PropTypes.func,
		options: PropTypes.array,
	}),
	filterOption: PropTypes.string.isRequired,
	handleFilterOpt: PropTypes.func.isRequired,
	filterOptionsList: PropTypes.arrayOf(PropTypes.string).isRequired,
};
