import React, { useState, useReducer } from "react";
import styles from "../../css/tables/Table.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { handleSorting } from "../../helpers/utils_table";
import TableActionsPanel from "./TableActionsPanel";
import TableHeadings from "./TableHeadings";
import TableBody from "./TableBody";
import TableRow from "./TableRow";

const SIZES = {
	half: {
		width: "50%",
		height: "40%",
	},
	sm: {
		width: "25rem",
		height: "20rem",
	},
	md: {
		width: "45rem",
		height: "30rem",
	},
	lg: {
		width: "60rem",
		height: "45rem",
	},
	xlg: {
		width: "80rem",
		height: "65rem",
	},
	full: {
		width: "100%",
		height: "100%",
	},
};

const getRowCount = (state) => {
	const { data } = state;

	if (isEmptyArray(data)) {
		return 0;
	} else {
		return data?.length;
	}
};

const getRowCountDisplay = (state) => {
	const count = getRowCount(state);
	return `Showing ${count} row(s)`;
};

const advancedSearch = (val, options = []) => {
	val = val?.toLowerCase();

	return options.filter((option) => {
		// checks: 'Resident', 'ADL' and 'Task' fields
		if (
			option?.["Resident"]?.toLowerCase().includes(val) ||
			option?.["Resident"]?.toLowerCase().startsWith(val) ||
			option?.["ADL"]?.toLowerCase().includes(val) ||
			option?.["ADL"]?.toLowerCase().startsWith(val) ||
			option?.["Task"]?.toLowerCase().includes(val)
		) {
			return option;
		} else {
			return null;
		}
	});
};

const tableReducer = (state, action) => {
	switch (action.type) {
		case "SORT": {
			const { sortType } = action.data;
			const { filtered, isSorted } = state;

			if (isSorted) {
				return {
					...state,
					filtered: [...filtered].reverse(),
					isSorted: !isSorted,
				};
			}
			return {
				...state,
				filtered: [...handleSorting(filtered, sortType, isSorted)],
				isSorted: !isSorted,
			};
		}
		case "SEARCH": {
			const { searchVal } = action.data;
			if (isEmptyVal(searchVal)) {
				return {
					...state,
					filtered: [...state.data],
				};
			}
			return {
				...state,
				filtered: [...advancedSearch(searchVal, state?.data)],
			};
		}
		case "SELECT_ALL": {
			return { ...state };
		}
		case "DE-SELECT_ALL": {
			return { ...state };
		}
		case "PRINT": {
			return { ...state };
		}
		case "REFRESH": {
			return { ...state };
		}
		default:
			return { ...state };
	}
};

const initialState = {
	columns: [],
	data: [],
	filtered: [],
	isSorted: false,
	sortType: null,
};

const Table = ({
	cols = [],
	data = [],
	size = "md",
	title = "",
	defaultSortType = null,
	enableActions = false,
	enableRowSelection = false,
	searchPlaceholder = "Search...",
	children,
}) => {
	const [search, setSearch] = useState("");
	const [state, dispatch] = useReducer(tableReducer, {
		...initialState,
		columns: [...cols],
		data: [...data],
		filtered: [...data],
		sortType: defaultSortType,
	});
	const custom = {
		...SIZES[size],
	};

	const handleSort = (sortType) => {
		return dispatch({
			type: "SORT",
			data: {
				sortType: sortType,
			},
		});
	};

	const handleSearch = (e) => {
		const { value } = e.target;
		setSearch(value);
		return dispatch({
			type: "SEARCH",
			data: {
				searchVal: value,
			},
		});
	};

	const handleCell = (cellData) => {};

	return (
		<div className={styles.Table} style={custom}>
			{/* TABLE TITLE */}
			<section className={styles.Table_top}>
				<h2 className={styles.Table_top_title}>{title}</h2>
				{/* SHOWING X ROWS DISPLAY */}
				<div className={styles.Table_top_rowCount}>
					{getRowCountDisplay(state)}
				</div>
			</section>
			{enableActions && (
				<div className={styles.Table_actionsPanel}>
					<TableActionsPanel
						cols={state.columns}
						data={state.data}
						isSorted={state.isSorted}
						sortType={state.sortType}
						searchName="search"
						searchVal={search}
						searchPlaceholder="Search by resident, ADL, or task"
						handleSearch={handleSearch}
					/>
				</div>
			)}
			<section className={styles.Table_headings}>
				<TableHeadings
					headings={state.columns}
					handleHeading={handleSort}
					sortType={state.sortType}
					enableRowSelection={enableRowSelection}
				/>
			</section>
			<section className={styles.Table_body}>
				<TableBody>
					{!isEmptyArray(state?.data) &&
						state.filtered.map((entry, idx) => (
							<TableRow
								key={`row-${idx}`}
								rowData={entry}
								handleCell={handleCell}
								enableRowSelection={enableRowSelection}
							/>
						))}
				</TableBody>
			</section>
			{children}
		</div>
	);
};

export default Table;

Table.defaultProps = {
	cols: [],
	data: [],
	size: "md",
	defaultSortType: null,
	enableActions: false,
	enableRowSelection: false,
	searchPlaceholder: `Search...`,
};

Table.propTypes = {
	cols: PropTypes.arrayOf(PropTypes.string).isRequired,
	data: PropTypes.arrayOf(PropTypes.string).isRequired,
	size: PropTypes.string,
	defaultSortType: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.instanceOf(Date),
	]),
	enableActions: PropTypes.bool,
	enableRowSelection: PropTypes.bool,
	searchPlaceholder: PropTypes.string,
	children: PropTypes.any,
};
