import React from "react";
import styles from "../../css/app/ForgotPasswordButton.module.scss";
import { PropTypes } from "prop-types";

// REQUIREMENTS:
// - Create modal for security questions:
// 		- Enter username
// 		- Load user's security questions
// 		-

const ForgotPasswordButton = ({ user = {}, openForgotPassword }) => {
	return (
		<div className={styles.ForgotPasswordButton}>
			<button
				onClick={openForgotPassword}
				className={styles.ForgotPasswordButton_btn}
			>
				Forgot Password?
			</button>
		</div>
	);
};

export default ForgotPasswordButton;

ForgotPasswordButton.defaultProps = {};

ForgotPasswordButton.propTypes = {
	user: PropTypes.object,
	openForgotPassword: PropTypes.func,
};
