import React from "react";
import styles from "../../css/timeview/TimeViewAdvancedOptions.module.scss";
import { PropTypes } from "prop-types";

const TimeViewAdvancedOptions = ({
	task = {},
	currentUser = {},
	currentFacility = {},
}) => {
	return (
		<div className={styles.TimeViewAdvancedOptions}>
			{/*  */}
			{/*  */}
			{/*  */}
		</div>
	);
};

export default TimeViewAdvancedOptions;

TimeViewAdvancedOptions.defaultProps = {};

TimeViewAdvancedOptions.propTypes = {};
