import { currentEnv } from "./utils_env";
import { emails } from "./utils_endpoints";

/**
 * List of emails to receive 'feedback' emails.
 * - Updated as of 2/15/21 at 8:15 AM
 */
const FEEDBACK_RECIPIENTS = [
	"feedback@aladvantage.com",
	"steven@aladvantage.com",
];

/**
 * Data-structure for sending an email to multiple users.
 * - 'userLoginId': a user login id.
 * - 'subject': email subject field
 * - 'body': email body field. Supports strings or html content.
 * - 'isBodyHtml': signifies the content type for the 'body' field.
 * - 'isAdminNotify': signifies whether the user's admins should be notified, respectively.
 */
const userEmailData = {
	UserLoginId: "",
	UserEmail: "",
	subject: "",
	Body: "",
	IsBodyHtml: true,
	IsAdminNotify: false,
	EmailMetric: {
		FacilityId: null,
		UserProfileID: 0,
		UserLoginID: "00000000-0000-0000-0000-000000000000",
		ApplicationID: 2,
		FeatureID: 0,
		FacilityName: null,
		FirstName: null,
		LastName: null,
		Email: null,
		ReferenceUrl: null,
		Feedback: null,
		MetricName: null,
	},
};
/**
 * Data-structure for sending an email to multiple users. Use either 'UserLoginIds' OR 'UserEmails'. NOT BOTH!!!
 * - 'UserLoginIds': an array of user login ids.
 * - 'UserEmails': an array of user email addresses.
 * - 'Subject': email subject field
 * - 'Body': email body field. Supports strings or html content.
 * - 'IsBodyHtml': signifies the content type for the 'body' field.
 * - 'IsAdminNotify': signifies whether the user's admins should be notified, respectively.
 */
const userEmailsData = {
	UserLoginIds: [],
	UserEmails: [],
	subject: "",
	Body: "",
	IsBodyHtml: true,
	IsAdminNotify: false,
	EmailMetric: {
		FacilityId: null,
		UserProfileID: 0,
		UserLoginID: "00000000-0000-0000-0000-000000000000",
		ApplicationID: 2,
		FeatureID: 0,
		FacilityName: null,
		FirstName: null,
		LastName: null,
		Email: null,
		ReferenceUrl: null,
		Feedback: null,
		MetricName: null,
	},
};
/**
 * Data-structure for sending an email to multiple users. Use either 'UserLoginIds' OR 'UserEmails'. NOT BOTH!!!
 * - 'UserLoginIds': an array of user login ids.
 * - 'UserEmails': an array of user email addresses.
 * - 'Subject': email subject field
 * - 'Body': email body field. Supports strings or html content.
 * - 'IsBodyHtml': signifies the content type for the 'body' field.
 * - 'IsAdminNotify': signifies whether the user's admins should be notified, respectively.
 */
const feedbackEmailData = {
	UserLoginIds: [],
	UserEmails: [...FEEDBACK_RECIPIENTS],
	Body: "",
	IsBodyHtml: true,
	IsAdminNotify: false,
	EmailMetric: {
		FacilityId: null,
		UserProfileID: 0,
		UserLoginID: "00000000-0000-0000-0000-000000000000",
		ApplicationID: 2,
		FeatureID: 0,
		FacilityName: null,
		FirstName: null,
		LastName: null,
		Email: null,
		ReferenceUrl: null,
		Feedback: null,
		MetricName: null,
	},
};

/**
 * Sends an email to a target user, and their admin if requested.
 * @param {String} token - Security token
 * @param {String} userEmail - A user's email.
 * @param {Object} emailContent - An object of various email content: 'Subject', 'Body' etc. Supports HTML body.
 * @property {Boolean} emailContent.shouldNotifyAdmin - Set to 'true' to send email to admin, as well as target user.
 * @property {String} emailContent.subject - Email 'Subject' field.
 * @property {String|HTMLDocument} emailContent.body - Email's 'Body' content. Supports text or html format.
 * @property {Boolean} emailContent.isBodyHtml - Boolean indicating whether the 'body' is HTML or not.
 * @property {Boolean} emailContent.shouldNotifyAdmin - Boolean to trigger an additional email to the user's Admin.
 */
const sendUserEmail = async (token, emailContent = { ...userEmailData }) => {
	let url = currentEnv.base + emails.sendEmail;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json; charset=UTF-8",
			},
			body: JSON.stringify(emailContent),
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Sends an email to one or more users via their 'userLoginId'
 * @param {String} token - Security token
 * @param {String} userEmail - A user's email.
 * @param {Object} emailContent - An object of various email content: 'Subject', 'Body' etc. Supports HTML body.
 * @property {Boolean} emailContent.shouldNotifyAdmin - Set to 'true' to send email to admin, as well as target user.
 * @property {String} emailContent.subject - Email 'Subject' field.
 * @property {String|HTMLDocument} emailContent.body - Email's 'Body' content. Supports text or html format.
 * @property {Boolean} emailContent.isBodyHtml - Boolean indicating whether the 'body' is HTML or not.
 * @property {Boolean} emailContent.shouldNotifyAdmin - Boolean to trigger an additional email to the user's Admin.
 */
const sendUsersEmail = async (token, emailContent = { ...userEmailsData }) => {
	let url = currentEnv.base + emails.sendEmails;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json; charset=UTF-8",
			},
			body: JSON.stringify(emailContent),
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Sends one or more emails via a list of email addresses.
 * @param {String} token - Security token
 * @param {Object} emailContent - Content for the email to be sent w/ including: 'HTML EMail', 'Subject' and to whom
 */
const sendEmailsByEmailList = async (
	token,
	emailContent = { ...userEmailsData }
) => {
	let url = currentEnv.base + emails.sendEmails;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(emailContent),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Sends one or more emails via a list of 'UserLoginIds'.
 * @param {String} token - Security token
 * @param {Object} emailContent - Content for the email to be sent w/ including: 'HTML EMail', 'Subject' and to whom
 */
const sendEmailsByLoginIDs = async (
	token,
	emailContent = { ...userEmailsData }
) => {
	let url = currentEnv.base + emails.sendEmails;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(emailContent),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Sends a 'Feedback: XXXX' email to a pre-supposed list of email addresses.
 * @param {String} token - Security token
 * @param {Object} emailContent - Object containing data for feedback email to be sent with and to whom.
 */
const sendFeedbackEmail = async (
	token,
	emailContent = { ...feedbackEmailData }
) => {
	let url = currentEnv.base + emails.sendEmails;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(emailContent),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// retrieves 'feedback message'
const getFeedbackMsg = (type, vals = {}) => {
	switch (type) {
		case "Issue": {
			return vals.issueMsg;
		}
		case "Idea": {
			return vals.ideaMsg;
		}
		case "Other": {
			return vals.otherMsg;
		}
		default:
			return;
	}
};

// email requests
export {
	sendUserEmail,
	sendUsersEmail,
	sendEmailsByEmailList,
	sendEmailsByLoginIDs,
	sendFeedbackEmail,
};
// email content data-structure(s)
export { feedbackEmailData, userEmailData, userEmailsData };
// list of logins/emails for feedback email(s)
export { FEEDBACK_RECIPIENTS };

export { getFeedbackMsg };
