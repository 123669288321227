import React, { useState, useEffect } from "react";
import styles from "../../css/app/Beacon.module.scss";
import { PropTypes } from "prop-types";

const SIZES = {
	XSM: {
		width: "1rem",
		height: "1rem",
	},
	SM: {
		width: "2rem",
		height: "2rem",
	},
	MD: {
		width: "5rem",
		height: "5rem",
	},
	LG: {
		width: "8rem",
		height: "8rem",
	},
	XLG: {
		width: "10rem",
		height: "10rem",
	},
};

const Beacon = ({ expiry = 4000, size = "XSM", customStyles = {} }) => {
	const [show, setShow] = useState(true);
	const custom = {
		...SIZES[size],
		...customStyles,
	};

	// disappears after set expiration time (in ms)
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		let timerID;
		if (show) {
			timerID = setTimeout(() => {
				setShow(false);
			}, expiry);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!show) {
		return null;
	}
	return (
		<div className={styles.Beacon} style={custom}>
			<div className={styles.Beacon_inner}></div>
		</div>
	);
};

export default Beacon;

Beacon.defaultProps = {
	expiry: 4000,
	size: "XSM",
	customStyles: {},
};

Beacon.propTypes = {
	expiry: PropTypes.number,
	size: PropTypes.string,
	customStyles: PropTypes.object,
};
