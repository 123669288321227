import React, { useEffect, useState } from "react";
import styles from "../../css/app/ResidentCard.module.scss";
import sprite from "../../assets/icons/resident-details.svg";
import { PropTypes } from "prop-types";
import { NavLink } from "react-router-dom";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { replaceNullWithMsg } from "../../helpers/utils_processing";
import { getResidentPhotoSrc } from "../../helpers/utils_residentData";
import { getFileBlob, createURL } from "../../helpers/utils_files";
import { themeColors } from "../../helpers/utils_styles";
import { formatResidentName } from "../../helpers/utils_residents";
import { saveToStorage, getFromStorage } from "../../helpers/utils_caching";
import { format } from "date-fns";

import MedsList from "./MedsList";
import ResidentPhoto from "./ResidentPhoto";
import ModalFull from "../shared/ModalFull";
import ResidentInfoPanel from "../residentinfo/ResidentInfoPanel";

const setKey = (id) => {
	return `Photo-${id}`;
};

// MEDS HEART ICON: `${sprite}#icon-heartbeat`

// current resident comes from global state
const ResidentCard = ({
	hasSelection = false,
	residentDetails,
	currentResident = {},
	currentUser = {},
}) => {
	const [showResidentInfo, setShowResidentInfo] = useState(false);
	const [photoSrc, setPhotoSrc] = useState("");

	// SUPPORTS CACHED PHOTOS FROM LOCALSTORAGE
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		const setResidentSrc = async () => {
			const { token } = currentUser;
			const { ResidentID } = currentResident;
			const cacheFile = getFromStorage(`Photo-${ResidentID}`);

			// if resident photo is NOT cached (localStorage)...
			// ...then fetch it & generate img URL string 'src'
			if (!isEmptyVal(cacheFile?.photoID)) {
				const fileBlob = await getFileBlob(token, cacheFile.photoID);
				return setPhotoSrc(createURL(fileBlob));
			}

			// if resident photo already exists in cache (localStorage)...
			// ...then set state w/ cached img URL string 'src'
			const src = await getResidentPhotoSrc(token, ResidentID);
			return setPhotoSrc(src);
		};

		// wait until a resident is selected/loaded
		if (!isEmptyObj(currentResident)) {
			setResidentSrc();
		}

		return () => {
			isMounted = false;
		};
	}, [currentResident, currentUser]);

	if (!hasSelection || isEmptyObj(currentResident)) {
		return null;
	}
	return (
		<>
			<article className={styles.ResidentCard}>
				<div className={styles.ResidentCard_row}>
					<section className={styles.ResidentCard_row_top}>
						<div
							className={
								residentDetails.isLOA ? styles.isLOA_active : styles.isLOA
							}
						>
							<ResidentPhoto imgSize="MD" imgSrc={photoSrc} />
						</div>
						<hgroup className={styles.ResidentCard_row_top_group}>
							<h2 className={styles.ResidentCard_row_top_group_title}>
								{replaceNullWithMsg(currentResident.FirstName, "NA") +
									" " +
									replaceNullWithMsg(currentResident.LastName, "NA")}{" "}
								{residentDetails.isLOA && (
									<b style={{ color: themeColors.main.red }}>(LOA)</b>
								)}
							</h2>
							<h4 className={styles.ResidentCard_row_top_group_subtitle}>
								Unit:{" "}
								{isEmptyVal(residentDetails.FloorUnit)
									? "Load resident"
									: replaceNullWithMsg(residentDetails.FloorUnit, "Unknown")}
							</h4>
							<h4 className={styles.ResidentCard_row_top_group_subtitle}>
								Room: {replaceNullWithMsg(residentDetails.RoomNum, "")}
							</h4>
							<h4 className={styles.ResidentCard_row_top_group_subtitle}>
								ALA ID: {replaceNullWithMsg(currentResident.ResidentID, "NA")}
							</h4>
						</hgroup>
					</section>
				</div>
				<button
					className={styles.ResidentCard_viewDetails}
					onClick={() => setShowResidentInfo(true)}
					disabled={!hasSelection}
				>
					View Details
				</button>
			</article>

			{showResidentInfo && (
				<ModalFull
					title={`Resident Info: ${formatResidentName(currentResident)}`}
					closeModal={() => setShowResidentInfo(false)}
				>
					<ResidentInfoPanel
						currentResident={currentResident}
						currentUser={currentUser}
						imgSrc={photoSrc}
					/>
				</ModalFull>
			)}
		</>
	);
};

export default ResidentCard;

ResidentCard.defaultProps = {
	hasSelection: false,
	currentUser: {},
	currentResident: {},
};

ResidentCard.propTypes = {
	currentUser: PropTypes.object.isRequired,
	currentResident: PropTypes.object.isRequired,
	residentDetails: PropTypes.object.isRequired,
	hasSelection: PropTypes.bool,
};
