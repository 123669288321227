import React, { useState, useEffect } from "react";
import styles from "../../css/app/ChangePassword.module.scss";
import { useForm } from "../../utils/useForm";
import { PropTypes } from "prop-types";
import { purple, red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
import { changeUserPassword } from "../../helpers/utils_user";
// components
import PasswordValidator from "../forms/PasswordValidator";
import ButtonSM from "../shared/ButtonSM";
import ConfirmedIndicator from "../forms/ConfirmedIndicator";
import DoNotMatchIndicator from "../forms/DoNotMatchIndicator";
import PasswordSuccess from "./PasswordSuccess";

const customCSS = {
	cancel: {
		padding: ".7rem 1.4rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.4rem",
	},
	change: {
		padding: ".7rem 1.4rem",
		backgroundColor: purple[700],
		color: "#ffffff",
		fontSize: "1.4rem",
	},
};

const enableChangeBtn = (vals) => {
	const { createdPassword, confirmCreatedPassword } = vals;

	const enable =
		!isEmptyVal(createdPassword) &&
		!isEmptyVal(confirmCreatedPassword) &&
		isSamePassword(createdPassword, confirmCreatedPassword);

	return enable;
};

const isSamePassword = (str1, str2) => {
	if (!isEmptyVal(str1) && !isEmptyVal(str2)) {
		return str1 === str2;
	} else {
		return false;
	}
};

const ChangePassword = ({
	authData = {},
	systemUser = {},
	accountSecurity = {},
	oldPassword = {}, // uses 'OTP' for resets
	dispatchAlert,
	closeModal,
}) => {
	const { formState, handleChange } = useForm({
		createdPassword: "",
		confirmCreatedPassword: "",
	});
	const { values } = formState;
	const { createdPassword, confirmCreatedPassword } = values;
	const [passwordsMatch, setPasswordsMatch] = useState(false);
	const [passwordWasChanged, setPasswordWasChanged] = useState(false);

	const handleAlert = (type) => {
		if (type === "SUCCESS") {
			return dispatchAlert(type, {
				heading: `Success!`,
				subheading: `Password was updated!`,
			});
		} else {
			return dispatchAlert(type, {
				heading: `Error!`,
				subheading: `There was an error.`,
			});
		}
	};

	const saveNewPassword = async () => {
		const { token } = systemUser;
		const { userID } = accountSecurity;
		const wasChanged = await changeUserPassword(token, {
			userID: userID,
			oldPassword: oldPassword,
			newPassword: createdPassword,
		});

		if (wasChanged) {
			setPasswordWasChanged(true);
			return handleAlert("SUCCESS");
		} else {
			setPasswordWasChanged(false);
			return handleAlert("ERROR");
		}
		// fire off request
		// need to figure out user's existing password
	};

	const cancelNewPassword = () => {
		closeModal();
	};

	// validate matching passwords on key entry
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		setPasswordsMatch(false);
		if (!isEmptyVal(createdPassword) && !isEmptyVal(confirmCreatedPassword)) {
			setPasswordsMatch(
				isSamePassword(createdPassword, confirmCreatedPassword)
			);
		}

		return () => {
			isMounted = false;
		};
	}, [confirmCreatedPassword, createdPassword]);

	return (
		<>
			<div className={styles.ChangePassword}>
				<div className={styles.ChangePassword_header}>
					<h2 className={styles.ChangePassword_header_title}>Reset Password</h2>
					<p className={styles.ChangePassword_header_desc}>
						Create a new password, confirm new password by re-entering it and
						click "Change Password".
					</p>
				</div>
				<div className={styles.ChangePassword_form}>
					<PasswordValidator
						name="createdPassword"
						id="createdPassword"
						label="Create a Password"
						initialVal={values.createdPassword}
						handleChange={handleChange}
						placeholder="Create a password..."
					/>
				</div>
				<div className={styles.ChangePassword_form}>
					<PasswordValidator
						name="confirmCreatedPassword"
						id="confirmCreatedPassword"
						label="Re-enter Password"
						initialVal={values.confirmCreatedPassword}
						handleChange={handleChange}
						placeholder="Re-enter password..."
					/>
				</div>
				<div className={styles.ChangePassword_indicators}>
					{passwordsMatch && (
						<ConfirmedIndicator confirmedMsg="Passwords Match!" />
					)}
					{!passwordsMatch && !isEmptyVal(confirmCreatedPassword) && (
						<DoNotMatchIndicator failedMsg="Passwords Do Not Match!" />
					)}
				</div>
				<div className={styles.ChangePassword_actions}>
					<ButtonSM
						customStyles={customCSS.cancel}
						handleClick={cancelNewPassword}
					>
						<span>Cancel</span>
					</ButtonSM>
					<ButtonSM
						customStyles={customCSS.change}
						isDisabled={!enableChangeBtn(values)}
						handleClick={saveNewPassword}
					>
						<span>Change Password</span>
					</ButtonSM>
				</div>
			</div>

			{passwordWasChanged && <PasswordSuccess closeModal={closeModal} />}
		</>
	);
};

export default ChangePassword;

ChangePassword.defaultProps = {};

ChangePassword.propTypes = {};
