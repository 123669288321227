import React, { useLayoutEffect, useState } from "react";
import styles from "../../css/app/ScrollToTop.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";

/**
 * Button appears once the 'threshold' has been crossed.
 * @param {Number|String} threshold - Percentage of the screen to scroll before showing 'button'
 * @param {Object} pos - The position (ie 'top' & 'left') to scroll to when clicked.
 */

const ScrollToTop = ({ threshold = "20", pos = { top: 0, left: 0 } }) => {
	const [showBtn, setShowBtn] = useState(false);

	const scrollToTop = (e) => {
		e.preventDefault();
		const { top, left } = pos;

		window.scrollTo({
			top: top,
			left: left,
			behavior: "smooth",
		});
	};

	// show btn once 'threshold' is passed,
	// ...hide if 'threshold' is NOT passed
	useLayoutEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		function checkScrollPos(e) {
			// amount in px, scrolled from top
			const fromTop =
				document.body.scrollTop || document.documentElement.scrollTop;
			// window height (ie scrollable height)
			const height =
				document.documentElement.scrollHeight -
				document.documentElement.clientHeight;

			const amountScrolled = (fromTop / height) * 100;

			if (amountScrolled >= +threshold) {
				return setShowBtn(true);
			} else if (amountScrolled < +threshold) {
				return setShowBtn(false);
			} else {
				return;
			}
		}

		if (window.scrollTop > +threshold) {
			console.log(`Scrolled past threshold:`, threshold);
		}
		window.addEventListener("scroll", checkScrollPos);

		return () => {
			isMounted = false;
			window.removeEventListener("scroll", checkScrollPos);
		};
	}, [threshold]);

	if (!showBtn) {
		return null;
	}
	return (
		<button onClick={scrollToTop} type="button" className={styles.ScrollToTop}>
			<svg className={styles.ScrollToTop_icon}>
				<use xlinkHref={`${sprite}#icon-arrow_upward`}></use>
			</svg>
			<span className={styles.ScrollToTop_text}>TOP</span>
		</button>
	);
};

export default ScrollToTop;

ScrollToTop.defaultProps = {};

ScrollToTop.propTypes = {
	pos: PropTypes.shape({
		top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	threshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
