import { currentEnv } from "./utils_env";
import { security } from "./utils_endpoints";
import { isEmptyArray, isEmptyObj } from "./utils_types";

/**
 * Fetches a user's security info: profile, logins, alerts, emails roles, communities etc.
 * @param {String} token - Auth token
 * @param {String} userIdOrUsername - This arg accepts either 'UserId' or a 'Username/Email'
 */
const getUserSecurityInfo = async (token, userIdOrUsername) => {
	let url = currentEnv.base + security.info.get;
	url += "?" + new URLSearchParams({ IdOrName: userIdOrUsername });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetch security questions matching specific criteria from the query params.
 * @param {Object} params - An object or query params to filter the request's data.
 */
const getAllSecurityQuestions2 = async (token, params = {}) => {
	let url = currentEnv.base + security.questions.get2;
	url += "?" + new URLSearchParams(params);

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Check whether a user's 'username' or 'email' already exist in the system.
 * @param {String} token - Security token.
 * @param {String} usernameOrEmail - A user's 'LoginName' to validate.
 * @returns {Boolean} - Returns 'true|false'
 */
const validateUserLogin = async (token, usernameOrEmail) => {
	let url = currentEnv.base + security.userLogin.validate;
	url += "?" + new URLSearchParams({ name: usernameOrEmail });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Tests a password security strength.
 * @param {String} token - Security token.
 * @param {String} password - A password to test strength on.
 */
const validateStrongPassword = async (token, password) => {
	let url = currentEnv.base + security.userLogin.checkPasswordStrength;
	url += "?" + new URLSearchParams({ password: encodeURIComponent(password) });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Validates a given user's security question ONLY. Does NOT support answers.
 * @param {String} token - Auth token
 * @param {String} userLoginId - User's guid/id
 * @param {String} question - A string-form security question.
 */
const validateSecurityQuestion = async (token, userLoginId, question) => {
	let url = currentEnv.base + security.questions.validate.userQuestion;
	url += "?" + new URLSearchParams({ userLoginId });
	url += "&" + new URLSearchParams({ question });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Validates a given user's security question ONLY. Does NOT support answers.
 * @param {String} token - Auth token
 * @param {String} userLoginId - User's guid/id
 * @param {String} question - A string-form security question.
 */
const validateSecurityAnswer = async (
	token,
	userLoginId,
	questionId,
	answer
) => {
	let url = currentEnv.base + security.questions.validate.userAnswer;
	url += "?" + new URLSearchParams({ userLoginId });
	url += "&" + new URLSearchParams({ securityQuestionId: questionId });
	url += "&" + new URLSearchParams({ answer });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

const RESETS = {
	isPwdResetByAdmin: "Admin",
	isPwdResetByEmail: "Email",
	isPwdResetByQuestions: "Questions",
};

/**
 * Determines list of user password reset methods.
 * @param {Object} clientData - Response data from 'GetUserPasswordRules'
 * @returns {Array} - Returns an array of strings.
 */
const getResetMethods = (clientData = {}) => {
	const {
		IsPwdResetByAdmin: isPwdResetByAdmin,
		IsPwdResetByEmail: isPwdResetByEmail,
		IsPwdResetByQuestions: isPwdResetByQuestions,
	} = clientData;

	const admin = isPwdResetByAdmin ? RESETS.isPwdResetByAdmin : null;
	const email = isPwdResetByEmail ? RESETS.isPwdResetByEmail : null;
	const questions = isPwdResetByQuestions ? RESETS.isPwdResetByQuestions : null;

	return [admin, email, questions].filter(Boolean);
};

// SECURITY UTILS //

const processAccountSecurity = (data = {}) => {
	if (isEmptyObj(data) || !data) return {};

	const {
		IsProfileActive,
		IsProfileLockout,
		IsLoginActive,
		IsLoginLockout,
		IsSuspended,
		IsPwdResetByAdmin,
		IsPwdResetByEmail,
		IsPwdResetByQuestions,
	} = data;

	const isLockedOut =
		(IsProfileLockout || IsLoginLockout) && IsLoginActive && IsProfileActive;

	const methods = getResetMethods(data);

	return {
		isLockedOut: isLockedOut,
		isSuspended: IsSuspended,
		isPwdResetByAdmin: IsPwdResetByAdmin,
		isPwdResetByEmail: IsPwdResetByEmail,
		isPwdResetByQuestions: IsPwdResetByQuestions,
		resetMethods: [...methods],
	};
};

// QUESTION/ANSWER PROCESSING UTILS //

const createQuestionMap = (allQuestions = []) => {
	return allQuestions.reduce((map, question) => {
		if (!map[question.Question]) {
			map[question.Question] = {
				id: question.SecurityQuestionID,
				question: question.Question,
				isActive: question.IsActive,
			};
			return map;
		}
		return map;
	}, {});
};

const prepareAnswerRequests = (allQuestions = [], vals = {}) => {
	const {
		confirmSecurityQuestion1,
		confirmSecurityQuestion2,
		confirmSecurityQuestion3,
	} = vals;
	const questionMap = createQuestionMap(allQuestions);

	// get ids for each question
	const questionId1 = questionMap[confirmSecurityQuestion1];
	const questionId2 = questionMap[confirmSecurityQuestion2];
	const questionId3 = questionMap[confirmSecurityQuestion3];

	return {
		questionOneID: questionId1.id,
		questionTwoID: questionId2.id,
		questionThreeID: questionId3.id,
	};
};

// extracts just the string questions into an array
const formatSecurityQuestions = (questions = []) => {
	if (isEmptyArray(questions)) return [];
	// saveToStorage(`_QUESTIONS_`, questions);
	return questions.map((record) => record?.Question);
};

// consider creating a 'message' handler to return any errors to the user via UI
const validateAllQuestions = async (token, userLoginId, questions = {}) => {
	const { securityQuestion1, securityQuestion2, securityQuestion3 } = questions;
	// fire off ALL requests, if any fail they all fail???
	const [question1, question2, question3] = await Promise.all([
		validateSecurityQuestion(token, userLoginId, securityQuestion1),
		validateSecurityQuestion(token, userLoginId, securityQuestion2),
		validateSecurityQuestion(token, userLoginId, securityQuestion3),
	]);

	return {
		questionResult1: question1,
		questionResult2: question2,
		questionResult3: question3,
	};
};
// consider creating a 'message' handler to return any errors to the user via UI
const validateAllAnswers = async (
	token,
	userLoginId,
	allQuestions,
	answers = {}
) => {
	const {
		confirmSecurityAnswer1,
		confirmSecurityAnswer2,
		confirmSecurityAnswer3,
	} = answers;
	const { questionOneID, questionTwoID, questionThreeID } =
		prepareAnswerRequests(allQuestions, answers);
	// fire off ALL requests, if any fail they all fail???
	const [answer1, answer2, answer3] = await Promise.all([
		validateSecurityAnswer(
			token,
			userLoginId,
			questionOneID,
			confirmSecurityAnswer1
		),
		validateSecurityAnswer(
			token,
			userLoginId,
			questionTwoID,
			confirmSecurityAnswer2
		),
		validateSecurityAnswer(
			token,
			userLoginId,
			questionThreeID,
			confirmSecurityAnswer3
		),
	]);

	return {
		answerResult1: answer1,
		answerResult2: answer2,
		answerResult3: answer3,
	};
};

export { getUserSecurityInfo, getAllSecurityQuestions2 };

export { getResetMethods };

export { processAccountSecurity, formatSecurityQuestions, RESETS };

export {
	validateAllAnswers,
	validateAllQuestions,
	validateStrongPassword,
	validateUserLogin,
	createQuestionMap,
};
