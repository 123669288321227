import React, { useState, useEffect, useRef } from "react";
import styles from "../../css/daily/TaskUpdateForm.module.scss";
import sprite from "../../assets/icons/tasks.svg";
import sprite2 from "../../assets/icons/modals-complete.svg";
import sprite3 from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { format } from "date-fns";
import {
	isEmptyVal,
	isEmptyArray,
	isEmptyObj,
	hasProp,
} from "../../helpers/utils_types";
import { red, themeColors } from "../../helpers/utils_styles";
import {
	getTaskNotes,
	isCompleted,
	isScheduledTask,
	isLocked,
	getTaskDueTime,
	getTaskNameExtended,
	getTaskCategory,
} from "../../helpers/utils_tasks";
import { BASE_EXCEPTION_TYPES as baseExceptionTypes } from "../../helpers/utils_options";
import { getExceptionsFromRecords } from "../../helpers/utils_exceptions";
import { isRecurring } from "../../helpers/utils_repeatingTasks";
import { changedLock } from "../../helpers/utils_validation";
import { isAdminLevel } from "../../helpers/utils_userTypes";
import {
	enableFeatureViaInternalList,
	featureFlags,
} from "../../helpers/utils_features";
import { getTaskNotesByTaskID } from "../../helpers/utils_notes";
// components
import ResidentHeader from "../app/ResidentHeader";
import RadioButton from "../shared/RadioButton";
import CustomDropdown from "../shared/CustomDropdown";
import Checkbox from "../shared/Checkbox";
import Textarea from "../shared/Textarea";
import TextInput from "../shared/TextInput";
import ButtonSM from "../shared/ButtonSM";
import RepeatDesc from "./RepeatDesc";
import FormSection from "../forms/FormSection";
import FormActions from "../forms/FormActions";
import Divider from "../forms/Divider";
import RecentReassess from "./RecentReassess";
import TaskNotesController from "../notes/TaskNotesController";
import { isDevOrTest, isLocalhost } from "../../helpers/utils_env";
import OriginalTaskNote from "../notes/OriginalTaskNote";
import DueDatePicker from "./DueDatePicker";

// ##TODOS:
// - Migrate custom CSS to 'customCSS' object ✓
// - Disablee & hide 'Employee Signature' ✓
// - Disable 'Shift Selector' for completions ✓

const customCSS = {
	sectionSave: {
		backgroundColor: themeColors.main.blackBlue,
		color: "#ffffff",
		borderRadius: "5rem",
		fontWeight: "600",
		marginRight: ".5rem",
	},
	radios: {
		marginBottom: ".5rem",
	},
	checks: {
		marginBottom: ".5rem",
	},
	saveBtn: {
		padding: "1rem 1.6rem",
		backgroundColor: themeColors.main.main,
		borderRadius: "5rem",
		fontSize: "1.3rem",
	},
	cancelBtn: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: ".8rem 1.6rem",
		backgroundColor: "transparent",
		color: "#333",
		borderRadius: "5rem",
		fontSize: "1.3rem",
		border: "1px solid #ccc",
		marginLeft: "1rem",
	},
	notesTxt: {
		height: "10rem",
	},
	deleteBtn: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "#ffffff",
		backgroundColor: themeColors.main.red,
	},
};

const done = {
	color: themeColors.main.green,
};
const notDone = {
	color: themeColors.main.altYellow,
};

const disableDueDateBtn = (formState) => {
	const { touched, values } = formState;
	const { scheduledDueDate: date, scheduledDueTime: time } = values;
	const noChanges =
		!hasProp(touched, "scheduledDueDate") &&
		!hasProp(touched, "scheduledDueTime");
	const isEmpty = isEmptyVal(date) || isEmptyVal(time);

	return noChanges || isEmpty;
};

/**
 * Show task repeat settings if:
 * 1. User is admin type or above
 * 2. Task is NOT an assessment/scheduled task (eg. created/unscheduled task)
 *
 */
const showRepeatFeature = (task, currentUser) => {
	const { isAdmin, isExecutiveAdmin, isFacilityAdmin, isSuperUser } =
		currentUser;
	const isAdminType =
		isAdmin || isExecutiveAdmin || isFacilityAdmin || isSuperUser;

	const isUnscheduled = !isScheduledTask(task);

	return isUnscheduled && isAdminType;
};

const showLockTaskFeature = (task, currentUser) => {
	const { isAdmin, isExecutiveAdmin, isFacilityAdmin, isSuperUser } =
		currentUser;
	const isAdminType =
		isAdmin || isExecutiveAdmin || isFacilityAdmin || isSuperUser;
	const isUnscheduled = !isScheduledTask(task);

	return isUnscheduled && isAdminType;
};

const showNewTaskNotes = (currentUser) => {
	const isEnabled = true;
	// const isEnabled = enableFeatureViaInternalList(currentUser?.userID);
	// const isLocal = isLocalhost();
	// const isDev = isDevOrTest();
	// const isNotProd = isLocal || isDev;

	return isEnabled;
};
// PERMANENTLY DISABLED
const showOldTaskNotesSection = (currentUser) => {
	const isEnabled = false;

	return isEnabled;
};

// AKA "ADVANCED OPTIONS"
const TaskUpdateForm = ({
	formState = {},
	vals = {},
	task = {},
	handleShift,
	handleChange,
	handleSettings,
	handleCheckbox,
	syncTaskNotes,
	handleAdvancedCompletion,
	handleDateRange,
	handleDueDate,
	handleDueTime,
	handleRecurrences,
	saveSectionChanges,
	saveChanges,
	cancelChanges,
	deleteTask,
	setFormState,
	currentResident = {},
	currentUser = {},
	currentFacility = {},
	facilityExceptions = [],
	changedRecurringSettings = false,
	wasNoteSaved = false,
	saveRef = null,
}) => {
	const [showRepeatModal, setShowRepeatModal] = useState(false);
	const [repeatDesc, setRepeatDesc] = useState("");
	// task notes records
	const [allTaskNotes, setAllTaskNotes] = useState([]);

	const {
		exceptionType,
		reassess,
		reassessNotes,
		shift,
		taskNotes,
		signature,
		markComplete,
	} = vals;
	const { touched } = formState;

	// 'markComplete' is checked, make sure
	const disableForm = () => {
		const { touched, values } = formState;
		// const { markComplete, reassess } = values;

		const wasTouched = !isEmptyObj(touched);

		return !wasTouched;

		// if (markComplete) {
		// 	return false;
		// } else {
		// 	return isEmptyVal(exceptionType) && !reassess;
		// }
	};

	// closes repeat modal & indicates changes have been made
	const saveRepeat = (e) => {
		setShowRepeatModal(false);
		setFormState({
			...formState,
			values: {
				...vals,
				changedSettings: true,
			},
		});
	};

	const cancelRepeat = (e) => {
		setShowRepeatModal(false);
		setFormState({
			...formState,
			values: {
				...formState.values,
				isRecurring: false,
				recurringSelection: "Never", // REMOVE??? - duplicate of 'recurringType'???
				recurringType: "Never", // daily, weekly, etc.
				recurringCycle: "1", // every "2" weeks, every 2 months etc.
				recurringCycleOption: "",
				startDate: "",
				endDate: "",
				isRecurringSun: false,
				isRecurringMon: false,
				isRecurringTue: false,
				isRecurringWed: false,
				isRecurringThu: false,
				isRecurringFri: false,
				isRecurringSat: false,
			},
		});
	};

	const triggerSaveNote = (e) => {
		saveRef.current.click(e);
	};

	const statusTitle = (
		<span>
			Mark Task Complete:{" "}
			<b style={isCompleted(task) || markComplete ? done : notDone}>
				{isCompleted(task) || markComplete ? "Complete" : "Not-Complete"}
			</b>
		</span>
	);

	// fetches raw task note records for a task & sets state
	const fetchTaskNotes = async () => {
		const { token } = currentUser;
		const taskNoteRecords = await getTaskNotesByTaskID(token, task);

		if (!isEmptyArray(taskNoteRecords)) {
			return setAllTaskNotes([...taskNoteRecords]);
		} else {
			return setAllTaskNotes([]);
		}
	};

	// syncs list of task notes' changes
	const syncUpdatedTaskNotes = (newNotesList = []) => {
		setAllTaskNotes(newNotesList);
	};

	// fetches relevant task note records
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchTaskNotes();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<article className={styles.TaskUpdateForm}>
			{/* RESIDENT PHOTO,NAME, ID */}
			<section className={styles.TaskUpdateForm_resident}>
				<ResidentHeader
					currentResident={currentResident}
					currentUser={currentUser}
				/>
			</section>
			{/* TASK DETAILS */}
			<section className={styles.TaskUpdateForm_top}>
				<div className={styles.TaskUpdateForm_top_title}>
					<div className={styles.TaskUpdateForm_top_title_adl}>
						{getTaskCategory(task)}:
					</div>
					<div className={styles.TaskUpdateForm_top_title_name}>
						<div className={styles.TaskUpdateForm_top_title_name_icon}>
							<svg
								className={
									task.IsCompleted
										? styles.TaskUpdateForm_top_title_name_icon_statusBadge_isCompleted
										: styles.TaskUpdateForm_top_title_name_icon_statusBadge
								}
							>
								<use xlinkHref={`${sprite2}#icon-check_circle`}></use>
							</svg>
						</div>
						<div className={styles.TaskUpdateForm_top_title_name_task}>
							{getTaskNameExtended(task)}
						</div>
					</div>
				</div>
				{/* TRACK DATE - DUE DATE */}
				<div className={styles.TaskUpdateForm_top_date}>
					<svg className={styles.TaskUpdateForm_top_date_icon}>
						<use xlinkHref={`${sprite}#icon-event_note`}></use>
					</svg>
					<span className={styles.TaskUpdateForm_top_date_text}>
						{getTaskDueTime(task, new Date(), currentFacility.shifts)}
					</span>
				</div>
			</section>
			<Divider customStyles={{ opacity: ".2" }} />

			{/* DUE DATE/TIME SCHEDULER */}
			{featureFlags?.enableDueDate && (
				<>
					<FormSection
						title="Due Date/Time:"
						icon="clock"
						hoverText="Add a due date and time."
					>
						<DueDatePicker
							vals={vals}
							currentUser={currentUser}
							currentFacility={currentFacility}
							handleDueDate={handleDueDate}
							handleDueTime={handleDueTime}
						/>
						<FormActions
							key={`TUP--DUE-DATE-PICKER`}
							isDisabled={disableDueDateBtn(formState)}
							saveTxt="Save Date/Time"
							cancelTxt="Cancel"
							saveHandler={saveSectionChanges}
							cancelHandler={cancelChanges}
							saveCSS={customCSS.sectionSave}
						/>
					</FormSection>
					<Divider customStyles={{ opacity: ".2" }} />
				</>
			)}

			{/* EXCEPTIONS */}
			<FormSection
				title="Task Exceptions:"
				icon="calendarBusy"
				hoverText="Reason why care was not provided."
			>
				<>
					<div className={styles.TaskUpdateForm_main}>
						<CustomDropdown
							name="exceptionType"
							id="exceptionType"
							selection={exceptionType}
							setSelection={handleSettings}
							placeholder="Select Exception"
							autoComplete="off"
							inputMode="none"
							options={
								isEmptyArray(getExceptionsFromRecords(facilityExceptions))
									? baseExceptionTypes
									: getExceptionsFromRecords(facilityExceptions)
							}
						/>
					</div>
					<FormActions
						isDisabled={isEmptyVal(exceptionType)}
						saveTxt="Save Exception"
						cancelTxt="Cancel"
						saveHandler={saveSectionChanges}
						cancelHandler={cancelChanges}
						saveCSS={customCSS.sectionSave}
					/>
				</>
			</FormSection>
			<Divider customStyles={{ opacity: ".2" }} />

			{/* REASSESSMENT/SERVICE-PLAN-CHANGE SECTION */}
			{isScheduledTask(task) && (
				<FormSection
					// title="Reassess Task:"
					title="Service Plan Change:"
					icon="infoOutline"
					hoverText="Task requires service plan change. Timewise, or other reason."
				>
					<div className={styles.TaskUpdateForm_main}>
						<Checkbox
							id="reassess"
							name="reassess"
							// label="Task needs a reassessment"
							label="Suggested Service Plan change"
							val={reassess}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checks}
						/>

						{reassess && (
							<>
								<Textarea
									name="reassessNotes"
									id="reassessNotes"
									label="Service Plan Update Notes (REQUIRED)"
									placeholder={reassessNotes}
									val={reassessNotes}
									addRequiredFlag={true}
									enableCharCount={true}
									maxChar={250}
									handleChange={handleChange}
									customStyles={customCSS.checks}
								/>
								<FormActions
									isDisabled={isEmptyVal(reassessNotes)}
									saveTxt="Save Suggestion"
									cancelTxt="Cancel"
									saveHandler={saveSectionChanges}
									cancelHandler={cancelChanges}
									saveCSS={customCSS.sectionSave}
								/>
							</>
						)}
						<RecentReassess
							key={`REASSESS-RECORDS-${currentResident?.reassessRecords?.length}`}
							currentResident={currentResident}
							reassessRecords={currentResident?.reassessRecords}
							task={task}
						/>
					</div>
				</FormSection>
			)}
			<Divider customStyles={{ opacity: ".2" }} />

			{/* MARK COMPLETED SECTION: SHIFTS - RADIO BUTTONS */}
			<FormSection title={statusTitle} icon="checkbox" hideInfoIcon={true}>
				<div className={styles.TaskUpdateForm_main}>
					<div className={styles.TaskUpdateForm_main_markComplete}>
						<button
							className={styles.TaskUpdateForm_main_markComplete_btn}
							onClick={handleAdvancedCompletion}
						>
							<svg className={styles.TaskUpdateForm_main_markComplete_btn_icon}>
								<use xlinkHref={`${sprite2}#icon-check_circle`}></use>
							</svg>
							<span>
								{isCompleted(task) || markComplete
									? "Completed!"
									: "Click to Complete"}
							</span>
						</button>
					</div>

					{featureFlags?.enableShiftSelector && (
						<>
							<div className={styles.TaskUpdateForm_main_subLabel}>
								Task Was Completed During:
							</div>
							<RadioButton
								id="AM"
								name="shift"
								label="AM Shift"
								val={shift === "AM"}
								handleSelection={handleShift}
								customStyles={customCSS.radios}
							/>
							<RadioButton
								id="PM"
								name="shift"
								label="PM Shift"
								val={shift === "PM"}
								handleSelection={handleShift}
								customStyles={customCSS.radios}
							/>
							<RadioButton
								id="NOC"
								name="shift"
								label="NOC Shift"
								val={shift === "NOC"}
								handleSelection={handleShift}
								customStyles={customCSS.radios}
							/>
						</>
					)}
				</div>
				<FormActions
					isDisabled={!markComplete} //  (!markComplete || isEmptyVal(shift)) when enabled
					saveTxt="Save Status"
					cancelTxt="Cancel"
					saveHandler={saveSectionChanges}
					cancelHandler={cancelChanges}
					saveCSS={customCSS.sectionSave}
				/>
			</FormSection>
			<Divider customStyles={{ opacity: ".2" }} />

			{/* RECURRING/REPEATING TASK OPTIONS/DESCRIPTIONS */}
			{/* {!isScheduledTask(task) && ( */}
			{showRepeatFeature(task, currentUser) && (
				<>
					<FormSection
						title="Recurring/Repeat Settings:"
						icon="recurring"
						hoverText="Update/view when this task event repeats."
						isDisabled={isLocked(task)}
					>
						<div className={styles.TaskUpdateForm_main}>
							{isLocked(task) && (
								<div className={styles.TaskUpdateForm_main_isLocked}>
									<svg className={styles.TaskUpdateForm_main_isLocked_icon}>
										<use xlinkHref={`${sprite3}#icon-lock11`}></use>
									</svg>
									<span>Settings Locked</span>
								</div>
							)}
							<div className={styles.TaskUpdateForm_main_recurringDesc}>
								<RepeatDesc
									task={task}
									vals={vals}
									isRecurring={isRecurring(task)}
									addRepeat={() => setShowRepeatModal(true)}
									handleRecurrences={handleRecurrences}
									handleDateRange={handleDateRange}
									handleSettings={handleSettings}
									saveRepeat={saveRepeat}
									cancelRepeat={cancelRepeat}
									isLocked={isLocked(task)}
								/>
							</div>
							<FormActions
								isDisabled={!changedRecurringSettings}
								saveTxt="Save Repeat Settings"
								cancelTxt="Cancel"
								saveHandler={saveSectionChanges}
								cancelHandler={cancelChanges}
								saveCSS={customCSS.sectionSave}
								hide={!changedRecurringSettings}
							/>
						</div>
					</FormSection>
					<Divider customStyles={{ opacity: ".2" }} />
				</>
			)}

			{/* TASK NOTES */}
			{showOldTaskNotesSection(currentUser) && (
				<>
					<FormSection
						title="Add a Task Note:"
						icon="note"
						hoverText="Any notes will be recorded on the task record."
					>
						<div className={styles.TaskUpdateForm_main}>
							<Textarea
								name="taskNotes"
								id="taskNotes"
								val={taskNotes}
								handleChange={handleChange}
								enableCharCount={true}
								maxChar={250}
								customStyles={customCSS.notesTxt}
								placeholder={getTaskNotes(task)}
							/>
							<FormActions
								isDisabled={isEmptyVal(taskNotes)}
								saveTxt="Save Notes"
								cancelTxt="Cancel"
								saveHandler={saveSectionChanges}
								cancelHandler={cancelChanges}
								saveCSS={customCSS.sectionSave}
							/>
						</div>
					</FormSection>
					<Divider customStyles={{ opacity: ".2" }} />
				</>
			)}

			{/* NEW TASK NOTES SECTION */}
			{/* NEW TASK NOTES SECTION */}
			{/* NEW TASK NOTES SECTION */}
			{showNewTaskNotes(currentUser) && (
				<>
					<FormSection
						title="Task Notes:"
						icon="note"
						hoverText="Any notes will be recorded on the task record."
					>
						{/* <NewTaskNote vals={values}
					task={task}
					wasNoteSaved={wasNoteSaved}
					handleChange={handleChange}
					handleSubmit={handleTaskNoteSubmit}
					syncTaskNotes={syncTaskNotes}
					saveNewTaskNote={saveNewTaskNote}
					cancelNewTaskNote={cancelNewTaskNote}
					saveRef={saveRef} />  */}
						<TaskNotesController
							key={`TASK-NOTES-${allTaskNotes?.length}`}
							task={task}
							currentUser={currentUser}
							allTaskNotes={allTaskNotes}
							syncTaskNotes={syncTaskNotes}
							syncTaskNotesHandler={syncUpdatedTaskNotes}
							saveRef={saveRef}
							// new field
							wasNoteSaved={wasNoteSaved}
						/>
					</FormSection>
					<Divider customStyles={{ opacity: ".2" }} />
				</>
			)}
			{/* NEW TASK NOTES SECTION */}
			{/* NEW TASK NOTES SECTION */}
			{/* NEW TASK NOTES SECTION */}

			{/* LOCK/UNLOCK TASK */}
			{/* {currentUser?.isAdmin && !isScheduledTask(task) && ( */}
			{showLockTaskFeature(task, currentUser) && (
				<>
					<FormSection
						title="Lock/Unlock Task"
						icon="lock"
						iconColor={vals?.isLocked ? `#e84855` : null}
						hoverText="Locking a task prevents changes to it's recurring settings fro NON-ADMIN users."
					>
						<div className={styles.TaskUpdateForm_main_desc}>
							Locking a task will prevent unauthorized changes to a task from
							any "NON-ADMIN" user. Only users with "ADMIN" access/permissions
							are able to make changes to a "locked" task.
						</div>
						<Checkbox
							name="isLocked"
							id="isLocked"
							val={vals?.isLocked}
							label="Lock Task"
							handleCheckbox={handleCheckbox}
						/>
						<FormActions
							isDisabled={!changedLock(vals, task)}
							saveTxt="Save Lock"
							cancelTxt="Cancel"
							saveHandler={saveSectionChanges}
							cancelHandler={cancelChanges}
							saveCSS={customCSS.sectionSave}
						/>
					</FormSection>
					<Divider customStyles={{ opacity: ".2" }} />
				</>
			)}

			{/* DELETE TASK - FEATURE FLAG ONLY */}
			{featureFlags?.enableDelete && (
				<>
					<FormSection
						title="Delete Task (DEV ONLY)"
						icon="trash"
						iconColor={red[600]}
						titleColor={red[600]}
						hoverText="Delete this task permanently"
					>
						<div className={styles.TaskUpdateForm_main_delete}>
							<div className={styles.TaskUpdateForm_main_delete_msg}>
								Warning: Deleting a task will permanently remove it from the
								system. Use with caution.
							</div>

							<ButtonSM
								handleClick={() => deleteTask(task)}
								customStyles={customCSS.deleteBtn}
							>
								<svg className={styles.TaskUpdateForm_main_delete_icon}>
									<use xlinkHref={`${sprite}#icon-delete`}></use>
								</svg>
								<span>Delete</span>
							</ButtonSM>
						</div>
					</FormSection>

					<Divider customStyles={{ opacity: ".2" }} />
				</>
			)}

			{/* EMPLOYEE SIGNATURE - SAVE & CANCEL BUTTONS */}

			{featureFlags?.enableSignature && (
				<FormSection
					isDisabled={true}
					isHidden={true}
					title="Add a Signature:"
					icon="signature"
					hoverText="Employee signatures are recorded when a task is updated."
				>
					<div className={styles.TaskUpdateForm_main}>
						<TextInput
							name="signature"
							id="signature"
							val={signature}
							placeholder="Sara Johnso...."
							handleChange={handleChange}
						/>
						<div className={styles.TaskUpdateForm_main_timestamp}>
							Task updated at{" "}
							<b styles={{ color: themeColors.main.red }}>
								{format(new Date(), "MM/DD/YYYY h:mm a")}
							</b>
						</div>
					</div>
				</FormSection>
			)}
			{/* ACTION BUTTONS: SAVE/CANCEL */}
			{/* {featureFlags?.enableSaveAll && ( */}
			<div className={styles.TaskUpdateForm_main_bottom}>
				<ButtonSM
					type="button"
					isDisabled={disableForm()}
					customStyles={customCSS.saveBtn}
					handleClick={(e) => {
						saveChanges(task);
						triggerSaveNote(e);
					}}
					title="Please complete the form, first :)"
				>
					<svg className={styles.TaskUpdateForm_main_bottom_icon}>
						<use xlinkHref={`${sprite}#icon-save11`}></use>
					</svg>
					<span>Save All Changes</span>
				</ButtonSM>
				<ButtonSM
					customStyles={customCSS.cancelBtn}
					handleClick={cancelChanges}
				>
					<svg className={styles.TaskUpdateForm_main_bottom_cancel}>
						<use xlinkHref={`${sprite2}#icon-clearclose`}></use>
					</svg>
					<span>Cancel</span>
				</ButtonSM>
			</div>
			{/* )} */}
		</article>
	);
};

export default TaskUpdateForm;

TaskUpdateForm.defaultProps = {
	task: {},
	vals: {},
	currentResident: {},
	facilityExceptions: [],
};

TaskUpdateForm.propTypes = {
	vals: PropTypes.object.isRequired,
	task: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleCheckbox: PropTypes.func.isRequired,
	handleShift: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired, // handles submit and global store update
	cancelChanges: PropTypes.func.isRequired, // handles cancelling form submission, clears values
	currentResident: PropTypes.object.isRequired,
	facilityExceptions: PropTypes.array.isRequired,
};
