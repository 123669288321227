import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { versionRelease, logVersion } from "./helpers/utils_version";
import { currentEnvName } from "./helpers/utils_env";

// console.log(`Release:`, versionRelease);

// ##TODOS:
// - Turn off debug mode later and wire up custom options for 'Google Analytics'
// - Finish setting custom options for 'Google Analytics'
// - Consider implementing userId: generateID(6)

// updates document title based on environment (ENV)
/**
 * Checks current 'ENVIRONMENT' to determine what the document.title (ie. the tab) show display
 * - PRODUCTION: "Care Tracker"
 * - TEST: "Care Tracker ~ 👉 TEST 👈 "
 */
const getDocTitle = () => {
	const envName = currentEnvName;
	if (envName === "prod" || envName === "production") {
		return (document.title = "Care Tracker");
	} else {
		return (document.title = "Care Tracker ~ 👉 TEST 👈 ");
	}
};

getDocTitle();

/**
 * Initilized "Google Analytics" tracker via tracking ID.
 * - Supports pageview tracking
 */
const trackingId = "UA-180266919-1";
ReactGA.initialize(trackingId, {
	debug: false,
	gaOptions: {},
});

/**
 * Initialized "Sentry" tracker via DSN key
 * - Added 7/29/2020 @ 8:44 AM
 */
Sentry.init({
	dsn: "https://9e4a191841b04c0a9d0e633ca61f431e@o426685.ingest.sentry.io/5369788",
	release: `ALA Care Tracker: ${versionRelease}`,
});

logVersion(versionRelease);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
