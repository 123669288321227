import React from "react";
import styles from "../../css/adlschedule/AdlColumnHeadings.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import AdlColumnHeading from "./AdlColumnHeading";

// uses 'AssessmentCategory' records

const AdlColumnHeadings = ({
	adlHeadings = [],
	disabledADLs = ["Bathing", "Laundry", "StatusChecks", "Health"],
	isLoadingSettings = false,
}) => {
	return (
		<section className={styles.AdlColumnHeadings}>
			{!isEmptyArray(adlHeadings) &&
				adlHeadings.map((adl, i) => (
					<AdlColumnHeading
						adl={adl}
						key={`${adl.AssessmentCategoryId}___${adl.Name}`}
						isDisabled={disabledADLs.includes(adl.Name)}
					/>
				))}
		</section>
	);
};

export default AdlColumnHeadings;

AdlColumnHeadings.defaultProps = {
	adlHeadings: [],
	disabledADLs: ["Bathing", "Laundry", "StatusChecks", "Health"],
};

AdlColumnHeadings.propTypes = {
	adlHeadings: PropTypes.arrayOf(
		PropTypes.shape({
			AssessmentCategoryId: PropTypes.number.isRequired,
			Name: PropTypes.string.isRequired,
			Description: PropTypes.string.isRequired,
			IndicatorColor: PropTypes.string,
			IndicatorIcon: PropTypes.string,
		})
	).isRequired,
	disabledADLs: PropTypes.arrayOf(PropTypes.string).isRequired,
};
