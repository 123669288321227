import React, { useState, useRef } from "react";
import sprite from "../../assets/icons/tasks.svg";
import styles from "../../css/pastdue/PastDueEntry.module.scss";
import closeIcon from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
// helpers
import { themeColors } from "../../helpers/utils_styles";
import {
	getTaskCategory,
	getTaskDescription,
	getPastDueTime,
} from "../../helpers/utils_tasks";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useEffect } from "react";
// components
import StatusBadge from "../shared/StatusBadge";

// ##TODOS:
// 1. Add 'MarkComplete' feature handler ✓
// 		- Fetch tracking record ✓
// 		- Apply 'Mark Complete' update(s) ✓
// 		- Fire off update request ✓
// 		- Update local store ✓

const featureConfig = {
	edit: false,
	complete: true,
};

const PastDueEntry = ({
	task,
	taskIndex,
	shiftTimes = [],
	markComplete,
	editTask,
}) => {
	const [showOptions, setShowOptions] = useState(false);

	return (
		<article
			className={styles.PastDueEntry}
			title={getTaskDescription(task, 200)}
		>
			<span className={styles.PastDueEntry_taskNumber}>{taskIndex}</span>
			{/* TASK NAME, DESC & ADL */}
			<section className={styles.PastDueEntry_group}>
				<h2 className={styles.PastDueEntry_group_title}>
					{getTaskCategory(task)}
				</h2>
				<p className={styles.PastDueEntry_group_text}>
					{getTaskDescription(task, 50)}
				</p>
			</section>
			{/* STATUS BADGE */}
			<section className={styles.PastDueEntry_group_two}>
				<StatusBadge size="XSM" status={`PAST-DUE`}>
					{`PAST-DUE`}
				</StatusBadge>
			</section>
			{/* PAST DUE TIME/DATE */}
			<section className={styles.PastDueEntry_group_three}>
				<svg className={styles.PastDueEntry_icon}>
					<use xlinkHref={`${sprite}#icon-timer`}></use>
				</svg>
				<h4 className={styles.PastDueEntry_group_three_date}>
					Due <b>{getPastDueTime(task, shiftTimes)}</b>
				</h4>
			</section>
			{/* OPTIONS MENU - DOTS */}
			<section className={styles.PastDueEntry_group_four}>
				<svg
					className={styles.PastDueEntry_icon}
					onClick={() => setShowOptions(true)}
				>
					<use xlinkHref={`${sprite}#icon-dots-three-horizontal`}></use>
				</svg>

				{showOptions && (
					<PastDueEntryMenu
						closeOptions={() => setShowOptions(false)}
						markComplete={markComplete}
						editTask={editTask}
					/>
				)}
			</section>
		</article>
	);
};

export default PastDueEntry;

PastDueEntry.defaultProps = {
	currentUser: {},
	shiftTimes: [],
};

PastDueEntry.propTypes = {
	task: PropTypes.object.isRequired,
	currentUser: PropTypes.object.isRequired,
	shiftTimes: PropTypes.array.isRequired,
	markComplete: PropTypes.func.isRequired,
	editTask: PropTypes.func.isRequired,
};

// ##TODOS:
// - Add supporting code for options menu items:
// 		- 'Remove Task'
// 		- 'Mark Complete'
// 		- 'Print Report'
// 		- 'Edit/Update Task' (ie Advanced Options)

const PastDueEntryMenu = ({ closeOptions, markComplete, editTask }) => {
	const optionsRef = useRef();
	const { isOutside } = useOutsideClick(!optionsRef ? null : optionsRef);

	// close 'Options' menu when clicking outside
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside) {
			closeOptions();
		}
		return () => {
			isMounted = false;
		};
	}, [closeOptions, isOutside]);

	return (
		<aside className={styles.PastDueEntryMenu} ref={optionsRef}>
			<ul className={styles.PastDueEntryMenu_list}>
				<li className={styles.PastDueEntryMenu_list_item}>
					<svg className={styles.PastDueEntry_icon} onClick={closeOptions}>
						<use
							xlinkHref={`${closeIcon}#icon-clearclose`}
							fill={themeColors.main.red}
						></use>
					</svg>
				</li>
				<li
					onClick={markComplete}
					className={styles.PastDueEntryMenu_list_item}
				>
					<span>Mark Completed</span>
				</li>
				{featureConfig?.edit && (
					<li onClick={editTask} className={styles.PastDueEntryMenu_list_item}>
						<span>Edit/Update Task</span>
					</li>
				)}
			</ul>
		</aside>
	);
};

PastDueEntryMenu.defaultProps = {};
PastDueEntryMenu.propTypes = {
	closeOptions: PropTypes.func.isRequired,
	markComplete: PropTypes.func.isRequired,
	editTask: PropTypes.func.isRequired,
};
