import React, { useState } from "react";
import { getAlertType, hasMsg } from "../helpers/utils_alerts";
import AlertsNotifier from "../components/shared/AlertsNotifier";

// example of static config - ignore
export const initialSettings = {
	type: "SUCCESS",
	trigger: false,
	msg: {
		heading: "Success!",
		subheading: "Your changes were saved.",
	},
	expiry: 3000,
};

export const useAlerts = ({ ...initialAlertState }) => {
	const [alerts, setAlerts] = useState(initialAlertState);

	// falls back to default settings, is empty "msg"
	const dispatchAlert = (type = "success", msg = {}) => {
		if (!hasMsg(msg)) {
			return setAlerts({
				...alerts,
				type: type,
				trigger: true,
			});
		} else {
			return setAlerts({
				type: type,
				trigger: true,
				msg: { ...msg },
			});
		}
	};

	const resetTrigger = () => {
		setAlerts({
			...alerts,
			trigger: false,
		});
	};

	const AlertsHandler = (
		<AlertsNotifier
			triggerAlert={alerts.trigger}
			resetTrigger={resetTrigger}
			type={getAlertType(alerts.type)}
			expiry={alerts.expiry}
			alerts={alerts.msg}
		/>
	);

	return {
		alerts,
		setAlerts,
		dispatchAlert,
		resetTrigger,
		AlertsHandler,
	};
};
