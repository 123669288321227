import React, { useState, useEffect, useRef } from "react";
import styles from "../../css/reports/RecentlyViewedReportCard.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import sprite2 from "../../assets/icons/icon-bar.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { formatReportDesc } from "../../helpers/utils_descriptions";

const RecentlyViwedReportCardMenu = ({
	reportDetails = {},
	rerunReport,
	saveReport,
	printReport,
	removeItem,
	closeMenu,
}) => {
	const menuRef = useRef();
	const { isOutside } = useOutsideClick(menuRef);

	// closes menu when click event outside of menu occurs
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside) {
			return closeMenu();
		}

		return () => {
			isMounted = false;
		};
	}, [closeMenu, isOutside]);

	return (
		<aside className={styles.RecentlyViewedReportCardMenu} ref={menuRef}>
			<ul className={styles.RecentlyViewedReportCardMenu_list}>
				<li
					className={styles.RecentlyViewedReportCardMenu_list_item}
					onClick={() => saveReport(reportDetails.id)}
				>
					<svg className={styles.RecentlyViewedReportCardMenu_list_item_icon}>
						<use xlinkHref={`${sprite}#icon-save11`}></use>
					</svg>
					<span>Save Report</span>
				</li>
				<li
					className={styles.RecentlyViewedReportCardMenu_list_item}
					onClick={() => printReport(reportDetails)}
				>
					<svg className={styles.RecentlyViewedReportCardMenu_list_item_icon}>
						<use xlinkHref={`${sprite}#icon-local_print_shopprint`}></use>
					</svg>
					<span>Print Report</span>
				</li>
				<li
					className={styles.RecentlyViewedReportCardMenu_list_item}
					onClick={() => rerunReport(reportDetails)}
				>
					<svg className={styles.RecentlyViewedReportCardMenu_list_item_icon}>
						<use xlinkHref={`${sprite}#icon-add_chart`}></use>
					</svg>
					<span>Re-run Report</span>
				</li>
				<li
					className={styles.RecentlyViewedReportCardMenu_list_item}
					onClick={() => removeItem(reportDetails.id)}
				>
					<svg className={styles.RecentlyViewedReportCardMenu_list_item_icon}>
						<use
							xlinkHref={`${sprite}#icon-clearclose`}
							fill="hsla(352, 70%, 50%, 1)"
						></use>
					</svg>
					<span style={{ color: "hsla(352, 70%, 50%, 1)" }}>
						Remove from "Recently Viewed"
					</span>
				</li>
			</ul>
		</aside>
	);
};

const RecentlyViewedReportCard = ({
	reportDetails = {},
	rerunReport,
	saveReport,
	printReport,
	removeItem,
}) => {
	const [showMenu, setShowMenu] = useState(false);

	return (
		<article className={styles.RecentlyViewedReportCard}>
			<section className={styles.RecentlyViewedReportCard_top}>
				<h6 className={styles.RecentlyViewedReportCard_top_name}>
					{reportDetails?.name}
				</h6>
				<svg
					className={styles.RecentlyViewedReportCard_top_icon}
					onClick={() => setShowMenu(true)}
				>
					<use xlinkHref={`${sprite2}#icon-dots-three-horizontal`}></use>
				</svg>
				{showMenu && (
					<RecentlyViwedReportCardMenu
						reportDetails={reportDetails}
						saveReport={saveReport}
						printReport={printReport}
						removeItem={() => removeItem(reportDetails?.id)}
						closeMenu={() => setShowMenu(false)}
					/>
				)}
			</section>
			<section className={styles.RecentlyViewedReportCard_desc}>
				<p className={styles.RecentlyViewedReportCard_desc_text}>
					{formatReportDesc(reportDetails?.desc)}
				</p>
				<span className={styles.RecentlyViewedReportCard_desc_dateCreated}>
					Date Created: <b>{reportDetails?.dateCreated}</b>
				</span>
			</section>
			<section className={styles.RecentlyViewedReportCard_actions}>
				<button
					className={styles.RecentlyViewedReportCard_actions_rerunReport}
					title="Re-run Report"
					onClick={rerunReport}
				>
					<svg
						className={styles.RecentlyViewedReportCard_actions_rerunReport_icon}
					>
						<use xlinkHref={`${sprite}#icon-insert_chartpollassessment`}></use>
					</svg>
					<span>Run Report →</span>
				</button>
				<button
					className={styles.RecentlyViewedReportCard_actions_remove}
					title="Remove report from 'Recently Viewed' list."
					onClick={removeItem}
				>
					<svg className={styles.RecentlyViewedReportCard_actions_remove_icon}>
						<use xlinkHref={`${sprite}#icon-delete`}></use>
					</svg>
					<span>Remove</span>
				</button>
			</section>
		</article>
	);
};

export default RecentlyViewedReportCard;

RecentlyViwedReportCardMenu.defaultProps = {
	reportDetails: {},
};

// dropdown menu component
RecentlyViwedReportCardMenu.propTypes = {
	reportDetails: PropTypes.object.isRequired,
	rerunReport: PropTypes.func.isRequired,
	saveReport: PropTypes.func.isRequired,
	printReport: PropTypes.func.isRequired,
	removeItem: PropTypes.func.isRequired,
	closeMenu: PropTypes.func.isRequired,
};

RecentlyViewedReportCard.defaultProps = {
	reportDetails: {},
};

RecentlyViewedReportCard.propTypes = {
	reportDetails: PropTypes.object.isRequired,
	rerunReport: PropTypes.func.isRequired,
	saveReport: PropTypes.func.isRequired,
	printReport: PropTypes.func.isRequired,
	removeItem: PropTypes.func.isRequired,
	rerunReportHandler: PropTypes.func.isRequired,
};
