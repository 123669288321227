import * as Sentry from "@sentry/react";
import { currentEnv } from "./utils_env";
import { scheduledTasks, unscheduledTasks } from "./utils_endpoints";
import { isEmptyArray, isEmptyVal, isEmptyObj, hasProp } from "./utils_types";
import { getShiftID } from "./utils_shifts";
import { getResolutionID } from "./utils_resolution";
import {
	saveUnscheduledUpdates,
	getUnscheduledRecord,
} from "./utils_unscheduled";
import {
	saveScheduledUpdates,
	isScheduledTask,
	toggleComplete,
	toggleCompleteByADL,
	getTaskID,
	findTasksByADL,
	isUITask,
	getScheduledRecord,
	checkForPastDue,
	getTaskType,
	isUnscheduledTask,
} from "./utils_tasks";
import { getExceptionID, hasException } from "./utils_exceptions";
import { getRecurringTypeID } from "./utils_repeatingTasks";
import { denyPastDueChange } from "./utils_pastdue";
import {
	format,
	startOfWeek,
	endOfWeek,
	eachDay,
	getTime,
	setHours,
	setMinutes,
	getHours,
	getMinutes,
} from "date-fns";
import { getCategoryNameFromID } from "./utils_categories";
import {
	initAndUpdateReassessModel,
	saveReassessMany,
	updateReassessModel,
} from "./utils_reassess";
import { initAndUpdateNewTaskNote, saveTaskNotes } from "./utils_notes";
import {
	convertHoursStringToMilitary,
	extractTimeUnitsFromString,
} from "./utils_dates";
import { checkForChanges } from "./utils_validation";
import { featureFlags } from "./utils_features";

// TASK IDS BY TYPE
const SCHEDULED_ID = "AssessmentTrackingTaskId";
const UNSCHEDULED_ID = "AssessmentUnscheduleTaskId";

/////////////////////////////////////////////////////////////////
///////////// TASK RECORD UPDATE HELPERS (PAST-DUE) /////////////
/////////////////////////////////////////////////////////////////

// updates a single 'scheduled' tracking record
const saveScheduledRecord = async (token, record) => {
	let url = currentEnv.base + scheduledTasks.save.task;
	url +=
		"?" +
		new URLSearchParams({
			AssessmentTrackingTaskId: record?.AssessmentTrackingTaskId,
		});

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(record),
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};
// updates a single 'unscheduled' tracking record
const saveUnscheduledRecord = async (token, record) => {
	let url = currentEnv.base + unscheduledTasks.save.task;
	url +=
		"?" +
		new URLSearchParams({
			AssessmentUnscheduleTaskId: record?.AssessmentUnscheduleTaskId,
		});

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(record),
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// checks task type, fires off 'SAVE' request
const savePastDueTracking = async (token, record) => {
	if (isScheduledTask(record)) {
		return await saveScheduledRecord(token, record);
	} else {
		return await saveUnscheduledRecord(token, record);
	}
};
const getPastDueTracking = async (token, task) => {
	if (isScheduledTask(task)) {
		const { AssessmentTrackingTaskId: id } = task;
		const [tracking] = await getScheduledRecord(token, id);
		return tracking;
	} else {
		const { AssessmentUnscheduleTaskId: id } = task;
		const [tracking] = await getUnscheduledRecord(token, id);
		return tracking;
	}
};

const markCompleteTracking = (record, currentShiftID) => {
	return {
		...record,
		IsCompleted: true,
		CompletedDate: new Date().toISOString(),
		CompletedAssessmentShiftId: currentShiftID,
		AssessmentTaskStatusId: 2,
	};
};

/////////////////////////////////////////////////////////////////
//////////////// TASK RECORD UPDATE MINI-HELPERS ////////////////
/////////////////////////////////////////////////////////////////

/**
 * @description - Creates 'timestamp' message w/ the date & time something was changed/edited.
 * @param {Date} date - A date instance used as the base for the formatted timestamp.
 * @returns {String} - Returns formatted string w/ the data & time. Format: '- Updated MM/DD/YYYY at hh:mm AM|PM'
 */
const addTimeStamp = (date = new Date()) => {
	const newDate = format(date, "MM/DD/YYYY");
	const msg = `- Updated ${newDate} at ${format(date, "hh:mm A")}`;
	return msg;
};

/**
 * @description - Merges & formats existing task notes with newly entered notes. Now adds user stamp
 * Details:
 * Will check for new & existing notes (including 'reassess notes')
 * ...and merge them together in the 'Notes' field and add a timestamp.
 * @param {Object} vals - An object of "user-selected" values.
 * @param {String} prevNotes - The existing notes for the current active task being updated.
 */
const mergeTaskNotes = (vals, prevNotes, currentUser) => {
	const { username } = currentUser;

	// ##TODOS: 8/3/2022 at 8:34AM
	// - Update to replace empty lines (eg. ' \n\n')
	// 		- These are no longer needed, since notes are handled differently now

	// let newNotes = replaceNullWithMsg(prevNotes, "");
	let newNotes = isEmptyVal(prevNotes) ? "" : prevNotes;
	// if 'prevNotes' is NOT empty, then add line break, otherwise move on
	if (!isEmptyVal(newNotes)) {
		newNotes += ` \n\n`;
	}
	if (vals.reassess) {
		// only 'reassessNotes' applied
		if (isEmptyVal(vals.taskNotes)) {
			newNotes += `Suggest: ${vals.reassessNotes} \n\n `;
			newNotes += `${addTimeStamp()} (${username})`;
			return newNotes;
		}
		// 'reassessNotes' & 'taskNotes' applied
		// newNotes += `Updated: ${vals.taskNotes} \n\n`;
		newNotes += `Suggest: ${vals.reassessNotes} \n\n`;
		newNotes += ` ${addTimeStamp()} (${username})`;
		return newNotes;
	} else {
		// no new notes applied; returns original notes
		if (isEmptyVal(vals.taskNotes)) {
			return newNotes;
		}
		// only 'taskNotes' applied
		// newNotes += `Updated: ${vals.taskNotes} \n\n`;
		// newNotes += ` ${addTimeStamp()} (${username})`;
		return newNotes;
	}
};

/////////////////////////////////////////////////////////////////
////////////// SCHEDULED|UNSCHEDULED TASK UPDATES //////////////
/////////////////////////////////////////////////////////////////

/**
 * @description - Find an "ADLCareTask"s matching tracking record.
 * @param {Object} activeTask - An "ADLCareTask" used to find its matching 'Tracking' record.
 * @param {Array} trackingTasks - An array of "AssessmentTrackingTask" records, to search for a match in.
 * @returns {Object} - Returns the matching "AssessmentTrackingTask" record; otherwise an empty object is returned (ie {}).
 */
const findTrackingRecord = (activeTask, trackingTasks = []) => {
	return trackingTasks.reduce((match, current) => {
		if (activeTask[SCHEDULED_ID] === current[SCHEDULED_ID]) {
			match = { ...current };
			return match;
		}
		return match;
	}, {});
};

/**
 * @description - Find a (modified) "Unscheduled" tasks matching "AssessmentUnscheduleTask" record.
 * @param {Object} activeTask - An "AssessmentUnscheduleTask" record (w/ property additions) used to find its matching 'Raw' record.
 * @param {Array} unscheduledRaw - An array of (unmodified) "AssessmentUnscheduleTask" records that match the corresponding table in the database.
 * @returns {Object} - Returns the matching 'AssessmentUnscheduleTask' record; otherwise an empty object is returned (ie {}).
 */
const findUnscheduledRecord = (activeTask, unscheduledRaw = []) => {
	return unscheduledRaw.reduce((match, current) => {
		if (activeTask[UNSCHEDULED_ID] === current[UNSCHEDULED_ID]) {
			match = { ...current };
			return match;
		}
		return isEmptyObj(match) ? activeTask : match;
	}, {});
};

/**
 * @description - Returns an active task's matching record to be updated and saved to ALA Services. (Supports 'scheduled'/'unscheduled')
 * @param {Object} activeTask - An active task: "ADLCareTask" or a modified "AssessmentUnscheduleTask" record.
 * @param {Array} trackingTasks - An array of "AssessmentTrackingTask" records to check for matches.
 * @param {Array} unscheduledTasksRaw - An array of "AssessmentUnscheduleTask" records to check for matches.
 * @returns {Object} - Returns the matching record, or an empty object (ie {}) if not match is found.
 */
const findMatchingRecord = (
	activeTask,
	trackingTasks = [],
	unscheduledTasksRaw = []
) => {
	if (isScheduledTask(activeTask)) {
		const matchingRecord = findTrackingRecord(activeTask, trackingTasks);
		return matchingRecord;
	} else {
		const matchingRecord = findUnscheduledRecord(
			activeTask,
			unscheduledTasksRaw
		);
		return matchingRecord;
	}
};

/**
 * @description - Applies "user-selected" values to an "ADLCareTask" record.
 * @param {Object} vals - An object of 'user-selected' values to be applied.
 * @param {Object} recordToUpdate - A task record (ie "ADLCareTask") to be updated.
 * @return {Object} - Returns the updated task record w/ the applied form values.
 */
const applyScheduledUpdates = (vals, recordToUpdate, currentUser) => {
	const { shift, signature, reassess } = vals;
	if (reassess) {
		return {
			...recordToUpdate,
			AssessmentTaskStatusId: 2,
			CompletedAssessmentShiftId: getShiftID(shift),
			AssessmentShiftId: getShiftID(shift),
			AssessmentResolutionId: getResolutionID("COMPLETED-REASSESSMENT-NEEDED"),
			AssessmentReasonId: 6,
			IsCompleted: true,
			Notes: mergeTaskNotes(vals, recordToUpdate.Notes, currentUser),
			SignedBy: signature,
			CompletedDate: new Date().toISOString(),
		};
	} else {
		return {
			...recordToUpdate,
			AssessmentTaskStatusId: 2,
			CompletedAssessmentShiftId: getShiftID(shift),
			AssessmentShiftId: getShiftID(shift),
			AssessmentResolutionId: getResolutionID("COMPLETED"),
			AssessmentReasonId: 6,
			IsCompleted: true,
			Notes: mergeTaskNotes(vals, recordToUpdate.Notes, currentUser),
			SignedBy: signature,
			CompletedDate: new Date().toISOString(),
		};
	}
};

/**
 * @description - Applies 'user-selected' values to an unscheduled task record, prior to sending to ALA Services.
 * @param {Object} vals - An object of 'user-selected' values from the "Advanced Options" modal.
 * @param {Object} recordToUpdate - An "AssessmentUnscheduleTask" record, to be updated w/ user values.
 * @returns {Object} - Returns the updated "AssessmentUnscheduleTask" record, w/ the applied user values.
 * - Updated 'CompletedDate' to use ISOString() 12/3/2020 at 6:11 AM
 */
const applyUnscheduledUpdates = (vals, recordToUpdate) => {
	const { shift, signature, reassess } = vals;

	if (reassess) {
		return {
			...recordToUpdate,
			AssessmentTaskStatusId: 2,
			CompletedAssessmentShiftId: getShiftID(shift),
			AssessmentShiftId: getShiftID(shift),
			AssessmentResolutionId: getResolutionID("COMPLETED-REASSESSMENT-NEEDED"),
			AssessmentReasonId: 6,
			IsCompleted: true,
			Notes: mergeTaskNotes(vals, recordToUpdate.Notes),
			SignedBy: signature,
			CompletedDate: new Date().toISOString(),
		};
	} else {
		return {
			...recordToUpdate,
			AssessmentTaskStatusId: 2,
			CompletedAssessmentShiftId: getShiftID(shift),
			AssessmentShiftId: getShiftID(shift),
			AssessmentResolutionId: getResolutionID("COMPLETED"),
			AssessmentReasonId: 6,
			IsCompleted: true,
			Notes: mergeTaskNotes(vals, recordToUpdate.Notes),
			SignedBy: signature,
			CompletedDate: new Date().toISOString(),
		};
	}
};

/**
 * @description - Finds the matching task record, applies user-selected values & submits/saves to the server (ALA Services)
 * Supports: Scheduled("ADLCareTask") & Unscheduled("AssessmentUnscheduleTask") record.
 * @param {String} token - A base64 encoded auth token.
 * @param {Object} vals - An object of "user-selected" form values (from the <TaskUpdateForm/>).
 * @param {Object} activeTask - An active task record to apply updates for. (ie "Scheduled"/"Unscheduled" task).
 * @param {Array} trackingTasks - An array of AssessmentTrackingTask records used server-side in the database.
 * @param {Array} unscheduledTasksRaw - An array of unmodified AssessmentUnscheduleTask records, used for updates.
 */
const findAndApplyTaskUpdates = async (
	token,
	vals,
	activeTask,
	trackingTasks = [],
	unscheduledTasksRaw = [],
	currentUser = {}
) => {
	if (isScheduledTask(activeTask)) {
		// find trackingRecord, apply updates, send request
		const matchingRecord = findMatchingRecord(activeTask, trackingTasks);
		const updatedRecord = applyScheduledUpdates(
			vals,
			matchingRecord,
			currentUser
		);
		return await saveScheduledUpdates(token, updatedRecord);
	} else {
		// find unscheduledRaw record, apply updates, send request
		const matchingRecord = findUnscheduledRecord(
			activeTask,
			unscheduledTasksRaw
		);
		const updatedRecord = applyUnscheduledUpdates(vals, matchingRecord);
		return await saveUnscheduledUpdates(token, updatedRecord);
	}
};

// custom resolver/wrapper around updater fn
const taskResolver = async (
	token,
	vals,
	activeTask,
	trackingTasks,
	unscheduledTasksRaw,
	currentUser
) => {
	const taskID =
		activeTask?.AssessmentTrackingTaskId ??
		activeTask.AssessmentUnscheduleTaskId;
	const response = await findAndApplyTaskUpdates(
		token,
		vals,
		activeTask,
		trackingTasks,
		unscheduledTasksRaw,
		currentUser
	);
	if (response) {
		return response.includes(taskID);
	}
	return response;
};

// pure request fn - only handles server updates
const applyTaskChanges = async (token, updatedTask) => {
	if (isScheduledTask(updatedTask)) {
		const response = await saveScheduledUpdates(token, updatedTask);
		return response;
	} else {
		const response = await saveUnscheduledUpdates(token, updatedTask);
		return response;
	}
};

// applies server-side updates from the "quick-complete" actions
const applyQuickComplete = async (token, task, trackingTasks) => {
	if (isScheduledTask(task)) {
		// handle scheduled task request here...
		const matchingRecord = findMatchingRecord(task, trackingTasks);
		const response = await saveScheduledUpdates(token, matchingRecord);
		return response;
	} else {
		// handle unscheduled task request here...
		const response = await saveUnscheduledUpdates(token, task);
		return response;
	}
};

// finds matching record, toggles it complete/not-complete, sends requet
const recordQuickComplete = async (
	token,
	activeTask,
	trackingTasks,
	unscheduledTasksRaw,
	shiftTimes = [],
	currentUser = {}
) => {
	const matchingRecord = findMatchingRecord(
		activeTask,
		trackingTasks,
		unscheduledTasksRaw
	);
	const withPastDue = pastDueHandler(matchingRecord, shiftTimes);
	const updatedTask = {
		...withPastDue,
		IsCompleted: activeTask.IsCompleted,
		CompletedDate: activeTask.CompletedDate,
		AssessmentTaskStatusId: activeTask?.AssessmentTaskStatusId, // fixed to assist CompletionReport
		CompletedByUserId: currentUser?.userID,
		UserId: currentUser?.userID,
	};

	// const response = true;

	console.log("updatedTask (recordQuickComplete):", updatedTask);
	const response = await applyTaskChanges(token, updatedTask);

	if (response) {
		return response;
	} else {
		return response;
	}
};

///////////////////////////////////////////////////////////////////////
///////////////// MARK ALL COMPLETE HELPERS/HANDLERS /////////////////
///////////////////////////////////////////////////////////////////////

/**
 * @description - Finds the matching records (multiple) given a list of tasks.
 * @param {Array} activeTasks - An array of active task records (supports 'scheduled'|'unscheduled' tasks).
 * @param {Array} scheduledRecords - An array of 'AssessmentTrackingTask' records.
 * @param {Array} unscheduledRecords - An array of 'UnscheduleRaw' task records.
 * @return {Array} - Returns an array of matching records to be updated.
 */
const findAllMatchingRecords = (
	activeTasks,
	scheduledRecords = [],
	unscheduledRecords = []
) => {
	const matchingRecords = activeTasks.map((active, idx) => {
		const match = findMatchingRecord(
			active,
			scheduledRecords,
			unscheduledRecords
		);
		return match;
	});
	return [...matchingRecords];
};

/**
 * @description - Returns an object w/ the provided tracking records grouped by type: 'Scheduled' & 'Unscheduled'.
 * - Will initialize a tracking type w/ an empty [], if there's no records for that type.
 * - Used w/ the "markAllComplete" feature. This separates the record types for sending requests.
 * @param {Array} allPendingRecords - List of pending tracking records needing to be sent server-side to be updated.
 * @returns {Object} - Returns an object w/ 'Scheduled' & 'Unscheduled' properties w/ their respective records in an array.
 */
const groupTrackingByType = (allPendingRecords) => {
	// sets keys in object by task
	const iteratee = (task) =>
		isScheduledTask(task) ? "Scheduled" : "Unscheduled";
	return allPendingRecords.reduce((acc, item) => {
		const keyToSortBy = iteratee(item);
		if (!acc[keyToSortBy]) {
			acc[keyToSortBy] = [];
		}
		acc[keyToSortBy].push(item);
		return {
			Scheduled: acc?.Scheduled ?? [],
			Unscheduled: acc?.Unscheduled ?? [],
		};
	}, {});
};

/**
 * @description - 'Mark All Complete' handler. Toggles UI tasks by ADL, finds/updates tracking records, returns tracking grouped by task type.
 * @param {Array} allTasks - An array of UI task records (ie 'ADLCareTask', 'AssessmentUnscheduleTask').
 * @param {String} adl - An 'AssessmentCategory' string for the active 'card' being updated.
 * @param {Array} scheduledRecords - An array of ALL 'AssessmentTrackingTask' records; used for tracking.
 * @param {Array} unscheduledRecords - An array of ALL 'AssessmentUnscheduleTaskRaw' records; used for tracking.
 * @returns {Object} - Returns an object w/ 'Scheduled' & 'Unscheduled' as properties to access each updated record by type.
 * - "updated.Scheduled": updated 'AssessmentTrackingTask' records, ready to be sent to ALA Services.
 * - "updated.Unscheduled": updated 'AssessmentUnscheduleTaskRaw' records, ready to be sent to ALA Services.
 */
const markCompleteTasksAndRecords = (
	allTasks,
	adl,
	scheduledRecords = [],
	unscheduledRecords = []
) => {
	const updatedAllTasks = toggleCompleteByADL(allTasks, adl);
	const activeUpdateTasks = findTasksByADL(updatedAllTasks, adl);
	// find matching records, & apply updates at same time
	const updatedTracking = copyTaskUpdatesToRecords(
		activeUpdateTasks,
		scheduledRecords,
		unscheduledRecords
	);
	return groupTrackingByType(updatedTracking);

	// send requests to server
};

/**
 * @description - Finds matching tracking records for tasks, then copies each task's status/state over to its tracking record.
 * @param {Array} updatedActiveTasks - An array of already updated UI tasks (may include scheduled & unscheduled tasks).
 * @param {Array} scheduledRecords - An array 'Scheduled' tracking records ('AssessmentTrackingTask' records).
 * @param {Array} unscheduledRecords - An array of 'Unscheduled' tracking records ('AssessmentUnscheduleTaskRaw' records).
 * @returns {Array} - Returns an array of ALL current & updated tracking records; now matches their UI task's state.
 */
const copyTaskUpdatesToRecords = (
	updatedActiveTasks,
	scheduledRecords = [],
	unscheduledRecords = []
) => {
	// find matching records
	// copy changes/updates from UI tasks to tracking records
	const matchingRecords = findAllMatchingRecords(
		updatedActiveTasks,
		scheduledRecords,
		unscheduledRecords
	);
	return matchingRecords.reduce((updatedTracking, curTracking, idx) => {
		const currentTask = updatedActiveTasks[idx];
		// apply updated task status to tracking record
		if (getTaskID(curTracking) === getTaskID(currentTask)) {
			const {
				AssessmentTaskStatusId,
				IsCompleted,
				CompletedAssessmentShiftId,
				CompletedDate,
			} = currentTask;
			const updatedMatch = {
				...curTracking,
				IsCompleted: IsCompleted,
				AssessmentTaskStatusId: AssessmentTaskStatusId,
				CompletedAssessmentShiftId: CompletedAssessmentShiftId,
				CompletedDate: CompletedDate,
			};
			updatedTracking.push(updatedMatch);
			return updatedTracking;
		}
		return updatedTracking;
	}, []);
};

/**
 * @description - Determines which records require updating and which request(s) to send. Then makes the required request(s).
 * @param {String} token - Base64 encoded auth/security token.
 * @param {Array} pendingScheduled - An array of pending 'AssessmentTrackingTask' records to be sent to ALA Services.
 * @param {Array} pendingUnscheduled - An array of pending 'AssessmentUnscheduleTaskRaw' records to be sent to ALA Services.
 * @returns {Array} - Returns 1 of 3 responses:
 * - An array w/ two nested arrays containing ALL task IDs that were updated.
 * - An array ONLY containing the scheduled IDs that were updated
 * - An array ONLY containing the unscheduled IDs that were updated.
 */
const savePendingRecords = async (
	token,
	pendingScheduled,
	pendingUnscheduled
) => {
	let isPendingScheduled = !isEmptyArray(pendingScheduled);
	let isPendingUnscheduled = !isEmptyArray(pendingUnscheduled);
	let bothPending = isPendingScheduled && isPendingUnscheduled;

	// fire-off BOTH 'scheduled' & 'unscheduled' updates
	if (bothPending) {
		return await Promise.all([
			saveScheduledUpdates(token, pendingScheduled),
			saveUnscheduledUpdates(token, pendingUnscheduled),
		]);
	}

	// ONLY fire-off 'scheduled' updates
	if (isPendingScheduled) {
		return await saveScheduledUpdates(token, pendingScheduled);
	}
	// ONLY fire-off 'unscheduled' updates
	if (isPendingUnscheduled) {
		return await saveUnscheduledUpdates(token, pendingUnscheduled);
	}
};

////////////////////////////////////////////////////////////////////
//////////// NEW 'SINGLE-RESPONSIBILITY' UPDATE HELPERS ////////////
////////////////////////////////////////////////////////////////////

const getWeekDays = () => {
	const start = startOfWeek(new Date());
	const end = endOfWeek(new Date());
	return eachDay(start, end).map((day) => format(day, "ddd"));
};

const getChangedDays = (vals) => {
	const base = `isRecurring`;
	const days = getWeekDays();

	return days.filter((day) => {
		const prop = [`${base}${day}`];
		if (vals[prop]) {
			return day;
		}
		return;
	});
};
const getChangedShifts = (vals) => {
	const { isRecurringAM, isRecurringPM, isRecurringNOC } = vals;
	const hasAM = isRecurringAM ? "AM" : null;
	const hasPM = isRecurringPM ? "PM" : null;
	const hasNOC = isRecurringNOC ? "NOC" : null;
	const newShifts = [hasAM, hasPM, hasNOC].filter((x) => Boolean(x));

	return newShifts;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// TASK UPDATER HANDLERS (ADLCareTask, TRACKING TASK) //////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * Updates task w/ 'LOCK' status, if applicable.
 * @param {Object} vals - Updated task values
 * @param {Object} task - An 'AssessmentTrackingTask' or 'AssessmentUnscheduleTask' record to be updated.
 * @returns {Object} - Returns updated task
 */
const lockTaskHandler = (vals, task) => {
	return {
		...task,
		IsLocked: vals?.isLocked,
	};
};
/**
 * Updates task w/ an Exception, if applicable including ExceptionType and ExceptionDate
 * @param {Object} vals - Updated task values
 * @param {Object} task - An 'AssessmentTrackingTask' or 'AssessmentUnscheduleTask' record to be updated.
 * @param {Object[]} facilityExceptions - An array of AssessmentFacilityException records (defines available exception types for a facility)
 * @returns {Object} - Returns updated task
 */
const exceptionHandler = (
	formState = {},
	task = {},
	facilityExceptions = [],
	currentUser = {}
) => {
	const { values: vals, touched } = formState;
	// if exception is pre-existing & has no new changes, then return early w/o updating exceptions for task
	if (!hasProp(touched, "exceptionType")) return { ...task };
	// if (denyExceptionChange(task)) return { ...task };

	if (!isEmptyVal(vals.exceptionType)) {
		if (isUITask(task)) {
			return {
				...task,
				AssessmentExceptionId: getExceptionID(
					vals.exceptionType,
					facilityExceptions
				),
				ExceptionDate: new Date().toISOString(),
				Exception: vals.exceptionType,
				ExceptionByUserId: currentUser?.userID,
			};
		} else {
			return {
				...task,
				AssessmentExceptionId: getExceptionID(
					vals.exceptionType,
					facilityExceptions
				),
				ExceptionDate: new Date().toISOString(),
				ExceptionByUserId: currentUser?.userID,
			};
		}
	} else {
		return { ...task };
	}
};
/**
 * Updates task w/ reassess(SP Update) details, status and any applicable reassess notes.
 * @param {Object} vals - Updated task values
 * @param {Object} task - An 'AssessmentTrackingTask' or 'AssessmentUnscheduleTask' record to be updated.
 * @param {Object} currentUser - Current user object (ie the user applying the update)
 * @returns {Object} - Returns updated task
 */
const reassessHandler = (vals, task, currentUser) => {
	const { reassess, reassessNotes } = vals;
	if (!reassess || !isScheduledTask(task)) return task;

	// ##TODOS:
	// - Remove: "[notesField]: newNotes" to quit copying reassess notes to the task record (this will hide them from the report as well)

	if (isUITask(task)) {
		// REMOVED - 8/8/2022 REASSESS NOTES are not added to 'Notes' field in task anymore
		const username = currentUser?.username;
		const notesWithUserCreds = `${reassessNotes}\n\n - ${username} \n\n${format(
			new Date(),
			"M/D/YYYY"
		)} at ${format(new Date(), "hh:mm:ss A")}`;
		// newly added to maintain reassess notes
		const notesField = !hasProp(task, "TaskNotes") ? "Notes" : "TaskNotes";
		const newNotes = `Suggest: ${notesWithUserCreds}`;

		return {
			...task,
			// ReasonForReassess: reassessNotes,
			// [notesField]: newNotes,
			ReasonForReassess: notesWithUserCreds, // REMOVED
			AssessmentResolutionId: getResolutionID("COMPLETED-REASSESSMENT-NEEDED"),
			AssessmentReasonId: 6,
		};
	} else {
		// REMOVED - 8/8/2022 REASSESS NOTES are not added to 'Notes' field in task anymore
		const username = currentUser?.username;
		const notesWithUserCreds = `${reassessNotes}\n\n - ${username} \n\n${format(
			new Date(),
			"M/D/YYYY"
		)} at ${format(new Date(), "hh:mm:ss A")}`;
		// newly added to maintain reassess notes
		const notesField = !hasProp(task, "TaskNotes") ? "Notes" : "TaskNotes";
		const newNotes = `Suggest: ${notesWithUserCreds}`;

		return {
			...task,
			// [notesField]: newNotes,
			AssessmentResolutionId: getResolutionID("COMPLETED-REASSESSMENT-NEEDED"),
			AssessmentReasonId: 6,
		};
	}
};
/**
 * Updates task w/ any new notes while forwarding/saving pre-existing notes. Will merge existing notes w/ new notes. (including reassess notes)
 * @param {Object} vals - Updated task values
 * @param {Object} task - An 'AssessmentTrackingTask' or 'AssessmentUnscheduleTask' record to be updated.
 * @param {Object} currentUser - Current user object (ie the user applying the update)
 * @returns {Object} - Returns updated task
 */
const notesHandler = (vals, task, currentUser) => {
	/**
	 * Determine 'notes' field name,
	 * merge prev. notes & new notes
	 * apply to task record
	 */
	const notesField = !hasProp(task, "Notes") ? "TaskNotes" : "Notes";
	const mergedNotes = mergeTaskNotes(vals, task[notesField], currentUser);
	return {
		...task,
		[notesField]: mergedNotes,
		SignedBy: vals.signature,
	};
};
/**
 * Checks if a task being updated is past its' due date & updates it accordingly, if applicable.
 * @param {Object} task - An 'AssessmentTrackingTask' or 'AssessmentUnscheduleTask' record to be updated.
 * @param {Object[]} shiftTimes - An array of AssessmentFacilityShift records for a given facility's shift times.
 * @returns {Object} - Returns updated task
 */
const pastDueHandler = (task, shiftTimes = []) => {
	if (denyPastDueChange(task)) return { ...task };

	if (checkForPastDue(task, new Date(), shiftTimes)) {
		return {
			...task,
			IsPastDue: true,
			PastDueDate: new Date().toISOString(),
		};
	} else {
		return { ...task };
	}
};
/**
 * Updates task w/ repeat/recurring settings, if applicable
 * @param {Object} vals - Updated task values
 * @param {Object} task - An 'AssessmentTrackingTask' or 'AssessmentUnscheduleTask' record to be updated.
 * @returns {Object} - Returns updated task
 */
const repeatSettingsHandler = (vals = {}, task = {}, currentUser = {}) => {
	// if scheduled task or recurring settings not changed; return early
	if (!vals.changedSettings || isScheduledTask(task)) return { ...task };

	// if 'ADLCareTask' record format
	if (isUITask(task)) {
		// recurring shifts
		const hasAM = vals.isRecurringAM ? "AM" : null;
		const hasPM = vals.isRecurringPM ? "PM" : null;
		const hasNOC = vals.isRecurringNOC ? "NOC" : null;
		const newShifts = [hasAM, hasPM, hasNOC].filter((x) => Boolean(x));
		// recurring days
		const newDays = getChangedDays(vals);

		// handles null-ish date values
		const startDate = !isEmptyVal(vals?.startDate)
			? new Date(vals?.startDate).toISOString()
			: vals?.startDate;
		const endDate = !isEmptyVal(vals?.endDate)
			? new Date(vals?.endDate).toISOString()
			: vals?.endDate;

		return {
			...task,
			RecurringDescription: "",
			AssessmentRecurringId: getRecurringTypeID(vals?.recurringType),
			RecurringType: vals?.recurringType,
			RecurringStartDate: startDate,
			RecurringEndDate: endDate,
			RecurringDays: [...newDays],
			RecurringShifts: newShifts,
			RecurringChangedByUserId: currentUser?.userID,
			RecurringChangedDate: new Date().toISOString(),
		};
		// if 'AssessmentUnscheduleTask' record format
	} else {
		const { startDate: rawStart, endDate: rawEnd } = vals;
		// format start/end dates into ISO/UTC format
		const startDate = !isEmptyVal(vals?.startDate)
			? new Date(rawStart).toISOString()
			: vals?.startDate;
		const endDate = !isEmptyVal(vals?.endDate)
			? new Date(rawEnd).toISOString()
			: vals?.endDate;

		// prevents empty string values
		const mon = isEmptyVal(vals?.isRecurringMon) ? false : vals?.isRecurringMon;
		const tue = isEmptyVal(vals?.isRecurringTue) ? false : vals?.isRecurringTue;
		const wed = isEmptyVal(vals?.isRecurringWed) ? false : vals?.isRecurringWed;
		const thu = isEmptyVal(vals?.isRecurringThu) ? false : vals?.isRecurringThu;
		const fri = isEmptyVal(vals?.isRecurringFri) ? false : vals?.isRecurringFri;
		const sat = isEmptyVal(vals?.isRecurringSat) ? false : vals?.isRecurringSat;
		const sun = isEmptyVal(vals?.isRecurringSun) ? false : vals?.isRecurringSun;

		return {
			...task,
			RecurringDescription: "",
			AssessmentRecurringId: getRecurringTypeID(vals.recurringType),
			// RecurringStartDate: vals.startDate,
			// RecurringEndDate: vals.endDate,
			RecurringStartDate: startDate,
			RecurringEndDate: endDate,
			RecurringMon: mon,
			RecurringTue: tue,
			RecurringWed: wed,
			RecurringThu: thu,
			RecurringFri: fri,
			RecurringSat: sat,
			RecurringSun: sun,
			RecurringAMShiftId: vals.isRecurringAM ? 1 : null,
			RecurringPMShiftId: vals.isRecurringPM ? 2 : null,
			RecurringNOCShiftId: vals.isRecurringNOC ? 3 : null,
			RecurringChangedByUserId: currentUser?.userID,
			RecurringChangedDate: new Date().toISOString(),
		};
	}
};
/**
 * Updates task w/ the shift the task was updated in (eg AM, PM, or NOC)
 * @param {Object} vals - Updated task values
 * @param {Object} task - An 'AssessmentTrackingTask' or 'AssessmentUnscheduleTask' record to be updated.
 * @returns {Object} - Returns updated task
 */
const shiftHandler = (vals, task) => {
	if (isEmptyVal(vals.shift)) return { ...task };
	return {
		...task,
		CompletedAssessmentShiftId: getShiftID(vals.shift),
	};
};
/**
 * Updates task records w/ new task status, including marking them completed if relevant
 * @param {Object} vals - Updated task values
 * @param {Object} task - An 'AssessmentTrackingTask' or 'AssessmentUnscheduleTask' or 'ADLCareTask' record to be updated.
 * @returns {Object} - Returns updated task
 */
const statusHandler = (vals, task) => {
	if (isUITask(task)) {
		const { AssessmentTaskStatusId, TaskStatus } = task;
		return {
			...task,
			TaskStatus: vals.markComplete ? "COMPLETE" : TaskStatus,
			IsCompleted: vals.markComplete,
			AssessmentTaskStatusId: vals.markComplete ? 2 : AssessmentTaskStatusId,
			CompletedAssessmentShiftId: getShiftID(vals.shift),
			CompletedDate: vals.markComplete ? new Date().toISOString() : null,
			CompletedShift: vals.shift,
		};
	} else {
		const { AssessmentTaskStatusId } = task;
		return {
			...task,
			IsCompleted: vals?.markComplete ?? false,
			AssessmentTaskStatusId: vals.markComplete ? 2 : AssessmentTaskStatusId,
			CompletedDate: vals.markComplete ? new Date().toISOString() : null,
		};
	}
};
/**
 * Applies status updates to task, then checks if task is being completed and applies userID accordingly if so.
 * @param {Object} vals - Updated task field values
 * @param {Object} task - An 'AssessmentTrackingTask', 'AssessmentUnscheduleTask' or an 'ADLCareTask' record.
 * @param {Object} currentUser - Current user object
 * @returns {Object} - Returns task with applied status updates as well as UserId, if being completed.
 */
const statusAndUserStampHandler = (vals, task, currentUser) => {
	const { userID } = currentUser;
	// Apply task status updates
	const withStatus = statusHandler(vals, task);
	const isMarkedComplete = vals?.markComplete;

	// Check if task is being completed, if so:
	// 		- then apply 'UserId'
	// 		- otherwise return updated task
	if (isMarkedComplete) {
		return {
			...withStatus,
			UserId: userID,
			CompletedByUserId: userID,
		};
	} else {
		return withStatus;
	}
};
/**
 * Applies 'AssessmentResolution' to a given task record such as ('COMPLETED', 'NOT-COMPLETE' etc)
 * @param {Object} vals - Updated task values
 * @param {Object} task - An 'AssessmentTrackingTask' or 'AssessmentUnscheduleTask' record to be updated.
 * @returns {Object} - Returns updated task
 */
const resolutionHandler = (vals, task) => {
	if (!vals.reassess) {
		return {
			...task,
			AssessmentResolutionId: vals.markComplete
				? getResolutionID("COMPLETED")
				: null,
		};
	} else {
		return {
			...task,
			AssessmentResolutionId: vals.markComplete
				? getResolutionID("COMPLETED-REASSESSMENT-NEEDED")
				: null,
		};
	}
};
/**
 * Applies changes to a task's scheduled due date and time & returns updated task record.
 * @param {Object} formState - User values from 'useForm()'s local state object.
 * @param {Object} task - A task record (ie. 'AssessmentTrackingTask' or 'AssessmentUnscheduleTask')
 * @returns {Object} - Returns updated task record
 */
const dueDateHandler = (formState, task) => {
	const { values: vals, touched } = formState;
	const { scheduledDueDate: date, scheduledDueTime: time } = vals;
	// return early if no changes were made
	const hasChanges = checkForChanges(formState, [
		"scheduledDueDate",
		"scheduledDueTime",
	]);
	if (!hasChanges || !featureFlags?.enableDueDate) return task;

	const taskType = isScheduledTask(task) ? "SCHEDULED" : "UNSCHEDULED";
	// apply vals to a real date
	const newDate = prepareScheduledDate(date, time);

	switch (taskType) {
		case "SCHEDULED": {
			const updatedTask = {
				...task,
				FollowUpDate: newDate,
			};

			return updatedTask;
		}
		case "UNSCHEDULED": {
			const updatedTask = {
				...task,
				ScheduledDate: newDate,
			};
			return updatedTask;
		}
		default:
			return new Error("INVALID TASK TYPE");
	}
};

/**
 * @description - Wrapper around ALL task-related update handlers used for applying updates to UI tasks.
 * @param {Object} vals - User-selected values from local state (ie 'Advanced Options' modal).
 * @param {Object} task - Any UI task type (ie 'Scheduled' and/or 'Unscheduled').
 * @param {Array} facilityExceptions - An array of facility exception types.
 * @param {Array} shiftTimes - An array of facility shift times.
 * @param {Object} currentUser - Current user object.
 * @returns {Object} - Returns the updated UI task record, w/ applied changes.
 * - Updated 8/11/2020 @ 4:28 PM
 * - Tracked down 'pastDueHandler()' bug, was an error w/ the 'saveAppliedTaskUpdates' NOT receiving the 'shiftTimes' array
 * - Added 'lockTaskHandler()' to apply lock status changes upon update
 */
const applyUpdatesToTask = (
	formState,
	task,
	facilityExceptions = [],
	shiftTimes = [],
	currentUser = {}
) => {
	const { values: vals } = formState;
	// ##TODOS:
	// - Add 'due date' handler & support code
	const pendingTask = { ...task };
	const withLock = lockTaskHandler(vals, pendingTask);
	const withException = exceptionHandler(
		formState,
		// vals,
		withLock,
		facilityExceptions,
		currentUser
	);
	const withReassess = reassessHandler(vals, withException, currentUser);
	// REMOVED 8/9/2022 AT 7:04 AM - NOTES AREN'T ADDED TO TASK RECORD ANYMORE
	// const withNotes = notesHandler(vals, withReassess, currentUser);
	const withRepeat = repeatSettingsHandler(vals, withReassess, currentUser);
	// const withShift = shiftHandler(vals, withRepeat); // DISABLED
	// const withStatus = statusHandler(vals, withRepeat);
	const withStatus = statusAndUserStampHandler(vals, withRepeat, currentUser);
	const withPastDue = pastDueHandler(withStatus, shiftTimes);
	// newly added due date/time handler
	const withDueDate = dueDateHandler(formState, withPastDue);

	return withDueDate;
	// return withPastDue;
};

/**
 * Applies task updates (from 'Advanced Options' modal) to matching tracking record.
 * Prepares tracking to be submitted to server.
 */
const applyUpdatesToTracking = (
	formState,
	// vals,
	task,
	allTracking = [],
	facilityExceptions = [],
	shiftTimes = [],
	currentUser = {}
) => {
	const matchingRecord = findTrackingRecord(task, allTracking);
	const updatedRecord = applyUpdatesToTask(
		formState,
		// vals,
		matchingRecord,
		facilityExceptions,
		shiftTimes,
		currentUser
	);
	return { ...updatedRecord };
};

// handles one or more scheduled/unscheduled
// this should replace 'taskResolver' in <DailySummaryListItem/>
const saveAppliedTaskUpdates = async (
	token,
	formState,
	// vals,
	activeTask,
	scheduledTracking = [],
	unscheduledTracking = [],
	facilityExceptions = [],
	shiftTimes = [],
	currentUser = {}
) => {
	const matchingRecord = findMatchingRecord(
		activeTask,
		scheduledTracking,
		unscheduledTracking
	);
	// scheduled task records (trackingTask)
	if (isScheduledTask(activeTask)) {
		const updatedRecord = applyUpdatesToTracking(
			formState,
			// vals,
			matchingRecord,
			scheduledTracking,
			facilityExceptions,
			shiftTimes,
			currentUser
		);
		return await saveScheduledUpdates(token, updatedRecord);
		// unscheduled task records (unscheduleTask)
	} else {
		const updatedRecord = applyUpdatesToTracking(
			formState,
			// vals,
			matchingRecord,
			unscheduledTracking,
			facilityExceptions,
			shiftTimes,
			currentUser
		);
		return await saveUnscheduledUpdates(token, updatedRecord);
	}
};

// LOGGER UTILS
const exceptionLogger = (vals, task) => {
	const taskID = getTaskID(task);
	const taskType = getTaskType(task);
	if (vals?.exceptionType) {
		return Sentry.captureMessage(
			`✓ Exception Logging\n\n
			✓ TaskType: ${taskType}\n\n
			✓ TaskID: ${taskID}\n\n
			✓ TaskHasException? ${hasException(task)}\n\n
			✓ UserException: ${vals.exceptionType}\n\n
			✓ ADL: ${getCategoryNameFromID(task?.AssessmentCategoryId)}
			`
		);
	} else {
		return Sentry.captureMessage(
			`✓ Exception Logging (no exception)\n\n
			✓ TaskType: ${taskType}\n\n
			✓ TaskID: ${taskID}\n\n
			✓ TaskHasException? ${hasException(task)}\n\n
			✓ UserException: ${vals?.exceptionType}\n\n
			✓ ADL: ${getCategoryNameFromID(task?.AssessmentCategoryId)}
			`
		);
	}
};

// NEW!!! TASK UPDATER UTILS - 8/8/2022 AT 9:49 AM //
// TASK UPDATE 'SAVE ALL CHANGES' //

// new task updaters' wrapper
const applyChangeToTask = (
	formState,
	// vals,
	task,
	facilityExceptions = [],
	shiftTimes = [],
	currentUser = {}
) => {
	const { values: vals } = formState;

	const pendingTask = { ...task };
	const withLock = lockTaskHandler(vals, pendingTask);
	const withException = exceptionHandler(
		formState,
		// vals,
		withLock,
		facilityExceptions,
		currentUser
	);
	const withReassess = reassessHandler(vals, withException, currentUser);
	// REMOVED 'NOTES' HANDLER - DUE TO NEW UI
	// const withNotes = notesHandler(vals, withReassess, currentUser);
	// const withRepeat = repeatSettingsHandler(vals, withNotes, currentUser);
	const withRepeat = repeatSettingsHandler(vals, withReassess, currentUser);
	// const withShift = shiftHandler(vals, withRepeat); // DISABLED
	const withStatus = statusAndUserStampHandler(vals, withRepeat, currentUser);
	const withPastDue = pastDueHandler(withStatus, shiftTimes);

	return withPastDue;
};

const saveAllChangesHandler = async (
	vals = {},
	updatedTask = {},
	currentUser = {}
) => {
	const { token, userID } = currentUser;
	const fallbackType = "UNSCHEDULED";
	const taskType = isScheduledTask(updatedTask) ? "SCHEDULED" : "UNSCHEDULED";
	const type = !isEmptyVal(taskType) ? taskType : fallbackType;

	switch (type) {
		case "SCHEDULED": {
			const reassessModel = updateReassessModel(
				vals,
				userID,
				updatedTask,
				currentUser
			);

			const [
				wasTaskSaved = null,
				wasReassessSaved = null,
				wasNoteSaved = null,
			] = await Promise.all([
				// condition ? request() : Promise.resolve({}) - task updater
				saveScheduledUpdates(token, updatedTask),
				// condition ? request() : Promise.resolve({}) - reassess updater
				vals?.reassess
					? saveReassessMany(token, reassessModel)
					: Promise.resolve({}),
				// condition ? request() : Promise.resolve({}) - task notes updater
				// vals?.syncNotesVal ? saveTaskNotes(token, server) : Promise.resolve({}),
			]);

			console.group("Save All (SCHEDULED)");
			console.log("wasTaskSaved:", wasTaskSaved);
			console.log("wasNoteSaved", wasNoteSaved);
			console.log("wasReassessSaved", wasReassessSaved);
			console.log("reassessModel", reassessModel);
			console.groupEnd();

			return {
				wasTaskSaved: wasTaskSaved,
				wasReassessSaved: wasReassessSaved,
				wasNoteSaved: wasNoteSaved,
				reassessRecord: {
					...reassessModel,
					AssessmentTrackingReassessId: wasReassessSaved ?? 0,
				},
			};
		}
		case "UNSCHEDULED": {
			const [wasTaskSaved = null] = await Promise.all([
				// condition ? request() : Promise.resolve({}) - task updater
				saveUnscheduledUpdates(token, updatedTask),
				// condition ? request() : Promise.resolve({}) - task notes updater
				// vals?.syncNotesVal ? saveTaskNotes(token, server) : Promise.resolve({}),
			]);

			console.group("Save All (UNSCHEDULED)");
			console.log("wasTaskSaved:", wasTaskSaved);
			// console.log("wasNoteSaved", wasNoteSaved);
			console.groupEnd();

			return {
				wasTaskSaved: wasTaskSaved,
				wasNoteSaved: null,
				wasReassessSaved: null,
				reassessRecord: {},
			};
		}
		default:
			return new Error("INVALID TASK TYPE!");
	}
};

// prepares & applies 'values' to relevant models/data-structures
const prepareSaveAllChangesData = (
	formState = {},
	rawTask = {},
	trackingTasks = [],
	unscheduledRaw = [],
	facilityExceptions = [],
	facilityShifts = [],
	currentUser = {}
) => {
	const { values: vals } = formState;
	// applies all updates to db-format task records
	const taskModel = applyUpdatesToTracking(
		formState,
		rawTask,
		[...trackingTasks, ...unscheduledRaw],
		facilityExceptions,
		facilityShifts,
		currentUser
	);
	// applies updates to reassess model, if applicable
	const reassessModel = initAndUpdateReassessModel(
		vals,
		taskModel,
		currentUser
	);
	// applies updates to task note model, if applicable
	const { client, server } = initAndUpdateNewTaskNote(taskModel, {
		...vals,
		entryDate: new Date().toISOString(),
		createdDate: new Date().toISOString(),
		modifiedDate: new Date().toISOString(),
	});

	return {
		task: { ...taskModel },
		reassess: { ...reassessModel },
		taskNote: { client, server },
	};
};

const findAndApplyTaskRecordUpdates = (
	formState,
	// vals,
	activeTask = {},
	scheduledTracking = [],
	unscheduledTracking = [],
	facilityExceptions = [],
	shiftTimes = [],
	currentUser = {}
) => {
	// find the matching tracking/unschedule record
	const matchingRecord = findMatchingRecord(
		activeTask,
		scheduledTracking,
		unscheduledTracking
	);

	switch (true) {
		case isScheduledTask(activeTask): {
			const updatedTask = applyChangeToTask(
				// vals,
				formState,
				matchingRecord, // table record
				facilityExceptions,
				shiftTimes,
				currentUser
			);

			return updatedTask;
		}
		case isUnscheduledTask(activeTask): {
			const updatedTask = applyChangeToTask(
				formState,
				// vals,
				matchingRecord, // table record
				facilityExceptions,
				shiftTimes,
				currentUser
			);
			return updatedTask;
		}
		default:
			return "TASK TYPE IS INVALID";
	}
};

// extracts hrs, mins & tod & set's it to a given date string
const prepareScheduledDate = (date, time) => {
	const base = new Date(date);
	const { hrs, mins, tod } = extractTimeUnitsFromString(time);
	const numHrs = convertHoursStringToMilitary(hrs, tod);
	const numMins = Number(mins);
	// set hours & mins
	const withHrs = setHours(base, numHrs);
	const total = setMinutes(withHrs, numMins);

	console.log("withHrs", withHrs);
	console.log("total", total);

	return total;
};

export { prepareScheduledDate };

// LOGGING HELP
export { exceptionLogger };

// MINI-UPDATE HELPERS
export {
	mergeTaskNotes,
	addTimeStamp,
	getWeekDays,
	getChangedDays,
	getChangedShifts,
};
export {
	exceptionHandler,
	reassessHandler,
	notesHandler,
	pastDueHandler,
	repeatSettingsHandler,
	shiftHandler,
	statusHandler,
	resolutionHandler,
	statusAndUserStampHandler,
	dueDateHandler,
	// wrappers for ALL task handlers
	applyUpdatesToTask,
	applyUpdatesToTracking,
};

// TASK RECORD UPDATE HELPERS
export {
	// record helpers
	getPastDueTracking,
	markCompleteTracking,
	// request helpers
	saveScheduledRecord,
	saveUnscheduledRecord,
	savePastDueTracking,
	// NEW ASYNC REQUEST HELPER (FOR TRACKING RECORDS)
	applyScheduledUpdates,
	applyUnscheduledUpdates,
	saveAppliedTaskUpdates,
};

// RECORD FINDING HELPERS
export { findTrackingRecord, findUnscheduledRecord, findMatchingRecord };

// TASK UPDATE REQUEST HELPERS
export {
	findAndApplyTaskUpdates,
	taskResolver,
	applyTaskChanges,
	applyQuickComplete,
	recordQuickComplete,
};

// 'MARK ALL COMPLETE' HELPERS
export {
	groupTrackingByType,
	findAllMatchingRecords,
	copyTaskUpdatesToRecords,
	// main helper to be exposed; use in <DailySummaryCard/> for 'mark all complete' feature
	savePendingRecords,
	markCompleteTasksAndRecords,
};

// "SAVE ALL CHANGES" HELPERS //

export {
	applyChangeToTask,
	findAndApplyTaskRecordUpdates,
	saveAllChangesHandler,
};
