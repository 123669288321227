import React from "react";
import styles from "../../css/app/ResetPasswordByAdmin.module.scss";
import { PropTypes } from "prop-types";

const ResetPasswordByAdmin = () => {
	return (
		<div className={styles.ResetPasswordByAdmin}>
			<div className={styles.ResetPasswordByAdmin_details}>
				You're account is currently locked!
			</div>
			<p className={styles.ResetPasswordByAdmin_more}>
				We've notified your Admin. They will unlock your account shortly.
			</p>
		</div>
	);
};

export default ResetPasswordByAdmin;

ResetPasswordByAdmin.defaultProps = {};

ResetPasswordByAdmin.propTypes = {};
