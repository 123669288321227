import { checkCategoryNaming } from "./utils_categories";
import {
	isPastDue,
	isCompleted,
	isNotComplete,
	isException,
} from "./utils_tasks";

// MIRRORS THE SASS MAPS IN _variables.scss
const themeColors = {
	brand: {
		blueLight: "hsla(192, 100%, 46%, 1)", // #00BCEB
		blueDarker: "hsla(210, 83%, 34%, 1)", // #0F579F
		blueDark: "hsla(210, 52%, 47%, 1)", // #3A78B6
		lightGrey: "hsla(204, 12%, 92%, 1)",
		altLightGrey: "hsla(210, 45%, 96%, 1)",
		mainWhite: "hsla(0, 0%, 100%, 1)",
		mainGreen: "hsla(79, 71%, 48%, 1)",
		lightGreen: "hsla(89, 54%, 85%, 1)",
	},
	main: {
		main: "hsla(242, 89%, 64%, 1)",
		blue: "hsla(197, 100%, 50%, 1)",
		green: "hsla(170, 100%, 39%, 1)",
		red: "hsla(352, 70%, 50%, 1)",
		orange: "hsla(11, 100%, 75%, 1)",
		yellow: "hsla(60, 92%, 71%, 1)",
		mustard: "hsla(46, 100%, 50%, 1)",
		charcoal: "hsla(268, 10%, 30%, 1)",
		grey: "hsla(216, 14%, 93%, 1)",
		blackBlue: "hsla(220, 18%, 20%, 1)",
		violet: "hsla(292, 65%, 68%, 1)",
		teal: "hsla(186, 100%, 50%, 1)",
		pink: "hsla(332, 100%, 74%, 1)",
		neonGreen: "hsla(151, 100%, 45%, 1)",
		altYellow: "hsla(39, 100%, 70%, 1)",
		altRed: "hsla(352, 70%, 60%, 1)",
	},
	flat: {
		main: "hsla(259, 77%, 64%, .4)",
		blue: "hsla(197, 100%, 50%, .3)",
		purple: "hsla(222, 89%, 64%, .3)",
		vibe: "hsla(259, 77%, 64%, .4)",
		green: "hsla(144, 69%, 63%, .4)",
		red: "hsla(330, 100%, 41%, .22)",
		yellow: "hsla(60, 92%, 71%, .7)",
		orange: "hsla(11, 100%, 75%, .4)",
		violet: "hsla(292, 65%, 68%, .3)",
		teal: "hsla(186, 100%, 50%, .4)",
		neonGreen: "hsla(151, 100%, 45%, .4)",
		blackBlue: "hsla(220, 18%, 20%, .4)",
		altRed: "hsla(352, 70%, 60%, .3)",
	},
	blueGreys: {
		main: "hsla(214, 32%, 91%, 1)",
		saturated: "hsla(211, 25%, 84%, 1)",
		text: "hsla(216, 15%, 52%, 1)",
		headings: "hsla(218, 17%, 35%, 1)",
		subheadings: "hsla(218, 17%, 65%, 1)",
		light: "hsla(204, 46%, 98%, 1)",
		lightened: "hsla(234, 32%, 91%, 0.4)",
	},
	greys: {
		dark: "hsla(0, 0%, 13%, 1)",
		medium: "hsla(0, 0%, 29%, 1)",
		mediumGrey: "hsla(0, 0%, 45%, 1)",
		lightGrey: "hsla(214, 20%, 69%, 1)",
		extraLightGrey: "hsla(211, 25%, 84%, 1)",
		whiteish: "hsla(240, 14%, 97.3%, 1)",
		chalk: "hsla(0, 0%, 91%, 1)",
	},
};

const { brand, main, flat, blueGreys, greys } = themeColors;

/////////////////////////////////////////////////////////////////////////////
///////////////////////////// COLOR-SHADE MAPS /////////////////////////////
/////////////////////////////////////////////////////////////////////////////

const COLORS_MAP = {
	purple: {
		50: "rgb(245, 243, 255)",
		100: "rgb(237, 233, 254)",
		200: "rgb(221, 214, 254)",
		300: "rgb(196, 181, 253)",
		400: "rgb(167, 139, 250)",
		500: "rgb(139, 92, 246)",
		600: "rgb(124, 58, 237)",
		700: "rgb(109, 40, 217)",
		800: "rgb(91, 33, 182)",
		900: "rgb(76, 29, 149)",
	},
	blue: {
		50: "rgb(239, 246, 255)",
		100: "rgb(219, 234, 254)",
		200: "rgb(191, 219, 254)",
		300: "rgb(147, 197, 253)",
		400: "rgb(96, 165, 250)",
		500: "rgb(59, 130, 246)",
		600: "rgb(37, 99, 235)",
		700: "rgb(29, 78, 216)",
		800: "rgb(30, 64, 175)",
		900: "rgb(30, 58, 138)",
	},
	red: {
		50: "rgb(254, 242, 242)",
		100: "rgb(254, 226, 226)",
		200: "rgb(254, 202, 202)",
		300: "rgb(252, 165, 165)",
		400: "rgb(248, 113, 113)",
		500: "rgb(239, 68, 68)",
		600: "rgb(220, 38, 38)",
		700: "rgb(185, 28, 28)",
		800: "rgb(153, 27, 27)",
		900: "rgb(127, 29, 29)",
	},
	pink: {
		100: "rgb(255, 245, 247)",
		200: "rgb(254, 215, 226)",
		300: "rgb(251, 182, 206)",
		400: "rgb(246, 135, 179)",
		500: "rgb(237, 100, 166)",
		600: "rgb(213, 63, 140)",
		700: "rgb(184, 50, 128)",
		800: "rgb(151, 38, 109)",
		900: "rgb(112, 36, 89)",
	},
	green: {
		50: "rgb(236, 253, 245)",
		100: "rgb(209, 250, 229)",
		200: "rgb(167, 243, 208)",
		300: "rgb(110, 231, 183)",
		400: "rgb(52, 211, 153)",
		500: "rgb(16, 185, 129)",
		600: "rgb(5, 150, 105)",
		700: "rgb(4, 120, 87)",
		800: "rgb(6, 95, 70)",
		900: "rgb(6, 78, 59)",
	},
	yellow: {
		50: "rgb(255, 251, 235)",
		100: "rgb(254, 243, 199)",
		200: "rgb(253, 230, 138)",
		300: "rgb(252, 211, 77)",
		400: "rgb(251, 191, 36)",
		500: "rgb(245, 158, 11)",
		600: "rgb(217, 119, 6)",
		700: "rgb(180, 83, 9)",
		800: "rgb(146, 64, 14)",
		900: "rgb(120, 53, 15)",
	},
	orange: {
		50: "rgb(255, 247, 237)",
		100: "rgb(255, 237, 213)",
		200: "rgb(254, 215, 170)",
		300: "rgb(253, 186, 116)",
		400: "rgb(251, 146, 60)",
		500: "rgb(249, 115, 22)",
		600: "rgb(234, 88, 12)",
		700: "rgb(194, 65, 12)",
		800: "rgb(154, 52, 18)",
		900: "rgb(124, 45, 18)",
	},
	grey: {
		50: "rgb(250, 250, 250)",
		100: "rgb(244, 244, 245)",
		200: "rgb(228, 228, 231)",
		300: "rgb(212, 212, 216)",
		400: "rgb(161, 161, 170)",
		500: "rgb(113, 113, 122)",
		600: "rgb(82, 82, 91)",
		700: "rgb(63, 63, 70)",
		800: "rgb(39, 39, 42)",
		900: "rgb(24, 24, 27)",
	},
	blueGrey: {
		50: "rgb(248, 250, 252)",
		100: "rgb(241, 245, 249)",
		200: "rgb(226, 232, 240)",
		300: "rgb(203, 213, 225)",
		400: "rgb(148, 163, 184)",
		500: "rgb(100, 116, 139)",
		600: "rgb(71, 85, 105)",
		700: "rgb(51, 65, 85)",
		800: "rgb(30, 41, 59)",
		900: "rgb(15, 23, 42)",
	},
	teal: {
		100: "rgb(230, 255, 250)",
		200: "rgb(178, 245, 234)",
		300: "rgb(129, 230, 217)",
		400: "rgb(79, 209, 197)",
		500: "rgb(56, 178, 172)",
		600: "rgb(49, 151, 149)",
		700: "rgb(44, 122, 123)",
		800: "rgb(40, 94, 97)",
		900: "rgb(35, 78, 82)",
	},
};

const { purple, blue, red, pink, green, yellow, orange, grey, blueGrey, teal } =
	COLORS_MAP;

// ADL CATEGORY COLORS - HARDCODED
const adlColors = {
	Ambulate: themeColors.main.blue,
	Ambulation: themeColors.main.blue, // alias for Ambulate
	Bathing: themeColors.main.green,
	Dressing: themeColors.main.yellow,
	Grooming: themeColors.main.red,
	SpecialCare: themeColors.main.main,
	Care: themeColors.main.main, // alias for SpecialCare
	Laundry: themeColors.main.blackBlue,
	Meals: themeColors.main.mustard,
	MedAssist: themeColors.main.pink,
	Meds: themeColors.main.pink, // alias for MedAssist
	Psychosocial: themeColors.main.violet,
	Mental: themeColors.main.violet, // alias for Psychosocial
	StatusChecks: themeColors.main.teal,
	Toileting: themeColors.main.green,
	Transfers: themeColors.flat.red,
};

///////////////////////////////////////////////////////////////
//////////////////////// STATUS COLORS ////////////////////////
///////////////////////////////////////////////////////////////

// TASK STATUS COLORS - ✅ "PRIMARY TASK STATUS COLOR-PALETTE!!!"
const statusColors = {
	complete: {
		backgroundColor: themeColors.flat.green,
		color: themeColors.main.green,
		border: `1px solid ${themeColors.main.green}`,
	},
	notComplete: {
		backgroundColor: themeColors.blueGreys.altYellow, // should be altYellow
		color: "hsla(218, 17%, 35%, 1)",
		border: `1px solid ${themeColors.main.orange}`,
	},
	pastDue: {
		backgroundColor: themeColors.flat.main,
		color: themeColors.main.main,
		border: `1px solid ${themeColors.main.main}`,
	},
	exception: {
		backgroundColor: themeColors.flat.red,
		color: themeColors.main.red,
		border: `1px solid ${themeColors.main.red}`,
	},
};

const statusBadgeColors = {
	complete: {
		...statusColors.complete,
		color: "#ffffff",
	},
	notComplete: {
		...statusColors.notComplete,
		color: "#ffffff",
	},
	pastDue: {
		...statusColors.pastDue,
		color: "#ffffff",
	},
	exception: {
		...statusColors.exception,
		color: "#ffffff",
	},
};

// JUST TEXT COLORS
const statusTextColor = {
	complete: {
		color: themeColors.main.green,
	},
	notcomplete: {
		color: themeColors.main.altYellow,
	},
	missedevent: {
		color: themeColors.main.red,
	},
	pastdue: {
		color: themeColors.main.main,
	},
	exception: {
		color: themeColors.main.red,
	},
};

const iconChart = {
	stopwatch: "access_alarmalarm",
	close: "clearclose",
	comments: "comments2",
	settings: "cog2",
	caretDown: "caret-down",
	caretUp: "caret-up",
	caretLeft: "caret-left",
	caretRight: "caret-right",
	getIcons: function () {
		return console.log(Object.getOwnPropertyNames(this));
	},
};

// updated as of 4/14/2020 - changed default status to "NOT-COMPLETE", updated "PENDING" status to match "NOT-COMPLETE" status styles.
const statusReducer = (status = "NOT-COMPLETE") => {
	const fonts = {
		fontSize: "1.3rem",
		fontWeight: "400",
	};
	switch (status) {
		case "COMPLETE": {
			return {
				backgroundColor: themeColors.flat.green,
				color: "hsla(218, 17%, 35%, 1)",
				border: `1px solid ${themeColors.main.green}`,
				...fonts,
			};
		}
		case "NOT-COMPLETE": {
			return {
				backgroundColor: themeColors.main.mustard,
				color: themeColors.main.charcoal,
				border: `1px solid ${themeColors.main.charcoal}`,
				...fonts,
			};
		}
		case "PAST-DUE": {
			return {
				backgroundColor: themeColors.flat.main,
				color: themeColors.main.main,
				border: `1px solid ${themeColors.main.main}`,
				...fonts,
			};
		}
		case "EXCEPTION": {
			return {
				backgroundColor: themeColors.flat.red,
				color: themeColors.main.red,
				border: `1px solid ${themeColors.main.red}`,
				...fonts,
			};
		}
		default:
			return {
				backgroundColor: themeColors.main.mustard,
				color: themeColors.main.charcoal,
				border: `1px solid ${themeColors.main.charcoal}`,
				...fonts,
			};
	}
};

const statusHandler = (task, dueDate = new Date(), shiftTimes = []) => {
	switch (true) {
		case isException(task) && !isCompleted(task): {
			return `EXCEPTION`;
		}
		case isPastDue(task, dueDate, shiftTimes): {
			return `PAST-DUE`;
		}
		case isNotComplete(task, dueDate, shiftTimes): {
			return `NOT-COMPLETE`;
		}
		case isCompleted(task): {
			return `COMPLETE`;
		}
		default:
			return `NOT-COMPLETE`;
	}
};

/////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// ADL CARD ICONS ////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

const adlCardIconsMap = {
	Ambulation: {
		icon: "shipped",
		alt: "elderly-person",
		al2: "elderly-person-2",
		styles: {
			fill: orange[500],
		},
	},
	Bathing: {
		icon: "bath",
		alt: "towel",
		al2: "bathtub",
		styles: {
			fill: blue[500],
		},
	},
	Dressing: {
		icon: "get-dressed",
		alt: "get-dressed-2",
		al2: "mirror",
		al3: "mirror-2",
		al4: "bathrobe",
		styles: {
			fill: blueGrey[600],
		},
	},
	Grooming: {
		icon: "man-combing-hair",
		alt: "interior-mirror",
		al2: "fitting-room",
		al3: "hair-dryer",
		styles: {
			fill: green[500],
		},
	},
	// a.k.a. STATUS CHECKS
	Health: {
		icon: "heart-with-pulse",
		alt: "handshake-heart",
		al2: "medical-doctor",
		al3: "health-reminder",
		al4: "heart-health",
		styles: {
			fill: orange[500],
		},
	},

	Laundry: {
		icon: "washing",
		alt: "washing-machine",
		al2: "fitting-room",
		al3: "hair-dryer",
		styles: {
			fill: teal[500],
		},
	},
	Meals: {
		icon: "food-bar",
		alt: "vegan-food",
		al2: "buffet-breakfast",
		al3: "fast-moving-consumer-goods",
		styles: {
			fill: yellow[500],
		},
	},
	Meds: {
		icon: "prescription-pill-bottle",
		al: "pharmacy",
		al2: "health-graph",
		al3: "fast-moving-consumer-goods",
		styles: {
			fill: purple[600],
		},
	},
	// a.k.a. PSYCHOSOCIAL
	Mental: {
		icon: "mental-health",
		alt: "mental-health-2",
		al2: "mental-health-3",
		al3: "mind",
		styles: {
			fill: purple[500],
		},
	},
	// a.k.a. CARE
	SpecialCare: {
		icon: "paste-special",
		alt: "paste-special-2",
		al2: "paste-special-3",
		al3: "health-reminder",
		styles: {
			fill: pink[600],
		},
	},
	Toileting: {
		icon: "toilet-room",
		alt: "toilet-bowl",
		styles: {
			fill: green[500],
		},
	},
	Transfers: {
		icon: "walking-stick",
		alt: "walking-stick-2",
		styles: {
			fill: blue[500],
		},
	},
	Other: {
		icon: "treatment-list",
		alt: "treatment-list",
		al2: "treatment",
		styles: {
			fill: purple[500],
		},
	},
};

const getAdlCardIcon = (adlName) => {
	switch (adlName) {
		case "Ambulate":
		case "Ambulation": {
			const iconName = adlCardIconsMap["Ambulation"]?.icon;
			return iconName;
		}
		case "Bathing":
		case "Bath": {
			const iconName = adlCardIconsMap["Bathing"]?.icon;
			return iconName;
		}
		case "Dressing":
		case "Dress": {
			const iconName = adlCardIconsMap[adlName]?.icon;
			return iconName;
		}
		case "Grooming": {
			const iconName = adlCardIconsMap[adlName]?.icon;
			return iconName;
		}
		case "StatusChecks":
		case "Status Checks":
		case "Health": {
			const iconName = adlCardIconsMap["Health"]?.icon;
			return iconName;
		}
		case "Laundry": {
			const iconName = adlCardIconsMap[adlName]?.icon;
			return iconName;
		}
		case "Meals": {
			const iconName = adlCardIconsMap[adlName]?.icon;
			return iconName;
		}
		case "Medications":
		case "MedAssist":
		case "Meds": {
			const iconName = adlCardIconsMap["Meds"]?.icon;
			return iconName;
		}
		case "Mental":
		case "Psychosocial": {
			const iconName = adlCardIconsMap["Mental"]?.icon;
			return iconName;
		}
		case undefined:
		case null:
		case "Other": {
			const iconName = adlCardIconsMap["Other"]?.icon;
			return iconName;
		}
		case "Special Care":
		case "SpecialCare": {
			const iconName = adlCardIconsMap["SpecialCare"]?.icon;
			return iconName;
		}
		case "Transfers":
		case "Transfer": {
			const iconName = adlCardIconsMap["Transfers"]?.icon;
			return iconName;
		}
		case "Toileting":
		case "Toilets":
		case "Toilet": {
			const iconName = adlCardIconsMap["Toileting"]?.icon;
			return iconName;
		}

		default:
			return;
	}
};

const getIconNameFromStr = (adl) => {
	const adlName = checkCategoryNaming(adl);
	const icon = getAdlCardIcon(adlName);

	return icon;
};

const getIconName = (category = {}) => {
	const adlName = checkCategoryNaming(category.AdlCategoryType);
	const icon = getAdlCardIcon(adlName);

	return icon;
};

// PERFORMANT VERSION OF "iconsReducer"
const adlIcons = {
	Dressing: {
		icon: "dry_cleaning",
		styles: {
			fill: "hsla(268, 10%, 30%, .2)",
		},
	},
	Dress: {
		icon: "dry_cleaning",
		styles: {
			fill: "hsla(268, 10%, 30%, .2)",
		},
	},
	Grooming: {
		icon: "face",
		styles: {
			fill: "hsla(144, 69%, 63%, .4)",
		},
	},
	Groom: {
		icon: "face",
		styles: {
			fill: "hsla(144, 69%, 63%, .4)",
		},
	},
	Bathing: {
		icon: "bathtub",
		styles: {
			fill: "hsla(222, 89%, 64%, .3)",
		},
	},
	Bath: {
		icon: "bathtub",
		styles: {
			fill: "hsla(222, 89%, 64%, .3)",
		},
	},
	MedAssist: {
		icon: "stethoscope", // tasks.svg
		styles: {
			fill: "hsla(330, 100%, 41%, .22)",
		},
	},
	Meds: {
		icon: "stethoscope", // tasks.svg
		styles: {
			fill: "hsla(330, 100%, 41%, .22)",
		},
	},
	Psychosocial: {
		icon: "sentiment_very_dissatisfied",
		styles: {
			fill: "hsla(11, 100%, 75%, .4)",
		},
	},
	Mental: {
		icon: "list_alt", // tasks.svg
		styles: {
			fill: "hsla(11, 100%, 75%, .4)",
		},
	},
	StatusChecks: {
		icon: "timer",
		styles: {
			fill: "hsla(259, 77%, 64%, .4)",
		},
	},
	Health: {
		icon: "medkit", // "timer", tasks.svg
		styles: {
			fill: "hsla(259, 77%, 64%, .4)",
		},
	},
	Toileting: {
		icon: "news",
		styles: {
			fill: "hsla(268, 10%, 30%, .2)",
		},
	},
	Toilet: {
		icon: "news",
		styles: {
			fill: "hsla(268, 10%, 30%, .2)",
		},
	},
	SpecialCare: {
		icon: "local_hospital",
		styles: {
			fill: "hsla(144, 69%, 63%, .4)",
		},
	},
	Care: {
		icon: "local_hospital",
		styles: {
			fill: "hsla(144, 69%, 63%, .4)",
		},
	},
	Ambulation: {
		icon: "bus_alert",
		styles: {
			fill: "hsla(330, 100%, 41%, .22)",
		},
	},
	Ambulate: {
		icon: "bus_alert",
		styles: {
			fill: "hsla(330, 100%, 41%, .22)",
		},
	},
	Transfers: {
		icon: "transfer_within_a_station", // ambulance from tasks.svg??
		styles: {
			fill: "hsla(268, 10%, 30%, .2)",
		},
	},
	Laundry: {
		icon: "local_laundry_service",
		styles: {
			fill: "hsla(197, 100%, 50%, .3)",
		},
	},
	Meals: {
		icon: "restaurant",
		styles: {
			fill: "hsla(259, 77%, 64%, .4)",
		},
	},
	All: {
		icon: "perm_contact_calendar",
		styles: {
			fill: "hsla(218, 17, 65, 1)",
		},
	},
	Other: {
		icon: "assignment_ind",
		styles: {
			fill: "hsla(218, 17, 65, 1)",
		},
	},
};

const iconsReducer = (type) => {
	switch (true) {
		case type === "Dressing" || type === "Dress": {
			return {
				icon: "dry_cleaning",
				styles: {
					fill: "hsla(268, 10%, 30%, .2)",
				},
			};
		}
		case type === "Grooming" || type === "Groom": {
			return {
				icon: "face",
				styles: {
					fill: "hsla(144, 69%, 63%, .4)",
				},
			};
		}
		case type === "Bathing" || type === "Bath": {
			return {
				icon: "bathtub",
				styles: {
					fill: "hsla(222, 89%, 64%, .3)",
				},
			};
		}
		case type === "MedAssist" || type === "Meds": {
			return {
				icon: "sentiment_very_dissatisfied",
				styles: {
					fill: "hsla(330, 100%, 41%, .22)",
				},
			};
		}
		case type === "Psychosocial" || type === "Mental": {
			return {
				icon: "news",
				styles: {
					fill: "hsla(11, 100%, 75%, .4)",
				},
			};
		}
		case type === "StatusChecks" || type === "Health": {
			return {
				icon: "timer",
				styles: {
					fill: "hsla(259, 77%, 64%, .4)",
				},
			};
		}
		case type === "Toileting" || type === "Toilet": {
			return {
				icon: "new_releases",
				styles: {
					fill: "hsla(268, 10%, 30%, .2)",
				},
			};
		}
		case type === "SpecialCare" || type === "Care": {
			return {
				icon: "face",
				styles: {
					fill: "hsla(144, 69%, 63%, .4)",
				},
			};
		}
		case type === "Ambulation" || type === "Ambulate": {
			return {
				icon: "bus_alert",
				styles: {
					fill: "hsla(330, 100%, 41%, .22)",
				},
			};
		}
		case type === "Transfers": {
			return {
				icon: "transfer_within_a_station",
				styles: {
					fill: "hsla(268, 10%, 30%, .2)",
				},
			};
		}
		case type === "Laundry": {
			return {
				icon: "local_laundry_service",
				styles: {
					fill: "hsla(197, 100%, 50%, .3)",
				},
			};
		}
		case type === "Meals": {
			return {
				icon: "restaurant",
				styles: {
					fill: "hsla(259, 77%, 64%, .4)",
				},
			};
		}
		case type === "All": {
			return {
				icon: "perm_contact_calendar",
				styles: {
					fill: "hsla(218, 17, 65, 1)",
				},
			};
		}
		case type === "Other": {
			return {
				icon: "assignment_ind",
				styles: {
					fill: "hsla(218, 17, 65, 1)",
				},
			};
		}
		default:
			return new Error("Category type not recognized");
	}
};

/////////////////////////////
/////// ALERT HELPERS ///////
/////////////////////////////

const BORDERS = {
	ERROR: `5px solid ${themeColors.main.red}`,
	SUCCESS: `5px solid ${themeColors.main.green}`,
	WARN: `5px solid ${themeColors.main.altYellow}`,
	INFO: `5px solid ${themeColors.main.main}`,
};

const ICONS = {
	ERROR: "exclamation-outline",
	WARN: "warning",
	SUCCESS: "checkmark-outline",
	INFO: "information-outline",
};

const FILLS = {
	SUCCESS: themeColors.main.green,
	WARN: themeColors.main.altYellow,
	INFO: themeColors.main.main,
	ERROR: themeColors.main.red,
};

/////////////////////////////////////
// DIALOG COMPONENT - ICON HELPERS //
/////////////////////////////////////
const DIALOG_ICONS = {
	ERROR: "exclamation-outline",
	WARN: "warningreport_problem",
	WARN2: "warning",
	SUCCESS: "check_circle",
	INFO: "info-with-circle",
	INFO2: "information-outline",
	REPORT: "insert_chart_outlined",
	PRINT: "local_print_shopprint",
	SAVE: "save11",
	CHECKMARK: "check_circle",
	SIGNATURE: "gesture",
	ALARM: "access_alarmalarm",
	EDIT: "createmode_editedit",
	HELP: "live_help",
	MEDS: "local_hospital",
	CALENDAR: "event_note",
	CALENDAR_DONE: "event_available",
	CALENDAR_MISSED: "event_busy",
	USER: "account_circle",
	SETTINGS: "settings1",
	CHART: "chart-bar",
	ALERT: "notifications1",
	SHOW: "view-show",
	IMAGES: "images",
};

/**
 * Determining mobile screen size, excluding 'browser frame':
 * - The following calculates the viewport excluding the browser url and bottom navs that appears on mobile.
 */

const getViewport = () => {
	let e = window;
	let a = "inner";
	if (!("innerWidth" in window)) {
		a = "client";
		e = document.documentElement || document.body;
	}
	return { width: e[a + "Width"], height: e[a + "Height"] };
};

/**
 * THEME COLORS & COLOR-SHADE MAPS
 */
export {
	// theme
	themeColors,
	brand,
	main,
	flat,
	blueGreys,
	greys,
	// shades
	COLORS_MAP,
	purple,
	blue,
	red,
	pink,
	green,
	yellow,
	orange,
	grey,
	blueGrey,
	teal,
};

export {
	adlColors,
	adlIcons,
	statusColors,
	statusTextColor,
	statusBadgeColors,
	iconChart,
	statusHandler,
	statusReducer,
	iconsReducer,
	// adl card icons (NEW)
	getAdlCardIcon,
	getIconName,
	getIconNameFromStr,
};

// ALERT HELPERS //
export { BORDERS, ICONS, FILLS };

// DIALOG HELPERS
export { DIALOG_ICONS };

export { getViewport };
