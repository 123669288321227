import React, { useState, useEffect, useContext } from "react";
import styles from "../../css/app/NonAuthenticatedView.module.scss";
import { PropTypes } from "prop-types";
// packages
import * as Sentry from "@sentry/react";
import { addMinutes, format } from "date-fns";
// helpers
import { AuthContext } from "../../state/AuthContext";
import { useForm } from "../../utils/useForm";
import { login, getUserAuthStatus } from "../../helpers/utils_auth";
import { getSystemUserAuth, SYSTEM_USER } from "../../helpers/utils_systemUser";
import { convertDaysToMins } from "../../helpers/utils_dates";
import { isOtp } from "../../helpers/utils_lockouts";
import { getBaseUrl, SERVICES } from "../../helpers/utils_apps";
import { themeColors } from "../../helpers/utils_styles";
import { featureFlags } from "../../helpers/utils_features";
import { openUrl } from "../../helpers/utils_params";
import { getEnvName, isLocalhost } from "../../helpers/utils_env";
// components
import LoginForm from "../../components/app/LoginForm";
import LoginPage from "../../components/pages/LoginPage";
import Dialog from "../../components/shared/Dialog";
import ButtonSM from "../../components/shared/ButtonSM";
// password reset
import ForgotPasswordButton from "../../components/app/ForgotPasswordButton";
import ChangePasswordModal from "../../components/app/ChangePasswordModal";
import ForgotPasswordModal from "../../components/app/ForgotPasswordModal";
import ForgotPassword from "../../components/app/ForgotPassword";

const custom = {
	backgroundColor: themeColors.main.red,
	color: "#ffffff",
	borderRadius: ".5rem",
};

const NonAuthenticatedView = ({ history }) => {
	const { setAuthData } = useContext(AuthContext);
	const { formState, handleChange } = useForm({
		username: "",
		password: "",
	});
	const [isLoading, setIsLoading] = useState(false);
	const [errorDialog, setErrorDialog] = useState({
		show: false,
		msg: null,
	});
	// response from 'isOtp()' call
	const [otpInfo, setOtpInfo] = useState({});
	// only show when password is 'OTP' AND 'OTP' is NOT expired
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	// forgot password
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

	const resetLogin = () => {
		setErrorDialog({
			show: false,
			msg: null,
		});
	};

	// Updated to support more descriptive error messages (eg 'lockouts', 'invalid credentials' etc)
	const handleLogin = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		const { username, password } = formState.values;
		const { AdvantageTracker } = SERVICES;
		// ##TODOS:
		// - Add 'isOtp()' check at same time as 'login()' call
		// - IF is valid OTP, then show change password UI
		// - IF is NOT OTP, then login normally
		// - IF is expired OTP, then show expiry notice UI
		const otpData = await isOtp(SYSTEM_USER.token, username, password);
		const token = await login(username, password, AdvantageTracker.alias);

		// handle OTP reset flow
		if (otpData.isOtp && !otpData.isExpired) {
			// redirect to change password UI
			setOtpInfo(otpData);
			return handleOtp(otpData);
		}

		if (token) {
			const newAuth = {
				username: username,
				password: password,
				token: token,
				expiry: addMinutes(Date.now(), 180),
				isAuthenticated: true,
			};
			setIsLoading(false);
			setAuthData(newAuth);
			Sentry.setUser({
				email: username,
				username: username,
			});
			Sentry.configureScope((scope) => {
				return scope.setUser({
					id: `${format(Date.now(), "M/D/YYYY h:mm:ss A")}`,
					username: username,
					expiry: newAuth?.expiry,
				});
			});
			return history.replace({
				pathname: "/dashboard/daily",
				state: {
					from: history.location.pathname,
					wasLoggedOut: false,
				},
			});
		} else {
			setIsLoading(false);
			// const { token: sysToken } = await getSystemUserAuth();
			const { token: sysToken } = SYSTEM_USER;

			const loginRange = convertDaysToMins(3);
			const authState = await getUserAuthStatus(sysToken, username, loginRange);

			// handle non-users
			if (!authState.isValidUser) {
				return setErrorDialog({
					show: true,
					title: `INVALID USER!`,
					msg: `User Does Not Exist`,
					subMsg: `Sorry. That user was not found in our system.`,
				});
			}
			return setErrorDialog({
				show: true,
				title: authState?.reasonForFailure?.name ?? "Unknown Reason!!!",
				msg: authState?.reasonForFailure?.name ?? "Unknown Reason!!!",
				subMsg: authState?.reasonForFailure?.desc,
			});
		}
	};

	const handleSignup = (e) => {
		e.persist();
		e.preventDefault();
		return alert(
			"Sorry we're not taking new registrations at the moment. \nTry again later!"
		);
	};

	const handleOtp = (otpData) => {
		if (otpData.isOtp && !otpData.isExpired) {
			// show redirect password modal
			return setShowChangePasswordModal(true);
		} else {
			// has otp, but is expired, show notice
			return setErrorDialog({
				show: true,
				msg: null,
				title: `Ooops! OTP is Expired!`,
				subMsg: `It appears that one-time-password is expired. Please request a new one and try again.`,
			});
		}
	};

	const initSystemUser = async () => {
		if (!isLocalhost()) return;
		const resp = await getSystemUserAuth();
		SYSTEM_USER.token = resp.token;
		SYSTEM_USER.isAuthenticated = resp.isAuthenticated;
	};

	// users that land on the Tracker's login page are redirected to the portal.
	// ...Tracker is now ONLY accessible via the Portal's login & 'YourAppsPage' (except for 'localhost')
	const forceSSORedirect = () => {
		if (isLocalhost()) return;
		const targetUrl = getBaseUrl("AdminPortal");
		console.log(`Url:`, targetUrl);
		openUrl(targetUrl);
	};

	// (previously) initializes 'SYSTEM_USER' auth
	// force redirect from 'Tracker' LoginPage to 'Portal' LoginPage
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		forceSSORedirect(); // force redirects to the 'Portal's login page
		initSystemUser(); // disabled for ALL env(s) except 'local'

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={styles.NonAuthenticatedView}>
				<LoginPage>
					<LoginForm
						vals={formState.values}
						handleChange={handleChange}
						handleLogin={handleLogin}
						handleSignup={handleSignup}
						isLoading={isLoading}
					/>

					<ForgotPasswordButton
						openForgotPassword={() => setShowForgotPasswordModal(true)}
					/>

					{featureFlags.showSignupLink && (
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.aladvantage.com/"
							className={styles.NonAuthenticatedView_link}
						>
							Not Signed Up Yet? Learn More →
						</a>
					)}
				</LoginPage>
			</div>

			{showChangePasswordModal && (
				<ChangePasswordModal
					systemUser={SYSTEM_USER}
					closeModal={() => setShowChangePasswordModal(false)}
					currentUser={{
						username: formState.values.username,
						password: formState.values.password,
						isAuthenticated: false,
						otpData: { ...otpInfo, otp: formState.values.password },
					}}
				/>
			)}

			{errorDialog.show && (
				<Dialog
					icon="ERROR"
					title={errorDialog.title}
					heading={errorDialog.msg}
					subheading={errorDialog.subMsg}
					closeModal={resetLogin}
				>
					<ButtonSM customStyles={custom} handleClick={resetLogin}>
						<span>Try Again?</span>
					</ButtonSM>
				</Dialog>
			)}

			{showForgotPasswordModal && (
				<ForgotPasswordModal
					closeModal={() => setShowForgotPasswordModal(false)}
				>
					<ForgotPassword
						closeModal={() => setShowForgotPasswordModal(false)}
					/>
				</ForgotPasswordModal>
			)}
		</>
	);
};

// export default withRouter(NonAuthenticatedView); // REMOVED 6-10-2021
export default NonAuthenticatedView;

NonAuthenticatedView.defaultProps = {};

NonAuthenticatedView.propTypes = {
	history: PropTypes.object,
	children: PropTypes.any,
};
