import React from "react";
import styles from "../../css/reportpicker/ReportOtherOptions.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import Checkbox from "../shared/Checkbox";
import CustomDropdown from "../shared/CustomDropdown";

const hideSortOpts = (otherOptions, reportType) => {
	if (isEmptyObj(otherOptions)) return true;
	if (reportType === `Daily`) return true;
	if (isEmptyVal(reportType)) return true;
	return false;
};

const ReportOtherOptions = ({
	settings = {}, // selected vals
	otherOptions = {},
	handleCheckbox,
	handleCustom,
}) => {
	const { showStrikeOuts, useTimeZoneOverride, hideNotes } = otherOptions;

	if (hideSortOpts(otherOptions, settings?.reportType)) {
		return null;
	}
	return (
		<div className={styles.ReportOtherOptions}>
			<div className={styles.ReportOtherOptions_strikeouts}>
				<Checkbox
					key={`HIDE-NOTES`}
					id={hideNotes?.id}
					name={hideNotes?.name}
					label={hideNotes?.label}
					val={settings?.[hideNotes?.name]}
					handleCheckbox={handleCheckbox}
				/>
			</div>
			{/* STRIKEOUTS GETS DISABLED & SET TO FALSE IF 'HIDE-NOTES' IS ENABLED */}
			<div className={styles.ReportOtherOptions_strikeouts}>
				<Checkbox
					key={`SHOW-STRIKEOUTS`}
					id={showStrikeOuts?.id}
					name={showStrikeOuts?.name}
					label={showStrikeOuts?.label}
					val={settings?.hideNotes ? false : settings?.[showStrikeOuts?.name]}
					handleCheckbox={handleCheckbox}
					isDisabled={settings?.hideNotes}
				/>
			</div>
			{settings?.useTimeZoneOverride && (
				<div className={styles.ReportOtherOptions_timezone}>
					<div className={styles.ReportOtherOptions_timezone_toggle}>
						<Checkbox
							key={`USE-TZ-OVERRIDE`}
							id={useTimeZoneOverride?.id}
							name={useTimeZoneOverride?.name}
							label={useTimeZoneOverride?.label}
							val={settings?.[useTimeZoneOverride?.name]}
							handleCheckbox={handleCheckbox}
						/>
					</div>
					<div className={styles.ReportOtherOptions_timezone_selector}>
						<CustomDropdown
							key={`TZ-OVERRIDE-SELECTOR`}
							id="selectTz"
							name="selectTz"
							label="Select Timezone Override"
							selection={settings?.["selectTz"]}
							setSelection={handleCustom}
							options={useTimeZoneOverride?.options}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default ReportOtherOptions;

ReportOtherOptions.defaultProps = {};

ReportOtherOptions.propTypes = {};
