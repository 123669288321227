import React from "react";
import styles from "../../css/adlshiftreport/AdlSelectionPanel.module.scss";
import { PropTypes } from "prop-types";
import ButtonSM from "../shared/ButtonSM";

const btn = {
	padding: ".8rem 1.5rem",
	fontSize: "1.6rem",
	fontWeight: "600",
};

const AdlSelectionPanel = ({ openResidentTable }) => {
	return (
		<article className={styles.AdlSelectionPanel}>
			<section className={styles.AdlSelectionPanel_section}>
				<h2 className={styles.AdlSelectionPanel_section_title}>
					Select one or ALL residents for a facility.
				</h2>
				<p className={styles.AdlSelectionPanel_section_desc}>
					You may select one or ALL to apply shift updates to by ADL.
				</p>
			</section>
			<section className={styles.AdlSelectionPanel_section}>
				<ButtonSM
					handleClick={() => openResidentTable(true)}
					customStyles={btn}
				>
					<span>Select Resident(s)</span>
				</ButtonSM>
			</section>
		</article>
	);
};

export default AdlSelectionPanel;

AdlSelectionPanel.defaultProps = {};

AdlSelectionPanel.propTypes = {
	openResidentTable: PropTypes.func.isRequired, // opens resident table modal
};
