import React, { useState } from "react";
import { PropTypes } from "prop-types";
import * as Sentry from "@sentry/react";
import { useForm } from "../../utils/useForm";
import { useAlerts, initialSettings } from "../../utils/useAlerts";
import { useScrollPosition, config } from "../../utils/useScrollPosition";
import { useRecents } from "../../utils/useRecents";
import {
	getCurrentFacilityStr,
	getCurrentResidentStr,
	hasFacility,
	formatAndSortFacilities,
} from "../../helpers/utils_facility";
import { getServicePlanByType } from "../../helpers/utils_reports";
import { getReportDesc } from "../../helpers/utils_descriptions";
import { isEmptyArray } from "../../helpers/utils_types";
import {
	servicePlanCols as cols,
	getServicePlanRows,
} from "../../helpers/utils_table";
import {
	SERVICEPLAN_CONFIG,
	amendHistoricalConfig,
} from "../../helpers/utils_config";
import { calculateRestrictionRange } from "../../helpers/utils_dates";
import { formatAndSortResidents } from "../../helpers/utils_residents";
import { updateRecentsModel } from "../../helpers/utils_recentlyViewed";
import { generateID } from "../../helpers/utils_processing";
import { format } from "date-fns";
import { getSvcPlanFields } from "../../helpers/utils_validation";
// components
import ReportTypeController from "../../components/reports/ReportTypeController";
import ServicePlanViewer from "../../components/serviceplan/ServicePlanViewer";
import ErrorDialog from "../../components/errors/ErrorDialog";
import ServicePlanPicker from "../../components/serviceplan/ServicePlanPicker";
import { useEffect } from "react";
import { sendPageTracking } from "../../helpers/utils_tracking";

// initial input values
const initialValState = {
	reportType: "Historical",
	dateRangeType: "",
	byQuarter: "",
	byMonth: "",
	byDate: "",
	byDateRange: "",
	startDate: "",
	endDate: "",
	filterBy: "Resident",
	filterByResident: "",
};

// ##TODOS:
// 1. merge/remove 'hasLoaded' with 'isLoadingReport'; 'hasLoaded' is NOT needed.

const ServicePlanReport = ({
	currentUser,
	currentFacility,
	currentResident,
	residents = [],
	facilities = [],
	residentsByFacility = {},
	adlCategories = [],
	dispatch,
}) => {
	useScrollPosition(config);
	const { formState, setFormState, handleCheckbox, handleReset } = useForm({
		...initialValState,
	});
	const { values } = formState;
	const { cache, addItem, removeItem } = useRecents(
		"Recents-ServicePlanReport",
		{
			recents: [],
			timestamp: Date.now(),
		}
	);
	const { dispatchAlert, AlertsHandler } = useAlerts({
		...initialSettings,
		expiry: 2000,
		msg: {
			heading: "Success!",
			subheading: `You've changed facilities`,
		},
	});

	const [selectedResident, setSelectedResident] = useState(
		getCurrentResidentStr(currentResident)
	);
	const [selectedFacility, setSelectedFacility] = useState(
		getCurrentFacilityStr(currentFacility)
	);

	const [facilityData, setFacilityData] = useState({});
	const [showReportPicker, setShowReportPicker] = useState(false);
	const [isLoadingReport, setIsLoadingReport] = useState(false);
	const [dailyReportData, setDailyReportData] = useState([]);
	// date-range restriction (from ALADVSystem)
	const [rangeRestriction, setRangeRestriction] = useState(
		calculateRestrictionRange(3)
	);

	const FILTER_OPTIONS = [
		{
			name: "filterByResident",
			id: "Resident",
			label: "Choose a resident",
			placeholder: "Resident...",
			options: [...formatAndSortResidents(currentFacility.residents)],
		},
		{
			name: "filterByFacility",
			id: "Facility",
			label: "Choose a facility",
			placeholder: "Facility...",
			options: [...formatAndSortFacilities(currentUser.facilities)],
		},
		{
			name: "filterByADL",
			id: "ADL",
			label: "Choose a ADL",
			placeholder: "ADL...",
			options: [...adlCategories],
		},
	];

	const getFieldsToValidate = (values) => {
		const fields = getSvcPlanFields(values, [
			`reportType`,
			`filterBy`,
			`filterByResident`,
			`dateRangeType`,
		]);
		return fields;
	};

	const rerunHistoricalReport = async (vals) => {
		const { token } = currentUser;
		const reportData = await getServicePlanByType(token, vals, facilities);

		if (!isEmptyArray(reportData)) {
			setIsLoadingReport(false);
			return setDailyReportData(reportData);
		} else {
			setIsLoadingReport(false);
			return setDailyReportData([]);
		}
	};

	const getHistoricalReport = async () => {
		if (!hasFacility(selectedFacility)) {
			setIsLoadingReport(false);
			return dispatchAlert("warning", {
				heading: `Please select a facility.`,
			});
		}
		const { token } = currentUser;
		const reportData = await getServicePlanByType(token, values, facilities);

		// init & populate recents model
		const recentsModel = updateRecentsModel({
			id: generateID(6),
			type: `Historical`,
			name: `Historical Service Plan Report`,
			desc: getReportDesc(values, `Service Plan Report`),
			dateCreated: format(new Date(), "M/D/YYYY h:mm A"),
			data: { ...values },
		});
		addItem(recentsModel);

		if (!isEmptyArray(reportData)) {
			setIsLoadingReport(false);
			return setDailyReportData(reportData);
		} else {
			setIsLoadingReport(false);
			return setDailyReportData([]);
		}
	};

	const createReport = async (e) => {
		switch (true) {
			case !hasFacility(selectedFacility): {
				dispatchAlert(`warn`, {
					heading: `No facility selected!`,
				});
				return setShowReportPicker(false);
			}
			case values.reportType === `Historical`: {
				setShowReportPicker(false);
				setIsLoadingReport(true);
				return await getHistoricalReport();
			}

			default:
				return;
		}
	};

	const cancelReport = (e) => {
		e.preventDefault();
		setShowReportPicker(false);
		handleReset(e);
	};

	const rerunReport = async (recentsModel) => {
		const { data } = recentsModel;

		setShowReportPicker(false);
		setIsLoadingReport(true);
		return await rerunHistoricalReport(data);
	};

	// only used for dispatching an alert
	// global store is updated from side-effect (ie 'useEffect')
	const changeFacility = (selectedVal) => {
		// not needed; facility is loaded via 'setFacility'
		return dispatchAlert("info", {
			heading: `Changed Facility`,
			subheading: `Loaded ${selectedVal}.`,
		});
	};
	// not really used??? except for 'NON-ADMIN' users
	const changeResident = (selectedVal) => {
		setSelectedResident(selectedVal);
	};

	// sends tracking to Sentry.io
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		sendPageTracking(currentUser?.username, {
			pagename: `ServicePlanReport`,
			path: `/dashboard/reports/serviceplan`,
		});
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Sentry.ErrorBoundary fallback={ErrorDialog}>
				<ReportTypeController
					reportTitle="Service Plan Report"
					reportDesc={getReportDesc(values, "Service Plan")}
					setFacilityData={setFacilityData}
					reportType={values.reportType}
					currentUser={currentUser}
					currentFacility={currentFacility}
					residents={residents}
					residentsByFacility={residentsByFacility}
					selectedFacility={selectedFacility}
					setSelectedFacility={setSelectedFacility}
					setSelectedResident={setSelectedResident}
					selectedResident={selectedResident}
					changeFacility={changeFacility}
					changeResident={changeResident}
					recentlyViewedList={cache.recents}
					removeRecentsItem={removeItem}
					rerunReport={rerunReport}
					cancelReport={cancelReport}
					dispatch={dispatch}
					handleReportsPicker={() => setShowReportPicker(true)}
				>
					<ServicePlanViewer
						key={dailyReportData}
						noReportData={isEmptyArray(dailyReportData)}
						reportType={values.reportType}
						isLoading={isLoadingReport}
						tableData={{
							size: `FULL`,
							title: `${values.reportType} Service Plan Report`,
							cols: [...cols],
							data: [...getServicePlanRows(dailyReportData)],
							enableActions: true,
						}}
					/>
				</ReportTypeController>

				<ServicePlanPicker
					reportName={`Service Plan Report`}
					reportTypeOpts={[`Historical`]}
					formState={formState}
					setFormState={setFormState}
					handleCheckbox={handleCheckbox}
					handleReset={handleReset}
					currentFacility={currentFacility}
					currentUser={currentUser}
					adls={adlCategories}
					showPicker={showReportPicker}
					closeModal={() => setShowReportPicker(false)}
					createReport={createReport}
					cancelReport={cancelReport}
					fieldsToValidate={getFieldsToValidate(values)}
					disableShiftSelections={true}
					config={amendHistoricalConfig(
						{ ...SERVICEPLAN_CONFIG },
						"filterOptions",
						[...FILTER_OPTIONS]
					)}
					dateRangeRestrictions={{
						isActive: true,
						rangeStart: rangeRestriction.rangeStart,
						rangeEnd: rangeRestriction.rangeEnd,
					}}
				/>

				{/* ALERTS NOTIFIER UI */}
				{AlertsHandler}
			</Sentry.ErrorBoundary>
		</>
	);
};

export default ServicePlanReport;

ServicePlanReport.defaultProps = {
	currentUser: {},
	currentFacility: {},
	currentResident: {},
	residents: [],
	facilities: [],
	residentsByFacility: {},
};

ServicePlanReport.propTypes = {
	currentUser: PropTypes.shape({
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired,
		password: PropTypes.string.isRequired,
		userID: PropTypes.string.isRequired,
		facilityID: PropTypes.string.isRequired,
		title: PropTypes.string,
		isAdmin: PropTypes.bool.isRequired,
		token: PropTypes.string.isRequired,
		facilities: PropTypes.array.isRequired,
	}),
	currentResident: PropTypes.shape({
		ResidentID: PropTypes.number,
		isLOA: PropTypes.bool,
		FirstName: PropTypes.string,
		LastName: PropTypes.string,
		FloorUnit: PropTypes.string,
		RoomNum: PropTypes.string,
	}),
	currentFacility: PropTypes.shape({
		communityName: PropTypes.string,
		facilityID: PropTypes.string,
		parentID: PropTypes.string,
		address: PropTypes.object,
		residents: PropTypes.array,
		shifts: PropTypes.array,
	}),
	residents: PropTypes.array.isRequired,
	facilities: PropTypes.array,
	residentsByFacility: PropTypes.object,
	dispatch: PropTypes.func.isRequired,
	children: PropTypes.any,
};
