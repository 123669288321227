import { useState } from "react";

// UPDATED WITH "handleReset" AS OF 2/10/2020

// const initialState = {
// 	values: {},
// 	touched: {},
// 	isSubmitting: false
// };

export const useForm = ({ ...vals }) => {
	const [formState, setFormState] = useState({
		values: { ...vals },
		touched: {},
		isSubmitting: false,
	});

	const handleBlur = (e) => {
		e.persist();
		const { name } = e.target;
		const { touched } = formState;
		return setFormState({
			...formState,
			touched: { ...touched, [name]: true },
		});
	};

	const handleFocus = (inputRef) => {
		return inputRef.current.focus();
	};

	const handleReset = (e) => {
		e.persist();
		// e.preventDefault();
		return setFormState({
			...formState,
			values: { ...vals },
		});
	};

	const handleChange = (e) => {
		e.persist();
		const { name, value } = e.target;
		const { values, touched } = formState;
		return setFormState({
			...formState,
			values: {
				...values,
				[name]: value,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};

	const handleKeyDown = (e) => {
		const { name, value } = e.target;
		const { values, touched } = formState;
		return setFormState({
			...formState,
			values: {
				...values,
				[name]: value,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};

	const handleCheckbox = (e) => {
		e.persist();
		const { name, checked } = e.target;
		const { values, touched } = formState;
		setFormState({
			...formState,
			values: {
				...values,
				[name]: checked,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};

	const handleSubmit = (e, callback = null) => {
		e.preventDefault();
		e.persist();
		setFormState({
			...formState,
			isSubmitting: true,
		});
		if (!callback) return;
		return callback();
	};

	return {
		formState,
		setFormState,
		handleBlur,
		handleFocus,
		handleReset,
		handleCheckbox,
		handleChange,
		handleKeyDown,
		handleSubmit,
	};
};
