import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { withRouter, useRouteMatch } from "react-router-dom";
import { NAV_LINKS as navLinks, linksHandler } from "../routes/Routes";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { versionRelease } from "../../helpers/utils_version";
import styles from "../../css/app/Sidetab.module.scss";
import sprite from "../../assets/icons/sidetab.svg";
import logo from "../../assets/images/BRAND/logo.png";

const Sidetab = ({ history, currentUser }) => {
	const match = useRouteMatch();
	const [isOpen, setIsOpen] = useState(false);

	// closes overlay, when route changes
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		setIsOpen(false);

		return () => {
			isMounted = false;
		};
	}, [history.location]);

	return (
		<>
			<aside className={styles.Sidetab} onClick={() => setIsOpen(true)}>
				<svg className={styles.Sidetab_icon}>
					<use xlinkHref={`${sprite}#icon-sort`}></use>
				</svg>
			</aside>

			{isOpen && (
				<nav className={styles.Sidetab_menu}>
					<section className={styles.Sidetab_menu_logo}>
						<img
							src={logo}
							alt="AL Advantage Logo"
							className={styles.Sidetab_menu_logo_img}
						/>
						<div className={styles.Sidetab_menu_logo_version}>
							{versionRelease}
						</div>
					</section>
					<section className={styles.Sidetab_menu_top}>
						<ul className={styles.Sidetab_menu_top_list}>
							{!isEmptyVal(currentUser?.userID) &&
								!isEmptyArray(navLinks) && [
									...linksHandler(currentUser, navLinks, history, match),
								]}
						</ul>
					</section>

					<section
						className={styles.Sidetab_menu_close}
						onClick={() => setIsOpen(false)}
					>
						<svg className={styles.Sidetab_menu_close_icon}>
							<use xlinkHref={`${sprite}#icon-clearclose`}></use>
						</svg>
					</section>
				</nav>
			)}
		</>
	);
};

export default withRouter(Sidetab);

Sidetab.defaultProps = {};

Sidetab.propTypes = {
	history: PropTypes.object,
	currentUser: PropTypes.object.isRequired,
};
