import { useState, useEffect } from "react";
import {
	getFromStorage,
	saveToStorage,
	clearStorage,
} from "../helpers/utils_caching";
import { alreadyExists } from "../helpers/utils_processing";

/**
 * @description - Persists the most recent x number of data structures passed to it.
 * - Currently used to access the 6 most recent reports a user has accessed
 */

// checks if maxLength has been reached
const hasMax = (list, maxLength = 6) => list.length >= maxLength;

/**
 * @description - The shape of "useStorage"'s state object;
 * - "cache.recents": the actual cache, saved to localStorage (used to be called 'cache.value')
 * - "cache.timestamp": when cache was last updated
 */
export const initialState = {
	recents: [],
	timestamp: Date.now(),
};

export const useRecents = (
	key,
	defaultState = { ...initialState },
	maxLength = 6
) => {
	const [cache, setCache] = useState(() => {
		const recentFromCache = getFromStorage(key);

		return recentFromCache !== null ? recentFromCache : defaultState;
	});

	// add entry to 'recents' list, maintain 'maxLength'
	const addItem = (item) => {
		if (alreadyExists(item, cache.recents)) return;
		if (hasMax(cache.recents, maxLength)) {
			return setCache({
				recents: [item, ...cache.recents.slice(0, cache.recents.length - 1)],
				timestamp: Date.now(),
			});
		} else {
			return setCache({
				recents: [item, ...cache.recents],
				timestamp: Date.now(),
			});
		}
	};

	// remove entry from 'recent's list;
	// if no 'id' given, remove from end of 'recents' list
	const removeItem = (id = null) => {
		if (!id) {
			const { recents } = cache;
			recents.pop();
			return setCache({
				recents: [...recents],
				timestamp: Date.now(),
			});
		} else {
			return setCache({
				recents: [...cache.recents.filter((x) => x.id !== id)],
				timestamp: Date.now(),
			});
		}
	};

	const clearRecents = () => clearStorage();

	useEffect(() => {
		saveToStorage(key, {
			recents: cache.recents,
			timestamp: Date.now(),
		});
	}, [cache, key]);

	return {
		cache,
		setCache,
		addItem,
		removeItem,
		clearRecents,
	};
};
