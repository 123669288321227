/**
 * Update Endpoint & ENV Variables 6/2 at 8:00 AM
 * Azure ENV Endpoint: https://ala-api.azurewebsites.net
 */
const endpoints = {
	base: "https://apitest.aladvantage.com/alaservices/v1/",
	auth: {
		login: "Security/Login",
		logout: "Security/Logout",
		loginStatus: "Security/SecurityTokenValid",
		validateToken: "Security/SecurityTokenValid",
		refreshToken: "Security/SecurityTokenRefresh",
		sessionDetails: "Security/GetSecurityTokenDetail",
		userAccessByID: "Security/GetUserAccess",
		userAccessByEmail: "Security/GetUserAccessByEmail",
	},
	generic: {
		count: "Data/Count",
		get: "Data/Get",
		get2: "Data/Get2",
		delete: "Data/Delete",
		execute: "Data/Execute",
		insert: "Data/Insert",
		save: "Data/Save",
		update: "Data/Update",
	},
	uploads: {
		upload: "Upload/PutFile",
		uploadMany: "Upload/PutFileMany",
		saveFileRegistry: "Upload/SaveFileRegistry",
		saveFileRegistryMany: "Upload/SaveFileRegistryMany",
	},
	downloads: {
		getFile: "Download/GetFile",
		getFileMany: "Download/GetFileMany",
		getFileRegistry: {
			byUser: "Download/GetFileRegistryByUser",
			byResident: "Download/GetFileRegistryByResident",
			byFacility: "Download/GetFileRegistryByFacility",
			byMeta: "Download/GetFileRegistryByMeta",
		},
	},
	residents: {
		getResidents: "Advantage/GetResidents",
		byUser: "Resident/GetResidentsByUser",
		byUserEmail: "Resident/GetResidentsByUserEmail",
	},
	residentData: {
		getPhotos: "Advantage/GetResidentPhotos",
		getSummary: "Advantage/GetSummary",
		getAssessment: "Resident/GetResidentAssessment",
		getContacts: "Advantage/GetContacts",
		getProfile: "Resident/GetResidentProfile",
		getLOA: "Advantage/GetLeaveOfAbsence",
		getMeds: "Advantage/GetMedications",
		getInventory: "Advantage/GetResidentInventory",
		getResidentBM: "Advantage/GetResidentBowelMovements",
		getResidentWeight: "Advantage/GetResidentWeight",
		forTracker: {
			byDay: "Resident/GetResidentDayForAdvantageTracker",
			byWeek: "Resident/GetResidentWeekForAdvantageTracker",
			byDayMaster: "Resident/GetResidentDayAssessmentMaster",
			byWeekMaster: "Resident/GetResidentWeekAssessmentMaster",
			byDayDetails: "Resident/GetResidentDayAssessmentDetails",
			byWeekDetails: "Resident/GetResidentDayAssessmentDetails",
			purgeByDay: "Resident/DeleteResidentTrackingTasks",
		},
		// for adl shift schedule (ie shifts per adl)
		adlSchedule: {
			getSchedule: "Advantage/GetAssessmentResidentAdlShift",
			saveScheduleMany: "Resident/UpdateResidentsAdlShifts",
			getAdlShiftChanges: "Facility/GetFacilityAdlShifts",
		},
	},
	assessmentTracking: {
		save: "Advantage/SaveAssessmentTracking",
		update: "Advantage/UpdateAssessmentTracking",
	},
	scheduledTasks: {
		// ASSESSMENT TRACKING TASK RECORDS = SCHEDULED TASKS
		get: {
			task: "Advantage/GetAssessmentTrackingTask",
			task2: "Advantage/GetAssessmentTrackingTask2",
			taskNotes: "Advantage/GetAssessmentTrackingTaskNote2",
		},
		update: {
			task: "Advantage/UpdateAssessmentTrackingTask",
			taskMany: "Advantage/UpdateAssessmentTrackingTaskMany",
		},
		// PRIMARY UPDATE ENDPOINTS
		save: {
			task: "Advantage/SaveAssessmentTrackingTask",
			taskMany: "Advantage/SaveAssessmentTrackingTaskMany",
			taskNotesMany: "Advantage/SaveAssessmentTrackingTaskNoteMany",
		},
		delete: {
			task: "Advantage/DeleteAssessmentTrackingTask",
			taskMany: "Advantage/DeleteAssessmentTrackingTaskMany",
			generatedTasks: "Resident/DeleteResidentTrackingTasks",
			taskNote: "Advantage/DeleteAssessmentTrackingTaskNote",
		},
		virtualDelete: {
			taskNote: "Advantage/UpdateAssessmentTrackingTaskNote",
		},
		insert: {
			task: "Advantage/InsertAssessmentTrackingTask",
			taskMany: "Advantage/InsertAssessmentTrackingTaskMany",
		},
		count: {
			task: "Advantage/CountAssessmentTrackingTask",
			task2: "Advantage/CountAssessmentTrackingTask2",
		},
	},
	unscheduledTasks: {
		get: {
			task: "Advantage/GetAssessmentUnscheduleTask",
			task2: "Advantage/GetAssessmentUnscheduleTask2",
			taskNotes: "Advantage/GetAssessmentUnscheduleTaskNote2",
		},
		update: {
			task: "Advantage/UpdateAssessmentUnscheduleTask",
			taskMany: "Advantage/UpdateAssessmentUnscheduleTaskMany",
		},
		// PRIMARY UPDATE ENDPOINTS - USE *IN-PLACE-OF* "UPDATE" ROUTES
		save: {
			task: "Advantage/SaveAssessmentUnscheduleTask",
			taskMany: "Advantage/SaveAssessmentUnscheduleTaskMany",
			taskNotesMany: "Advantage/SaveAssessmentUnscheduleTaskNoteMany",
		},
		delete: {
			task: "Advantage/DeleteAssessmentUnscheduleTask",
			taskMany: "Advantage/DeleteAssessmentUnscheduleTaskMany",
			taskNote: "Advantage/DeleteAssessmentUnscheduleTaskNote",
		},
		virtualDelete: {
			taskNote: "Advantage/UpdateAssessmentUnscheduleTaskNote",
		},
		insert: {
			task: "Advantage/InsertAssessmentUnscheduleTask",
			taskMany: "Advantage/InsertAssessmentUnscheduleTaskMany",
		},
		count: {
			task: "Advantage/CountAssessmentUnscheduleTask",
			task2: "Advantage/CountAssessmentUnscheduleTask2",
		},
	},
	facilityTasks: {
		get: "Advantage/GetAssessmentCommunityTask",
		get2: "Advantage/GetAssessmentCommunityTask2",
		save: "Advantage/SaveAssessmentCommunityTask",
		saveMany: "Advantage/SaveAssessmentCommunityTaskMany",
		remove: "Advantage/DeleteAssessmentCommunityTask",
		removeMany: "Advantage/DeleteAssessmentCommunityTaskMany",
	},
	pastDue: {
		get: "Community/GetCommunityPastDueTasks",
		byFacility: "Community/GetCommunityPastDueTasks",
		byResident: "Resident/GetResidentPastDueTasks",
	},
	reassess: {
		getReassess: "Advantage/GetAssessmentTrackingReassess",
		updateSingle: "Advantage/UpdateAssessmentTrackingReassess",
		updateMany: "Advantage/UpdateAssessmentTrackingReassessMany",
		saveReassess: "Advantage/SaveAssessmentTrackingReassess",
		saveReassessMany: "Advantage/SaveAssessmentTrackingReassessMany",
	},
	// SECURITY MODULE
	// SECURITY MODULE
	security: {
		resetType: {
			save: "Security/SetUserPasswordRules",
			get: "Security/GetUserPasswordRules",
		},
		questions: {
			get: "ALADVSystem/GetSecurityQuestion",
			get2: "ALADVSystem/GetSecurityQuestion2",
			update: "ALADVSystem/UpdateSecurityQuestion",
			create: "Security/CreateUserSecurityQuestionAndAnswers",
			saveUserAnswer: "Security/SetUserSecurityAnswer",
			validate: {
				questionExists: "Security/ValidateSecurityQuestion",
				userQuestion: "Security/ValidateUserSecurityQuestion",
				userAnswer: "Security/ValidateUserSecurityAnswer",
			},
		},
		info: {
			get: "Security/GetUserInformation",
			getFacilitySecurityInfo: "Security/GetFacilityUserInformation",
		},
		userLogin: {
			createNew: "Security/CreateUserLogin",
			validate: "Security/ValidateUserLoginExists",
			checkPasswordStrength: "Security/IsStrongPassword",
			suspendLogin: "Security/SetUserLoginSuspend",
			unsuspendLogin: "Security/UnsetUserLoginSuspend",
		},
		userProfile: {
			createNew: "Security/CreateUserProfile",
			get: "ALADVSystem/GetUserProfile",
			save: "ALADVSystem/SaveUserProfile", // only updates the main 'UserProfile' record data
		},
		userType: {
			get: "ALADVSystem/GetUserType",
			get2: "ALADVSystem/GetUserType2",
			save: "ALADVSystem/SaveUserType",
		},
		userTitle: {
			get: "ALADVSystem/GetUserTitle",
			get2: "ALADVSystem/GetUserTitle2",
			save: "ALADVSystem/SaveUserTitle",
			create: "ALADVSystem/SaveUserTitle",
		},
		lockouts: {
			profile: {
				lock: "Security/SetUserProfileLockout",
				unlock: "Security/UnsetUserProfileLockout",
			},
			login: {
				lock: "Security/SetUserLoginLockout",
				unlock: "Security/UnsetUserLoginLockout",
				suspend: "Security/SetUserLoginSuspend",
				unsuspend: "Security/UnsetUserLoginSuspend",
			},
			otp: {
				isOtp: "Security/IsOtp",
				generate: "Security/GenerateOTP",
				generateAndSend: "Security/GenerateAndSendOTP",
			},
			summary: {
				getSummary: "Security/GetUserLockoutSummary",
				getLoginAttempts: "Security/GetFailedLoginAttempts",
			},
			confirmation: {
				sendConfirmationEmail: "Security/SendConfirmationEmail",
				registerConfirmationEmail: "Security/RegisterConfirmationEmail",
			},
		},
		facilityAccess: {
			getAccessList: "Facility/GetFacilityAccess", // returns list of facilityIDs
			registerAccess: "Facility/RegisterFacilityAccess", // sets access w/ custom array
			grantAccess: "Facility/GrantFacilityAccess",
			denyAccess: "Facility/DeniedFacilityAccess",
		},
	},
	user: {
		getProfile: "Security/GetUserProfile",
		getProfileByEmail: "Security/GetUserProfileByEmail",
	},
	reports: {
		getInfo: "Reports/GetReportInformation",
		executeReport: "Reports/ExecuteReport",
		executeReportAsync: "Reports/ExecuteReportAsync",
		getReportParams: "Reports/GetReportParameters", // by report name
		getReportInfo: "Reports/GetReportInformation",
		// daily reports APIs
		getDailyExceptions: "Community/GetDailyExceptionTasks",
		getDailyCompletions: "Community/GetDailyCompletionTasks",
		getDailyTaskCreatedReport: "Assessment/GetUnscheduleTasks",
		getDailyReassessReport: "Assessment/GetReassessTasks",
		getDailyServicePlanReport: "Assessment/GetServicePlans",
		getPastDueReportByFacility: "Community/GetCommunityPastDueTasks",
		getPastDueReportByResident: "Resident/GetResidentPastDueTasks",
		// historical report APIs
		getHistoricalTaskCreated: "Assessment/GetUnscheduleTasks",
		getHistoricalReassess: "Assessment/GetReassessTasks",
		getHistoricalServicePlan: "Assessment/GetServicePlans",
		getHistoricalActualTime: "ENDPOINT-TBD",
	},
	// UPDATE FACILITY'S SHIFT TIMES (START/END TIME)
	shiftTimes: {
		getShiftTimes: "Advantage/GetAssessmentFacilityShift",
		getShiftTimes2: "Advantage/GetAssessmentFacilityShift2", // "POST" TYPE
		saveShiftTimes: "Advantage/SaveAssessmentFacilityShift",
		saveShiftTimesMany: "Advantage/SaveAssessmentFacilityShiftMany",
	},
	facility: {
		getCategories: "Advantage/GetAssessmentCategory",
		getAdlCategories: "Advantage/GetAssessmentFacilityCategory",
		getShifts: "Advantage/GetAssessmentShift",
		getFacilities: {
			byUser: "Community/GetCommunitiesByUser",
			byUserEmail: "Community/GetCommunitiesByUserEmail",
		},
		exceptions: {
			getExceptionType: "Advantage/GetAssessmentFacilityException",
			saveExceptionType: "Advantage/SaveAssessmentFacilityException",
			saveExceptionTypeMany: "Advantage/SaveAssessmentFacilityExceptionMany",
			deleteExceptionType: "Advantage/DeleteAssessmentFacilityException",
			deleteExceptionTypeMany:
				"Advantage/DeleteAssessmentFacilityExceptionMany",
		},
		cancellations: {
			getCancellationTypes: "Advantage/GetAssessmentFacilityCancellation",
			getCancellationTypes2: "Advantage/GetAssessmentFacilityCancellation2",
			saveCancellationType: "Advantage/SaveAssessmentFacilityCancellation",
			saveCancellationTypeMany:
				"Advantage/SaveAssessmentFacilityCancellationMany",
		},
	},
	exceptions: {
		// 'AssessmentException' table (ie global)
		byAssessment: {
			saveException: "Advantage/SaveAssessmentException",
			saveExceptionMany: "Advantage/SaveAssessmentExceptionMany",
		},
		// 'AssessmentFacilityException' table (ie by facility)
		byFacility: {
			getException: "Advantage/GetAssessmentFacilityException",
			saveException: "Advantage/SaveAssessmentFacilityException",
			saveExceptionMany: "Advantage/SaveAssessmentFacilityExceptionMany",
			deleteException: "Advantage/DeleteAssessmentFacilityException",
			deleteExceptionMany: "Advantage/DeleteAssessmentFacilityExceptionMany",
		},
	},
	cancellations: {
		// 'AssessmentCancellation' table (ie global)
		base: {
			saveCancellation: "Advantage/SaveAssessmentCancellation",
			saveCancellationMany: "Advantage/SaveAssessmentCancellationMany",
		},
		// 'AssessmentFacilityCancellation' table (ie by facility)
		byFacility: {
			getCancellation: "Advantage/GetAssessmentFacilityCancellation",
			getCancellation2: "Advantage/GetAssessmentFacilityCancellation2",
			saveCancellation: "Advantage/SaveAssessmentFacilityCancellation",
			saveCancellationMany: "Advantage/SaveAssessmentFacilityCancellationMany",
			deleteCancellation: "Advantage/DeleteAssessmentFacilityCancellation",
			deleteCancellationMany:
				"Advantage/DeleteAssessmentFacilityCancellationMany",
		},
	},
	// APPLICATION/FACILITY SETTINGS
	settings: {
		dataRestriction: {
			get: "ALADVSystem/GetSettingApplication",
			save: "ALADVSystem/SaveSettingApplication",
			remove: "ALADVSystem/DeleteSettingApplication",
		},
		type: {
			get: "ALADVSystem/GetSettingType",
			save: "ALADVSystem/SaveSettingType",
			remove: "ALADVSystem/DeleteSettingType",
		},
		apps: {
			get: "ALADVSystem/GetApplication",
			save: "ALADVSystem/SaveApplication",
			remove: "ALADVSystem/DeleteApplication",
		},
		facility: {
			get: "ALADVSystem/GetSettingFacility",
			save: "ALADVSystem/SaveSettingFacility",
			remove: "ALADVSystem/DeleteSettingFacility",
		},
	},
	emails: {
		sendEmail: "Security/SendUserEmail",
		sendEmails: "Security/SendUsersEmail",
	},
};

const {
	auth,
	generic,
	uploads,
	downloads,
	downloads: { getFileRegistry },
	residents,
	residentData,
	residentData: { forTracker, adlSchedule },
	assessmentTracking,
	scheduledTasks,
	unscheduledTasks,
	pastDue,
	reassess,
	user,
	security,
	security: {
		lockouts,
		questions,
		userLogin,
		userProfile,
		userType,
		userTitle,
		info,
		facilityAccess,
	},
	reports,
	shiftTimes,
	facility: { getFacilities },
	facility,
	exceptions,
	cancellations,
	settings,
	emails,
} = endpoints;

export {
	endpoints,
	auth, // login, logout, checkLoginStatus
	generic, // get, save, delete, update
	uploads,
	downloads, // download single, download many
	getFileRegistry,
	residents,
	residentData,
	forTracker,
	adlSchedule, // resident adl schedule (bulk or single resident)
	assessmentTracking,
	scheduledTasks,
	unscheduledTasks,
	pastDue,
	reassess,
	user,
	security,
	lockouts,
	questions,
	userLogin,
	userProfile,
	userType,
	userTitle,
	info,
	facilityAccess,
	reports,
	shiftTimes, // by facility
	facility,
	exceptions,
	cancellations,
	getFacilities,
	settings,
	emails,
};
