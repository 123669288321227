import React from "react";
import styles from "../../css/adlschedule/ShiftHeadings.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import { getShiftName } from "../../helpers/utils_shifts";
import { FALLBACK_SHIFTS as fallbackShifts } from "../../helpers/utils_options";
import ShiftHeading from "./ShiftHeading";

const ShiftHeadings = ({ shiftHeadings = [], isLoadingSettings = false }) => {
	return (
		<div className={styles.ShiftHeadings}>
			<div className={styles.ShiftHeadings_adl}>
				<span>ADL</span>
			</div>
			{!isEmptyArray(shiftHeadings) &&
				shiftHeadings.map((shift, i) => (
					<ShiftHeading
						shift={shift}
						allShifts={shiftHeadings}
						key={`SHIFT--${getShiftName(shift?.AssessmentShiftId)}-${
							shift?.AssessmentFacilityShiftId
						}`}
					/>
				))}
		</div>
	);
};

export default ShiftHeadings;

ShiftHeadings.defaultProps = {
	shiftHeadings: [],
};

ShiftHeadings.propTypes = {
	shiftHeadings: PropTypes.arrayOf(PropTypes.object).isRequired,
};
