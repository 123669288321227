import React from "react";
import styles from "../../css/shared/MonthPickerMonth.module.scss";
import { PropTypes } from "prop-types";
import { months } from "../../utils/useDates";

const MonthPickerMonth = ({
	currentYear,
	month,
	handleMonth,
	isSelected = false,
	isRestricted = false,
}) => {
	const handleStyles = () => {
		if (isRestricted) {
			return styles.MonthPickerMonth_isRestricted;
		} else if (isSelected) {
			return styles.MonthPickerMonth_isSelected;
		} else {
			return styles.MonthPickerMonth;
		}
	};

	return (
		<div
			className={handleStyles()}
			onClick={isRestricted ? null : () => handleMonth(month)}
		>
			<time
				className={styles.MonthPickerMonth_month}
				dateTime={new Date(`${currentYear}-${months.indexOf(month)}`)}
			>
				{month}
			</time>
		</div>
	);
};

export default MonthPickerMonth;

MonthPickerMonth.defaultProps = {
	isSelected: false,
	isRestricted: false,
};

MonthPickerMonth.propTypes = {
	month: PropTypes.string.isRequired,
	isRestricted: PropTypes.bool,
};
