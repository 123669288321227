import React from "react";
import styles from "../../css/reports/ReportSettings.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { getReportDateRange } from "../../helpers/utils_descriptions";
import ReportDesc from "./ReportDesc";

const getReportType = (reportName, reportSettings) => {
	const { reportType } = reportSettings;
	return `${reportType} ${reportName}`;
};

const ReportSettings = ({
	title,
	reportSettings = {},
	reportDesc,
	hasLoaded = false,
	showDetails = false,
	handleReportsPicker,
}) => {
	return (
		<article className={styles.ReportSettings} title={title}>
			<h4 className={styles.ReportSettings_panelHeading}>
				Report Settings/Create a Report
			</h4>
			<section className={styles.ReportSettings_desc}>
				<p className={styles.ReportSettings_desc_text}>
					{!hasLoaded ? `Please Select a Report` : reportDesc}
				</p>
			</section>
			{/* REPORT BY, DATE RANGE */}
			{showDetails && (
				<section className={styles.ReportSettings_current}>
					<h4 className={styles.ReportSettings_current_heading}>
						Current Report (selected options)
					</h4>
					<ReportDesc reportName={title} reportDesc={reportDesc} />
				</section>
			)}

			<section className={styles.ReportSettings_actions}>
				<button
					className={styles.ReportSettings_actions_btn}
					onClick={handleReportsPicker}
				>
					<svg className={styles.ReportSettings_actions_btn_icon}>
						<use xlinkHref={`${sprite}#icon-add_chart`}></use>
					</svg>
					<span>Select Settings</span>
				</button>
			</section>
		</article>
	);
};

export default ReportSettings;

ReportSettings.defaultProps = {
	hasLoaded: false,
	showDetails: false,
};

ReportSettings.propTypes = {
	title: PropTypes.string,
	hasLoaded: PropTypes.bool,
	reportDesc: PropTypes.string,
	showDetails: PropTypes.bool,
	handleReportsPicker: PropTypes.func.isRequired, // triggers showing the reports picker/generator
};

////////////////////////////////////////////////////
//////// REPORT SETTINGS SECTION - DETAILS ////////
////////////////////////////////////////////////////

const ReportSettingsDetails = ({ title, reportDesc, reportSettings = {} }) => {
	const range = getReportDateRange(reportSettings);
	return (
		<ul className={styles.ReportSettings_current_details}>
			<li className={styles.ReportSettings_current_details_item}>
				Report By {getReportType(title, reportSettings)}
			</li>
			<li className={styles.ReportSettings_current_details_item}>
				Date Range: {reportSettings.dateRangeType}
			</li>
			<li className={styles.ReportSettings_current_details_item}>
				Start Date: <b>{range?.startDate}</b>
			</li>
			<li className={styles.ReportSettings_current_details_item}>
				End Date: <b>{range?.endDate}</b>
			</li>
		</ul>
	);
};

export { ReportSettingsDetails };
