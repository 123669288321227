import React, { useState, useEffect } from "react";
import styles from "../../css/serviceplan/ServicePlanPickerController.module.scss";
import { PropTypes } from "prop-types";
import {
	validateFields,
	validateSvcPlanFields,
} from "../../helpers/utils_validation";
import { themeColors } from "../../helpers/utils_styles";
import { getReportDesc } from "../../helpers/utils_descriptions";
// components
import ReportTypePicker from "../reportpicker/ReportTypePicker";
import ReportPickerDesc from "../reportpicker/ReportPickerDesc";
import ButtonSM from "../shared/ButtonSM";

const createBtn = {
	padding: "1.2rem 1.6rem",
	fontSize: "1.4rem",
	fontWeight: "600",
	borderRadius: ".5rem",
	backgroundColor: themeColors.main.main,
};
const cancelBtn = {
	padding: "1.05rem 1.6rem",
	fontSize: "1.4rem",
	fontWeight: "600",
	borderRadius: ".5rem",
	backgroundColor: "transparent",
	border: "1px solid #ccc",
	color: "#333",
	marginRight: "1rem",
};

const ServicePlanPickerController = ({
	settings = {},
	handleFilters, // 'filterBy'
	handleSorts, // 'sortBy'
	handleCheckbox,
	handleSettings,
	handleDateRange,
	handleReset,
	reportTypeOpts = [],
	createReport,
	cancelReport,
	reportName,
	fieldsToValidate = [],
	children,
}) => {
	const [readyToSubmit, setReadyToSubmit] = useState(
		validateSvcPlanFields(settings, [...fieldsToValidate])
	);
	const [reportDesc, setReportDesc] = useState(() => {
		return getReportDesc(settings, reportName);
	});

	// inject handlers & settings into 'props.children'
	const withHandlers = React.Children.map(children, (child, i) =>
		React.cloneElement(child, {
			settings,
			handleReset,
			handleFilters,
			handleSorts,
			handleCheckbox,
			handleSettings,
			handleDateRange,
		})
	);

	// enables 'btn' when form is ready
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		// checks if fields are filled out
		const checkForm = () => {
			if (settings.reportType === "Historical") {
				return setReadyToSubmit(
					validateSvcPlanFields(settings, [...fieldsToValidate])
				);
			} else {
				return setReadyToSubmit(
					validateSvcPlanFields(settings, [`reportType`])
				);
			}
		};
		checkForm();
		return () => {
			isMounted = false;
		};
	}, [fieldsToValidate, settings]);

	// update desc everytime a selection is made
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		setReportDesc(() => {
			return getReportDesc(settings, reportName);
		});

		return () => {
			isMounted = false;
		};
	}, [reportName, settings]);

	const preventDefaultSubmit = (e) => {
		console.log(`⌛ Prevented default submission...`);
	};

	return (
		<form
			className={styles.ServicePlanPickerController}
			onSubmit={preventDefaultSubmit}
		>
			<ReportTypePicker
				reportTypeName="reportType"
				selection={settings.reportType}
				setSelection={handleSettings}
				options={reportTypeOpts}
			/>
			{/* CUSTOM OPTIONS START HERE */}
			{withHandlers}
			{/* CUSTOM OPTIONS END HERE */}
			<ReportPickerDesc reportName={reportName} desc={reportDesc} />
			<div className={styles.ServicePlanPickerController_actions}>
				<ButtonSM handleClick={cancelReport} customStyles={cancelBtn}>
					<span>Cancel</span>
				</ButtonSM>
				<ButtonSM
					handleClick={createReport}
					customStyles={createBtn}
					isDisabled={!readyToSubmit}
				>
					<span>Create Report</span>
				</ButtonSM>
			</div>
		</form>
	);
};

export default ServicePlanPickerController;

ServicePlanPickerController.defaultProps = {
	settings: {},
	isDisabled: false,
};

ServicePlanPickerController.propTypes = {
	settings: PropTypes.func,
	handleFilters: PropTypes.func,
	handleSorts: PropTypes.func,
	handleCheckbox: PropTypes.func,
	handleChange: PropTypes.func,
	handleSettings: PropTypes.func,
	isDisabled: PropTypes.func,
};
