import React from "react";
import styles from "../../css/reportpicker/ReportSortOptions.module.scss";
import { PropTypes } from "prop-types";
import {
	isEmptyVal,
	isEmptyArray,
	isEmptyObj,
} from "../../helpers/utils_types";
import RadioButton from "../shared/RadioButton";

const getSort = (settings) => {
	if (isEmptyVal(settings.sortBy)) return "";
	const { sortBy } = settings;
	const trimmed = sortBy.replace(" ", "");
	return `sort${trimmed}`;
};

const hideSortOpts = (sortOptions, reportType) => {
	if (isEmptyObj(sortOptions)) return true;
	if (reportType === `Daily`) return true;
	if (isEmptyVal(reportType)) return true;
	return false;
};

const ReportSortOptions = ({
	settings,
	sortOptions = {},
	handleSortOptions,
}) => {
	if (hideSortOpts(sortOptions, settings?.reportType)) {
		return null;
	}
	return (
		<div className={styles.ReportSortOptions}>
			{!isEmptyVal(settings.sortBy) &&
				!isEmptyArray(sortOptions[getSort(settings)]) &&
				sortOptions[getSort(settings)].map((sortOpt, idx) => (
					<RadioButton
						key={`${sortOpt}--${idx}`}
						name={sortOpt.name}
						id={sortOpt.id}
						label={sortOpt.label}
						val={settings[sortOpt.name] === sortOpt.id}
						handleSelection={handleSortOptions}
					/>
				))}
		</div>
	);
};

export default React.memo(ReportSortOptions);

ReportSortOptions.defaultProps = {};

ReportSortOptions.propTypes = {
	settings: PropTypes.object,
};
