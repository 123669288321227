import React from "react";
import styles from "../../css/app/ValidationLoader.module.scss";
import { PropTypes } from "prop-types";
import Spinner from "../shared/Spinner";

const ValidationLoader = () => {
	return (
		<div className={styles.ValidationLoader}>
			<div className={styles.ValidationLoader_inner}>
				<Spinner />
				<div className={styles.ValidationLoader_inner_message}>
					Validating your entries...
				</div>
			</div>
		</div>
	);
};

export default ValidationLoader;

ValidationLoader.defaultProps = {};

ValidationLoader.propTypes = {};
