import React from "react";
import styles from "../../css/forms/ConfirmedIndicator.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";

const ConfirmedIndicator = ({ confirmedMsg = `Passwords Match` }) => {
	return (
		<div className={styles.ConfirmedIndicator}>
			<svg className={styles.ConfirmedIndicator_icon}>
				<use xlinkHref={`${sprite}#icon-done_all`}></use>
			</svg>
			<span className={styles.ConfirmedIndicator_msg}>{confirmedMsg}</span>
		</div>
	);
};

export default ConfirmedIndicator;

ConfirmedIndicator.defaultProps = {};

ConfirmedIndicator.propTypes = {
	confirmedMsg: PropTypes.string,
};
