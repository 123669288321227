import React from "react";
import styles from "../../css/adlshiftreport/AdlShiftReportPanel.module.scss";
import { PropTypes } from "prop-types";
import ButtonSM from "../shared/ButtonSM";

const btn = {
	padding: ".8rem 1.5rem",
	fontSize: "1.6rem",
	fontWeight: "600",
};

const AdlShiftReportPanel = ({ openShiftReport }) => {
	return (
		<article className={styles.AdlShiftReportPanel}>
			<section className={styles.AdlShiftReportPanel_section}>
				<h2 className={styles.AdlShiftReportPanel_section_title}>
					View Current Settings
				</h2>
				<p className={styles.AdlShiftReportPanel_section_desc}>
					Changes made to a resident's Adl Shift Settings will alter which
					shifts (ie AM, PM, NOC) that a resident's tasks are generated for and
					occur in.
				</p>
				<p className={styles.AdlShiftReportPanel_section_warning}>
					Warning: Any changes made will NOT overwrite a resident's existing
					assessment.
				</p>
			</section>
			{/* BELOW - NOT CURRENT IMPLEMENTED!!! */}
			<section className={styles.AdlShiftReportPanel_section}>
				{/* LIST THE RECENT CHANGES HERE... */}
				{/* LIST THE RECENT CHANGES HERE... */}
				{/* LIST THE RECENT CHANGES HERE... */}
				{/* LIST THE RECENT CHANGES HERE... */}
			</section>
			{/* ABOVE - NOT CURRENT IMPLEMENTED!!! */}
			<section className={styles.AdlShiftReportPanel_section}>
				<ButtonSM handleClick={() => openShiftReport(true)} customStyles={btn}>
					<span>View Current Settings</span>
				</ButtonSM>
			</section>
		</article>
	);
};

export default AdlShiftReportPanel;

AdlShiftReportPanel.defaultProps = {};

AdlShiftReportPanel.propTypes = {
	openShiftReport: PropTypes.func.isRequired, // opens adl shift report modal
};
