import React from "react";
import styles from "../../css/tables/TableHeadings.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import TableHeading from "./TableHeading";
import Checkbox from "../shared/Checkbox";

const TableHeadings = ({
	headings = [],
	sortType,
	selectAllVal,
	enableRowSelection = false,
	handleHeading,
	handleSelectAll,
}) => {
	return (
		<header className={styles.TableHeadings}>
			<div className={styles.TableHeadings_content}>
				{enableRowSelection && (
					<div className={styles.TableHeadings_content_selectAll}>
						<input
							type="checkbox"
							name="selectAll"
							id="selectAll"
							checked={selectAllVal}
							className={styles.TableHeadings_content_selectAll_checkbox}
							onChange={handleSelectAll}
						/>
						<label
							htmlFor="selectAll"
							className={styles.TableHeadings_content_selectAll_label}
						>
							Select All
						</label>
					</div>
				)}
				{!isEmptyArray(headings) &&
					headings.map((heading, idx) => (
						<TableHeading
							key={`${heading}_${idx}`}
							heading={heading}
							sortType={sortType}
							handleHeading={() => handleHeading(heading)}
						/>
					))}
			</div>
		</header>
	);
};

export default TableHeadings;

TableHeadings.defaultProps = {
	headings: [],
};

TableHeadings.propTypes = {
	headings: PropTypes.arrayOf(PropTypes.string).isRequired,
	handleHeading: PropTypes.func.isRequired,
};
