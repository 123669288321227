import React, { useState } from "react";
import styles from "../../css/daily/DailyViewSwitcher.module.scss";
import { PropTypes } from "prop-types";

// panels wrapper
const DailyViewPanels = ({ activeIndex, children }) => {
	const withActiveIndex = React.Children.map(children, (child, i) =>
		React.cloneElement(child, { activeIndex: activeIndex, tabIndex: i })
	);
	return (
		<section className={styles.DailyViewPanels}>{withActiveIndex}</section>
	);
};
// view panel
const DailyViewPanel = ({ activeIndex, tabIndex, children }) => {
	return <>{activeIndex === tabIndex ? children : null}</>;
};

// buttons wrapper
const DailyViewSwitcherButtons = ({
	handleTabButton,
	activeIndex,
	children,
}) => {
	const withProps = React.Children.map(children, (child, i) =>
		React.cloneElement(child, {
			handleTabButton,
			tabIndex: i,
			activeIndex: activeIndex,
		})
	);

	return (
		<section className={styles.DailyViewSwitcherButtons}>{withProps}</section>
	);
};

// button
const DailyViewSwitcherButton = ({
	handleTabButton,
	activeIndex,
	tabIndex,
	children,
}) => {
	return (
		<button
			type="button"
			className={
				activeIndex === tabIndex
					? styles.DailyViewSwitcherButton_isActive
					: styles.DailyViewSwitcherButton
			}
			onClick={() => handleTabButton(tabIndex)}
		>
			{children}
		</button>
	);
};

// controller/container
const DailyViewSwitcher = ({ defaultIndex, children }) => {
	const [activeIndex, setActiveIndex] = useState(defaultIndex);

	const handleTabButton = (tabIndex) => {
		return setActiveIndex(tabIndex);
	};

	const withHandler = React.Children.map(children, (child, i) =>
		React.cloneElement(child, {
			handleTabButton: handleTabButton,
			activeIndex: activeIndex,
		})
	);
	return (
		<div className={styles.DailyViewSwitcher}>
			<div className={styles.DailyViewSwitcher_inner}>{withHandler}</div>
		</div>
	);
};

export default DailyViewSwitcher;

export {
	DailyViewPanel,
	DailyViewPanels,
	DailyViewSwitcherButton,
	DailyViewSwitcherButtons,
};

DailyViewSwitcher.defaultProps = {};

DailyViewSwitcher.propTypes = {};
