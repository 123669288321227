import React from "react";
import "./App.scss";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AppProviders } from "./state/AppProviders";
import ReactGA from "react-ga";
// routes/views
import Main from "./components/Main";
import NonAuthenticatedView from "./views/app/NonAuthenticatedView";
import AuthenticatedView from "./views/app/AuthenticatedView";
import PageNotFound from "./components/pages/PageNotFound";
import ProtectedRoute from "./auth/ProtectedRoute";
import AuthTimeout from "./auth/AuthTimeout";
import { envLogger } from "./helpers/utils_processing";
import { currentEnvName } from "./helpers/utils_env";

envLogger(currentEnvName, process.env.NODE_ENV);

export const history = createBrowserHistory();
history.listen((location, action) => {
	// location is an object like window.location
	// records each page location that's visited
	ReactGA.set({ page: location.pathname });
});

function App() {
	return (
		<Router history={history}>
			<AppProviders>
				<AuthTimeout>
					<div className="App">
						<Main>
							<Switch>
								<Route exact path="/" component={NonAuthenticatedView} />
								<Route path="/login" component={NonAuthenticatedView} />
								<ProtectedRoute
									path="/dashboard"
									component={AuthenticatedView}
								/>
								<Route component={PageNotFound} />
							</Switch>
						</Main>
					</div>
				</AuthTimeout>
			</AppProviders>
		</Router>
	);
}

export default App;
