import React, { useState, useEffect } from "react";
import styles from "../../css/daily/DailySummaryCardCounts.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import { getCountsByStatus } from "../../helpers/utils_processing";

const handleCount = (allTasks = [], countsObj, type) => {
	if (isEmptyArray(allTasks)) return 0;
	return countsObj[type];
};

const DailySummaryCardCounts = ({
	allTasks = [],
	shiftTimes = [],
	hasUpdated = false,
}) => {
	const [taskCounts, setTaskCounts] = useState({
		...getCountsByStatus(allTasks, shiftTimes),
	});

	// "watches" for changes and updates status counts
	useEffect(() => {
		if (hasUpdated) {
			return setTaskCounts({
				...getCountsByStatus(allTasks, shiftTimes),
			});
		}
	}, [allTasks, hasUpdated, shiftTimes]);

	return (
		<section className={styles.DailySummaryCardCounts}>
			{/* TOTAL TASKS */}
			<div className={styles.DailySummaryCardCounts_tile}>
				<h4 className={styles.DailySummaryCardCounts_tile_heading}>
					<b>Tasks</b>
				</h4>
				<h4
					className={styles.DailySummaryCardCounts_tile_val}
					value={taskCounts.total}
				>
					{handleCount(allTasks, taskCounts, "total")}
				</h4>
			</div>
			{/* COMPLETED */}
			<div className={styles.DailySummaryCardCounts_tile}>
				<h4 className={styles.DailySummaryCardCounts_tile_heading}>
					Completed
				</h4>
				<h4
					className={styles.DailySummaryCardCounts_tile_val_green}
					value={taskCounts.complete}
				>
					{handleCount(allTasks, taskCounts, "complete")}
				</h4>
			</div>
			{/* NOT COMPLETE */}
			<div className={styles.DailySummaryCardCounts_tile}>
				<h4 className={styles.DailySummaryCardCounts_tile_heading}>
					Not-Complete
				</h4>
				<h4
					className={styles.DailySummaryCardCounts_tile_val_altYellow}
					value={taskCounts.notComplete}
				>
					{handleCount(allTasks, taskCounts, "notComplete")}
				</h4>
			</div>
			{/* PAST DUE */}
			<div className={styles.DailySummaryCardCounts_tile}>
				<h4 className={styles.DailySummaryCardCounts_tile_heading}>Past Due</h4>
				<h4
					className={styles.DailySummaryCardCounts_tile_val_purple}
					value={taskCounts.pastDue}
				>
					{handleCount(allTasks, taskCounts, "pastDue")}
				</h4>
			</div>
			{/* EXCEPTIONS */}
			<div className={styles.DailySummaryCardCounts_tile}>
				<h4 className={styles.DailySummaryCardCounts_tile_heading_red}>
					Exceptions
				</h4>
				<h4
					className={styles.DailySummaryCardCounts_tile_val_red}
					value={taskCounts.exceptions}
				>
					{handleCount(allTasks, taskCounts, "exceptions")}
				</h4>
			</div>
		</section>
	);
};

export default DailySummaryCardCounts;

DailySummaryCardCounts.defaultProps = {
	allTasks: [],
	hasUpdated: false,
};

DailySummaryCardCounts.propTypes = {
	allTasks: PropTypes.arrayOf(PropTypes.object),
	hasUpdated: PropTypes.bool,
};
