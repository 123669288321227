import { useLayoutEffect } from "react";

export const config = {
	position: {
		top: 0,
		left: 0,
		behavior: "smooth",
	},
};

export const useScrollPosition = ({ position }) => {
	useLayoutEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		window.scrollTo({
			top: position.top,
			left: position.left,
			behavior: position.behavior,
		});
		return () => {
			isMounted = false;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
