import React from "react";
import styles from "../../css/residentinfo/ResidentSvcPlanTable.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import ResidentSvcPlanEntry from "./ResidentSvcPlanEntry";

const ResidentSvcPlanTable = ({ svcPlanEntries = [] }) => {
	return (
		<div className={styles.ResidentSvcPlanTable}>
			<header className={styles.ResidentSvcPlanTable_header}>
				<div className={styles.ResidentSvcPlanTable_header_column}>
					<div className={styles.ResidentSvcPlanTable_header_column_label}>
						Needs/Problems
					</div>
				</div>
				<div className={styles.ResidentSvcPlanTable_header_column}>
					<div className={styles.ResidentSvcPlanTable_header_column_label}>
						Goal(s)
					</div>
				</div>
				<div className={styles.ResidentSvcPlanTable_header_column}>
					<div className={styles.ResidentSvcPlanTable_header_column_label}>
						Intervention(s)
					</div>
				</div>
				{/* <div className={styles.ResidentSvcPlanTable_header_column}>
					<div className={styles.ResidentSvcPlanTable_header_column_label}>
						Time/Date
					</div>
				</div> */}
			</header>
			<div className={styles.ResidentSvcPlanTable_body}>
				{!isEmptyArray(svcPlanEntries) &&
					svcPlanEntries.map((svcPlanEntry, idx) => (
						<ResidentSvcPlanEntry
							key={`SVC-PLAN-ENTRY-${idx}`}
							svcPlanEntry={svcPlanEntry}
						/>
					))}
				{/*  */}
			</div>
		</div>
	);
};

export default ResidentSvcPlanTable;

ResidentSvcPlanTable.defaultProps = {};

ResidentSvcPlanTable.propTypes = {};
