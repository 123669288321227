import React, { useState, useEffect, useContext } from "react";
import styles from "../../css/dashboard/ReportsView.module.scss";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { GlobalStateContext } from "../../state/GlobalStateContext";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import {
	getAdlCategories,
	sortAndFormatAdls,
} from "../../helpers/utils_categories";
// components
import ReportsDashboard from "../reports/ReportsDashboard";
// Reports Views (active) - (nested sub-routes)
import CompletionReport from "../reports/CompletionReport";
import ExceptionReport from "../reports/ExceptionReport";
import PastDueReport from "../reports/PastDueReport";
import ReassessReport from "../reports/ReassessReport";
import TaskCreatedReport from "../reports/TaskCreatedReport";
import TaskStatusReport from "../reports/TaskStatusReport";
// Reports Views (inactive)
import ServicePlanReport from "../reports/ServicePlanReport";
import ActualTimeReport from "../reports/ActualTimeReport";

/**
 * PURPOSE:
 * - Distribute required props & stateful values from the Global Store & Auth Store.
 * - Declare the report routes & their corresponding components.
 *
 * CURRENTLY AVAILABLE REPORTS (as of 6/7/2022):
 * - "Completion Report": daily & historical
 * - "Exception Report": daily & historical
 * - "Past Due Report": daily only
 * - "Task Created Report": daily & historical
 * - "Service Plan Update Report" (formerly "Reassess Report"): daily & historical
 * - "Task Status Report": daily & historical
 *
 * CURRENTLY UNAVAILABLE REPORTS (as of 6/7/2022):
 * - "Actual Time Report": daily & historical
 * - "Service Plan Report": daily & historical
 *
 */

const ReportsView = ({ history }) => {
	const match = useRouteMatch();
	const { state, dispatch } = useContext(GlobalStateContext);

	const { user, globals } = state;
	const {
		currentResident,
		currentFacility,
		residents: allResidents,
		residentsByFacility,
		categories,
	} = globals;
	const [adls, setAdls] = useState(sortAndFormatAdls(categories));

	const getAdls = async () => {
		const { token } = user;
		const adlCategories = await getAdlCategories(token, 0, 25);

		if (!isEmptyArray(adlCategories)) {
			return setAdls(sortAndFormatAdls(adlCategories));
		} else {
			return setAdls([]);
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isEmptyArray(categories)) {
			getAdls();
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.ReportsView}>
			<Switch>
				<Route exact path={`${match.url}`} component={ReportsDashboard} />
				<Route path={`${match.url}/exception`}>
					<ExceptionReport
						globalState={state}
						currentFacility={currentFacility}
						currentResident={currentResident}
						currentUser={user}
						residents={allResidents}
						residentsByFacility={residentsByFacility}
						adlCategories={adls}
						dispatch={dispatch}
					/>
				</Route>
				<Route path={`${match.url}/completion`}>
					<CompletionReport
						globalState={state}
						currentFacility={currentFacility}
						currentResident={currentResident}
						currentUser={user}
						residents={allResidents}
						residentsByFacility={residentsByFacility}
						adlCategories={adls}
						dispatch={dispatch}
					/>
				</Route>
				<Route path={`${match.url}/taskstatus`}>
					<TaskStatusReport
						globalState={state}
						currentFacility={currentFacility}
						currentResident={currentResident}
						currentUser={user}
						residents={allResidents}
						residentsByFacility={residentsByFacility}
						adlCategories={adls}
						dispatch={dispatch}
					/>
				</Route>
				<Route path={`${match.url}/pastdue`} component={PastDueReport}>
					<PastDueReport
						globalState={state}
						currentFacility={currentFacility}
						currentResident={currentResident}
						currentUser={user}
						residents={allResidents}
						facilities={user.facilities}
						residentsByFacility={residentsByFacility}
						adlCategories={adls}
						dispatch={dispatch}
					/>
				</Route>
				<Route path={`${match.url}/reassess`}>
					{/* NOW CALLED "SERVICE PLAN UPDATE REPORT" */}
					<ReassessReport
						globalState={state}
						currentFacility={currentFacility}
						currentResident={currentResident}
						currentUser={user}
						residents={allResidents}
						facilities={user.facilities}
						residentsByFacility={residentsByFacility}
						adlCategories={adls}
						dispatch={dispatch}
					/>
				</Route>
				<Route path={`${match.url}/taskcreated`}>
					<TaskCreatedReport
						globalState={state}
						currentFacility={currentFacility}
						currentResident={currentResident}
						currentUser={user}
						residents={allResidents}
						facilities={user.facilities}
						residentsByFacility={residentsByFacility}
						adlCategories={adls}
						dispatch={dispatch}
					/>
				</Route>
				<Route path={`${match.url}/serviceplan`}>
					<ServicePlanReport
						globalState={state}
						currentFacility={currentFacility}
						currentResident={currentResident}
						currentUser={user}
						residents={allResidents}
						facilities={user.facilities}
						residentsByFacility={residentsByFacility}
						adlCategories={adls}
						dispatch={dispatch}
					/>
				</Route>

				{/* INACTIVE CURRENTLY */}
				<Route path={`${match.url}/actualtime`}>
					<ActualTimeReport
						globalState={state}
						currentFacility={currentFacility}
						currentResident={currentResident}
						currentUser={user}
						residents={allResidents}
						facilities={user.facilities}
						residentsByFacility={residentsByFacility}
						adlCategories={adls}
						dispatch={dispatch}
					/>
				</Route>
			</Switch>
		</div>
	);
};

export default ReportsView;

ReportsView.defaultProps = {};

ReportsView.propTypes = {
	history: PropTypes.object,
};
