import React from "react";
import styles from "../../css/reportsviewer/ReportViewerTableRow.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
// components
import ReportViewerNotesCell from "./ReportViewerNotesCell";
import ReportViewerTableCell from "./ReportViewerTableCell";
import Checkbox from "../shared/Checkbox";

const ReportViewerTableRow = ({
	rowData = {},
	handleCell,
	selectionLabel = "Select",
	selectionVal,
	handleSelectRow,
	enableRowSelection = false,
}) => {
	// grabs keys from row data, to know which fields to render where.
	const cellAccessors = Object.keys(rowData);

	return (
		<div className={styles.ReportViewerTableRow}>
			<div className={styles.ReportViewerTableRow_cells}>
				{enableRowSelection && (
					<Checkbox
						name="selectRow"
						id="selectRow"
						val={selectionVal}
						label={selectionLabel}
						handleCheckbox={handleSelectRow}
					/>
				)}

				{!isEmptyArray(cellAccessors) &&
					cellAccessors.map((dataKey, idx) => {
						if (dataKey === "Notes") {
							return (
								<ReportViewerNotesCell
									key={`ROW-${dataKey}--${idx}`}
									cellData={rowData[dataKey]}
									maxLength={500}
									handleCell={handleCell}
								/>
							);
						} else {
							return (
								<ReportViewerTableCell
									key={`ROW-${dataKey}--${idx}`}
									cellData={rowData[dataKey]}
									maxLength={500}
									handleCell={handleCell}
								/>
							);
						}
					})}
			</div>
		</div>
	);
};

export default ReportViewerTableRow;

ReportViewerTableRow.defaultProps = {
	rowData: {},
	enableRowSelection: false,
};

ReportViewerTableRow.propTypes = {
	rowData: PropTypes.object.isRequired,
	handleCell: PropTypes.func,
	enableRowSelection: PropTypes.bool,
};
