import React from "react";
import styles from "../../css/widget/FeedbackWidgetStep.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";

const FeedbackWidgetStep = ({
	showBackArrow = false,
	isActive,
	goBack,
	children,
}) => {
	if (!isActive) {
		return null;
	}
	return (
		<div className={styles.FeedbackWidgetStep}>
			{showBackArrow && (
				<svg className={styles.FeedbackWidgetStep_back} onClick={goBack}>
					<use xlinkHref={`${sprite}#icon-arrow_back`}></use>
				</svg>
			)}
			{children}
		</div>
	);
};

export default FeedbackWidgetStep;

FeedbackWidgetStep.defaultProps = {};

FeedbackWidgetStep.propTypes = {};
