import React from "react";
import styles from "../../css/reportpicker/ReportFilterPicker.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import FormSection from "../forms/FormSection";
import RadioButton from "../shared/RadioButton";
import Divider from "../forms/Divider";

/**
 * Purpose: handles the 'filterBy' initial type.
 * - Uses a <RadioButton/> group that passes the 'id' prop as the arg to the event handler.
 *
 */

const hideFilters = (filters, reportType) => {
	if (isEmptyArray(filters)) return true;
	if (isEmptyVal(reportType)) return true;
	if (reportType === `Daily`) return true;
	return false;
};

const ReportFilterPicker = ({ settings, filters = [], handleFilters }) => {
	if (hideFilters(filters, settings?.reportType)) {
		return null;
	}
	return (
		<FormSection
			icon="inbox"
			title="Choose the report dataset"
			hoverText="Create a report by resident, facility or ADL."
		>
			{!isEmptyArray(filters) &&
				filters.map((filterBy, idx) => (
					<RadioButton
						key={`${filterBy.name}--${idx}`}
						name={filterBy.name}
						id={filterBy.id}
						label={`By ${filterBy.id}`}
						val={settings[filterBy.name] === filterBy.id}
						handleSelection={handleFilters}
					/>
				))}
		</FormSection>
	);
};

export default React.memo(ReportFilterPicker);

ReportFilterPicker.defaultProps = {
	filters: [],
};

ReportFilterPicker.propTypes = {
	settings: PropTypes.object,
	filters: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			id: PropTypes.string, // 'Resident', 'Facility'
			label: PropTypes.string,
			val: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
			handleFilters: PropTypes.func,
		})
	),
};
