import React from "react";
import styles from "../../css/reportpicker/ReportFilterOptions.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import FilterOption from "./FilterOption";
import Divider from "../forms/Divider";

/**
 * Purpose: handles the sub-options for report filters.
 * - Ex. 'By Resident', then show list of residents to select from.
 * - Ex. 'By Facility', then show list of facilities to select from.
 * - Ex. 'By ADL', then show list of ADLs to select from.
 * - Filter 'sub'-Options are handled by <CustomDropdown/>
 */

const hideFilterOpts = (filterOptions, reportType) => {
	if (isEmptyArray(filterOptions)) return true;
	if (isEmptyVal(reportType)) return true;
	if (reportType === `Daily`) return true;
	return false;
};

const ReportFilterOptions = ({
	settings,
	filterOptions = [],
	handleFilterOptions,
}) => {
	if (hideFilterOpts(filterOptions, settings?.reportType)) {
		return null;
	}
	return (
		<>
			<div className={styles.ReportFilterOptions}>
				{!isEmptyArray(filterOptions) &&
					filterOptions.map((option, idx) => (
						<FilterOption
							key={`${option.name}--${idx}`}
							settings={settings}
							filterProps={option}
							filterOption={option.id}
							filterOptionsList={option.options}
							handleFilterOpt={handleFilterOptions}
						/>
					))}
			</div>
			<Divider />
		</>
	);
};

export default ReportFilterOptions;

ReportFilterOptions.defaultProps = {};

ReportFilterOptions.propTypes = {};
