import React, { useEffect, useState } from "react";
import styles from "../../css/app/ForgotPassword.module.scss";
import { PropTypes } from "prop-types";
import { initialSettings, useAlerts } from "../../utils/useAlerts";
import { getSystemUserAuth } from "../../helpers/utils_systemUser";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import ResetOptions from "./ResetOptions";
import Spinner from "../shared/Spinner";

const ForgotPassword = ({ authData, closeModal }) => {
	const [systemUser, setSystemUser] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const { AlertsHandler, dispatchAlert } = useAlerts(initialSettings);

	const syncSystemUser = async () => {
		const SYSTEM_USER = await getSystemUserAuth();
		setIsLoading(true);

		if (!isEmptyVal(SYSTEM_USER.token)) {
			setSystemUser({ ...SYSTEM_USER });
			return setIsLoading(false);
		} else {
			setSystemUser({});
			return setIsLoading(false);
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		syncSystemUser();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={styles.ForgotPassword}>
				<div className={styles.ForgotPassword_loader}>
					{isLoading && <Spinner />}
				</div>
				<ResetOptions
					systemUser={systemUser}
					authData={authData}
					closeModal={closeModal}
					dispatchAlert={dispatchAlert}
				/>
			</div>

			{AlertsHandler}
		</>
	);
};

export default ForgotPassword;

ForgotPassword.defaultProps = {};

ForgotPassword.propTypes = {
	authData: PropTypes.object,
	closeModal: PropTypes.func,
};
