import React from "react";
import styles from "../../css/serviceplan/ServicePlanViewer.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import ReportPane from "../reports/ReportPane";
import Table from "../tables/Table";
import Placeholder from "../shared/Placeholder";

const emptySCSS = {
	display: "flex",
	justifyContent: "center",
	textAlign: "center",
};

/**
 * Default table data structure, passed from props:
 * - "defaultData.cols": table columns (array of strings)
 * - "defaultData.data": raw table data, unprocessed (array of objects)
 * - "defaultData.title": table title (string)
 * - "defaultData.size": defines table's size prop (string). Options: FULL, XLG, LG, MD, SM, XSM
 * - "defaultData.enableActions": enables filtering, sorting & custom search. (boolean)
 */
const defaultData = {
	cols: [],
	data: [],
	title: "",
	size: "FULL",
	enableActions: true,
};

const ServicePlanViewer = ({
	reportType = `Daily`,
	isLoading,
	noReportData = false,
	tableData: { size, cols, data, title, enableActions },
}) => {
	return (
		<div className={styles.ReportViewer}>
			{reportType === `Daily` && (
				<ReportPane
					key={`Daily-${title}`}
					size={size}
					title={title}
					isLoading={isLoading}
					loadingMsg={`Loading daily report data...`}
				>
					{!isEmptyArray(data) ? (
						<Table
							cols={cols}
							data={data}
							size={size}
							title={title}
							enableActions={enableActions}
						/>
					) : (
						<div className={styles.ReportViewer_placeholder}>
							<Placeholder
								size="MD"
								msg={noReportData ? `No report data found.` : ``}
								color="red"
								customStyles={emptySCSS}
							/>
						</div>
					)}
				</ReportPane>
			)}

			{reportType === `Historical` && (
				<ReportPane
					key={`Historical-${title}`}
					size={size}
					title={title}
					isLoading={isLoading}
					loadingMsg={`Loading historical report data...`}
				>
					{!isEmptyVal(data) ? (
						<Table
							cols={cols}
							data={data}
							size={size}
							title={title}
							enableActions={enableActions}
						/>
					) : (
						<div className={styles.ReportViewer_placeholder}>
							<Placeholder
								size="MD"
								msg={noReportData ? `No report data found.` : ``}
								color="red"
								customStyles={emptySCSS}
							/>
						</div>
					)}
				</ReportPane>
			)}
		</div>
	);
};

export default ServicePlanViewer;

ServicePlanViewer.defaultProps = {
	reportType: `Daily`,
	noReportData: false,
};

ServicePlanViewer.propTypes = {
	reportType: PropTypes.string,
	isLoading: PropTypes.bool,
	noReportData: PropTypes.bool,
	tableData: PropTypes.shape({
		size: PropTypes.string,
		cols: PropTypes.arrayOf(PropTypes.string),
		data: PropTypes.arrayOf(PropTypes.object),
		title: PropTypes.string,
		enableActions: PropTypes.bool,
	}),
};
