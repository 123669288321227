import React, { useState, useEffect } from "react";
import styles from "../../css/residentinfo/ResidentInfoPanel.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { getResidentInfo } from "../../helpers/utils_residentData";
import { formatResidentName } from "../../helpers/utils_residents";
// components
import ResidentDetails from "./ResidentDetails";
import ResidentPanel from "./ResidentPanel";
import ResidentInfo from "./ResidentInfo";
import ResidentMeds from "./ResidentMeds";
import ResidentServicePlan from "./ResidentServicePlan";
import Spinner from "../shared/Spinner";
import ResidentHeader from "../app/ResidentHeader";

// REQUIREMENTS:
// - Add "Service Plan" details for each resident:
// 		- Resident Details:
// 				- Problems
// 				- Goals
// 				- Interventions
// 		- Create 3 x 3 grid/table for details (use legacy as basis)

const hasResident = (currentResident) => {
	if (isEmptyObj(currentResident)) return false;
	if (isEmptyVal(currentResident?.ResidentID)) return false;
	return true;
};

const ResidentInfoPanel = ({
	currentResident = {},
	currentUser = {},
	imgSrc = "",
}) => {
	const [residentProfile, setResidentProfile] = useState({});
	const [isLoadingProfile, setIsLoadingProfile] = useState(true);
	const [isProfileLoaded, setIsProfileLoaded] = useState(
		!isEmptyObj(residentProfile)
	);
	const { token } = currentUser;

	// fetches onMount & whenever 'currentResident' changes
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		// fetches profile, and sets "residentProfile"
		const fetchProfile = async (residentID) => {
			const profile = await getResidentInfo(token, residentID);

			if (!isEmptyObj(profile)) {
				setIsLoadingProfile(false);
				setResidentProfile(profile);
				return setIsProfileLoaded(true);
			} else {
				setIsLoadingProfile(false);
				setResidentProfile({});
				return setIsProfileLoaded(false);
			}
		};
		// if (hasResident(currentResident)) {
		fetchProfile(currentResident?.ResidentID);
		// }

		return () => {
			isMounted = false;
		};
	}, [currentResident, token]);

	return (
		<article className={styles.ResidentInfoPanel}>
			<header className={styles.ResidentInfoPanel_header}>
				<ResidentHeader
					currentResident={currentResident}
					currentUser={currentUser}
				/>
			</header>
			<main className={styles.ResidentInfoPanel_main}>
				{isLoadingProfile && (
					<div className={styles.ResidentInfoPanel_main_loader}>
						<div className={styles.ResidentInfoPanel_main_loader_msg}>
							Loading {formatResidentName(currentResident)}'s profile...
						</div>
						<Spinner />
					</div>
				)}
				{isProfileLoaded && (
					<>
						<ResidentPanel
							isProfileLoaded={isProfileLoaded}
							isLoadingProfile={isLoadingProfile}
							profile={residentProfile?.profile}
							contacts={residentProfile?.contacts}
							currentResident={currentResident}
						>
							<ResidentDetails title="General Info">
								<ResidentInfo />
							</ResidentDetails>
							<ResidentDetails title="Medical Info">
								<ResidentMeds />
							</ResidentDetails>
							{/* <ResidentDetails title="Service Plan(s)"> */}
							{/* </ResidentDetails> */}
						</ResidentPanel>
						<div className={styles.ResidentInfoPanel_main_heading}>
							Service Plan Info
						</div>
						<ResidentServicePlan
							currentUser={currentUser}
							currentResident={currentResident}
							residentProfile={residentProfile}
						/>
					</>
				)}
			</main>
		</article>
	);
};

export default ResidentInfoPanel;

ResidentInfoPanel.defaultProps = {
	currentResident: {},
	currentUser: {},
};

ResidentInfoPanel.propTypes = {
	currentResident: PropTypes.shape({
		FirstName: PropTypes.string,
		LastName: PropTypes.string,
		RoomNum: PropTypes.string,
		FloorUnit: PropTypes.string,
		ResidentType: PropTypes.string,
		AptPhone: PropTypes.string,
		isLOA: PropTypes.bool,
	}),
	currentUser: PropTypes.shape({
		token: PropTypes.string,
		username: PropTypes.string,
		password: PropTypes.string,
		isAdmin: PropTypes.bool,
		title: PropTypes.string,
		hasMultiFacilityAccess: PropTypes.bool,
	}),
};
