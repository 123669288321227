import React, { useReducer, useState } from "react";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import { sortResidentsBy, getSelected } from "../../helpers/utils_residents";
import styles from "../../css/residenttable/ResidentTable.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import sprite2 from "../../assets/icons/resident-table.svg";
import ResidentTableHeadings from "./ResidentTableHeadings";
import ResidentTableBody from "./ResidentTableBody";
import ResidentRow from "./ResidentRow";
import TextInput from "../shared/TextInput";
import ButtonSM from "../shared/ButtonSM";

// action buttons' CSS styles
const cancelBtn = {
	padding: ".5rem 1rem",
	backgroundColor: "transparent",
	color: "#333",
	border: "1px solid #ccc",
	marginRight: "1rem",
};
const saveBtn = {
	padding: ".5rem 1rem",
};

// shows selected resident count and total
const SelectedCount = ({ selected = [], total }) => {
	return (
		<div className={styles.SelectedCount}>
			Selected: {selected} out of {total}
		</div>
	);
};

// resident table selection reducer
const selectReducer = (state, action) => {
	switch (action.type) {
		case "SELECT": {
			const { id } = action.data;
			if ([...state.selectedList].includes(id)) {
				return {
					...state,
					selectedList: [...state.selectedList.filter((x) => x !== id)],
				};
			}
			return {
				...state,
				selectedList: [...state.selectedList, id],
			};
		}
		// NOT SURE IF NEEDED???
		case "REMOVE": {
			const { id } = action.data;
			return {
				...state,
				selectedList: [...state.selectedList.filter((x) => x !== id)],
			};
		}
		case "SELECT_ALL": {
			const { residents } = action.data;
			return {
				...state,
				allSelected: true,
				selectedList: [...getSelected(residents)],
			};
		}
		case "DE-SELECT_ALL": {
			return {
				...state,
				allSelected: false,
				selectedList: [],
			};
		}
		case "SORT": {
			const { residents, sortBy } = action.data;
			const { isSorted } = state;
			// if NOT sorted, apply sorting, if ALREADY sorted, remove sorting/return original residents array
			if (isSorted) {
				return {
					...state,
					isSorted: false,
					filtered: [...residents].reverse(),
				};
			} else {
				return {
					...state,
					isSorted: true,
					filtered: [...sortResidentsBy(residents, sortBy)],
				};
			}
		}
		case "SEARCH": {
			const { residents, search } = action.data;
			return {
				...state,
				filtered: [
					...residents.filter((x) =>
						x.LastName.toLowerCase().startsWith(search)
					),
				],
			};
		}
		default:
			return { ...state };
	}
};

const initialState = {
	allSelected: false,
	isSorted: false,
	filtered: [],
	selectedList: [],
};

const ResidentTable = ({
	title,
	headings = [],
	residents = [],
	alreadySelected = [],
	getSelectedResidents,
	closeModal,
}) => {
	const [showFilters, setShowFilters] = useState(true);
	const [residentSearch, setResidentSearch] = useState("");
	const [state, dispatch] = useReducer(selectReducer, {
		...initialState,
		filtered: [...residents],
		selectedList: [...alreadySelected], // persist selections between opening modal
	});
	const { allSelected, filtered, selectedList } = state;

	const handleSearch = (e) => {
		const { value } = e.target;
		setResidentSearch(value);
		dispatch({
			type: "SEARCH",
			data: {
				residents: [...residents],
				search: value,
			},
		});
	};

	const handleSelectAll = () => {
		if (selectedList.length === residents.length) {
			return dispatch({ type: "DE-SELECT_ALL" });
		}
		return dispatch({ type: "SELECT_ALL", data: { residents: residents } });
	};

	const selectSort = (sort) => {
		// if NOT sorted, apply sorting, else return original list(from props)
		dispatch({
			type: "SORT",
			data: {
				residents: residents,
				sortBy: sort,
			},
		});
	};

	// handles "isSelected" ResidentID(s)
	const addToSelected = (id) => {
		dispatch({
			type: "SELECT",
			data: {
				id: id,
			},
		});
	};

	// clears out selections locally & in <AdlScheduleView/>
	const clearSelection = (e) => {
		e.preventDefault();

		dispatch({ type: "DE-SELECT_ALL" });
		getSelectedResidents([]);
	};
	const saveSelection = (e) => {
		e.preventDefault();

		getSelectedResidents(selectedList);
		closeModal(e);
	};

	return (
		<article className={styles.ResidentTable}>
			<section className={styles.ResidentTable_top}>
				<svg className={styles.ResidentTable_top_icon}>
					<use xlinkHref={`${sprite}#icon-account_circle`}></use>
				</svg>
				<h4 className={styles.ResidentTable_top_title}>{title}</h4>
				<svg
					className={styles.ResidentTable_top_filterIcon}
					title="Filters"
					onClick={() => setShowFilters(!showFilters)}
				>
					<use xlinkHref={`${sprite2}#icon-filter_list_alt`}></use>
				</svg>
			</section>
			{showFilters && (
				<section className={styles.ResidentTable_filters}>
					<div className={styles.ResidentTable_filters_search}>
						<TextInput
							name="residentSearch"
							id="residentSearch"
							val={residentSearch}
							handleChange={handleSearch}
							customStyles={{ width: "25rem", margin: "0 auto" }}
							placeholder="Search by resident name..."
						/>
					</div>

					{/* SELECTED COUNT */}
					<SelectedCount
						selected={selectedList.length}
						total={residents.length}
					/>
					<div className={styles.ResidentTable_filters_controls}>
						<ButtonSM customStyles={cancelBtn} handleClick={clearSelection}>
							<span>Clear Selections</span>
						</ButtonSM>
						<ButtonSM customStyles={saveBtn} handleClick={saveSelection}>
							<span>Save Selections</span>
						</ButtonSM>
					</div>
				</section>
			)}
			<section className={styles.ResidentTable_headings}>
				<ResidentTableHeadings
					headings={headings}
					allSelected={allSelected}
					selectSort={selectSort}
					handleSelectAll={handleSelectAll}
				/>
			</section>
			<section className={styles.ResidentTable_body}>
				<ResidentTableBody>
					{!isEmptyArray(filtered) &&
						filtered.map((resident, i) => (
							<ResidentRow
								key={`${resident.ResidentID}_${i}`}
								residentData={resident}
								allSelected={allSelected}
								selectedList={selectedList}
								addToSelected={() => addToSelected(resident.ResidentID)}
							/>
						))}
				</ResidentTableBody>
			</section>
		</article>
	);
};

export default ResidentTable;

ResidentTable.defaultProps = {
	headings: [],
	residents: [],
};

ResidentTable.propTypes = {
	title: PropTypes.string.isRequired,
	headings: PropTypes.arrayOf(PropTypes.string).isRequired,
	residents: PropTypes.arrayOf(PropTypes.object).isRequired,
	getSelectedResidents: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
};
