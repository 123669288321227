import React, { useState, useEffect, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { PropTypes } from "prop-types";
import { usePrint } from "../../utils/usePrint";
import { useForm } from "../../utils/useForm";
import { useAlerts, initialSettings } from "../../utils/useAlerts";
import {
	useScrollPosition,
	config as scrollConfig,
} from "../../utils/useScrollPosition";
import { useRecents } from "../../utils/useRecents";
import {
	isEmptyVal,
	isEmptyArray,
	isEmptyObj,
} from "../../helpers/utils_types";
import { createBlob, createURL, getFileID } from "../../helpers/utils_files";
import { getFileBlob } from "../../helpers/utils_downloads";
import {
	getDailyExceptions,
	getShiftIDsFromSettings,
	getHistoricalExceptions,
	createReportModel,
} from "../../helpers/utils_reports";
import {
	getDailyReportDesc,
	getReportDesc,
	getReportDescByType,
} from "../../helpers/utils_descriptions";
import {
	exceptionCols as cols,
	getExceptionReportRows,
} from "../../helpers/utils_table";
import {
	hasFacility,
	getFacilityDay,
	formatAndSortFacilities,
} from "../../helpers/utils_facility";
import {
	formatResidentOnly,
	getResidentObj,
} from "../../helpers/utils_residents";
import { formatAndSortResidents } from "../../helpers/utils_residents";
import {
	EXCEPTION_CONFIG,
	amendHistoricalConfig,
} from "../../helpers/utils_config";
import { updateRecentsModel } from "../../helpers/utils_recentlyViewed";
import { generateID } from "../../helpers/utils_processing";
import { format } from "date-fns";
import {
	getDateRangeTypeKey,
	getFilterByKey,
	getFields,
} from "../../helpers/utils_validation";
import { calculateRestrictionRange } from "../../helpers/utils_dates";
import { sendPageTracking } from "../../helpers/utils_tracking";
import { mergeDailyResidentData } from "../../helpers/utils_residentData";
import { populateState } from "../../helpers/utils_initialResources";
// components
import ReportViewer from "../../components/reports/ReportViewer";
import ReportTypeController from "../../components/reports/ReportTypeController";
import ConfigurablePicker from "../../components/reportpicker/ConfigurablePicker";
import ReportsViewerController from "../../components/reportsviewer/ReportsViewerController";

// ##TODOS:
// - Add 'date-range' restrictions and logic ✓

export const getCurrentResidentStr = (globalResident) => {
	if (isEmptyVal(globalResident?.ResidentID)) return "";
	return formatResidentOnly(globalResident);
};

export const getCurrentFacilityStr = (globalFacility) => {
	if (isEmptyVal(globalFacility?.facilityID)) return "";
	return globalFacility?.communityName;
};

// merges ALL records together & checks for length >= 1
// switch this to Array.every() for accuracy & perf
const noExceptionsForToday = (dailyData) => {
	if (isEmptyArray(dailyData)) return true;
	return dailyData.reduce((hasData, record) => {
		const {
			ExceptionScheduleTasks: scheduled,
			ExceptionUnscheduleTasks: unscheduled,
		} = record;
		if (scheduled.length > 0 || unscheduled.length > 0) {
			hasData = true;
			return hasData;
		}
		return hasData;
	}, false);
};

// initial input values
const initialValState = {
	reportType: "Daily",
	filterBy: "",
	filterByResident: "",
	filterByFacility: "",
	filterByADL: "",
	shiftAM: false,
	shiftPM: false,
	shiftNOC: false,
	dateRangeType: "",
	byQuarter: "",
	byMonth: "",
	byDate: "",
	byDateRange: "",
	startDate: "",
	endDate: "",
	sortBy: "",
	sortByResident: "",
	sortByADL: "",
	sortByShift: "",
	sortByStaff: "",
	sortByExceptionType: "",
	sortByWasCompleted: "",
	// other options
	showStrikeOuts: true,
	hideNotes: false,
	useTimeZoneOverride: false,
	selectTz: "",
};

const ExceptionReport = ({
	globalState,
	currentFacility,
	currentResident,
	currentUser,
	residents = [],
	residentsByFacility = {},
	adlCategories = [],
	facilityExceptions = [],
	dispatch,
}) => {
	useScrollPosition(scrollConfig);
	const { handlePrint } = usePrint("printExceptionReport");
	const { cache, addItem, removeItem } = useRecents(
		"Recents-ExceptionReport",
		{ recents: [], timestamp: Date.now() } // state
	);
	const { formState, setFormState, handleCheckbox, handleReset } = useForm({
		...initialValState,
	});
	const { values } = formState;
	const { dispatchAlert, AlertsHandler } = useAlerts({
		...initialSettings,
		expiry: 2000,
		msg: {
			heading: "Success!",
			subheading: `You've changed facilities`,
		},
	});
	// local state
	const [selectedFacility, setSelectedFacility] = useState(
		getCurrentFacilityStr(currentFacility)
	);
	const [selectedResident, setSelectedResident] = useState(
		getCurrentResidentStr(currentResident)
	);
	const [showReportPicker, setShowReportPicker] = useState(false);
	const [facilityData, setFacilityData] = useState({});
	const [dailyReportData, setDailyReportData] = useState(null); // previously '[]'
	const [hasLoadedReport, setHasLoadedReport] = useState(false);
	const [isLoadingReport, setIsLoadingReport] = useState(false);
	const [src, setSrc] = useState("");
	// date-range restriction (from ALADVSystem)
	const [rangeRestriction, setRangeRestriction] = useState(
		calculateRestrictionRange(3)
	);

	const { shifts: shiftTimes } = currentFacility;

	const BASE_FILTER_OPTIONS = [
		{
			name: "filterByResident",
			id: "Resident",
			label: "Choose a resident",
			placeholder: "",
			options: [...formatAndSortResidents(currentFacility.residents)],
		},
		{
			name: "filterByFacility",
			id: "Facility",
			label: "Choose a facility",
			placeholder: "",
			options: [...formatAndSortFacilities(currentUser.facilities)],
		},
		{
			name: "filterByADL",
			id: "ADL",
			label: "Choose a ADL",
			placeholder: "",
			options: [...adlCategories],
		},
	];

	// determines the 'fields' to be validated
	const getFieldsToValidate = (values) => {
		const fields = getFields(values, [
			`reportType`,
			`filterBy`,
			getFilterByKey(values),
			`dateRangeType`,
			getDateRangeTypeKey(values),
		]);
		return fields;
	};

	// fetches file blob from server, tranforms into pdf blob, sets local state
	const getReportSrc = async (token, fileID) => {
		const blob = await getFileBlob(token, fileID);
		const pdfBlob = createBlob(blob, "application/pdf");
		const pdfURL = createURL(pdfBlob);

		setSrc(pdfURL);
		return setIsLoadingReport(false);
	};

	const rerunDailyReport = async (recentsVals) => {
		const { token } = currentUser;
		const { facilityID } = recentsVals;

		const shiftIDs = getShiftIDsFromSettings(recentsVals);
		const reportData = await getDailyExceptions(token, facilityID, shiftIDs);

		if (!isEmptyArray(reportData)) {
			setFormState({
				...formState,
				values: {
					...values,
					recentsVals,
				},
			});
			setIsLoadingReport(false);
			return setDailyReportData([...reportData]);
		} else {
			setDailyReportData(reportData);
			return setIsLoadingReport(false);
		}
	};

	const getDailyReport = async () => {
		if (!hasFacility(selectedFacility)) {
			return dispatchAlert("warning", {
				headings: `Please select a facility!`,
			});
		}
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const { startDate, endDate } = getFacilityDay(shiftTimes);

		const shiftIDs = getShiftIDsFromSettings(values);
		const reportData = await getDailyExceptions(
			token,
			facilityID,
			shiftIDs,
			startDate,
			endDate
		);

		const recentsModel = updateRecentsModel({
			id: generateID(3),
			type: `Daily`,
			name: `Daily Exception Report`,
			desc: getDailyReportDesc(values, `Exception`),
			dateCreated: format(new Date(), "M/D/YYYY h:mm A"),
			data: { ...values },
		});
		addItem(recentsModel);

		if (!isEmptyArray(reportData)) {
			setIsLoadingReport(false);
			setHasLoadedReport(true);
			return setDailyReportData([...reportData]);
		} else {
			setDailyReportData(reportData);
			setHasLoadedReport(true);
			return setIsLoadingReport(false);
		}
	};

	const getHistoricalReport = async () => {
		if (!hasFacility(selectedFacility)) {
			setIsLoadingReport(false);
			return dispatchAlert("warning", {
				heading: `Please select a facility.`,
			});
		}

		const { token } = currentUser;
		const { facilityID } = currentFacility;

		const reportModel = createReportModel(
			values,
			facilityID,
			currentUser?.facilities
		);
		const fileRegistry = await getHistoricalExceptions(token, reportModel);

		// add recently viewed entry
		const recentsModel = updateRecentsModel({
			id: getFileID(fileRegistry),
			type: `Historical`,
			name: `Historical Exception Report`,
			desc: getReportDesc(values, `Exception Report`),
			dateCreated: format(new Date(), "M/D/YYYY h:mm A"),
			data: { ...reportModel, facilityID },
		});
		addItem(recentsModel);

		setIsLoadingReport(false);
		setHasLoadedReport(true);
		if (isEmptyObj(fileRegistry)) return;
		const { FileRegistryID } = fileRegistry;

		return await getReportSrc(token, FileRegistryID);
	};

	const createReport = async (e) => {
		switch (true) {
			case !hasFacility(selectedFacility): {
				dispatchAlert(`warn`, {
					heading: `No facility selected!`,
				});
				return setShowReportPicker(false);
			}
			case values.reportType === `Daily`: {
				setShowReportPicker(false);
				setIsLoadingReport(true);
				return await getDailyReport();
			}
			case values.reportType === `Historical`: {
				setShowReportPicker(false);
				setIsLoadingReport(true);
				return await getHistoricalReport();
			}

			default:
				return;
		}
	};
	const cancelReport = (e) => {
		handleReset(e);
		setShowReportPicker(false);
	};
	const rerunReport = async (recentsModel) => {
		const { token } = currentUser;
		if (recentsModel.type === `Daily`) {
			setIsLoadingReport(true);
			return rerunDailyReport(recentsModel.data);
		} else {
			setIsLoadingReport(true);
			setFormState({
				...formState,
				values: {
					...values,
					reportType: `Historical`,
				},
			});
			return getReportSrc(token, recentsModel.id);
		}
	};

	// only used for dispatching an alert
	// global store is updated from side-effect (ie 'useEffect')
	const changeFacility = (selectedVal) => {
		// not needed; facility is loaded via 'setFacility'
		return dispatchAlert("info", {
			heading: `Changed Facility`,
			subheading: `Loaded ${selectedVal}.`,
		});
	};

	/**
	 * Extracts resident from 'selectedResident' state.
	 * Inits 'LOADING' state
	 * Fires off request, then syncs resident data to global store
	 */
	// ✓ - ADDED 9/23/2021 at 8:49 AM
	const loadResident = useCallback(async () => {
		if (isEmptyVal(selectedResident)) return alert("Select a resident");
		if (!selectedResident.includes("~ ALA ID:")) return;

		const { token } = currentUser;
		// match resident record from ID
		const curResident = getResidentObj(
			residentsByFacility,
			currentFacility?.facilityID ?? currentUser.facilityID, // 'null' causes empty 'selectedFacility'
			selectedResident
		);

		dispatch({ type: "LOADING" });

		const merged = await mergeDailyResidentData(
			token,
			curResident.ResidentID,
			new Date()
		);
		const oldState = { ...globalState };
		const newState = populateState(merged, oldState);
		// sync to state
		return dispatch({
			type: "SUCCESS",
			data: {
				newState: newState,
			},
		});
	});

	// sends tracking to Sentry.io
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		sendPageTracking(currentUser?.username, {
			pagename: `ExceptionReport`,
			path: `/dashboard/reports/exception`,
		});
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Sentry.ErrorBoundary>
				<ReportTypeController
					reportTitle="Exception Report"
					reportDesc={getReportDescByType(`Exception`, values)}
					setFacilityData={setFacilityData}
					reportType={values.reportType}
					currentUser={currentUser}
					currentFacility={currentFacility}
					residents={residents}
					residentsByFacility={residentsByFacility}
					selectedFacility={selectedFacility}
					setSelectedFacility={setSelectedFacility}
					setSelectedResident={setSelectedResident}
					selectedResident={selectedResident}
					changeFacility={changeFacility}
					changeResident={loadResident}
					recentlyViewedList={cache.recents}
					removeRecentsItem={removeItem}
					rerunReport={rerunReport}
					cancelReport={cancelReport}
					dispatch={dispatch}
					handleReportsPicker={() => setShowReportPicker(true)}
					hasLoaded={hasLoadedReport}
				>
					<ReportsViewerController
						key={values.reportType === `Daily` ? dailyReportData?.length : src}
						showSMARTTable={values.reportType === `Daily`}
						noReportData={isEmptyArray(dailyReportData) && isEmptyVal(src)}
						hasLoaded={isEmptyArray(dailyReportData) || hasLoadedReport}
						reportType={values.reportType}
						isLoading={isLoadingReport}
						embedSrc={src}
						tableData={{
							size: `FULL`,
							title: `${values.reportType} Exception Report`,
							cols: [...cols],
							data: getExceptionReportRows(dailyReportData, facilityExceptions),
							enableActions: true,
						}}
					/>
					{/* <ReportViewer
						key={values.reportType === `Daily` ? dailyReportData?.length : src}
						showSMARTTable={values.reportType === `Daily`}
						noReportData={isEmptyArray(dailyReportData) && isEmptyVal(src)}
						hasLoaded={isEmptyArray(dailyReportData) || hasLoadedReport}
						reportType={values.reportType}
						isLoading={isLoadingReport}
						embedSrc={src}
						tableData={{
							size: `FULL`,
							title: `${values.reportType} Exception Report`,
							cols: [...cols],
							data: getExceptionReportRows(dailyReportData, facilityExceptions),
							enableActions: true,
						}}
					/> */}
				</ReportTypeController>

				{/* REPORT PICKER */}
				<ConfigurablePicker
					currentFacility={currentFacility}
					currentUser={currentUser}
					adls={adlCategories}
					reportName={`Exception Report`}
					formState={formState}
					setFormState={setFormState}
					handleCheckbox={handleCheckbox}
					handleReset={handleReset}
					showPicker={showReportPicker}
					closeModal={() => setShowReportPicker(false)}
					createReport={createReport}
					cancelReport={cancelReport}
					fieldsToValidate={getFieldsToValidate(values)}
					config={amendHistoricalConfig(
						{ ...EXCEPTION_CONFIG },
						"filterOptions",
						BASE_FILTER_OPTIONS
					)}
					dateRangeRestrictions={{
						isActive: true,
						rangeStart: rangeRestriction.rangeStart,
						rangeEnd: rangeRestriction.rangeEnd,
					}}
				/>

				{/* ALERTS NOTIFIER UI */}
				{AlertsHandler}
			</Sentry.ErrorBoundary>
		</>
	);
};

export default ExceptionReport;

ExceptionReport.defaultProps = {};

ExceptionReport.propTypes = {
	currentFacility: PropTypes.object.isRequired,
	currentResident: PropTypes.object.isRequired,
	currentUser: PropTypes.object.isRequired,
	residents: PropTypes.array.isRequired,
	residentsByFacility: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};
