import React, { useState } from "react";
import styles from "../../css/adlschedule/AdlTableHeading.module.scss";
import { PropTypes } from "prop-types";
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	clear: {
		padding: ".5rem 1rem",
		backgroundColor: "transparent",
		border: "1px solid #ccc",
		color: "#333",
		marginRight: "1rem",
	},
	save: {
		padding: ".6rem 1rem",
	},
	slash: {
		fontSize: "1.5rem",
		fontWeight: "600",
		margin: "0 .3rem",
	},
};

const AdlTableHeading = ({
	heading,
	editCount,
	totalCount,
	saveSelection,
	clearSelection,
}) => {
	const [showSettings, setShowSettings] = useState(true); // toggles open the settings panel

	return (
		<header className={styles.AdlTableHeading}>
			<section className={styles.AdlTableHeading_top}>
				<span>{heading}</span>
				<div className={styles.AdlTableHeading_top_options}>
					<button
						className={styles.AdlTableHeading_top_options_btn}
						onClick={() => setShowSettings(!showSettings)}
					>
						Settings
					</button>
				</div>
			</section>
			{showSettings && (
				<section className={styles.AdlTableHeading_settingsPanel}>
					<div className={styles.AdlTableHeading_settingsPanels_edits}>
						<div className={styles.AdlTableHeading_settingsPanel_edits_count}>
							Editing <b id="editing">{editCount}</b>{" "}
							<b style={customCSS.slash}>/</b>
							<b id="count">{totalCount}</b> residents' schedules.
						</div>
					</div>
					<div className={styles.AdlTableHeading_settingsPanel_actions}>
						<ButtonSM
							customStyles={customCSS.clear}
							handleClick={clearSelection}
						>
							<span>Clear Selections</span>
						</ButtonSM>
						<ButtonSM customStyles={customCSS.save} handleClick={saveSelection}>
							<span>Save Changes</span>
						</ButtonSM>
					</div>
				</section>
			)}
		</header>
	);
};

export default AdlTableHeading;

AdlTableHeading.defaultProps = {};

AdlTableHeading.propTypes = {
	heading: PropTypes.string,
	editCount: PropTypes.number,
	totalCount: PropTypes.number,
};
