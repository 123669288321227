import React, { useState, useRef } from "react";
import styles from "../../css/notes/TaskNotesEntry.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import {
	getNotesCreatedDate,
	getTaskNoteCreatedDateInWords,
	getTaskNoteEditedDate,
	getTaskNoteStrikeOutBy,
	getTaskNoteStrikeOutDate,
	getTaskNoteUserInitials,
	getTaskNoteUserName,
	getWasEditedText,
	initAndUpdateExistingTaskNote,
	initAndUpdateNewTaskNote,
	prepareStrikeOutUpdate,
	saveTaskNotes,
	wasNoteEdited,
} from "../../helpers/utils_notes";
// components
import TaskNoteContent from "./TaskNoteContent";
import TaskNoteActions from "./TaskNoteActions";
import { isEmptyArray } from "../../helpers/utils_types";

// REQUIREMENTS:
// - Shows as normal note for non-admin users
// - Shows as editable/strike-outable note for admin users

// 'SG' etc
const NotesUserBadge = ({ taskNote = {} }) => {
	return (
		<div className={styles.NotesUserBadge}>
			<div className={styles.NotesUserBadge_initials}>
				{getTaskNoteUserInitials(taskNote)}
			</div>
		</div>
	);
};
// 'Steven Gore' etc
const NotesUserName = ({ taskNote = {} }) => {
	return (
		<div className={styles.NotesUserName}>
			<div
				className={styles.NotesUserName_user}
				title={`Created by ${taskNote?.createdBy}`}
			>
				{getTaskNoteUserName(taskNote)}
			</div>
		</div>
	);
};
// '2 hours ago', '42 mins ago' etc
const NotesTimeStamp = ({ taskNote = {} }) => {
	return (
		<div className={styles.NotesTimeStamp}>
			<div
				className={styles.NotesTimeStamp_desc}
				title={`Created ${getNotesCreatedDate(taskNote)}`}
			>
				{getTaskNoteCreatedDateInWords(taskNote)}
			</div>
		</div>
	);
};

// 'Edited (xx/xx/xxxx h:mm A)' etc
const WasEdited = ({ taskNote = {} }) => {
	const [isHovered, setIsHovered] = useState(false);

	if (!wasNoteEdited(taskNote)) {
		return null;
	}
	return (
		<div className={styles.WasEdited}>
			<div
				className={styles.WasEdited_desc}
				onMouseOver={() => setIsHovered(true)}
				onMouseOut={() => setIsHovered(false)}
			>
				<span className={styles.WasEdited_desc_label}>Edited</span>
				{/* (h:mm:ss A - <userID>) */}
				<span className={styles.WasEdited_desc_timestamp}>
					({getTaskNoteStrikeOutDate(taskNote)}
					{isHovered && <> - {getTaskNoteStrikeOutBy(taskNote)}</>})
				</span>
			</div>
		</div>
	);
};

const TaskNotesEntry = ({
	task = {},
	taskNote = {},
	isEditable = false,
	currentUser = {},
	deleteTaskNote,
}) => {
	const editRef = useRef();
	const [isEditing, setIsEditing] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { formState, setFormState, handleChange } = useForm({
		noteID: taskNote?.noteID ?? 0,
		trackingID: taskNote?.trackingID ?? 0,
		taskID: taskNote?.taskID ?? 0,
		notes: taskNote?.notes ?? "",
		initialBy: taskNote?.initialBy ?? "",
		isStrikeOut: taskNote?.isStrikeOut ?? false,
		strikeOutByUserId: taskNote?.strikeOutByUserId ?? null,
		strikeOutDate: taskNote?.strikeOutDate ?? null,
		entryDate: taskNote?.entryDate ?? null,
		entryByUserId: taskNote?.entryByUserId ?? null,
		modifiedDate: taskNote?.modifiedDate ?? null,
		modifiedBy: taskNote?.modifiedBy ?? null,
		createdBy: taskNote?.createdBy ?? "",
		createdDate: taskNote?.createdDate,
	});
	const { values } = formState;

	const initEdit = () => {
		setIsEditing(true);
	};

	// save edit task note
	const saveEditTaskNote = async () => {
		const { token } = currentUser;
		setIsSaving(true);

		// fire off request
		const newVals = {
			...values,
			modifiedBy: currentUser?.userID,
			modifiedDate: new Date().toISOString(),
		};

		const { client, server } = initAndUpdateExistingTaskNote(task, newVals);
		const wasSaved = await saveTaskNotes(token, server);
		// const wasSaved = true;

		console.group("Edited Task Note");
		console.log("client:", client);
		console.log("server:", server);
		console.log("newVals:", newVals);
		console.groupEnd();

		if (wasSaved) {
			setIsEditing(false);
			setIsSaving(false);
			return setFormState({
				...formState,
				values: { ...newVals },
			});
		} else {
			return setIsSaving(false);
		}
	};

	const cancelEditTaskNote = () => {
		setIsEditing(false);
		setFormState({
			...formState,
			values: {
				...values,
				notes: taskNote?.notes ?? "",
				initialBy: taskNote?.initialBy ?? "",
				isStrikeOut: taskNote?.isStrikeOut ?? false,
				strikeOutByUserId: taskNote?.strikeOutByUserId ?? "",
				strikeOutDate: taskNote?.strikeOutDate ?? "",
			},
		});
	};

	const initStrikeOut = async () => {
		const { token } = currentUser;

		// get current strike-out state & flip it
		const currentState = values?.isStrikeOut;
		const newState = !currentState;

		// set new state:
		// - if striking-out, then set meta fields
		// - if NOT undoing strike-out, then reset meta fields to last values
		const newStrikeOutState = {
			isStrikeOut: newState,
			strikeOutByUserId: newState
				? currentUser?.userID
				: values?.strikeOutByUserId,
			strikeOutDate: newState
				? new Date().toISOString()
				: values?.strikeOutDate,
		};

		// ONLY NEED TO PASS A COUPLE FIELDS FOR THIS TYPE OF UPDATE
		const updatedNote = prepareStrikeOutUpdate(taskNote, {
			...values,
			...newStrikeOutState,
		});

		const updatedID = await saveTaskNotes(token, updatedNote);
		const wasSaved = !isEmptyArray(updatedID);

		if (wasSaved) {
			return setFormState({
				...formState,
				values: {
					...values,
					isStrikeOut: newState,
					...newStrikeOutState,
				},
			});
		} else {
			return setFormState({ ...formState });
		}
	};

	const initDelete = () => {
		// deletions handled via parent component
		deleteTaskNote(taskNote);
	};
	const cancelDelete = () => {
		// do something...
		// do something...
	};

	return (
		<div className={styles.TaskNotesEntry}>
			<div className={styles.TaskNotesEntry_top}>
				<NotesUserBadge taskNote={taskNote} />
				<NotesUserName taskNote={taskNote} />
				<NotesTimeStamp taskNote={taskNote} />
				<WasEdited taskNote={taskNote} />
			</div>
			<div className={styles.TaskNotesEntry_content}>
				<TaskNoteContent
					isSaving={isSaving}
					inputRef={editRef}
					vals={values}
					isEditing={isEditing}
					taskNote={taskNote}
					handleTaskNote={handleChange}
					saveEditTaskNote={saveEditTaskNote}
					cancelEditTaskNote={cancelEditTaskNote}
				/>
			</div>
			<div className={styles.TaskNotesEntry_bottom}>
				{isEditable && !isEditing && (
					<TaskNoteActions
						isSaving={isSaving}
						vals={values}
						currentUser={currentUser}
						initEdit={initEdit}
						initStrikeOut={initStrikeOut}
						initDelete={initDelete}
					/>
				)}
			</div>
		</div>
	);
};

export default TaskNotesEntry;

TaskNotesEntry.defaultProps = {};

TaskNotesEntry.propTypes = {};
