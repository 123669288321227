import React, { useState, useEffect } from "react";
import styles from "../../css/notes/TaskNotesController.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import {
	initAndUpdateNewTaskNote,
	prepareVirtualDelete,
	processAndSortTaskNotes,
	processTaskNotes,
	saveTaskNotes,
	virtualDeleteTaskNote,
} from "../../helpers/utils_notes";
import { isEmptyArray } from "../../helpers/utils_types";
// components
import NewTaskNote from "./NewTaskNote";
import TaskNotesList from "./TaskNotesList";
import OriginalTaskNote from "./OriginalTaskNote";

// REQUIREMENTS:
// - Supports adding task notes
// - Supports striking-out a task note (ADMINS ONLY)
// - Shows 2 different UIs:
//    - ADMIN UI: shows task note list, can add notes, can edit and/or strike-out notes
//    - NON-ADMIN UI: only shows existing notes & allows adding new notes

const UpdateFailure = ({ msg }) => {
	return (
		<div className={styles.UpdateFailure}>
			<svg className={styles.UpdateFailure_icon}>
				<use xlinkHref={`${sprite}#icon-warningreport_problem`}></use>
			</svg>
			<div className={styles.UpdateFailure_msg}>{msg}</div>
		</div>
	);
};

const initialState = {
	newNote: "",
	createdBy: null,
	createdDate: new Date().toISOString(),
	entryDate: new Date().toISOString(),
	entryByUserId: null,
	initialBy: "",
	isActive: true,
	isStrikeOut: false,
	strikeOutByUserId: null,
	strikeOutDate: null,
};

const TaskNotesController = ({
	task = {},
	currentUser = {},
	allTaskNotes = [],
	syncTaskNotes,
	saveRef = null,
	wasNoteSaved = false,
}) => {
	const [allNotes, setAllNotes] = useState([
		...processAndSortTaskNotes("entryDate", allTaskNotes),
	]);
	// const [wasNoteSaved, setWasNoteSaved] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [errorOccurred, setErrorOccurred] = useState(null);
	// new task note values
	const { formState, handleChange, handleReset } = useForm({
		trackingID: task?.AssessmentTrackingId ?? 0,
		taskID:
			task?.AssessmentTrackingTaskId ?? task?.AssessmentUnscheduleTaskId ?? 0,
		newNote: "",
		createdBy: currentUser?.userID,
		createdDate: null,
		entryDate: null,
		entryByUserId: currentUser?.userID,
		initialBy: `${currentUser?.firstName} ${currentUser?.lastName}`,
		isActive: true,
		isStrikeOut: false,
		strikeOutByUserId: null,
		strikeOutDate: null,
		modifiedDate: null,
		modifiedBy: currentUser?.userID,
	});
	const { values } = formState;

	const saveNewTaskNote = async (e) => {
		const { token } = currentUser;
		// creates client-side & server-formatted note models
		const { client, server } = initAndUpdateNewTaskNote(task, {
			...values,
			entryDate: new Date().toISOString(),
			createdDate: new Date().toISOString(),
			modifiedDate: new Date().toISOString(),
		});

		// const wasSaved = true;
		const wasSaved = await saveTaskNotes(token, server);

		if (wasSaved) {
			const newNotesList = [...allNotes, client];
			setAllNotes([...newNotesList]);
			// prolly need to sync new task note to <TaskUpdateForm/> above, or re-fetch task notes
			return handleReset(e);
			// return setWasNoteSaved(true);
		} else {
			return;
			// return setWasNoteSaved(false);
		}
	};
	const cancelNewTaskNote = (e) => {
		handleReset(e);
	};

	// handles virtual delete & state
	const deleteTaskNote = async (targetTaskNote = {}) => {
		const { token } = currentUser;

		const preparedNote = prepareVirtualDelete(targetTaskNote);
		const wasDeleted = await virtualDeleteTaskNote(token, preparedNote);

		console.group(`Deletion`);
		console.log("targetTaskNote", targetTaskNote);
		console.log("preparedNote", preparedNote);
		console.log("wasDeleted", wasDeleted);
		console.groupEnd();

		if (wasDeleted) {
			// remove deleted note from client's list & sync to state
			const targetID = targetTaskNote?.noteID;
			const newNotes = [...allNotes].filter(
				(note) => note?.noteID !== targetID
			);

			return isEmptyArray(newNotes) ? setAllNotes([]) : setAllNotes(newNotes);
		} else {
			// handle error
			return setErrorOccurred(wasDeleted);
		}

		// OLD CODE
		// const targetID = targetTaskNote?.noteID;
		// const newNotes = [...allNotes].filter((note) => note?.noteID !== targetID);
		// if (isEmptyArray(newNotes)) {
		// 	return setAllNotes([]);
		// } else {
		// 	return setAllNotes(newNotes);
		// }
	};

	// reset 'wasSaved' message timer
	// useEffect(() => {
	// 	let isMounted = true;
	// 	if (!isMounted) {
	// 		return;
	// 	}
	// 	let resetID;
	// 	if (wasNoteSaved) {
	// 		resetID = setTimeout(() => {
	// 			setWasNoteSaved(false);
	// 		}, 3000);
	// 	}

	// 	return () => {
	// 		isMounted = false;
	// 		clearTimeout(resetID);
	// 	};
	// }, [wasNoteSaved]);

	return (
		<div className={styles.TaskNotesController}>
			<div className={styles.TaskNotesController_newNote}>
				<NewTaskNote
					vals={values}
					task={task}
					wasNoteSaved={wasNoteSaved}
					handleChange={handleChange}
					syncTaskNotes={syncTaskNotes}
					saveNewTaskNote={saveNewTaskNote}
					cancelNewTaskNote={cancelNewTaskNote}
					saveRef={saveRef}
				/>
			</div>
			<div className={styles.TaskNotesController_existingNotes}>
				{errorOccurred && <UpdateFailure msg="Oops! Something went wrong." />}
				<OriginalTaskNote task={task} currentUser={currentUser} />
				<TaskNotesList
					task={task}
					taskNotes={allNotes}
					currentUser={currentUser}
					deleteTaskNote={deleteTaskNote}
				/>
			</div>
		</div>
	);
};

export default TaskNotesController;

TaskNotesController.defaultProps = {};

TaskNotesController.propTypes = {};
