import React from "react";
import styles from "../../css/calendar/StatusLegend.module.scss";
import { PropTypes } from "prop-types";
import { themeColors } from "../../helpers/utils_styles";

const baseMap = {
	Complete: {
		backgroundColor: themeColors.main.green,
		border: `1px solid #ccc`,
	},
	"Not Complete": {
		backgroundColor: themeColors.main.altYellow,
		border: `1px solid #ccc`,
	},
	"Past Due": {
		backgroundColor: themeColors.main.main,
		border: `1px solid #ccc`,
	},
	Exceptions: {
		backgroundColor: themeColors.main.altRed,
		border: `1px solid #ccc`,
	},
};

const LegendBadge = ({ label, desc, customStyles = {} }) => {
	return (
		<div className={styles.LegendBadge}>
			<span
				className={styles.LegendBadge_badge}
				title={desc}
				style={customStyles}
			></span>
			<span className={styles.LegendBadge_desc}>{label}</span>
		</div>
	);
};

const StatusLegend = ({ colorMap = { ...baseMap } }) => {
	return (
		<div className={styles.StatusLegend}>
			<div className={styles.StatusLegend_wrapper}>
				<LegendBadge
					label="Complete"
					desc="Tasks marked as finished/completed."
					customStyles={colorMap["Complete"]}
				/>
				<LegendBadge
					label="Not Complete"
					desc="An incomplete task BEFORE its due date/time."
					customStyles={colorMap["Not Complete"]}
				/>
				<LegendBadge
					label="Past Due"
					desc="An incomplete task AFTER its due date/time."
					customStyles={colorMap["Past Due"]}
				/>
				<LegendBadge
					label="Exceptions"
					desc="A cancelled and/or rescheduled task."
					customStyles={colorMap["Exceptions"]}
				/>
			</div>
		</div>
	);
};

export default StatusLegend;

StatusLegend.defaultProps = {};

StatusLegend.propTypes = {};
