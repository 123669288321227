import React from "react";
import { PropTypes } from "prop-types";

/**
 * A secured 'wrapper' for hiding/disabling a permissioned feature/function.
 * - Will auto-hide/disable feature is <condition> is NOT met
 */

const ProtectedFeature = ({ isEnabled = false, children }) => {
	if (!isEnabled) {
		return null;
	}
	return <>{children}</>;
};

export default ProtectedFeature;

ProtectedFeature.defaultProps = {
	isEnabled: false,
};

ProtectedFeature.propTypes = {
	isEnabled: PropTypes.bool.isRequired,
	children: PropTypes.any.isRequired,
};
