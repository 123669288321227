import React, { useContext } from "react";
import styles from "../../css/views/DailyMultiViews.module.scss";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../../state/GlobalStateContext";
import DailyViewSwitcher, {
	DailyViewPanel,
	DailyViewPanels,
	DailyViewSwitcherButton,
	DailyViewSwitcherButtons,
} from "../../components/daily/DailyViewSwitcher";
import DailyResidentView from "../daily/DailyResidentView";
import DailyTimeView from "../daily/DailyTimeView";

const DailyMultiViews = ({ history }) => {
	const { state, dispatch } = useContext(GlobalStateContext);
	const { app, globals, user } = state;
	const { isLoading } = app;
	const {
		loa,
		categories,
		currentResident,
		currentFacility, // added
		currentUnitType,
		residents, // added
		residentsByFacility, // added
		scheduledTasks,
		trackingTasks,
		unscheduledTasks,
		unscheduledTasksRaw,
	} = globals;
	const { facilities } = user;
	const { isLOA } = currentResident;

	return (
		<div className={styles.DailyMultiViews}>
			<h1 className={styles.DailyMultiViews_viewTitle}>Today's Care Tasks</h1>
			<section className={styles.DailyMultiViews_content}>
				<DailyViewSwitcher defaultIndex={0}>
					{/* BUTTONS */}
					<DailyViewSwitcherButtons>
						<DailyViewSwitcherButton>Cards</DailyViewSwitcherButton>
						<DailyViewSwitcherButton>Time</DailyViewSwitcherButton>
					</DailyViewSwitcherButtons>
					{/* PANELS */}
					<DailyViewPanels>
						<DailyViewPanel>
							<DailyResidentView
								key={`RESIDENT-CARDS-VIEW`}
								state={state}
								dispatch={dispatch}
								currentUser={user}
								currentFacility={currentFacility}
							/>
						</DailyViewPanel>
						<DailyViewPanel>
							<DailyTimeView
								key={`TIME-VIEW`}
								state={state}
								dispatch={dispatch}
								currentUser={user}
								currentFacility={currentFacility}
							/>
						</DailyViewPanel>
					</DailyViewPanels>
				</DailyViewSwitcher>
			</section>
		</div>
	);
};

export default DailyMultiViews;

DailyMultiViews.defaultProps = {};

DailyMultiViews.propTypes = {};
