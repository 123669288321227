import React, { useRef, useEffect } from "react";
import styles from "../../css/notes/OriginalTaskNote.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { formatDateInWordsToNow } from "../../helpers/utils_dates";
import { format } from "date-fns";

// checks if there are any notes for the task
const hasNoOriginalNotes = (task) => {
	const notes = task?.TaskNotes ?? task?.Notes;
	const isEmpty = isEmptyVal(task?.TaskNotes) && isEmptyVal(task?.Notes);
	const isIllegible = notes === " \n\n" || notes === "\n\n";
	const isReassessOnly = /^(Suggest:)/gi.test(notes) ?? false; // starts with 'Suggest'

	return isEmpty || isIllegible || isReassessOnly;
};

// gets note content
const getOriginalNoteContent = (task = {}) => {
	const oldNotes = task?.TaskNotes ?? task?.Notes;

	const notes = isEmptyVal(oldNotes) ? `No notes found.` : oldNotes;

	return notes;
};

const getOriginalNoteUserInitials = (vals, task) => {
	const { EntryUserFirstName: firstName, EntryUserLastName: lastName } = task;
	const first = firstName.slice(0, 1);
	const last = lastName.slice(0, 1);

	return `${first}${last}`;
};
const getOriginalNoteUserName = (vals, task) => {
	const { EntryUserFirstName: firstName, EntryUserLastName: lastName } = task;

	return `${firstName} ${lastName}`;
};
const getOriginalNoteCreatedDate = (vals, task) => {
	const date = task?.TaskDate ?? task?.CreatedDate ?? null;

	const newDate = !isEmptyVal(date) ? format(date, "M/D/YYYY h:mm:ss A") : "";

	return newDate;
};
const getOriginalNoteCreatedDateInWords = (vals, task) => {
	const date = task?.TaskDate ?? task?.CreatedDate ?? null;

	const dateInWords = formatDateInWordsToNow(date);

	return `${dateInWords} ago`;
};

const getOriginalNoteCreatedBy = (vals, task) => {
	const { EntryUserFirstName: firstName, EntryUserLastName: lastName } = task;

	return `${firstName} ${lastName}`;
};

// 'SG' etc
const NotesUserBadge = ({ vals = {}, task = {} }) => {
	return (
		<div className={styles.NotesUserBadge}>
			<div className={styles.NotesUserBadge_initials}>
				{getOriginalNoteUserInitials(vals, task)}
			</div>
		</div>
	);
};
// 'Steven Gore' etc
const NotesUserName = ({ vals = {}, task = {} }) => {
	return (
		<div className={styles.NotesUserName}>
			<div
				className={styles.NotesUserName_user}
				title={`Created by XXXXX-XXXX-XXXXX-XXXXX`}
			>
				{getOriginalNoteUserName(vals, task)}
			</div>
		</div>
	);
};
// '2 hours ago', '42 mins ago' etc
const NotesTimeStamp = ({ vals = {}, task = {} }) => {
	return (
		<div className={styles.NotesTimeStamp}>
			<div
				className={styles.NotesTimeStamp_desc}
				title={`Created ${getOriginalNoteCreatedDate(vals, task)}`}
			>
				{getOriginalNoteCreatedDateInWords(vals, task)}
			</div>
		</div>
	);
};

const OriginalNoteContent = ({ note = "" }) => {
	const noteRef = useRef();

	// Sets 'el.innerText' in order to maintain formatting of note
	// - supports lists
	// - multi-line notes
	// - supports strike-through; even for multi-line notes
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		// store readable reference to element ref
		let newNote = noteRef?.current;

		if (newNote) {
			newNote.innerText = note;
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.OriginalNoteContent}>
			<div className={styles.OriginalNoteContent_note} ref={noteRef}>
				{/* PREVIOUS USAGE - KEEP FOR FALLBACK */}
				{/* {note} */}
			</div>
		</div>
	);
};

const OriginalTaskNote = ({ vals = {}, task = {} }) => {
	if (hasNoOriginalNotes(task)) {
		return null;
	}
	return (
		<div className={styles.OriginalTaskNote}>
			<div className={styles.OriginalTaskNote_top}>
				<NotesUserBadge vals={vals} task={task} />
				<NotesUserName vals={vals} task={task} />
				<NotesTimeStamp vals={vals} task={task} />
			</div>
			<div className={styles.OriginalTaskNote_inner}>
				<div
					className={styles.OriginalTaskNote_inner_badge}
					title="Note added at task creation."
				>
					Original Note
				</div>
				<OriginalNoteContent note={getOriginalNoteContent(task)} />
			</div>
		</div>
	);
};

export default OriginalTaskNote;

OriginalTaskNote.defaultProps = {};

OriginalTaskNote.propTypes = {};
