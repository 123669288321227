import React from "react";
import { PropTypes } from "prop-types";
import { isEmptyObj } from "../../helpers/utils_types";
// components
import FormSection from "../forms/FormSection";
import ReportOtherOptions from "./ReportOtherOptions";
import Divider from "../forms/Divider";

const hideOtherOptions = (disableOther, otherOptions = {}, reportType) => {
	if (disableOther) return true;
	if (isEmptyObj(otherOptions)) return true;
	if (reportType === `Daily`) return true;

	return false;
};

const ReportOtherPicker = ({
	reportType,
	settings = {},
	otherOptions = {},
	handleCheckbox,
	handleCustom,
	disableOther = false,
}) => {
	if (hideOtherOptions(disableOther, otherOptions, reportType)) {
		return null;
	}
	return (
		<>
			<FormSection
				icon="settings"
				title="Select Other Option(s)"
				hoverText="This is how the data will be organized."
			>
				<ReportOtherOptions
					settings={settings}
					otherOptions={otherOptions}
					handleCheckbox={handleCheckbox}
					handleCustom={handleCustom}
				/>
			</FormSection>
			<Divider />
		</>
	);
};

export default ReportOtherPicker;

ReportOtherPicker.defaultProps = {};

ReportOtherPicker.propTypes = {};
