import React from "react";
import styles from "../../css/daily/CreateADLTaskButton.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";

const CreateADLTaskButton = ({
	isDisabled,
	isHidden = true,
	initCreateADLTask,
}) => {
	if (isHidden) {
		return null;
	}
	return (
		<button
			type="type"
			disabled={isDisabled}
			className={styles.CreateADLTaskButton}
			onClick={initCreateADLTask}
		>
			<svg className={styles.CreateADLTaskButton_icon}>
				<use xlinkHref={`${sprite}#icon-plus21`}></use>
			</svg>
			<span className={styles.CreateADLTaskButton_text}>New Task</span>
		</button>
	);
};

export default CreateADLTaskButton;

CreateADLTaskButton.defaultProps = {};

CreateADLTaskButton.propTypes = {};
