import React from "react";
import styles from "../../css/calendar/ShiftStatusBadge.module.scss";
import { PropTypes } from "prop-types";
import { themeColors, statusBadgeColors } from "../../helpers/utils_styles";

const { main } = themeColors;

// custom status badge colors
const customColors = {
	COMPLETE: {
		backgroundColor: main.green,
		color: "#ffffff",
		left: ".5rem",
	},
	"NOT-COMPLETE": {
		backgroundColor: main.altYellow,
		border: "1px solid #ccc",
		color: "#ffffff",
		left: "2.2rem",
	},
	"PAST-DUE": {
		backgroundColor: main.main,
		color: "#ffffff",
		right: "2.2rem",
	},
	EXCEPTION: {
		backgroundColor: main.altRed,
		color: "#ffffff",
		right: ".5rem",
	},
};

const ShiftStatusBadge = ({
	tasks = [],
	status = "NOT-COMPLETE",
	count,
	selectByStatus,
}) => {
	return (
		<div
			className={styles.ShiftStatusBadge}
			style={customColors[status]}
			onClick={() => selectByStatus(tasks)}
		>
			<span className={styles.ShiftStatusBadge_count}>{count}</span>
		</div>
	);
};

export default React.memo(ShiftStatusBadge);

ShiftStatusBadge.defaultProps = {
	status: "NOT-COMPLETE",
	count: 0,
};

ShiftStatusBadge.propTypes = {
	status: PropTypes.string.isRequired,
	count: PropTypes.number.isRequired,
	tasks: PropTypes.arrayOf(PropTypes.object),
	selectByStatus: PropTypes.func.isRequired,
};
