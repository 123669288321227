// MOCK TASK DATA

const MOCK_RESIDENT_LIST = [
	{
		ResidentName: "Bill Bashful",
		ResidentID: 123258,
		RoomNum: "12(10)",
		ADL: "Bathing",
		TaskName: "Requires reminders only",
		ScheduledDate: "Mon Sep 8 2022 07:15:00 GMT-0700 (Mountain Standard Time)", // Today at 10:15 AM
	},
	{
		ResidentName: "Bill Bashful",
		ResidentID: 123258,
		RoomNum: "12(10)",
		ADL: "Ambulation",
		TaskName: "Requires 2 person assist while ambulating",
		ScheduledDate: "Mon Sep 8 2022 07:30:00 GMT-0700 (Mountain Standard Time)", // Today at 10:15 AM
	},
	{
		ResidentName: "Lois Byers",
		ResidentID: 123258,
		RoomNum: "A110",
		ADL: "Grooming",
		TaskName: "Requires reminders to groom appropriately",
		ScheduledDate: "Mon Sep 8 2022 07:45:00 GMT-0700 (Mountain Standard Time)", // Today at 10:15 AM
	},
	{
		ResidentName: "Bill Bashful",
		ResidentID: 123258,
		RoomNum: "12(10)",
		ADL: "Toileting",
		TaskName: "Requires verbal reminders to use the toilet",
		ScheduledDate: "Mon Sep 8 2022 07:25:00 GMT-0700 (Mountain Standard Time)", // Today at 10:15 AM
	},
	{
		ResidentName: "Lois Byers",
		ResidentID: 123258,
		RoomNum: "A110",
		ADL: "Psychosocial",
		TaskName: "Minimal - prompting, cueing, reminding",
		ScheduledDate: "Mon Sep 8 2022 07:45:00 GMT-0700 (Mountain Standard Time)", // Today at 10:15 AM
	},
];
const MOCK_FACILITY_LIST = [
	{
		ResidentName: "Bill Bashful",
		ResidentID: 123258,
		RoomNum: "12(10)",
		ADL: "Bathing",
		TaskName: "Requires reminders only",
		ScheduledDate: "Mon Sep 8 2022 18:15:00 GMT-0700 (Mountain Standard Time)", // Today at 10:15 AM
		IsCompleted: false,
	},
	{
		ResidentName: "Lois Byers",
		ResidentID: 137580,
		RoomNum: "201 A",
		ADL: "Meds",
		TaskName: "Pass Daily",
		ScheduledDate: "Mon Sep 8 2022 08:45:00 GMT-0700 (Mountain Standard Time)", // Today at 10:15 AM
		IsCompleted: true,
	},
	{
		ResidentName: "Bill Bashful",
		ResidentID: 123258,
		RoomNum: "12(10)",
		ADL: "Ambulation",
		TaskName: "Requires 2 person assist while ambulating",
		ScheduledDate: "Mon Sep 8 2022 13:30:00 GMT-0700 (Mountain Standard Time)", // Today at 10:15 AM
		IsCompleted: false,
	},
	{
		ResidentName: "Deena Campacci",
		ResidentID: 137582,
		RoomNum: "417",
		ADL: "Health",
		TaskName: "4 per shift - Status Check AM",
		ScheduledDate: "Mon Sep 8 2022 19:45:00 GMT-0700 (Mountain Standard Time)", // Today at 10:15 AM
		IsCompleted: false,
	},
	{
		ResidentName: "Lois Byers",
		ResidentID: 137580,
		RoomNum: "201 A",
		ADL: "Ambulation",
		TaskName: "Requires 1 person assist while ambulating",
		ScheduledDate: "Mon Sep 8 2022 12:45:00 GMT-0700 (Mountain Standard Time)", // Today at 10:15 AM
		IsCompleted: false,
	},
];

export { MOCK_RESIDENT_LIST, MOCK_FACILITY_LIST };
