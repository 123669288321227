import { REPORT_DESCRIPTIONS } from "./utils_descriptions";
// IMG PREVIEW SRC'S
import exceptionPreview from "../assets/images/PREVIEW/PREVIEW-ExceptionDaily.webp";
import completionPreview from "../assets/images/PREVIEW/PREVIEW-CompletionDaily.webp";
import taskStatusPreview from "../assets/images/PREVIEW/PREVIEW-TaskStatusHistorical.webp";
import taskCreatedPreview from "../assets/images/PREVIEW/PREVIEW-TaskCreatedHistorical.webp";
import reassessPreview from "../assets/images/PREVIEW/PREVIEW-ReassessHistorical.webp";
import pastDuePreview from "../assets/images/PREVIEW/PREVIEW-PastDueDaily.webp";
import servicePlanPreview from "../assets/images/PREVIEW/PREVIEW-ServicePlan.webp";
// IMG PREVIEW FALLBACK'S
import completionFallback from "../assets/images/PREVIEW/PREVIEW-CompletionDaily.png";
import taskStatusFallback from "../assets/images/PREVIEW/PREVIEW-TaskStatusHistorical.png";
import taskCreatedFallback from "../assets/images/PREVIEW/PREVIEW-TaskCreatedHistorical.png";
import exceptionFallback from "../assets/images/PREVIEW/PREVIEW-ExceptionDaily.png";
import reassessFallback from "../assets/images/PREVIEW/PREVIEW-ReassessHistorical.png";
import pastDueFallback from "../assets/images/PREVIEW/PREVIEW-PastDueDaily.png";
import servicePlanFallback from "../assets/images/PREVIEW/PREVIEW-ServicePlan.png";
import { getTimeZoneNames, primaryTimezones, timezones } from "./utils_dates";

////////////////////////////////////////////////////////////////////////
/////////////////// REPORT 'CARDS' CONFIG(S) & UTILS ///////////////////
////////////////////////////////////////////////////////////////////////

// REPORTS 'PREVIEW' ASSETS (.webp or .png as 'fallback')
const REPORT_PREVIEW_ASSETS = {
	Exception: {
		src: exceptionPreview,
		fallback: exceptionFallback,
	},
	Completion: {
		src: completionPreview,
		fallback: completionFallback,
	},
	TaskStatus: {
		src: taskStatusPreview,
		fallback: taskStatusFallback,
	},
	TaskCreated: {
		src: taskCreatedPreview,
		fallback: taskCreatedFallback,
	},
	ServicePlan: {
		src: servicePlanPreview,
		fallback: servicePlanFallback,
	},
	Reassess: {
		src: reassessPreview,
		fallback: reassessFallback,
	},
	PastDue: {
		src: pastDuePreview,
		fallback: pastDueFallback,
	},
	ActualTime: {
		src: null,
		fallback: null,
	},
};

/**
 * Reports' View Config:
 * - Controls which report views to show, hide and which assets to render w/ each UI card.
 * - Supports 'disable' and 'hide' completely
 * - Points to view's icon, route, name to display & preview asset(s)
 */
const REPORTS_CONFIG = [
	{
		name: `Exception Report`,
		route: `/exception`,
		icon: `archive`,
		previewSrc: REPORT_PREVIEW_ASSETS?.Exception?.src,
		fallback: REPORT_PREVIEW_ASSETS?.Exception?.fallback,

		desc: REPORT_DESCRIPTIONS[`Exception Report`],
		isDisabled: false,
		hide: false,
	},
	{
		name: `Completion Report`,
		route: `/completion`,
		icon: `checkbox`,
		previewSrc: REPORT_PREVIEW_ASSETS?.Completion?.src,
		fallback: REPORT_PREVIEW_ASSETS?.Completion?.fallback,
		desc: REPORT_DESCRIPTIONS[`Completion Report`],
		isDisabled: false,
		hide: false,
	},
	{
		name: `Past Due Report`,
		route: `/pastdue`,
		icon: `chartDark`,
		previewSrc: REPORT_PREVIEW_ASSETS?.PastDue?.src,
		fallback: REPORT_PREVIEW_ASSETS?.PastDue?.fallback,
		desc: REPORT_DESCRIPTIONS[`Past Due Report`],
		isDisabled: false,
		hide: false,
	},
	{
		name: `Service Plan Update Report`,
		route: `/reassess`,
		icon: `openBook`,
		previewSrc: REPORT_PREVIEW_ASSETS?.Reassess?.src,
		fallback: REPORT_PREVIEW_ASSETS?.Reassess?.fallback,
		desc: REPORT_DESCRIPTIONS[`Reassess Report`],
		isDisabled: false,
		hide: false,
	},
	{
		name: `Task Created Report`,
		route: `/taskcreated`,
		icon: `archive`,
		previewSrc: REPORT_PREVIEW_ASSETS?.TaskCreated?.src,
		fallback: REPORT_PREVIEW_ASSETS?.TaskCreated?.fallback,
		desc: REPORT_DESCRIPTIONS[`Task Created Report`],
		isDisabled: false,
		hide: false,
	},
	{
		name: `Task Status Report`,
		route: `/taskstatus`,
		icon: `chartLight`,
		previewSrc: REPORT_PREVIEW_ASSETS?.TaskStatus?.src,
		fallback: REPORT_PREVIEW_ASSETS?.TaskStatus?.fallback,
		desc: REPORT_DESCRIPTIONS[`Task Status Report`],
		isDisabled: false,
		hide: false,
	},
	// DISABLED/HIDDEN REPORT VIEWS
	{
		name: `Service Plan Report`,
		route: `/serviceplan`,
		icon: `news`,
		previewSrc: REPORT_PREVIEW_ASSETS?.ServicePlan?.src,
		fallback: REPORT_PREVIEW_ASSETS?.ServicePlan?.fallback,
		desc: REPORT_DESCRIPTIONS[`Service Plan Report`],
		isDisabled: true,
		hide: true,
	},
	{
		name: `Actual Time Report`,
		route: `/actualtime`,
		icon: `stopwatch`,
		previewSrc: REPORT_PREVIEW_ASSETS?.ActualTime?.src,
		fallback: REPORT_PREVIEW_ASSETS?.ActualTime?.fallback,
		desc: REPORT_DESCRIPTIONS[`Actual Time Report`],
		isDisabled: true,
		hide: true,
	},
];

////////////////////////////////////////////////////////////////////////
/////////////////// DEFAULT PICKER CONFIG(S) & UTILS ///////////////////
////////////////////////////////////////////////////////////////////////

// CONFIG AMENDMENT HELPERS //

// applies changes (additive) to 'Daily' config ONLY
const amendDailyConfig = (config, name, options) => {
	return {
		...config,
		Daily: {
			...config.Daily,
			[name]: options,
		},
	};
};
// applies changes (additive) to 'Historical' config ONLY
const amendHistoricalConfig = (config, name, options) => {
	return {
		...config,
		Historical: {
			...config.Historical,
			[name]: options,
		},
	};
};
// applies changes (additive) to both 'Daily' & 'Historical' configs
const amendConfigMany = (config, name, options) => {
	return {
		...config,
		Daily: {
			...config.Daily,
			[name]: options,
		},
		Historical: {
			...config.Historical,
			[name]: options,
		},
	};
};

// settings.filterBy - By Resident/Facility/ADL
const BASE_FILTERS = [
	{
		name: "filterBy",
		id: "Resident",
		label: "By Resident",
	},
	{
		name: "filterBy",
		id: "Facility",
		label: "By Facility",
	},
	{
		name: "filterBy",
		id: "ADL",
		label: "By ADL",
	},
];
// settings.filterByXXXX - requires an array of options typically derived from props
const BASE_FILTER_OPTIONS = [];
// settings.shiftXX toggles: AM/PM/NOC
const BASE_SHIFT_OPTIONS = [
	{
		name: "shiftAM",
		id: "shiftAM",
		label: "AM",
	},
	{
		name: "shiftPM",
		id: "shiftPM",
		label: "PM",
	},
	{
		name: "shiftNOC",
		id: "shiftNOC",
		label: "NOC",
	},
];
/**
 * Base Sorts: 'By Resident', 'By ADL', 'By Shift', 'By Staff'
 */
const BASE_SORTS = [
	{
		id: "By Resident",
		label: "By Resident",
	},
	{
		id: "By ADL",
		label: "By ADL",
	},
	{
		id: "By Shift",
		label: "By Shift",
	},
	{
		id: "By Staff",
		label: "By Staff",
	},
];
// settings.sortByXXXX sub options (ie 'Ascending'/'Descending')
const BASE_SORT_OPTIONS = {
	sortByResident: [
		{
			name: "sortByResident",
			id: "Ascending",
			label: "Ascending (A-Z)",
		},
		{
			name: "sortByResident",
			id: "Descending",
			label: "Descending (Z-A)",
		},
	],
	sortByADL: [],
	sortByShift: [], // no sub options
	sortByStaff: [
		{
			name: "sortByStaff",
			id: "Ascending",
			label: "Ascending (A-Z)",
		},
		{
			name: "sortByStaff",
			id: "Descending",
			label: "Descending (Z-A)",
		},
	],
	sortByFloorUnit: [], // no sub options

	sortByException: [
		// {
		// 	name: "sortByException",
		// 	id: "Resident Sleeping",
		// 	label: "Resident Sleeping",
		// },
		// {
		// 	name: "sortByException",
		// 	id: "Refused Care",
		// 	label: "Refused Care",
		// },
		// {
		// 	name: "sortByException",
		// 	id: "Not In Room",
		// 	label: "Not In Room",
		// },
		// {
		// 	name: "sortByException",
		// 	id: "Resident Unavailable",
		// 	label: "Resident Unavailable",
		// },
	],
};

// showStrikeOuts and useTimeZoneOverride (currently)
const BASE_OTHER_OPTIONS = {
	showStrikeOuts: {
		id: "showStrikeOuts",
		name: "showStrikeOuts",
		label: "Include Strike-Out Notes",
		default: true,
	},
	useTimeZoneOverride: {
		id: "useTimeZoneOverride",
		name: "useTimeZoneOverride",
		label: "Use Timezone Override (show dates in different timezone)",
		default: false,
		options: [...primaryTimezones],
		// options: [...getTimeZoneNames(timezones)],
	},
	hideNotes: {
		id: "hideNotes",
		name: "hideNotes",
		label: "Hide All Notes (including strike-outs)",
		default: false,
	},
};

////////////////////////////////////////////////////////////////////////
/////////////////////////// CUSTOM CONFIG(S) ///////////////////////////
////////////////////////////////////////////////////////////////////////

const COMBINED_CONFIG = {
	Daily: {
		filters: [...BASE_FILTERS],
		filterOptions: [],
		shiftOptions: [...BASE_SHIFT_OPTIONS],
		sorts: [],
		sortOptions: {},
	},
	Historical: {
		filters: [...BASE_FILTERS],
		filterOptions: [], // is custom populated in <XXXXXXXXReport/> view
		shiftOptions: [...BASE_SHIFT_OPTIONS],
		sorts: [...BASE_SORTS],
		sortOptions: { ...BASE_SORT_OPTIONS },
	},
};
//////////////////////////////////////////////
//// DAILY/HISTORICAL - COMPLETION REPORT ////
//////////////////////////////////////////////
const COMPLETION_FILTERS = [
	{
		name: "filterBy",
		id: "Resident",
		label: "By Resident",
	},
	{
		name: "filterBy",
		id: "Facility",
		label: "By Facility",
	},
	{
		name: "filterBy",
		id: "ADL",
		label: "By ADL",
	},
];
const COMPLETION_FILTER_OPTIONS = [...BASE_FILTER_OPTIONS];
const COMPLETION_SHIFT_OPTIONS = [...BASE_SHIFT_OPTIONS];
const COMPLETION_SORTS = [...BASE_SORTS];
const COMPLETION_SORT_OPTIONS = { ...BASE_SORT_OPTIONS };
const COMPLETION_OTHER_OPTIONS = { ...BASE_OTHER_OPTIONS };
// UPDATED CONFIG W/ "OTHER OPTIONS" - 7/24/2022
const COMPLETION_CONFIG = {
	Daily: {
		filters: [...COMPLETION_FILTERS],
		filterOptions: [],
		shiftOptions: [...COMPLETION_SHIFT_OPTIONS],
		sorts: [],
		sortOptions: [],
		otherOptions: {},
	},
	Historical: {
		filters: [...COMPLETION_FILTERS],
		filterOptions: [...COMPLETION_FILTER_OPTIONS],
		shiftOptions: [...COMPLETION_SHIFT_OPTIONS],
		sorts: [...COMPLETION_SORTS],
		sortOptions: { ...COMPLETION_SORT_OPTIONS },
		otherOptions: { ...COMPLETION_OTHER_OPTIONS },
	},
};
//////////////////////////////////////////////
//// DAILY/HISTORICAL - EXCEPTION REPORT ////
//////////////////////////////////////////////
const EXCEPTION_FILTERS = [...BASE_FILTERS];
const EXCEPTION_FILTER_OPTIONS = [...BASE_FILTER_OPTIONS];
const EXCEPTION_SHIFT_OPTIONS = [...BASE_SHIFT_OPTIONS];
// Resident, ADL, Staff, ExceptionType, WasCompleted, Shift
const EXCEPTION_SORTS = [
	...BASE_SORTS,
	{
		id: "By Exception Type",
		label: "By Exception Type",
	},
	{
		id: "Was Completed",
		label: "Was Completed",
	},
];
const EXCEPTION_SORT_OPTIONS = {
	...BASE_SORT_OPTIONS,
	sortByResident: [
		{
			name: "sortByResident",
			id: "Ascending",
			label: "Ascending (A-Z)",
		},
		{
			name: "sortByResident",
			id: "Descending",
			label: "Descending (Z-A)",
		},
	],
	sortByADL: [],
	sortByShift: [],
	// TO BE REMOVED AS THE 'Staff By' OPTION IS GONNA BE REMOVED TOO!!!
	sortByStaff: [
		{
			name: "sortByStaff",
			id: "Ascending",
			label: "Ascending (A-Z)",
		},
		{
			name: "sortByStaff",
			id: "Descending",
			label: "Descending (Z-A)",
		},
	],
	sortByExceptionType: [],
	sortWasCompleted: [],
};
const EXCEPTION_OTHER_OPTIONS = { ...BASE_OTHER_OPTIONS };
// UPDATED CONFIG W/ "OTHER OPTIONS" - 7/24/2022
const EXCEPTION_CONFIG = {
	Daily: {
		filters: [...COMPLETION_FILTERS],
		filterOptions: [],
		shiftOptions: [...COMPLETION_SHIFT_OPTIONS],
		sorts: [],
		sortOptions: [],
		otherOptions: {},
	},
	Historical: {
		filters: [...EXCEPTION_FILTERS],
		filterOptions: [...EXCEPTION_FILTER_OPTIONS],
		shiftOptions: [...EXCEPTION_SHIFT_OPTIONS],
		sorts: [...EXCEPTION_SORTS],
		sortOptions: { ...EXCEPTION_SORT_OPTIONS },
		otherOptions: { ...EXCEPTION_OTHER_OPTIONS },
	},
};

//////////////////////////////////////////////
/// DAILY/HISTORICAL - TASK-STATUS REPORT ///
//////////////////////////////////////////////
const TASKSTATUS_FILTERS = [...BASE_FILTERS];
const TASKSTATUS_FILTER_OPTIONS = [];
const TASKSTATUS_SHIFT_OPTIONS = [...BASE_SHIFT_OPTIONS];
const TASKSTATUS_SORTS = [...BASE_SORTS];
const TASKSTATUS_SORT_OPTIONS = {
	sortByResident: [
		{
			name: "sortByResident",
			id: "Ascending",
			label: "Ascending (A-Z)",
		},
		{
			name: "sortByResident",
			id: "Descending",
			label: "Descending (Z-A)",
		},
	],
	sortByADL: [],
	sortByShift: [],
	sortByStaff: [
		{
			name: "sortByStaff",
			id: "None",
			label: "No sorting",
		},
		{
			name: "sortByStaff",
			id: "Ascending",
			label: "Ascending (A-Z)",
		},
		{
			name: "sortByStaff",
			id: "Descending",
			label: "Descending (Z-A)",
		},
	],
	sortByFloorUnit: [],
};
// const TASKSTATUS_OTHER_OPTIONS = { ...BASE_OTHER_OPTIONS };
const TASKSTATUS_OTHER_OPTIONS = {};

const TASKSTATUS_CONFIG = {
	Daily: {
		filters: [],
		filterOptions: [],
		shiftOptions: [],
		sorts: [],
		sortOptions: {},
	},
	Historical: {
		filters: [...TASKSTATUS_FILTERS],
		filterOptions: [...TASKSTATUS_FILTER_OPTIONS],
		shiftOptions: [...TASKSTATUS_SHIFT_OPTIONS],
		sorts: [...TASKSTATUS_SORTS],
		sortOptions: { ...TASKSTATUS_SORT_OPTIONS },
		otherOptions: { ...TASKSTATUS_OTHER_OPTIONS },
	},
};

//////////////////////////////////////////////
///// DAILY/HISTORICAL - PAST-DUE REPORT /////
//////////////////////////////////////////////
const PASTDUE_FILTERS = [];
const PASTDUE_FILTER_OPTIONS = [];
const PASTDUE_SHIFT_OPTIONS = [];
const PASTDUE_SORTS = [];
const PASTDUE_SORT_OPTIONS = {};

const PASTDUE_CONFIG = {
	Daily: {
		filters: [...PASTDUE_FILTERS],
		filterOptions: [],
		shiftOptions: [],
		sorts: [],
		sortOptions: [],
	},
	Historical: {
		filters: [...PASTDUE_FILTERS],
		filterOptions: [...PASTDUE_FILTER_OPTIONS],
		shiftOptions: [...PASTDUE_SHIFT_OPTIONS],
		sorts: [...PASTDUE_SORTS],
		sortOptions: { ...PASTDUE_SORT_OPTIONS },
	},
};

//////////////////////////////////////////////////////////////////////////////
///// DAILY/HISTORICAL - REASSESS REPORT aka SERVICE PLAN UPDATE REPORT /////
//////////////////////////////////////////////////////////////////////////////
const REASSESS_FILTERS = [...BASE_FILTERS];
const REASSESS_FILTER_OPTIONS = [...BASE_FILTER_OPTIONS];
const REASSESS_SHIFT_OPTIONS = [...BASE_SHIFT_OPTIONS];
const REASSESS_SORTS = [
	{
		id: "By Resident",
		label: "By Resident",
	},
	{
		id: "By ADL",
		label: "By ADL",
	},
	{
		id: "By Staff",
		label: "By Staff",
	},
];
const REASSESS_SORT_OPTIONS = {
	sortByResident: [
		{
			name: "sortByResident",
			id: "Ascending",
			label: "Ascending (A-Z)",
		},
		{
			name: "sortByResident",
			id: "Descending",
			label: "Descending (Z-A)",
		},
	],
	sortByADL: [],
	sortByStaff: [
		{
			name: "sortByStaff",
			id: "Ascending",
			label: "Ascending (A-Z)",
		},
		{
			name: "sortByStaff",
			id: "Descending",
			label: "Descending (Z-A)",
		},
	],
};
const REASSESS_OTHER_OPTIONS = { ...BASE_OTHER_OPTIONS };
// UPDATED CONFIG W/ "OTHER OPTIONS" - 7/24/2022
const REASSESS_CONFIG = {
	Daily: {
		filters: [...REASSESS_FILTERS],
		filterOptions: [...REASSESS_FILTER_OPTIONS],
		shiftOptions: [...REASSESS_SHIFT_OPTIONS],
		sorts: [...REASSESS_SORTS],
		sortOptions: { ...REASSESS_SORT_OPTIONS },
		otherOptions: {},
	},
	Historical: {
		filters: [...REASSESS_FILTERS],
		filterOptions: [...REASSESS_FILTER_OPTIONS],
		shiftOptions: [...REASSESS_SHIFT_OPTIONS],
		sorts: [...REASSESS_SORTS],
		sortOptions: { ...REASSESS_SORT_OPTIONS },
		otherOptions: { ...REASSESS_OTHER_OPTIONS },
	},
};

//////////////////////////////////////////////
/// DAILY/HISTORICAL - TASK-CREATED REPORT ///
//////////////////////////////////////////////
const TASKCREATED_FILTERS = [
	{
		name: "filterBy",
		id: "Resident",
		label: "By Resident",
	},
	{
		name: "filterBy",
		id: "Facility",
		label: "By Facility",
	},
	{
		name: "filterBy",
		id: "ADL",
		label: "By ADL",
	},
];
const TASKCREATED_FILTER_OPTIONS = [...BASE_FILTER_OPTIONS];
const TASKCREATED_SHIFT_OPTIONS = [...BASE_SHIFT_OPTIONS];
const TASKCREATED_SORTS = [...BASE_SORTS];
const TASKCREATED_SORT_OPTIONS = { ...BASE_SORT_OPTIONS };

const TASKCREATED_CONFIG = {
	Daily: {
		filters: [...TASKCREATED_FILTERS],
		filterOptions: [...TASKCREATED_FILTER_OPTIONS],
		shiftOptions: [...TASKCREATED_SHIFT_OPTIONS],
		sorts: [...TASKCREATED_SORTS],
		sortOptions: { ...TASKCREATED_SORT_OPTIONS },
	},
	Historical: {
		filters: [...TASKCREATED_FILTERS],
		filterOptions: [...BASE_FILTER_OPTIONS],
		shiftOptions: [...TASKCREATED_SHIFT_OPTIONS],
		sorts: [],
		sortOptions: { ...TASKCREATED_SORT_OPTIONS },
	},
};

//////////////////////////////////////////////
/// DAILY/HISTORICAL - SERVICE-PLAN REPORT ///
//////////////////////////////////////////////
const SERVICEPLAN_FILTERS = [
	{
		name: "filterBy",
		id: "Resident",
		label: "By Resident",
	},
];
const SERVICEPLAN_FILTER_OPTIONS = [];
const SERVICEPLAN_SHIFT_OPTIONS = [];
const SERVICEPLAN_SORTS = [];
const SERVICEPLAN_SORT_OPTIONS = {};

const SERVICEPLAN_CONFIG = {
	Daily: {
		filters: [...SERVICEPLAN_FILTERS],
		filterOptions: [...SERVICEPLAN_FILTER_OPTIONS],
		shiftOptions: [...SERVICEPLAN_SHIFT_OPTIONS],
		sorts: [...SERVICEPLAN_SORTS],
		sortOptions: { ...SERVICEPLAN_SORT_OPTIONS },
	},
	Historical: {
		filters: [...SERVICEPLAN_FILTERS],
		filterOptions: [...BASE_FILTER_OPTIONS],
		shiftOptions: [...SERVICEPLAN_SHIFT_OPTIONS],
		sorts: [],
		sortOptions: { ...SERVICEPLAN_SORT_OPTIONS },
	},
};

// helpers
export { amendDailyConfig, amendHistoricalConfig, amendConfigMany };

export {
	BASE_FILTERS,
	BASE_FILTER_OPTIONS,
	BASE_SHIFT_OPTIONS,
	BASE_SORTS,
	BASE_SORT_OPTIONS,
	// NEWLY ADDED - 7/21/2022 at 10:40AM
	BASE_OTHER_OPTIONS,
};

// base/default config
export { COMBINED_CONFIG };

// custom config(s)
export {
	EXCEPTION_CONFIG,
	COMPLETION_CONFIG,
	TASKSTATUS_CONFIG,
	PASTDUE_CONFIG,
	REASSESS_CONFIG,
	TASKCREATED_CONFIG,
	SERVICEPLAN_CONFIG,
};

// REPORT 'CARDS' PREVIEW ASSETS & CONFIG
export { REPORT_PREVIEW_ASSETS, REPORTS_CONFIG };
