import React from "react";
import styles from "../../css/adlschedule/AdlRow.module.scss";
import { PropTypes } from "prop-types";
import { findShiftRecordsByShift } from "../../helpers/utils_adlSchedule";
import AdlShiftTile from "./AdlShiftTile";

const isDisabled = (adl, disabledADLs) => {
	return disabledADLs.includes(adl);
};

const AdlRow = ({
	adl,
	disabledADLs = [],
	shiftRecords = [],
	selectedResidents,
	currentUser,
}) => {
	return (
		<div className={styles.AdlRow}>
			<AdlShiftTile
				adl={adl}
				shift="AM"
				isDisabled={isDisabled(adl.Name, disabledADLs)}
				shiftModel={findShiftRecordsByShift("AM", shiftRecords)}
				selectedResidents={selectedResidents}
				currentUser={currentUser}
			/>
			<AdlShiftTile
				adl={adl}
				shift="PM"
				isDisabled={isDisabled(adl.Name, disabledADLs)}
				shiftModel={findShiftRecordsByShift("PM", shiftRecords)}
				selectedResidents={selectedResidents}
				currentUser={currentUser}
			/>
			<AdlShiftTile
				adl={adl}
				shift="NOC"
				isDisabled={isDisabled(adl.Name, disabledADLs)}
				shiftModel={findShiftRecordsByShift("NOC", shiftRecords)}
				selectedResidents={selectedResidents}
				currentUser={currentUser}
			/>
		</div>
	);
};

export default AdlRow;

AdlRow.defaultProps = {
	disabledADLs: [],
};

AdlRow.propTypes = {
	adl: PropTypes.shape({
		Name: PropTypes.string.isRequired, // main usage (ie "Bathing", "Dressing")
		AssessmentCategoryId: PropTypes.number.isRequired,
	}),
	disabledADLs: PropTypes.arrayOf(PropTypes.string).isRequired,
	currentUser: PropTypes.shape({
		token: PropTypes.string.isRequired,
	}),
};
