import React from "react";
import styles from "../../css/residentinfo/ResidentDetails.module.scss";
import { PropTypes } from "prop-types";

const ResidentDetails = ({
	profile = {},
	contacts = [],
	currentResident = {},
	title,
	children,
}) => {
	const withDetails = React.Children.map(children, (child, i) => {
		return React.cloneElement(child, {
			profile: profile,
			contacts: contacts,
			currentResident: currentResident,
		});
	});
	return (
		<section className={styles.ResidentDetails}>
			<div className={styles.ResidentDetails_top}>
				<h6 className={styles.ResidentDetails_top_title}>{title}</h6>
			</div>
			<div className={styles.ResidentDetails_main}>{withDetails}</div>
		</section>
	);
};

export default ResidentDetails;

ResidentDetails.defaultProps = {
	currentResident: {},
	profile: {},
	contacts: [],
};

ResidentDetails.propTypes = {
	title: PropTypes.string.isRequired,
	currentResident: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
	contacts: PropTypes.arrayOf(PropTypes.object),
	children: PropTypes.any.isRequired,
};
