import React, { useEffect, useRef } from "react";
import styles from "../../css/daily/DailySummaryList.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	getTaskID,
	getStatusDesc,
	sortTasksByShift,
} from "../../helpers/utils_tasks";
import { main } from "../../helpers/utils_styles";
// components
import DailySummaryListItem from "./DailySummaryListItem";

// determines show/hide state from 'status filters'
const shouldShowTask = (task, statusFilters = []) => {
	const status = getStatusDesc(task, new Date());
	const shouldShow = statusFilters.includes(status);
	return shouldShow;
};

const shouldPersistScroll = (listRef, scrollState = {}) => {
	return (
		!isEmptyVal(listRef.current) &&
		!isEmptyVal(scrollState.active) &&
		!isEmptyVal(scrollState.scrollPos)
	);
};

const DailySummaryList = ({
	tasks = [],
	trackingTasks = [],
	unscheduledTasksRaw = [],
	category = {},
	currentResident = {},
	currentUser = {},
	currentFacility = {},
	facilityExceptions = [],
	activeStatusFilters = [],
	dispatch,
	dispatchAlert,
	reloadResident,
	handleActive,
	scrollState = {},
}) => {
	let listRef = useRef();

	// persists scroll position after 'advanced' update
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (shouldPersistScroll(listRef, scrollState)) {
			listRef.current.scrollTop = scrollState.scrollPos;
		}

		return () => {
			isMounted = false;
		};
	}, [scrollState]);

	if (isEmptyArray(tasks)) {
		return (
			<span style={{ padding: "2rem 1rem", color: main.red }}>
				No {category.AdlCategoryType} tasks today
			</span>
		);
	}
	return (
		<ul className={styles.DailySummaryList} ref={listRef}>
			{!isEmptyArray(tasks) &&
				[...sortTasksByShift(tasks)].map((task, index) => (
					<DailySummaryListItem
						task={task}
						taskIndex={index + 1}
						dispatch={dispatch}
						key={`${getTaskID(task)}_${task?.AssessmentCategoryId}_${index}`}
						trackingTasks={trackingTasks}
						unscheduledTasksRaw={unscheduledTasksRaw}
						currentUser={currentUser}
						currentFacility={currentFacility}
						currentResident={currentResident}
						dispatchAlert={dispatchAlert}
						facilityExceptions={facilityExceptions}
						showTask={shouldShowTask(task, activeStatusFilters)}
						reloadResident={reloadResident}
						handleActive={handleActive}
						scrollState={scrollState}
						scrollPos={listRef?.current?.scrollTop}
						listRef={listRef}
						wasUpdated={
							getTaskID(task) === scrollState?.active && scrollState.wasUpdated
						}
					/>
				))}
		</ul>
	);
};

export default React.memo(DailySummaryList);

DailySummaryList.defaultProps = {
	trackingTasks: [],
	unscheduledTasksRaw: [],
	tasks: [],
	category: {},
	currentResident: {},
	facilityExceptions: [],
	activeStatusFilters: [],
};

DailySummaryList.propTypes = {
	tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
	category: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	dispatchAlert: PropTypes.func.isRequired,
	currentResident: PropTypes.object.isRequired,
	facilityExceptions: PropTypes.array.isRequired,
	activeStatusFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
	scrollState: PropTypes.shape({
		active: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.oneOf([null, undefined]),
		]),
		scrollPos: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.oneOf([null, undefined]),
		]),
	}),
};
