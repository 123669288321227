import React, { useState, useEffect } from "react";
import styles from "../../css/app/ResidentHeader.module.scss";
import { PropTypes } from "prop-types";
// helpers
import { formatResName, noResidentLoaded } from "../../helpers/utils_residents";
import { getFromStorage } from "../../helpers/utils_caching";
import { isEmptyVal } from "../../helpers/utils_types";
import { getResidentPhotoSrc } from "../../helpers/utils_residentData";
import { getIMGSource } from "../../helpers/utils_files";
// components
import ResidentPhoto from "./ResidentPhoto";

const ResidentHeader = ({ currentResident = {}, currentUser = {} }) => {
	const [photoSrc, setPhotoSrc] = useState("");

	// SUPPORTS CACHED PHOTOS FROM LOCALSTORAGE
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		const setResidentSrc = async () => {
			const { token } = currentUser;
			const { ResidentID } = currentResident;
			const cacheFile = getFromStorage(`Photo-${ResidentID}`);
			// if have file cache, but no photoSrc, then fetch source file
			if (!isEmptyVal(cacheFile?.photoID) && isEmptyVal(photoSrc)) {
				const url = await getIMGSource(token, cacheFile.photoID); // ← HERE'S THE ISSUE!!!
				return setPhotoSrc(url);
			}
			if (isEmptyVal(cacheFile?.photoID) && isEmptyVal(photoSrc)) {
				const src = await getResidentPhotoSrc(token, ResidentID);
				return setPhotoSrc(src);
			}
			return;
		};

		if (!isEmptyVal(currentResident?.ResidentID)) {
			setResidentSrc();
		}

		return () => {
			isMounted = false;
		};
	}, [currentResident, currentUser, photoSrc]);

	return (
		<section className={styles.ResidentHeader}>
			<section className={styles.ResidentHeader_photo}>
				<ResidentPhoto
					imgSrc={photoSrc}
					imgSize="MD"
					imgAlt="Resident Intake Photo"
				/>
			</section>
			<section className={styles.ResidentHeader_desc}>
				<span className={styles.ResidentHeader_desc_name}>
					{!noResidentLoaded(currentResident) ? (
						formatResName(currentResident)
					) : (
						<span className={styles.ResidentHeader_desc_name_empty}>
							No resident loaded.
						</span>
					)}
				</span>
				<p className={styles.ResidentHeader_desc_name_id}>
					ALA ID: {currentResident?.ResidentID}
				</p>
			</section>
		</section>
	);
};

export default ResidentHeader;

ResidentHeader.defaultProps = {
	currentResident: {},
};

ResidentHeader.propTypes = {
	currentResident: PropTypes.object.isRequired,
};
