import React, { useState, useEffect } from "react";
import styles from "../../css/calendar/ResidentDetails.module.scss";
import ResidentPhoto from "../app/ResidentPhoto";
import { PropTypes } from "prop-types";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { formatResName } from "../../helpers/utils_residents";
import { getResidentPhotoSrc } from "../../helpers/utils_residentData";
import { addEllipsis } from "../../helpers/utils_processing";
import { getFromStorage } from "../../helpers/utils_caching";
import { getFileBlob, createURL } from "../../helpers/utils_files";
import StatusLegend from "./StatusLegend";

// TODO:
// 1. add 'ServicePlanDue' to UI

// takes an object, a "property"(in that object), and a fallback value
// if the "property" is null/empty/undefined, then use "fallbackVal", else return property's val.
const getPropWithFallback = (prop, obj, fallbackVal) => {
	if (isEmptyVal(obj[prop])) {
		return fallbackVal;
	} else {
		return obj[prop];
	}
};

const ResidentDetails = ({ currentResident = {}, currentUser = {} }) => {
	const [photoSrc, setPhotoSrc] = useState("");

	// get resident photo; from cache, for fetch from ALA Svcs.
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		const setResidentSrc = async () => {
			const { token } = currentUser;
			const { ResidentID } = currentResident;
			const cacheFile = getFromStorage(`Photo-${ResidentID}`);

			if (!isEmptyVal(cacheFile?.photoID)) {
				const fileBlob = await getFileBlob(token, cacheFile.photoID);
				return setPhotoSrc(createURL(fileBlob));
			}

			const src = await getResidentPhotoSrc(token, ResidentID);
			return setPhotoSrc(src);
		};

		if (!isEmptyObj(currentResident)) {
			setResidentSrc();
		}

		return () => {
			isMounted = false;
		};
	}, [currentResident, currentUser]);

	return (
		<section className={styles.ResidentDetails}>
			<ResidentPhoto
				imgSrc={photoSrc}
				imgAlt="Resident Intake Photo"
				imgSize="MD"
			/>
			<div className={styles.ResidentDetails_resInfo}>
				{/* RESIDENT NAME */}
				<h4
					className={styles.ResidentDetails_resInfo_name}
					title={formatResName(currentResident)}
				>
					{!isEmptyVal(currentResident.ResidentID)
						? addEllipsis(formatResName(currentResident), 25)
						: "No resident loaded"}
				</h4>
				{/* RESIDENT AGE */}
				<p className={styles.ResidentDetails_resInfo_detail}>
					Age: {getPropWithFallback("Age", currentResident, "")}
				</p>
				{/* RESIDENT ROOM NUMBER */}
				<p className={styles.ResidentDetails_resInfo_detail}>
					Room: {getPropWithFallback("RoomNum", currentResident, "")}
				</p>
				{/* RESIDENT FLOOR UNIT */}
				<p className={styles.ResidentDetails_resInfo_detail}>
					Unit: {getPropWithFallback("FloorUnit", currentResident, "")}
				</p>
			</div>
			{/* STATUS BADGES LEGEND */}
			<div className={styles.ResidentDetails_legend}>
				<StatusLegend />
			</div>
		</section>
	);
};

export default ResidentDetails;

ResidentDetails.defaultProps = {
	currentResident: {},
	currentUser: {},
};

ResidentDetails.propTypes = {
	currentResident: PropTypes.object.isRequired,
	currentUser: PropTypes.object.isRequired,
};
