import React from "react";
import styles from "../../css/reports/ReportsCard.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import sprite2 from "../../assets/icons/alerts.svg";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import { isEmptyVal } from "../../helpers/utils_types";
// component
import ImgWithFallback from "../shared/ImgWithFallback";

const ICONS = {
	close: "clearclose",
	edit: "createmode_editedit",
	archive: "inventory",
	alarm: "access_alarmalarm",
	attachment: "attachment21",
	save: "save11",
	openBook: "open-book",
	upload: "cloud_uploadbackup",
	download: "cloud_download",
	cloud: "cloud5",
	chartDark: "insert_chartpollassessment",
	chartLight: "insert_chart_outlined",
	print: "print1",
	logout: "log-out",
	login: "login",
	search: "magnifying-glass",
	menu: "menu1111",
	lockOpen: "lock-open111",
	lockClosed: "lock11",
	add: "plus21",
	minus: "minus21",
	news: "news",
	tuner: "sound-mix",
	stopwatch: "stopwatch",
	arrowLeft: "arrow_left",
	arrowRight: "arrow_right",
	arrowDown: "arrow_drop_down",
	arrowUp: "arrow_drop_up",
	checkWithCircle: "check_circle",
	checkbox: "check_box",
};

const ReportsCard = ({
	name,
	route,
	icon,
	previewSrc = null,
	fallbackSrc = null,
	isDisabled = false,
	history,
	children,
}) => {
	const goToRoute = (route) => {
		return history.push({
			pathname: route,
			state: {
				from: history.location.pathname,
			},
		});
	};
	return (
		<button
			disabled={isDisabled}
			className={styles.ReportsCard}
			onClick={() => goToRoute(route)}
		>
			<section className={styles.ReportsCard_report}>
				<svg className={styles.ReportsCard_report_cardIcon}>
					<use xlinkHref={`${sprite}#icon-${ICONS[icon]}`}></use>
				</svg>
				<h4 className={styles.ReportsCard_report_name}>{name}</h4>
			</section>
			<section className={styles.ReportsCard_content}>{children}</section>
			<section className={styles.ReportsCard_preview}>
				{isEmptyVal(previewSrc) && (
					<>
						<svg className={styles.ReportsCard_preview_fallbackIcon}>
							<use xlinkHref={`${sprite2}#icon-warning`}></use>
						</svg>
						<h6 className={styles.ReportsCard_preview_fallbackTitle}>
							{isDisabled ? `Not Available` : `Report Preview Coming Soon!`}
						</h6>
					</>
				)}
				{/* WEBP W/ FALLBACK */}
				{!isEmptyVal(previewSrc) && (
					<ImgWithFallback
						alt={`${name} report description`}
						type="image/webp"
						src={previewSrc}
						fallback={fallbackSrc}
						size="FULL"
						fit="contain"
					/>
				)}
			</section>
		</button>
	);
};

export default withRouter(ReportsCard);

ReportsCard.defaultProps = {
	previewSrc: null,
	isDisabled: false,
};

ReportsCard.propTypes = {
	name: PropTypes.string.isRequired,
	route: PropTypes.string.isRequired,
	icon: PropTypes.string,
	previewSrc: PropTypes.string,
	fallbackSrc: PropTypes.string,
	isDisabled: PropTypes.bool,
	history: PropTypes.object,
	children: PropTypes.any,
};
