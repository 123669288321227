import React, { useState, useEffect } from "react";
import styles from "../../css/calendar/ADLShiftDetails.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
// UTILS
import { PropTypes } from "prop-types";
import {
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
} from "../../helpers/utils_types";
import { addEllipsis } from "../../helpers/utils_processing";
import { formatWithFullDay } from "../../helpers/utils_dates";
import { formatResName } from "../../helpers/utils_residents";
import {
	getTaskName,
	getTaskTypeDesc,
	getTaskDueDate,
	getTaskDesc,
	getTaskDate,
	getTaskDateInWords,
} from "../../helpers/utils_tasks";
import { statusReducer, statusHandler } from "../../helpers/utils_styles";
import {
	hasException,
	formatExceptionDesc,
} from "../../helpers/utils_exceptions";
import { getShiftName } from "../../helpers/utils_shifts";
import { getFromStorage } from "../../helpers/utils_caching";
import { getResidentPhotoSrc } from "../../helpers/utils_residentData";
import { getFileBlob, createURL } from "../../helpers/utils_files";
// COMPONENTS
import ResidentPhoto from "../app/ResidentPhoto";
import ShiftDetailsTaskList from "./ShiftDetailsTaskList";

// ##TODOS:
// - Include more accurate date/time in words for task's due date. ✓

const ADLShiftDetails = ({
	day,
	shift,
	adl,
	selectedTask = {},
	selectedTaskIndex,
	allTasksForShift = [],
	currentFacility = {},
	currentResident = {},
	currentUser = {},
	changeSelected,
}) => {
	const [photoSrc, setPhotoSrc] = useState("");

	const { shifts } = currentFacility;

	// get resident photo; from cache, for fetch from ALA Svcs.
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		const setResidentSrc = async () => {
			const { token } = currentUser;
			const { ResidentID } = currentResident;
			const cacheFile = getFromStorage(`Photo-${ResidentID}`);

			if (!isEmptyVal(cacheFile?.photoID)) {
				const fileBlob = await getFileBlob(token, cacheFile.photoID);
				return setPhotoSrc(createURL(fileBlob));
			}

			const src = await getResidentPhotoSrc(token, ResidentID);
			return setPhotoSrc(src);
		};

		if (!isEmptyObj(currentResident)) {
			setResidentSrc();
		}

		return () => {
			isMounted = false;
		};
	}, [currentResident, currentUser]);

	return (
		<article className={styles.ADLShiftDetails}>
			<section className={styles.ADLShiftDetails_residentInfo}>
				<div className={styles.ADLShiftDetails_residentInfo_photo}>
					<ResidentPhoto
						imgSrc={photoSrc}
						imgAlt="Resident Intake Photo"
						imgSize="LG"
					/>
				</div>
				<div className={styles.ADLShiftDetails_residentInfo_name}>
					<h3 className={styles.ADLShiftDetails_residentInfo_name_title}>
						{addEllipsis(formatResName(currentResident), 25)}
					</h3>
					<p className={styles.ADLShiftDetails_residentInfo_name_subtitle}>
						ALA ID: {currentResident.ResidentID}
					</p>
					<p className={styles.ADLShiftDetails_residentInfo_name_subtitle}>
						Room: {currentResident.RoomNum}
					</p>
					<p className={styles.ADLShiftDetails_residentInfo_name_subtitle}>
						Unit: {currentResident.FloorUnit}
					</p>
				</div>
			</section>
			<hr style={{ opacity: ".4", margin: "2rem 0" }} />
			{/* ADL, SHIFT, HAS-EXCEPTION, FULL DAY/DATE */}
			<section className={styles.ADLShiftDetails_top}>
				<h4 className={styles.ADLShiftDetails_top_title}>
					{adl}, {shift} Shift
				</h4>
				{hasException(selectedTask) && (
					<div className={styles.ADLShiftDetails_top_hasException}>
						<div className={styles.ADLShiftDetails_top_hasException_text}>
							Exception
						</div>
						<svg className={styles.ADLShiftDetails_top_hasException_icon}>
							<use xlinkHref={`${sprite}#icon-error_outline`}></use>
						</svg>
					</div>
				)}
				<span className={styles.ADLShiftDetails_top_date}>
					{formatWithFullDay(day)}
				</span>
			</section>
			<hr style={{ opacity: ".4", margin: "1rem 0" }} />
			{!isEmptyArray(allTasksForShift) ? (
				// ONLY SHOW IF THERE'S AT LEAST 1 TASK!!
				<section className={styles.ADLShiftDetails_details}>
					<div className={styles.ADLShiftDetails_details_heading}>
						<svg className={styles.ADLShiftDetails_details_heading_icon}>
							<use xlinkHref={`${sprite}#icon-event_note`}></use>
						</svg>
						<h6 className={styles.ADLShiftDetails_details_heading_title}>
							Task Details - <i>{getTaskTypeDesc(selectedTask)}</i>
						</h6>
						<span className={styles.ADLShiftDetails_details_heading_taskIndex}>
							{" "}
							<b> -</b> {selectedTaskIndex}
						</span>
					</div>
					{/* TASK NAME */}
					<div className={styles.ADLShiftDetails_details_section}>
						<p className={styles.ADLShiftDetails_details_section_item}>
							Task Name: <i>{getTaskName(selectedTask, 100)}</i>
						</p>
					</div>
					{/* TASK DESCRIPTION */}
					<div className={styles.ADLShiftDetails_details_section}>
						<p className={styles.ADLShiftDetails_details_section_item}>
							Description: <i>{getTaskDesc(selectedTask, 100)}</i>
						</p>
					</div>
					{/* EXCEPTION, IF APPLICABLE */}
					{hasException(selectedTask) && (
						<div className={styles.ADLShiftDetails_details_section}>
							<div className={styles.ADLShiftDetails_details_section_item}>
								<div
									className={
										styles.ADLShiftDetails_details_section_item_exception
									}
								>
									Exception:{" "}
									<b>{formatExceptionDesc(selectedTask.Exception)}</b>
								</div>
							</div>
						</div>
					)}
					{/* SHIFT SCHEDULING */}
					<div className={styles.ADLShiftDetails_details_section}>
						<p className={styles.ADLShiftDetails_details_section_item}>
							Shift: <i>{getShiftName(selectedTask.AssessmentShiftId)}</i>
						</p>
					</div>
					{/* TASK NOTES */}
					<div className={styles.ADLShiftDetails_details_section}>
						<p className={styles.ADLShiftDetails_details_section_item}>
							{/* Task Notes: <i>{selectedTask.TaskNotes}</i> */}
							<span>TaskNotes:</span>
							<textarea
								className={styles.ADLShiftDetails_details_section_item_notes}
								name="taskNotes"
								id="taskNotes"
								placeholder={selectedTask?.TaskNotes}
								readOnly={true}
							></textarea>
						</p>
					</div>
					{/* STATUS BADGE */}
					<div className={styles.ADLShiftDetails_details_section}>
						<div className={styles.ADLShiftDetails_details_section_item_status}>
							Current Status:{" "}
							<div
								className={
									styles.ADLShiftDetails_details_section_item_status_badge
								}
								style={statusReducer(statusHandler(selectedTask, day, shifts))}
							>
								<span>{statusHandler(selectedTask, day, shifts)}</span>
							</div>
						</div>
					</div>
					{/* TASK DATE IN WORDS */}
					<div className={styles.ADLShiftDetails_details_section}>
						<p className={styles.ADLShiftDetails_details_section_item}>
							Due: <i>{getTaskDateInWords(selectedTask, day, shifts)}</i>
						</p>
					</div>
				</section>
			) : (
				// ONLY SHOW IF THERE NO TASKS!!
				<section className={styles.ADLShiftDetails_empty}>
					<h4 className={styles.ADLShiftDetails_empty_msg}>
						No {adl} tasks found for the {shift} shift
					</h4>
				</section>
			)}
			{allTasksForShift && allTasksForShift.length > 1 && (
				<>
					<hr style={{ opacity: ".4", margin: "1rem 0" }} />
					<section className={styles.ADLShiftDetails_tasksSection}>
						<ShiftDetailsTaskList
							adl={adl}
							day={day}
							shift={shift}
							tasks={allTasksForShift}
							selectedTask={selectedTask}
							changeSelected={changeSelected}
							currentFacility={currentFacility}
						/>
					</section>
				</>
			)}
		</article>
	);
};

export default ADLShiftDetails;

ADLShiftDetails.defaultProps = {
	tasks: [],
	currentResident: {},
	currentFacility: {},
};

ADLShiftDetails.propTypes = {
	day: PropTypes.instanceOf(Date).isRequired,
	shift: PropTypes.string.isRequired,
	adl: PropTypes.string.isRequired,
	tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
	currentResident: PropTypes.object.isRequired,
	currentFacility: PropTypes.object.isRequired,
};
