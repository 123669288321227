import React, { useState } from "react";
import styles from "../../css/daily/FloatingFiltersButton.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
// components
import FiltersPanel from "./FiltersPanel";

const open = `clearclose`;
const closed = `tune`;

const FloatingFiltersButton = ({
	vals = {},
	filters = {},
	clearAllVal,
	selectAllVal,
	handleFilters,
	handleSelectAll,
	handleClearAll,
}) => {
	const [showFilters, setShowFilters] = useState(false);

	return (
		<aside className={styles.FloatingFiltersButton}>
			<button
				className={
					showFilters
						? styles.FloatingFiltersButton_floatingBtn_close
						: styles.FloatingFiltersButton_floatingBtn
				}
				onClick={() => setShowFilters(!showFilters)}
			>
				<svg className={styles.FloatingFiltersButton_floatingBtn_icon}>
					<use xlinkHref={`${sprite}#icon-${showFilters ? open : closed}`} />
				</svg>
			</button>

			{showFilters && (
				<FiltersPanel
					vals={vals}
					allFilters={filters}
					selectAllVal={selectAllVal}
					clearAllVal={clearAllVal}
					handleFilters={handleFilters}
					handleSelectAll={handleSelectAll}
					handleClearAll={handleClearAll}
				/>
			)}
		</aside>
	);
};

export default FloatingFiltersButton;

FloatingFiltersButton.defaultProps = {
	vals: {},
	filters: [],
};

FloatingFiltersButton.propTypes = {
	selectAllVal: PropTypes.bool.isRequired,
	vals: PropTypes.object.isRequired,
	filters: PropTypes.object,
	handleFilters: PropTypes.func.isRequired,
	handleSelectAll: PropTypes.func.isRequired,
};
