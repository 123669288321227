import React from "react";
import styles from "../../css/tables/TableCell.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";

const getMaxLength = (str, maxLength = 30) => {
	if (isEmptyVal(str)) return "";
	if (str?.length <= maxLength) return str;
	return str?.slice(0, maxLength);
};

const TableCell = ({
	cellData,
	handleCell,
	maxLength = 500,
	customStyles = {},
}) => {
	return (
		<div
			className={styles.TableCell}
			onClick={() => {
				handleCell(cellData);
			}}
			style={customStyles}
		>
			<div className={styles.TableCell_content}>
				{getMaxLength(cellData, maxLength)}
			</div>
		</div>
	);
};

export default TableCell;

TableCell.defaultProps = {
	content: null,
	customStyles: {},
};

TableCell.propTypes = {
	content: PropTypes.string,
	handleCell: PropTypes.func,
	customStyles: PropTypes.object,
	children: PropTypes.any,
};
