import React from "react";
import styles from "../../css/dashboard/DashboardNav.module.scss";
import sprite from "../../assets/icons/carets-arrows.svg";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";

const showReportLabels = (history) => {
	if (history.location.pathname.includes("/dashboard/reports/")) {
		return true;
	}
	return false;
};

const showBackBtn = (history) => {
	const { state } = history.location;
	const from = state?.from;
	const loginPage = "/";
	// last route & current route
	if (history.length < 3 || from === loginPage) {
		return false;
	} else {
		return true;
	}
};

const DashboardNav = ({ history, backLabel = null, forwardLabel = null }) => {
	const goBack = () => {
		return history.goBack();
	};
	const goForward = () => {
		return history.goForward();
	};

	history.listen((location, action) => {
		if (location.pathname && location.pathname.includes("/dashboard")) {
			// remove in PRODUCTION
		}
	});

	return (
		<nav className={styles.DashboardNav}>
			{showBackBtn(history) && (
				<button className={styles.DashboardNav_backBtn} onClick={goBack}>
					<svg className={styles.DashboardNav_backBtn_icon}>
						<use xlinkHref={`${sprite}#icon-arrow_back`}></use>
					</svg>
					{backLabel && showReportLabels(history) && (
						<span className={styles.DashboardNav_backBtn_backLabel}>
							{backLabel}
						</span>
					)}
				</button>
			)}
			{history && history.action === "POP" && (
				<button className={styles.DashboardNav_forwardBtn} onClick={goForward}>
					<svg className={styles.DashboardNav_forwardBtn_icon}>
						<use xlinkHref={`${sprite}#icon-arrow_forward`}></use>
					</svg>
					{forwardLabel && showReportLabels(history) && (
						<span className={styles.DashboardNav_forwardBtn_forwardLabel}>
							{forwardLabel}
						</span>
					)}
				</button>
			)}
		</nav>
	);
};

export default withRouter(DashboardNav);

DashboardNav.defaultProps = {};

DashboardNav.propTypes = {
	history: PropTypes.object.isRequired,
};
