import React from "react";
import styles from "../../css/timeview/TimeViewResidentBadge.module.scss";
import { PropTypes } from "prop-types";

const TimeViewResidentBadge = ({ task = {}, residentInfo = {} }) => {
	return (
		<div className={styles.TimeViewResidentBadge}>
			<div className={styles.TimeViewResidentBadge_roomNum}>
				{residentInfo?.RoomNum}
			</div>
		</div>
	);
};

export default TimeViewResidentBadge;

TimeViewResidentBadge.defaultProps = {};

TimeViewResidentBadge.propTypes = {};
