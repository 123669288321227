import React, { useRef, useEffect } from "react";
import styles from "../../css/app/ForgotPasswordModal.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useLockBodyScroll } from "../../utils/useLockBodyScroll";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";

const ForgotPasswordModal = ({ user = {}, closeModal, children }) => {
	const modalRef = useRef();
	const { isOutside } = useOutsideClick(modalRef);
	const wasEscaped = useKeyboardShortcut(["Escape"]);
	useLockBodyScroll();

	// close modal
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside || wasEscaped) {
			closeModal();
		}

		return () => {
			isMounted = false;
		};
	}, [closeModal, isOutside, wasEscaped]);

	return (
		<aside className={styles.ForgotPasswordModal} ref={modalRef}>
			<div className={styles.ForgotPasswordModal_top}>
				<div className={styles.ForgotPasswordModal_top_title}>
					Reset Password
				</div>
				<div
					className={styles.ForgotPasswordModal_top_close}
					onClick={closeModal}
				>
					<svg className={styles.ForgotPasswordModal_top_close_icon}>
						<use xlinkHref={`${sprite}#icon-clearclose`}></use>
					</svg>
				</div>
			</div>
			<div className={styles.ForgotPasswordModal_main}>{children}</div>
		</aside>
	);
};

export default ForgotPasswordModal;

ForgotPasswordModal.defaultProps = {};

ForgotPasswordModal.propTypes = {};
