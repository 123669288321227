import React from "react";
import styles from "../../css/daily/AllCompletedBanner.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";

const AllCompletedBanner = ({ taskCount = 0 }) => {
	return (
		<div className={styles.AllCompletedBanner}>
			<div className={styles.AllCompletedBanner_top}>
				<svg className={styles.AllCompletedBanner_top_icon}>
					<use xlinkHref={`${sprite}#icon-check_circle`} />
				</svg>
				<div className={styles.AllCompletedBanner_top_title}>
					All Complete for Today!
				</div>
			</div>
			<div className={styles.AllCompletedBanner_count}>
				<div className={styles.AllCompletedBanner_count_text}>
					<b>{taskCount}</b> Tasks Completed!
				</div>
			</div>
		</div>
	);
};

export default AllCompletedBanner;

AllCompletedBanner.defaultProps = {
	taskCount: 0,
};

AllCompletedBanner.propTypes = {
	taskCount: PropTypes.number.isRequired,
	viewTasksHandler: PropTypes.func,
};
