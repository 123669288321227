import { getEnvName, isDevOrTest } from "./utils_env";

/**
 * Application IDs: unique identifiers in the 'Application' table.
 */
const appIDs = {
	CareManager: 1,
	SeniorCareVB: 1,
	CareTracker: 2,
	AdvantageTracker: 2,
	AdminPortal: 3,
	ALAServices: 4,
	AccufloInterface: 5,
};
const appNames = {
	1: "SeniorCareVB",
	2: "AdvantageTracker",
	3: "AdminPortal",
	4: "ALAServices",
	5: "AccufloInterface",
};
const SERVICES = {
	SeniorCareVB: {
		appID: 1,
		primary: "Care Manager",
		alias: "SeniorCareVB",
		name: "Senior Care VB",
		desc: "EHR Care Platform",
		url: {
			test: "https://test.aladvantage.com/Account/Login.aspx",
			prod: "https://app.aladvantage.com/Account/Login.aspx",
		},
	},
	AdvantageTracker: {
		appID: 2,
		primary: "Care Tracker",
		alias: "AdvantageTracker",
		name: "Care Tracker",
		desc: "Care Tracking & Reporting Platform",
		url: {
			local: "http://localhost:3001", // TEST ENV ONLY!!!
			test: "https://trackertest.aladvantage.com",
			prod: "https://tracker.aladvantage.com",
		},
	},
	AdminPortal: {
		appID: 3,
		alias: "AdminPortal",
		name: "ALA Admin Portal",
		desc: "Portal for ALA app settings, user management and single-sign-on (SSO).",
		url: {
			local: "http://localhost:3000",
			test: "https://portaltest.aladvantage.com",
			prod: "https://portal.aladvantage.com",
		},
	},
	ALAServices: {
		appID: 4,
		alias: "ALAServices",
		name: "ALA Services",
		desc: "Web services for connecting to ALA's backend infrastructure.",
		url: {
			prod: "https://api.aladvantage.com/alaservices/v1",
			test: "https://apitest.aladvantage.com/alaservices/v1",
		},
	},
	AccufloInterface: {
		appID: 5,
		alias: "AccufloInterface",
		name: "Accu-Flow Interface",
		desc: "Third-party interface for transmitting resident data.",
		url: null,
	},
};

// REDIRECT TO APP UTILS //

// determines target app's URL based of 'targetAppName' and the 'currentEnv'
const getBaseUrl = (targetAppName) => {
	switch (targetAppName) {
		case "CareTracker":
		case "Care Tracker":
		case "AdvantageTracker":
		case "Advantage Tracker": {
			const urls = SERVICES["AdvantageTracker"].url;
			const base = urls[isDevOrTest() ? "test" : "prod"];
			return base;
		}
		case "CareManager":
		case "Care Manager":
		case "EHR: Care Manager":
		case "SeniorCareVB":
		case "Senior Care VB": {
			const urls = SERVICES["SeniorCareVB"].url;
			const base = urls[isDevOrTest() ? "test" : "prod"];
			return base;
		}
		case "CarePortal":
		case "Care Portal":
		case "AdminPortal":
		case "Admin Portal": {
			// const urls = SERVICES["AdminPortal"].url;
			// const base = urls[isDevOrTest() ? "test" : "prod"];
			// return base;
			const urls = SERVICES["AdminPortal"].url;
			const base = urls[getEnvName()];
			return base;
		}

		default:
			return;
	}
};

export { SERVICES, appIDs, appNames };
export { getBaseUrl };
