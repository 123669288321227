import React, { useCallback } from "react";
import styles from "../../css/calendar/DailyADL.module.scss";
import { PropTypes } from "prop-types";
import {
	groupTasksByShift,
	findAllTasksByAdl,
} from "../../helpers/utils_tasks";
import { format } from "date-fns";
import { generateID } from "../../helpers/utils_processing";
// COMPONENTS
import Shift from "./Shift";

const DailyADL = ({
	day,
	isToday = false,
	categories = [],
	tasks = [],
	currentResident = {},
	currentFacility = {},
	currentUser = {},
}) => {
	// map thru each adl category,
	// and check for tasks that match that adl and day,
	// groups tasks by their shift & pass to relevant component
	const generateUI = useCallback(
		(tasks, adlCategories, day) => {
			return adlCategories.map((adl, index) => {
				// updated for both scheduled & unscheduled
				// UPDATED 8/17/2020 @ 8:08 AM
				// const adlTasks = findAllTasksByAdlAndDay(
				// 	adl.AdlCategoryType,
				// 	format(day, "dddd"),
				// 	tasks
				// );
				const adlTasks = findAllTasksByAdl(adl.AdlCategoryType, tasks);

				const { AM, PM, NOC } = groupTasksByShift(adlTasks);

				return (
					<div
						className={styles.DailyADL_shifts}
						key={`${format(day, "ddd")}_${generateID(10)}`}
					>
						<Shift
							shift="AM"
							adl={adl.AdlCategoryType}
							tasks={AM}
							day={day}
							currentFacility={currentFacility}
							currentResident={currentResident}
							currentUser={currentUser}
							key={`${format(day, "x")}_2`}
						/>
						<Shift
							shift="PM"
							adl={adl.AdlCategoryType}
							tasks={PM}
							day={day}
							currentFacility={currentFacility}
							currentResident={currentResident}
							currentUser={currentUser}
							key={`${format(day, "x")}_3`}
						/>
						<Shift
							shift="NOC"
							adl={adl.AdlCategoryType}
							tasks={NOC}
							day={day}
							currentFacility={currentFacility}
							currentResident={currentResident}
							currentUser={currentUser}
							key={`${format(day, "X")}_4`}
						/>
					</div>
				);
			});
		},
		[currentFacility, currentResident, currentUser]
	);

	return (
		<div
			// ##TODOS: Enable 'Today' highlight - uncomment the below
			className={isToday ? styles.TodaysDailyADL : styles.DailyADL}
			// className={styles.DailyADL}
			data-day={format(day, "dddd")}
		>
			{generateUI(tasks, categories, day)}
		</div>
	);
};

export default DailyADL;

DailyADL.defaultProps = {
	tasks: [],
	categories: [],
	currentResident: {},
	currentFacility: {},
};

DailyADL.propTypes = {
	day: PropTypes.instanceOf(Date).isRequired,
	categories: PropTypes.arrayOf(PropTypes.object).isRequired,
	tasks: PropTypes.arrayOf(PropTypes.object),
	currentResident: PropTypes.object.isRequired,
	currentFacility: PropTypes.object.isRequired,
};
