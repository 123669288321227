import React from "react";
import styles from "../../css/residentinfo/ResidentMeds.module.scss";
import { PropTypes } from "prop-types";
import { themeColors } from "../../helpers/utils_styles";

// RESIDENT: MEDICAL INFO
// VALUES: Primary Diagnosis, Secondary Diagnosis, Allergies

const ResidentMeds = ({ profile = {} }) => {
	return (
		<section className={styles.ResidentMeds}>
			<div className={styles.ResidentMeds_cell}>
				<span>
					<b>Primary Diagnosis:</b> {profile.Diagnosis}
				</span>
				<span>
					<b>Secondary Diagnosis:</b> {profile.DiagnosisTwo}
				</span>
			</div>
			<hr style={{ opacity: ".4", margin: "1rem 0" }} />
			<div className={styles.ResidentMeds_cell}>
				<span style={{ color: themeColors.main.red }}>
					<b>Allergies:</b> {profile.Alergies}
				</span>
			</div>
		</section>
	);
};

export default ResidentMeds;

ResidentMeds.defaultProps = {
	profile: {}
};

ResidentMeds.propTypes = {
	profile: PropTypes.object.isRequired
};
