import React from "react";
import styles from "../../css/tables/TableBody.module.scss";
import { PropTypes } from "prop-types";

const TableBody = ({ children }) => {
	return (
		<section className={styles.TableBody}>
			<div className={styles.TableBody_rows}>{children}</div>
		</section>
	);
};

export default TableBody;

TableBody.defaultProps = {};

TableBody.propTypes = {
	children: PropTypes.any,
};
